import React, { useState, useEffect, useMemo } from "react";
import { Card, Table, Radio } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween"; // Import the isBetween plugin
import { getVendorName } from "../../Formatters/getVendorName";
import PurchaseOrderModal from "./PurchaseOrderModal";
import { getProjectName } from "../../Formatters/getProjectName";
import { getFirstNameFromEmail } from "../../Formatters/getNameFromEmail";
import { SearchInput } from "../../Styled/SearchInput";

dayjs.extend(isBetween); // Extend dayjs with isBetween plugin

const ExpectedTableCard = ({ purchaseOrders, vendors, projects }) => {
  const [viewMode, setViewMode] = useState("PO");
  const [filteredPOs, setFilteredPOs] = useState([]);
  const [filteredLineItems, setFilteredLineItems] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // State variables to hold the original unfiltered data
  const [allExpectedPOs, setAllExpectedPOs] = useState([]);

  // State variables to store search terms
  const [searchTermPOs, setSearchTermPOs] = useState("");
  const [searchTermLineItems, setSearchTermLineItems] = useState("");

  useEffect(() => {
    const startOfWeek = dayjs().startOf("week");
    const endOfWeek = dayjs().endOf("week");

    // Filter POs within the current week and exclude those where all line items are fully received
    const expectedPOs = purchaseOrders.filter(
      (po) =>
        po.requiredDate &&
        dayjs(po.requiredDate).isBetween(startOfWeek, endOfWeek) &&
        po.lines.some((line) => (line.qtyReceived || 0) < line.qtyOrdered)
    );

    // Set the original datasets
    setAllExpectedPOs(expectedPOs);
  }, [purchaseOrders]);

  // Determine if any PO has shipToAddress
  const hasShipToAddressPO = allExpectedPOs.some(
    (po) => po.shipToAddress && po.shipToAddress.trim() !== ""
  );

  // Apply search filter for POs
  useEffect(() => {
    const value = searchTermPOs.toLowerCase();
    if (value) {
      const filtered = allExpectedPOs.filter((po) =>
        [
          po.poNumber,
          getVendorName(po.vendor, vendors),
          getProjectName(po.projectNumber, projects),
          getFirstNameFromEmail(po.buyer),
          hasShipToAddressPO ? po.shipToAddress : null, // Include shipToAddress in search
        ]
          .filter(Boolean)
          .some((field) => field.toLowerCase().includes(value))
      );
      setFilteredPOs(filtered);
    } else {
      setFilteredPOs(allExpectedPOs);
    }
  }, [searchTermPOs, allExpectedPOs, vendors, projects, hasShipToAddressPO]);

  // Apply search filter for LineItems
  useEffect(() => {
    const value = searchTermLineItems.toLowerCase();

    // Generate line items from current allExpectedPOs
    const lineItems = getLineItems(allExpectedPOs);

    // Determine if any LineItem has shipToAddress
    const hasShipToAddressLineItem = lineItems.some(
      (item) => item.shipToAddress && item.shipToAddress.trim() !== ""
    );

    if (value) {
      const filtered = lineItems.filter((item) =>
        [
          item.poNumber,
          item.item, // Item Description
          getVendorName(item.vendor, vendors),
          getProjectName(item.projectNumber, projects),
          getFirstNameFromEmail(item.buyer),
          hasShipToAddressLineItem ? item.shipToAddress : null, // Include shipToAddress in search
        ]
          .filter(Boolean)
          .some((field) => field.toLowerCase().includes(value))
      );
      setFilteredLineItems(filtered);
    } else {
      setFilteredLineItems(lineItems);
    }
  }, [searchTermLineItems, allExpectedPOs, vendors, projects]);

  const handleSearchPOs = (e) => {
    setSearchTermPOs(e.target.value);
  };

  const handleSearchLineItems = (e) => {
    setSearchTermLineItems(e.target.value);
  };

  const handleViewModeChange = (e) => {
    const newViewMode = e.target.value;
    setViewMode(newViewMode);

    // Reset the search term of the view you're switching from
    if (newViewMode === "PO") {
      setSearchTermLineItems("");
    } else {
      setSearchTermPOs("");
    }
  };

  const getLineItems = (pos) => {
    return pos.reduce((acc, po) => {
      po.lines.forEach((line, index) => {
        if ((line.qtyReceived || 0) < line.qtyOrdered) {
          acc.push({
            key: `${po.poNumber}-${line.itemId}-${index}`, // Ensure unique key
            itemId: line.itemId,
            poNumber: po.poNumber,
            item: line.itemDescription,
            quantity: `${line.qtyReceived || 0} / ${line.qtyOrdered}`,
            vendor: po.vendor,
            requiredDate: po.requiredDate,
            projectNumber: po.projectNumber,
            buyer: po.buyer,
            shipToAddress: po.shipToAddress || "", // Include shipToAddress if available
          });
        }
      });
      return acc;
    }, []);
  };

  // Determine if any LineItem has shipToAddress
  const hasShipToAddressLineItem = useMemo(() => {
    return getLineItems(allExpectedPOs).some(
      (item) => item.shipToAddress && item.shipToAddress.trim() !== ""
    );
  }, [allExpectedPOs]);

  const columnsPO = [
    // Conditionally add "Ship To Address" as the first column
    ...(hasShipToAddressPO
      ? [
          {
            title: "Ship To Address",
            dataIndex: "shipToAddress",
            key: "shipToAddress",
            filters: [
              // Generate unique shipToAddress values for filtering
              ...Array.from(
                new Set(
                  allExpectedPOs
                    .map((po) => po.shipToAddress)
                    .filter((addr) => addr && addr.trim() !== "")
                )
              ).map((addr) => ({ text: addr, value: addr })),
            ],
            onFilter: (value, record) => record.shipToAddress === value,
            render: (text) => text || "N/A",
          },
        ]
      : []),
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
    {
      title: "Due Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
      defaultSortOrder: "ascend",
    },
    {
      title: "Line Items Received",
      dataIndex: "lineItemsReceived",
      key: "lineItemsReceived",
      render: (_, record) => {
        const totalItems = record.lines.length;
        const receivedItems = record.lines.filter(
          (line) => (line.qtyReceived || 0) >= line.qtyOrdered
        ).length;
        return `${receivedItems} / ${totalItems}`;
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => getVendorName(text, vendors),
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      render: (text) => getProjectName(text, projects),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (text) => getFirstNameFromEmail(text),
    },
  ];

  const columnsLineItems = [
    // Conditionally add "Ship To Address" as the first column
    ...(hasShipToAddressLineItem
      ? [
          {
            title: "Ship To Address",
            dataIndex: "shipToAddress",
            key: "shipToAddress",
            filters: [
              // Generate unique shipToAddress values for filtering
              ...Array.from(
                new Set(
                  filteredLineItems
                    .map((item) => item.shipToAddress)
                    .filter((addr) => addr && addr.trim() !== "")
                )
              ).map((addr) => ({ text: addr, value: addr })),
            ],
            onFilter: (value, record) => record.shipToAddress === value,
            render: (text) => text || "N/A",
          },
        ]
      : []),
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
    {
      title: "Item Description",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Due Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
      defaultSortOrder: "ascend",
    },
    {
      title: "Qty Received",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => getVendorName(text, vendors),
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      render: (text) => getProjectName(text, projects),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (text) => getFirstNameFromEmail(text),
      width: 150,
    },
  ];

  const onRowClick = (record) => {
    setSelectedRecord(
      allExpectedPOs.find((po) => po.poNumber === record.poNumber)
    );
    setModalVisible(true);
  };

  return (
    <>
      <Card
        title={
          <div>
            <Radio.Group
              value={viewMode}
              onChange={handleViewModeChange}
              style={{ marginRight: 8 }}
            >
              <Radio.Button value="PO">
                {allExpectedPOs.length} POs
              </Radio.Button>
              <Radio.Button value="LineItems">
                {getLineItems(allExpectedPOs).length} Line Items
              </Radio.Button>
            </Radio.Group>
            Expecting to Receive this week
          </div>
        }
      >
        {viewMode === "PO" && (
          <>
            <SearchInput
              placeholder="Search POs"
              onChange={handleSearchPOs}
              value={searchTermPOs}
              resultsLength={filteredPOs.length}
              style={{ marginBottom: 16 }}
            />
            <Table
              dataSource={filteredPOs}
              columns={columnsPO}
              rowKey="poNumber"
              pagination={false}
              rowClassName="clickable-row"
              size="small"
              className="small-table"
              scroll={{ y: 300 }}
              onRow={(record) => ({
                onClick: () => onRowClick(record),
              })}
            />
          </>
        )}

        {viewMode === "LineItems" && (
          <>
            <SearchInput
              placeholder="Search"
              onChange={handleSearchLineItems}
              value={searchTermLineItems}
              resultsLength={filteredLineItems.length}
              style={{ marginBottom: 16 }}
            />
            <Table
              dataSource={filteredLineItems}
              columns={columnsLineItems}
              rowKey="key"
              pagination={false}
              size="small"
              className="small-table"
              scroll={{ y: 300 }}
              onRow={(record) => ({
                onClick: () => onRowClick(record),
              })}
            />
          </>
        )}
      </Card>

      {selectedRecord && (
        <PurchaseOrderModal
          purchaseOrder={selectedRecord}
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          vendors={vendors}
          projects={projects}
        />
      )}
    </>
  );
};

export default ExpectedTableCard;
