import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Card,
  Switch,
  Button,
} from "antd";
import {
  useCustomers,
  useProjects,
  useManufacturingLocations,
} from "../../Contexts/useSpecificData";
import AddressAutocomplete from "../Google/AddressAutocomplete";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES, COLORS } from "../../constants";
import PhoneNumberInput from "../../Formatters/PhoneNumberInput";
import { useUser } from "@clerk/clerk-react";
import DisplayPhoneNumber from "../../Formatters/DisplayPhoneNumber";

const { Option } = Select;

function AddProjectModal({
  visible: propVisible,
  onCancel,
  onAddSuccess,
  children,
  project,
}) {
  const [form] = Form.useForm();
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerContacts, setCustomerContacts] = useState([]);
  const [primaryContact, setPrimaryContact] = useState(null);
  const [submitting, setSubmitting] = useState(false); // **Loading State**
  const { user } = useUser(); // Get current clerk user

  // Fetch existing customers, projects, and manufacturing locations
  const { data: customers, refresh: refreshCustomers } = useCustomers();
  const { data: projects, refresh: refreshProjects } = useProjects();
  const {
    data: manufacturingLocations,
    refresh: refreshManufacturingLocations,
  } = useManufacturingLocations();

  // Sort customers A-Z by companyName
  const sortedCustomers = customers
    ? [...customers].sort((a, b) => a.companyName.localeCompare(b.companyName))
    : [];

  // Sort manufacturing locations A-Z by nickname
  const sortedManufacturingLocations = manufacturingLocations
    ? [...manufacturingLocations].sort((a, b) =>
        a.nickname.localeCompare(b.nickname)
      )
    : [];

  /**
   * Function to calculate the next projectName based on existing projects for the selected customer.
   * @param {string|null} customerId - The ID of the selected customer. Null for new customers.
   * @param {string} prefix - The prefix to use for the project name.
   */
  const fetchNextProjectName = (customerId, prefix) => {
    if (!prefix) {
      // If prefix is not available, leave projectName blank
      form.setFieldsValue({ projectName: "" });
      return;
    }

    let newProjectName = "";

    if (customerId) {
      // Existing Customer: Increment based on existing projects
      const customerProjects = projects
        ? projects.filter((proj) => proj.customerId === customerId)
        : [];

      if (customerProjects.length > 0) {
        // Extract numeric suffix from projectName
        const suffixNumbers = customerProjects
          .map((proj) => {
            const match = proj.projectName.match(/(\D+)(\d+)$/);
            return match ? parseInt(match[2], 10) : null;
          })
          .filter((num) => num !== null);

        const maxNumber =
          suffixNumbers.length > 0 ? Math.max(...suffixNumbers) : 0;
        const newNumber = maxNumber + 1;

        newProjectName = `${prefix}${newNumber.toString().padStart(2, "0")}`;
      } else {
        // No existing projects for this customer, start at 01
        newProjectName = `${prefix}01`;
      }
    } else {
      // New Customer: Always start at 01
      newProjectName = `${prefix}01`;
    }

    // Update the projectName field
    form.setFieldsValue({ projectName: newProjectName });
  };

  /**
   * Validator to ensure prefix uniqueness.
   */
  const validatePrefixUniqueness = async (_, value) => {
    if (!value) {
      // Required rule will handle this
      return Promise.resolve();
    }

    const trimmedValue = value.trim().toUpperCase();

    // Only proceed if the prefix is exactly three letters
    if (!/^[A-Z]{3}$/.test(trimmedValue)) {
      // Pattern rule will handle this
      return Promise.resolve();
    }

    // Check if it's a new customer
    if (isNewCustomer) {
      const existingCustomer = customers.find((c) => c.prefix === trimmedValue);

      if (existingCustomer) {
        return Promise.reject(
          new Error(`Prefix already exists for ${existingCustomer.companyName}`)
        );
      }
    } else {
      // Editing an existing customer, ensure uniqueness excluding current customer
      const customerId = form.getFieldValue("customerId");
      const existingCustomer = customers.find(
        (c) => c.prefix === trimmedValue && c.id !== customerId
      );

      if (existingCustomer) {
        return Promise.reject(
          new Error(`Prefix already exists for ${existingCustomer.companyName}`)
        );
      }
    }

    return Promise.resolve();
  };

  useEffect(() => {
    // Determine current visibility
    const isModalVisible = propVisible !== undefined ? propVisible : visible;

    if (isModalVisible) {
      if (project && customers) {
        // **Edit Mode**
        form.setFieldsValue({
          customerId: project.customerId, // Pre-select the customer
          projectName: project.projectName,
          projectDescription: project.projectDescription,
          formattedAddress: project.formattedAddress,
          latitude: project.latitude,
          longitude: project.longitude,
          placeId: project.placeId,
          manufacturingLocationId: project.manufacturingLocation
            ? project.manufacturingLocation.id
            : undefined,
        });
        setSelectedCustomer(project.customerId);
        const customer = customers.find(
          (cust) => cust.id === project.customerId
        );
        if (customer) {
          setCustomerContacts(customer.contacts);
          const primary =
            customer.contacts.find((c) => c.primary) || customer.contacts[0];
          setPrimaryContact(primary || null);
          form.setFieldsValue({
            primaryContact: primary ? primary.id : undefined,
          });
        } else {
          setCustomerContacts([]);
          setPrimaryContact(null);
        }
        setIsNewCustomer(false);
      } else {
        // **Add Mode**
        form.resetFields();
        setIsNewCustomer(false);
        setSelectedCustomer(null);
        setCustomerContacts([]);
        setPrimaryContact(null);
      }
    }
  }, [project, form, visible, propVisible, customers, projects]);

  /**
   * Handle customer selection change.
   * - Sets the selected customer.
   * - Updates the customer contacts.
   * - Calculates the next projectName.
   */
  const handleCustomerChange = (customerId) => {
    setSelectedCustomer(customerId);
    const customer = sortedCustomers.find((cust) => cust.id === customerId);
    if (customer) {
      setCustomerContacts(customer.contacts);
      const primary =
        customer.contacts.find((c) => c.primary) || customer.contacts[0];
      setPrimaryContact(primary || null);
      // Fetch next project name for existing customer
      fetchNextProjectName(customerId, customer.prefix);
      form.setFieldsValue({ primaryContact: primary ? primary.id : undefined });
    } else {
      setCustomerContacts([]);
      setPrimaryContact(null);
      form.setFieldsValue({ projectName: "" });
    }
  };

  /**
   * Use Ant Design's useWatch to monitor 'prefix' changes when adding a new customer.
   */
  const prefix = Form.useWatch("prefix", form);

  useEffect(() => {
    if (isNewCustomer) {
      if (prefix) {
        fetchNextProjectName(null, prefix.toUpperCase());
      } else {
        form.setFieldsValue({ projectName: "" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefix, isNewCustomer]);

  /**
   * Handle form submission.
   * - Adds or updates the project.
   */
  const handleOk = async () => {
    try {
      setSubmitting(true); // Start loading

      const values = await form.validateFields();

      let customerId = values.customerId;

      // Handle new customer creation if necessary
      if (isNewCustomer) {
        // Prepare new customer data
        const newCustomerData = {
          companyName: values.companyName,
          prefix: values.prefix.toUpperCase(),
          contacts: [
            {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phone: values.phone,
              primary: true, // Assuming the first contact is primary
            },
          ],
        };

        // Add the new customer
        const newCustomer = await addItemsToTable(
          TABLES.CUSTOMERS,
          newCustomerData
        );
        customerId = newCustomer.id; // Update customerId to the new customer's ID

        // After creating a new customer, generate the initial projectName
        fetchNextProjectName(customerId, newCustomer.prefix);
      }

      // Determine companyName
      let companyName = isNewCustomer
        ? values.companyName
        : customers.find((cust) => cust.id === customerId)?.companyName || "";

      // Determine contact details
      let contactEmail = isNewCustomer
        ? values.email
        : primaryContact?.email || "";
      let contactFirstName = isNewCustomer
        ? values.firstName
        : primaryContact?.firstName || "";
      let contactLastName = isNewCustomer
        ? values.lastName
        : primaryContact?.lastName || "";
      let contactPhoneNumber = isNewCustomer
        ? values.phone
        : primaryContact?.phone || "";

      // Extract address details
      const place = form.getFieldValue("googleAddress");
      // First, read existing lat/long/placeId from the form
      let latitude = form.getFieldValue("latitude");
      let longitude = form.getFieldValue("longitude");
      let placeId = form.getFieldValue("placeId");

      // If user selected a new address
      if (place && place.geometry && place.geometry.location) {
        latitude = place.geometry.location.lat();
        longitude = place.geometry.location.lng();
        placeId = place.place_id;
      }

      // Get the selected manufacturing location object
      let manufacturingLocation = null;
      if (values.manufacturingLocationId) {
        manufacturingLocation = manufacturingLocations.find(
          (loc) => loc.id === values.manufacturingLocationId
        );
      }

      // Ensure projectName is set correctly
      let projectName = values.projectName;
      if (!projectName) {
        // If projectName is not set, generate it
        if (isNewCustomer) {
          const prefix = values.prefix.toUpperCase();
          projectName = `${prefix}01`;
        } else if (customerId) {
          const customer = customers.find((cust) => cust.id === customerId);
          const prefix = customer ? customer.prefix : "";
          projectName = `${prefix}01`;
        }
      }

      // Prepare project data
      const projectData = {
        projectName: projectName,
        projectDescription: values.projectDescription,
        formattedAddress: values.formattedAddress,
        latitude: latitude,
        longitude: longitude,
        placeId: placeId,
        customerId: customerId,
        companyName: companyName,
        contactEmail: contactEmail,
        contactFirstName: contactFirstName,
        contactLastName: contactLastName,
        contactPhoneNumber: contactPhoneNumber,
        manufacturingLocation: manufacturingLocation,
        submittedBy: user ? user.primaryEmailAddress.emailAddress : "",
      };

      if (project) {
        // **Edit Mode**
        await updateById(TABLES.PROJECTS, projectData, project.id);
        message.success("Project updated successfully!");
      } else {
        // **Add Mode**
        await addItemsToTable(TABLES.PROJECTS, projectData);
        message.success("Project added successfully!");
      }

      if (onAddSuccess) {
        onAddSuccess();
      }
      if (propVisible !== undefined && onCancel) {
        onCancel();
      } else {
        setVisible(false);
      }
    } catch (error) {
      console.error("Validation Failed:", error);
      message.error("Please fill in all required fields.");
    } finally {
      setSubmitting(false); // End loading
    }
  };

  const handleCancelModal = () => {
    if (onCancel) {
      onCancel();
    } else {
      setVisible(false);
    }
  };

  const handleAddressChange = (formattedAddress) => {
    // Only set formattedAddress
    form.setFieldsValue({
      formattedAddress: formattedAddress,
    });
  };

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: () => setVisible(true) })}
      <Modal
        visible={propVisible !== undefined ? propVisible : visible}
        title={project ? "Edit Project" : "Add Project"}
        onCancel={handleCancelModal}
        onOk={handleOk}
        width={800} // Adjust the width as needed
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={submitting} // **Loading Indicator**
          >
            {project ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          name="projectForm"
          initialValues={{
            projectName: "",
          }}
        >
          {/* Manufacturing Location Dropdown */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="manufacturingLocationId"
                label="Manufacturing Location"
                rules={[
                  {
                    required: true,
                    message: "Please select a manufacturing location",
                  },
                ]}
              >
                <Select
                  placeholder="Select a manufacturing location"
                  showSearch
                  optionFilterProp="children"
                >
                  {sortedManufacturingLocations.map((location) => (
                    <Option key={location.id} value={location.id}>
                      {location.nickname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Customer Selection */}
          <Card
            title="Customer"
            extra={
              <>
                <span
                  style={{
                    marginRight: 8,
                    fontStyle: "italic",
                    opacity: !isNewCustomer ? 1 : 0.7,
                    color: !isNewCustomer ? COLORS.PRIMARY : "black",
                  }}
                >
                  Existing Customer
                </span>
                <Switch
                  checked={isNewCustomer}
                  onChange={(checked) => setIsNewCustomer(checked)}
                />
                <span
                  style={{
                    marginLeft: 8,
                    fontStyle: "italic",
                    opacity: isNewCustomer ? 1 : 0.7,
                    color: isNewCustomer ? COLORS.PRIMARY : "black",
                  }}
                >
                  New Customer
                </span>
              </>
            }
            style={{ marginBottom: 16 }}
          >
            {!isNewCustomer ? (
              <>
                <Form.Item
                  name="customerId"
                  label="Company Name"
                  rules={[
                    { required: true, message: "Please select a customer" },
                  ]}
                >
                  <Select
                    placeholder="Select a customer"
                    showSearch
                    optionFilterProp="children"
                    onChange={handleCustomerChange}
                  >
                    {sortedCustomers.map((customer) => (
                      <Option key={customer.id} value={customer.id}>
                        {customer.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {customerContacts.length > 0 && (
                  <Form.Item
                    name="primaryContact"
                    label="Primary Contact"
                    rules={[
                      {
                        required: true,
                        message: "Please select a primary contact",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a primary contact"
                      showSearch
                      optionFilterProp="children"
                      onChange={(contactId) => {
                        const contact = customerContacts.find(
                          (c) => c.id === contactId
                        );
                        if (contact) {
                          setPrimaryContact(contact);
                        }
                      }}
                      value={primaryContact ? primaryContact.id : undefined}
                    >
                      {customerContacts.map((contact) => (
                        <Option key={contact.id} value={contact.id}>
                          {`${contact.firstName} ${contact.lastName}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {primaryContact && (
                  <Card
                    type="inner"
                    title="Contact Details"
                    style={{ marginTop: 16 }}
                    size="small"
                  >
                    <Row
                      gutter={16}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Col span={12}>
                        <p>
                          <strong>Email:</strong> {primaryContact.email}
                        </p>
                      </Col>
                      <Col span={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <strong>Phone:</strong>{" "}
                          <DisplayPhoneNumber
                            style={{ marginLeft: 8 }}
                            phoneNumber={primaryContact.phone}
                          ></DisplayPhoneNumber>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
              </>
            ) : (
              <>
                {/* New Customer Fields */}
                <Form.Item
                  name="companyName"
                  label="Company Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="prefix"
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>Prefix</div>
                      <div
                        style={{
                          opacity: 0.8,
                          fontStyle: "italic",
                          marginLeft: 4,
                          fontSize: 13,
                        }}
                      >
                        (e.g., VTV, CAP)
                      </div>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter a prefix",
                    },
                    {
                      pattern: /^[A-Z]{3}$/,
                      message: "Prefix must be exactly three uppercase letters",
                    },
                    {
                      validator: validatePrefixUniqueness,
                    },
                  ]}
                  normalize={(value) => (value ? value.toUpperCase() : value)}
                >
                  <Input maxLength={3} placeholder="Enter prefix" />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    { type: "email", message: "Enter a valid email" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    { required: true, message: "Please enter first name" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    { required: true, message: "Please enter last name" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                >
                  <PhoneNumberInput />
                </Form.Item>
              </>
            )}
          </Card>

          {/* Project Name */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="projectName"
                label="Project Name"
                rules={[
                  {
                    required: true,
                    message:
                      "Please select company name or enter prefix for a new customer",
                  },
                ]}
              >
                <Input
                  disabled
                  placeholder="Project name will be generated automatically"
                  readOnly
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Project Description */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="projectDescription"
                label="Project Description"
                rules={[
                  {
                    required: true,
                    message: "Please enter the project description",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter project description"
                  autoSize
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Delivery Address Autocomplete */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="formattedAddress"
                label="Delivery Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter the delivery address",
                  },
                ]}
              >
                <AddressAutocomplete
                  form={form}
                  value={form.getFieldValue("formattedAddress")}
                  onChange={handleAddressChange}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Hidden Fields for Latitude, Longitude, PlaceId */}
          <Form.Item name="latitude" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="longitude" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="placeId" noStyle>
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddProjectModal;
