import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  message,
  Typography,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useCustomItems } from "../../../Contexts/useSpecificData";
import { addItemsToTable } from "../../../Functions/addAria";
import { updateById } from "../../../Functions/updateById";
import {
  TABLES,
  EXPENSE_ACCOUNTS,
  ITEM_CATEGORIES,
  COLORS,
} from "../../../constants";
import { SearchInput } from "../../../Styled/SearchInput";
import HeaderText from "../../../Styled/HeaderText";
import PriceTag from "../../../Styled/PriceTag";
import { urlify } from "../../../Formatters/urlify";

const { Option } = Select;
const { Text } = Typography;

function ManageCustomItems() {
  // State Variables
  const {
    data: customItemsData,
    loading: customItemsLoading,
    refresh: refreshCustomItems,
  } = useCustomItems();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Modal States
  const [isModalVisible, setIsModalVisible] = useState(false); // For Add/Edit
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentItem, setCurrentItem] = useState(null); // Item being edited

  const [isDeleting, setIsDeleting] = useState(false); // Loading state for delete
  const [itemToDelete, setItemToDelete] = useState(null); // Item to delete

  const [isSaving, setIsSaving] = useState(false); // Loading state for saving

  // Form Instance
  const [form] = Form.useForm();

  /**
   * Filters custom items based on deletion status and the search term.
   * Ensures only items with valid categories and expense accounts are included.
   */
  const filterCustomItems = useCallback(() => {
    if (!customItemsData) {
      setFilteredItems([]);
      return;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const filtered = customItemsData.filter((item) => {
      // Exclude deleted items
      if (item.deleted) return false;

      // Ensure category and expense account are valid
      const itemCategory = (item.category || "").toLowerCase();
      const itemExpenseAccount = (item.expenseAccount || "").toLowerCase();

      if (
        !Object.keys(ITEM_CATEGORIES).some(
          (cat) => cat.toLowerCase() === itemCategory
        ) ||
        !EXPENSE_ACCOUNTS.some(
          (acc) => acc.toLowerCase() === itemExpenseAccount
        )
      ) {
        return false;
      }

      // If there's no search term, include the item
      if (!searchTerm) return true;

      // Safely access properties with default values
      const itemDesc = (item.itemDescription || "").toLowerCase();
      const internalDesc = (item.internalDescription || "").toLowerCase();

      return (
        itemDesc.includes(lowerCaseSearchTerm) ||
        internalDesc.includes(lowerCaseSearchTerm)
      );
    });

    setFilteredItems(filtered);
  }, [customItemsData, searchTerm]);

  // Initialize filteredItems when customItemsData or searchTerm changes
  useEffect(() => {
    filterCustomItems();
  }, [filterCustomItems]);

  /**
   * Opens the modal for adding a new custom item.
   */
  const openAddModal = () => {
    setIsEditMode(false);
    setCurrentItem(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  /**
   * Opens the modal for editing an existing custom item.
   */
  const openEditModal = (item) => {
    setIsEditMode(true);
    setCurrentItem(item);
    form.setFieldsValue({
      itemDescription: item.itemDescription,
      internalDescription: item.internalDescription,
      category: item.category,
      expenseAccount: item.expenseAccount,
      laborPrice: item.laborPrice,
      price: item.price,
    });
    setIsModalVisible(true);
  };

  /**
   * Handles adding a new custom item.
   */
  const handleAddItem = async (values) => {
    setIsSaving(true); // Start loading
    try {
      await addItemsToTable(TABLES.ESTIMATING_CUSTOM_ITEMS, {
        ...values,
        deleted: false, // Initialize as not deleted
      });
      message.success("Custom item added successfully!");
      setIsModalVisible(false);
      refreshCustomItems();
    } catch (error) {
      message.error("Failed to add custom item: " + error.message);
    } finally {
      setIsSaving(false); // Stop loading
    }
  };

  /**
   * Handles editing an existing custom item.
   */
  const handleEditItem = async (values) => {
    setIsSaving(true); // Start loading
    try {
      await updateById(TABLES.ESTIMATING_CUSTOM_ITEMS, values, currentItem.id);
      message.success("Custom item updated successfully!");
      setIsModalVisible(false);
      refreshCustomItems();
    } catch (error) {
      message.error("Failed to update custom item: " + error.message);
    } finally {
      setIsSaving(false); // Stop loading
    }
  };

  /**
   * Handles form submission for both add and edit.
   */
  const handleFormSubmit = (values) => {
    if (isEditMode) {
      handleEditItem(values);
    } else {
      handleAddItem(values);
    }
  };

  /**
   * Opens the confirmation modal to mark an item as deleted.
   */
  const confirmDelete = (item) => {
    setItemToDelete(item);
    Modal.confirm({
      title: "Confirm Deletion",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to mark "${item.itemDescription}" as deleted?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => handleDeleteItem(item),
    });
  };

  /**
   * Handles marking an item as deleted.
   */
  const handleDeleteItem = async (item) => {
    setIsDeleting(true);
    try {
      await updateById(
        TABLES.ESTIMATING_CUSTOM_ITEMS,
        { deleted: true },
        item.id
      );
      message.success("Custom item marked as deleted!");
      refreshCustomItems();
    } catch (error) {
      message.error("Failed to delete custom item: " + error.message);
    } finally {
      setIsDeleting(false);
      setItemToDelete(null);
    }
  };

  /**
   * Extracts unique categories from the current filtered items.
   */
  const getUniqueCategories = () => {
    const categoriesSet = new Set();
    filteredItems.forEach((item) => {
      if (item.category) {
        categoriesSet.add(item.category);
      }
    });
    return Array.from(categoriesSet);
  };

  /**
   * Extracts unique expense accounts from the current filtered items.
   */
  const getUniqueExpenseAccounts = () => {
    const accountsSet = new Set();
    filteredItems.forEach((item) => {
      if (item.expenseAccount) {
        accountsSet.add(item.expenseAccount);
      }
    });
    return Array.from(accountsSet);
  };

  /**
   * Defines the columns for the custom items table.
   */
  const columns = [
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      sorter: (a, b) => a.itemDescription.localeCompare(b.itemDescription),
    },
    {
      title: "Internal Description",
      dataIndex: "internalDescription",
      key: "internalDescription",
      sorter: (a, b) =>
        a.internalDescription.localeCompare(b.internalDescription),
      render: (text, record) => (
        <div dangerouslySetInnerHTML={{ __html: urlify(text) }} />
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: getUniqueCategories().map((cat) => ({
        text: cat,
        value: cat,
      })),
      onFilter: (value, record) => record.category === value,
    },
    {
      title: "Expense Account",
      dataIndex: "expenseAccount",
      key: "expenseAccount",
      filters: getUniqueExpenseAccounts().map((acc) => ({
        text: acc,
        value: acc,
      })),
      onFilter: (value, record) => record.expenseAccount === value,
    },
    {
      title: "Labor Price",
      dataIndex: "laborPrice",
      key: "laborPrice",
      render: (price) => <PriceTag amount={price} />,
      sorter: (a, b) => a.laborPrice - b.laborPrice,
    },
    {
      title: "Material Price",
      dataIndex: "price",
      key: "price",
      render: (price) => <PriceTag amount={price} />,
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button icon={<EditOutlined />} onClick={() => openEditModal(record)}>
            Edit
          </Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => confirmDelete(record)}
            loading={
              isDeleting && itemToDelete && itemToDelete.id === record.id
            }
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      {/* Header Section */}
      <Col span={12}>
        <HeaderText text="Custom Items" />
      </Col>
      <Col span={12} align="right">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={openAddModal}
          style={{ marginRight: 16 }}
        >
          Add New Custom Item
        </Button>
      </Col>

      {/* Search Input */}
      <Col span={24}>
        <SearchInput
          placeholder="Search Custom Items..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          resultsLength={filteredItems.length} // Pass the total number of filtered items
        />
      </Col>

      {/* Custom Items Table */}
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={filteredItems}
          rowKey="id"
          loading={customItemsLoading}
          pagination={{ pageSize: 10 }}
          locale={{
            emptyText: searchTerm
              ? "No custom items match your search."
              : "No custom items available.",
          }}
        />
        {/* Removed Total Results Text below the table */}
      </Col>

      {/* Add/Edit Modal */}
      <Modal
        title={isEditMode ? "Edit Custom Item" : "Add New Custom Item"}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setCurrentItem(null);
          form.resetFields();
        }}
        footer={null}
        destroyOnClose
        width={600}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            itemDescription: "",
            internalDescription: "",
            category: "",
            expenseAccount: "",
            laborPrice: 0,
            price: 0,
          }}
        >
          <Row gutter={16}>
            {/* Internal Description */}
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Internal Description</span>
                    <Text italic style={{ marginLeft: 8, opacity: 0.7 }}>
                      (Internal Use Only)
                    </Text>
                  </div>
                }
                name="internalDescription"
                rules={[
                  {
                    required: true,
                    message: "Please input internal description!",
                  },
                ]}
              >
                <Input placeholder="Internal Description" />
              </Form.Item>
            </Col>

            {/* Item Description */}
            <Col span={12}>
              <Form.Item
                label="Item Description"
                name="itemDescription"
                rules={[
                  {
                    required: true,
                    message: "Please input item description!",
                  },
                ]}
              >
                <Input placeholder="Item Description" />
              </Form.Item>
            </Col>

            {/* Category */}
            <Col span={12}>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  { required: true, message: "Please select a category!" },
                ]}
              >
                <Select
                  placeholder="Select Category"
                  onChange={(value) => {
                    const correspondingExpenseAccount =
                      ITEM_CATEGORIES[value]?.expenseAccount || "";
                    form.setFieldsValue({
                      expenseAccount: correspondingExpenseAccount,
                    });
                  }}
                  allowClear
                >
                  {Object.keys(ITEM_CATEGORIES).map((category) => (
                    <Option key={category} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Expense Account */}
            <Col span={12}>
              <Form.Item
                label="Expense Account"
                name="expenseAccount"
                rules={[
                  {
                    required: true,
                    message: "Please select an expense account!",
                  },
                ]}
              >
                <Select placeholder="Select Expense Account" allowClear>
                  {EXPENSE_ACCOUNTS.map((account) => (
                    <Option key={account} value={account}>
                      {account}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Labor Price */}
            <Col span={12}>
              <Form.Item
                label="Labor Price"
                name="laborPrice"
                rules={[
                  { required: true, message: "Please input labor price!" },
                  {
                    type: "number",
                    min: 0,
                    message: "Price must be at least 0!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Labor Price"
                  style={{ width: "100%" }}
                  min={0}
                  addonBefore="$"
                />
              </Form.Item>
            </Col>

            {/* Material Price */}
            <Col span={12}>
              <Form.Item
                label="Material Price"
                name="price"
                rules={[
                  { required: true, message: "Please input material price!" },
                  {
                    type: "number",
                    min: 0,
                    message: "Price must be at least 0!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Material Price"
                  style={{ width: "100%" }}
                  min={0}
                  addonBefore="$"
                />
              </Form.Item>
            </Col>

            {/* Submit Button */}
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSaving} // Show loading when saving
              >
                {isEditMode ? "Update Custom Item" : "Add Custom Item"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
}

export default ManageCustomItems;
