// src/Components/YourPath/AddEstimateModal.jsx

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Switch,
  message,
  Card,
} from "antd";
import dayjs from "dayjs";
import { updateById } from "../../Functions/updateById";
import { addItemsToTable } from "../../Functions/addAria";
import { COLORS, TABLES } from "../../constants";
import { getEstimateNumber } from "../../Functions/getEstimateNumber";
import { useCustomers } from "../../Contexts/useSpecificData";
import { useUser } from "@clerk/clerk-react";
import useUsers from "../../Contexts/useUsers";
import PhoneNumberInput from "../../Formatters/PhoneNumberInput";
import { sendEmail } from "../../Functions/sendEmail";
import { v4 as uuidv4 } from "uuid"; // For generating UUIDs
import { prefix } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

const AddEstimateModal = ({ children, estimate, onAddSuccess, title }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;
  const userName = user?.fullName;
  const users = useUsers();

  // Fetch existing customers
  const { data: customers } = useCustomers();

const validatePrefixUniqueness = async (_, value) => {
  if (!value) {
    // Required rule will handle this
    return Promise.resolve();
  }

  const trimmedValue = value.trim().toUpperCase();

  // Only proceed if the prefix is exactly three letters
  if (!/^[A-Z]{3}$/.test(trimmedValue)) {
    // Pattern rule will handle this
    return Promise.resolve();
  }

  // Get the current customerId from the form values
  const customerId = form.getFieldValue("customerId");

  // Check if the prefix exists for another customer
  const existingCustomer = customers.find(
    (c) => c.prefix === trimmedValue && c.id !== customerId
  );

  if (existingCustomer) {
    return Promise.reject(
      new Error(`Prefix already exists for ${existingCustomer.companyName}`)
    );
  }

  return Promise.resolve();
};


  useEffect(() => {
    if (visible) {
      if (estimate) {
        // Find the user ID based on the estimate's assignedTo email
        const assignedUser = users.data.find(
          (user) =>
            user.emailAddresses[0]?.emailAddress === estimate.assignedTo?.email
        );

        form.setFieldsValue({
          estimateName: estimate.estimateName,
          estimateDescription: estimate.estimateDescription,
          estimateStatus: estimate.estimateStatus || "Pre-Hand off",
          handedOff: estimate.handedOff,
          estimated: estimate.estimated,
          quoteBy: estimate.quoteBy ? dayjs(estimate.quoteBy) : null,
          assignedTo: assignedUser ? assignedUser.id : null,
          customerId: estimate.customerId || estimate.customer?.id || null,
        });
        setIsNewCustomer(false);
      } else {
        form.resetFields();
        setIsNewCustomer(false);
      }
    }
  }, [estimate, form, visible, users.data]);

  // Sort customers A-Z by companyName
  const sortedCustomers = customers
    ? [...customers].sort((a, b) => a.companyName.localeCompare(b.companyName))
    : [];

  // Determine if the environment is development
  const isDev = process.env.REACT_APP_STAGE === "_dev";

  // Filter users with role "estimator" and exclude "admin" unless in dev
  const estimatorUsers = users.data
    ? users.data.filter(
        (user) =>
          user.publicMetadata.roles.includes("estimator") &&
          (isDev || !user.publicMetadata.roles.includes("admin"))
      )
    : [];

  const handleOk = async () => {
    setLoading(true);
    try {
      // Validate form fields
      const values = await form.validateFields();
      console.log("Submitted Values:", values);

      let customerId = values.customerId;

      // If 'New' customer is selected, create a new customer
      if (isNewCustomer) {
        const newCustomer = {
          companyName: values.companyName,
          contacts: [
            {
              id: uuidv4(),
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phone: values.phone,
              primary: true, // Always primary for single contact
              prefix: values.prefix.toUpperCase(),
            },
          ],
        };

        const customerResponse = await addItemsToTable(
          TABLES.CUSTOMERS,
          newCustomer
        );
        customerId = customerResponse.id;
      }

      // Find the assignedTo user object
      const assignedToUser = users.data.find(
        (user) => user.id === values.assignedTo
      );
      if (!assignedToUser) {
        throw new Error("Selected user not found");
      }

      // Extract fullName and email
      const fullName = `${assignedToUser.firstName} ${assignedToUser.lastName}`;
      const email = assignedToUser.emailAddresses[0]?.emailAddress || "";

      // Prepare requestor
      let requestor = null;
      if (estimate && estimate.requestor) {
        requestor = estimate.requestor;
      } else {
        requestor = {
          fullName: userName,
          email: userEmail,
        };
      }

      // Prepare estimate data
      const estimateData = {
        estimateName: values.estimateName,
        estimateDescription: values.estimateDescription,
        estimateStatus: estimate?.estimateStatus || "Needs Quote",
        quoteBy: values.quoteBy ? values.quoteBy.toISOString() : null,
        assignedTo: {
          fullName,
          email,
        },
        requestor,
        customerId,
      };

      if (estimate && estimate.id) {
        // Update existing estimate
        await updateById(TABLES.ESTIMATES, estimateData, estimate.id);
        estimateData.id = estimate.id; // Ensure the ID is set for onAddSuccess
      } else {
        // Create new estimate

        // **Initialize Base Costs Here**
        const baseCosts = {
          contingency: 5, // 5%
          profitMargin: 50, // 50%
          saleAmount: 0, // $0
          profit: 0, // $0
        };

        const newEstimateNumber = await getEstimateNumber();
        estimateData.estimateNumber = newEstimateNumber;

        estimateData.costs = baseCosts;

        const newEstimate = await addItemsToTable(
          TABLES.ESTIMATES,
          estimateData
        );
        estimateData.id = newEstimate.id;

        // **Send Email Notification**

        // Get assignee's email
        const assigneeEmail =
          assignedToUser.emailAddresses[0]?.emailAddress || "";
        if (!assigneeEmail) {
          throw new Error("Assigned user does not have an email address.");
        }

        // Get customer name
        const customer = customers.find((c) => c.id === customerId);
        const customerName = customer
          ? customer.companyName
          : "Unknown Customer";

        // Prepare email content
        const emailSubject = `New Estimate Assigned: ${estimateData.estimateNumber}`;

        const estimateUrl = new URL(
          `/estimating/estimates/${newEstimate.id}`,
          window.location.origin
        ).href;

        const emailBody = `
          <p>Hello ${assignedToUser.firstName},</p>
          <p>A new estimate has been assigned to you.</p>
          <p><strong>Estimate Number:</strong> ${estimateData.estimateNumber}</p>
          <p><strong>Customer:</strong> ${customerName}</p>
          <p><strong>Description:</strong> ${estimateData.estimateDescription}</p>
          <br/>
          <span>View the estimate <a href="${estimateUrl}">here</a>.</span>
        `;

        const manager = "matt.mcconnell@bmarko.com";

        // Send the email
        await sendEmail({
          subject: emailSubject,
          htmlBody: emailBody,
          emailAddresses: [assigneeEmail, requestor.email],
          attachmentUrls: [],
          sender: requestor.email,
          manager: manager,
        });
      }

      setVisible(false);
      onAddSuccess(estimateData);
      setLoading(false);
    } catch (error) {
      console.error("Validation Failed:", error);
      message.error("Please fill in all required fields.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setIsNewCustomer(false);
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: () => setVisible(true) })
      )}
      <Modal
        title={title || (estimate ? "Edit Estimate" : "Add New Estimate")}
        visible={visible}
        onOk={handleOk}
        width={800}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {estimate ? "Save Changes" : "Add Estimate"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item label="Requestor">
                <Input
                  value={
                    estimate?.requestor?.fullName
                      ? `${estimate?.requestor?.fullName}`
                      : userName
                  }
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="quoteBy"
                label="Quote By"
                rules={[{ required: true, message: "Please enter a date" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="assignedTo"
                label="Assign To"
                rules={[{ required: true, message: "Please assign to a user" }]}
              >
                <Select
                  showSearch
                  placeholder={
                    estimatorUsers.length > 0
                      ? "Select an estimator"
                      : "No estimators available"
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={estimatorUsers.length === 0}
                >
                  {estimatorUsers.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {`${user.firstName} ${user.lastName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estimateName"
                label="Estimate Name"
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estimateDescription"
                label="Description"
                rules={[
                  { required: true, message: "Please enter a description" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          {/* Customer Selection */}
          <Card
            title="Customer"
            extra={
              <>
                <span
                  style={{
                    marginRight: 8,
                    fontStyle: "italic",
                    opacity: !isNewCustomer ? 1 : 0.7,
                    color: !isNewCustomer ? COLORS.PRIMARY : "black",
                  }}
                >
                  Existing Customer
                </span>
                <Switch
                  checked={isNewCustomer}
                  onChange={(checked) => setIsNewCustomer(checked)}
                />
                <span
                  style={{
                    marginLeft: 8,
                    fontStyle: "italic",
                    opacity: isNewCustomer ? 1 : 0.7,
                    color: isNewCustomer ? COLORS.PRIMARY : "black",
                  }}
                >
                  New Customer
                </span>
              </>
            }
            style={{ marginBottom: 16 }}
          >
            {!isNewCustomer ? (
              <Form.Item
                name="customerId"
                label="Company Name"
                rules={[
                  { required: true, message: "Please select a customer" },
                ]}
              >
                <Select
                  placeholder="Select a customer"
                  showSearch
                  optionFilterProp="children"
                >
                  {sortedCustomers.map((customer) => (
                    <Option key={customer.id} value={customer.id}>
                      {customer.companyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <>
                {/* New Customer Fields */}
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="companyName"
                      label="Company Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter company name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    </Col>
                    
                    <Col span={12}>
                  {/* Prefix */}
                  <Form.Item
                    name="prefix"
                    label={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>Project Prefix</div>
                        <div
                          style={{
                            opacity: 0.8,
                            fontStyle: "italic",
                            marginLeft: 4,
                            fontSize: 13,
                          }}
                        >
                          (ex: VTV, CAP)
                        </div>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter a prefix",
                      },
                      {
                        pattern: /^[A-Z]{3}$/,
                        message: "Prefix must be exactly three letters",
                      },
                      {
                        validator: validatePrefixUniqueness,
                      },
                    ]}
                    normalize={(value) => (value ? value.toUpperCase() : value)}
                  >
                    <Input maxLength={3} />
                      </Form.Item>
                      </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      rules={[
                        { required: true, message: "Please enter first name" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      rules={[
                        { required: true, message: "Please enter last name" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        { required: true, message: "Please enter email" },
                        { type: "email", message: "Enter a valid email" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <PhoneNumberInput
                      name="phone"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default AddEstimateModal;
