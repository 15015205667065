import React, { useState, useEffect, useMemo } from "react";
import { Card, Table, Radio, Image, Modal, Spin } from "antd";
import dayjs from "dayjs";
import { getVendorName } from "../../Formatters/getVendorName";
import { getProjectName } from "../../Formatters/getProjectName";
import PurchaseOrderModal from "../../Components/Dashboard/PurchaseOrderModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../constants";
import { getFirstNameFromEmail } from "../../Formatters/getNameFromEmail";
import { SearchInput } from "../../Styled/SearchInput";

const ReceivedTableCard = ({ purchaseOrders, vendors, projects }) => {
  const [viewMode, setViewMode] = useState("PO");
  const [filteredPOs, setFilteredPOs] = useState([]);
  const [filteredLineItems, setFilteredLineItems] = useState([]);
  const [allPOs, setAllPOs] = useState([]);
  const [allLineItems, setAllLineItems] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState(null); // Initially null
  const [selectedItem, setSelectedItem] = useState({}); // Stores item details
  const [selectedPoNumber, setSelectedPoNumber] = useState(""); // Track PO number separately
  const [loadingImages, setLoadingImages] = useState(false); // Loading state for images

  // State variables to store search terms
  const [searchTermPOs, setSearchTermPOs] = useState("");
  const [searchTermLineItems, setSearchTermLineItems] = useState("");

  const isValidDate = (date) => {
    return (
      date &&
      dayjs(
        date,
        ["YYYY-MM-DD", "MM/DD/YYYY", "DD-MM-YYYY", dayjs.ISO_8601],
        true
      ).isValid()
    );
  };

  useEffect(() => {
    filterReceivedPOs();
  }, [purchaseOrders]);

  const filterReceivedPOs = () => {
    if (!Array.isArray(purchaseOrders) || purchaseOrders.length === 0) {
      setAllPOs([]);
      setAllLineItems([]);
      setFilteredPOs([]);
      setFilteredLineItems([]);
      return;
    }

    const startOfWeek = dayjs().startOf("week");
    const endOfWeek = dayjs().endOf("week");

    const receivedPOs = purchaseOrders.filter((po) => {
      const allLinesReceived = po.lines.every(
        (line) =>
          line.qtyReceived >= line.qtyOrdered &&
          isValidDate(line.receivedDate) &&
          dayjs(line.receivedDate).isBetween(startOfWeek, endOfWeek, null, "[]")
      );

      const mostRecentReceivedDate = po.lines
        .map((line) => line.receivedDate)
        .filter(isValidDate)
        .sort((a, b) => dayjs(b).diff(dayjs(a)))[0];

      po.dateReceived = mostRecentReceivedDate || null;
      po.itemsReceived = `${
        po.lines.filter((line) => line.qtyReceived >= line.qtyOrdered).length
      } / ${po.lines.length}`;
      po.shipToAddress = po.shipToAddress || ""; // Ensure shipToAddress exists
      return (
        allLinesReceived &&
        dayjs(mostRecentReceivedDate).isBetween(
          startOfWeek,
          endOfWeek,
          null,
          "[]"
        )
      );
    });

    const receivedLineItems = getLineItems(receivedPOs);

    setAllPOs(receivedPOs);
    setAllLineItems(receivedLineItems);
    setFilteredPOs(receivedPOs);
    setFilteredLineItems(receivedLineItems);
  };

  // Generate Line Items from POs, including shipToAddress if available
  const getLineItems = (pos) => {
    if (!Array.isArray(pos)) return [];
    return pos.reduce((acc, po) => {
      if (po.lines && Array.isArray(po.lines)) {
        po.lines.forEach((line, index) => {
          if (
            line.qtyReceived >= line.qtyOrdered &&
            isValidDate(line.receivedDate) &&
            dayjs(line.receivedDate).isBetween(
              dayjs().startOf("week"),
              dayjs().endOf("week"),
              null,
              "[]"
            )
          ) {
            acc.push({
              key: `${po.poNumber}-${line.itemId}-${index}`, // Ensure unique key
              itemId: line.itemId,
              poNumber: po.poNumber,
              item: line.itemDescription,
              quantity: `${line.qtyReceived} / ${line.qtyOrdered}`,
              vendor: po.vendor,
              requiredDate: po.requiredDate,
              receivedDate: line.receivedDate,
              projectNumber: po.projectNumber,
              images: line.images || [],
              buyer: po.buyer,
              shipToAddress: po.shipToAddress || "", // Include shipToAddress if available
            });
          }
        });
      }
      return acc;
    }, []);
  };

  // Determine if any PO has shipToAddress
  const hasShipToAddressPO = useMemo(() => {
    return allPOs.some(
      (po) => po.shipToAddress && po.shipToAddress.trim() !== ""
    );
  }, [allPOs]);

  // Determine if any LineItem has shipToAddress
  const hasShipToAddressLineItem = useMemo(() => {
    return allLineItems.some(
      (item) => item.shipToAddress && item.shipToAddress.trim() !== ""
    );
  }, [allLineItems]);

  // Apply search filter for POs
  useEffect(() => {
    const value = searchTermPOs.toLowerCase();
    if (value) {
      const filtered = allPOs.filter((po) =>
        [
          po.poNumber,
          getVendorName(po.vendor, vendors),
          getProjectName(po.projectNumber, projects),
          getFirstNameFromEmail(po.buyer),
          hasShipToAddressPO ? po.shipToAddress : null, // Include shipToAddress in search
        ]
          .filter(Boolean)
          .some((field) => field.toLowerCase().includes(value))
      );
      setFilteredPOs(filtered);
    } else {
      setFilteredPOs(allPOs);
    }
  }, [searchTermPOs, allPOs, vendors, projects, hasShipToAddressPO]);

  // Apply search filter for LineItems
  useEffect(() => {
    const value = searchTermLineItems.toLowerCase();
    if (value) {
      const filtered = allLineItems.filter((item) =>
        [
          item.poNumber,
          item.item, // Item Description
          getVendorName(item.vendor, vendors),
          getProjectName(item.projectNumber, projects),
          getFirstNameFromEmail(item.buyer),
          hasShipToAddressLineItem ? item.shipToAddress : null, // Include shipToAddress in search
        ]
          .filter(Boolean)
          .some((field) => field.toLowerCase().includes(value))
      );
      setFilteredLineItems(filtered);
    } else {
      setFilteredLineItems(allLineItems);
    }
  }, [
    searchTermLineItems,
    allLineItems,
    vendors,
    projects,
    hasShipToAddressLineItem,
  ]);

  const handleSearchPOs = (e) => {
    setSearchTermPOs(e.target.value);
  };

  const handleSearchLineItems = (e) => {
    setSearchTermLineItems(e.target.value);
  };

  const handleViewModeChange = (e) => {
    const newViewMode = e.target.value;
    setViewMode(newViewMode);
    // Reset the search term of the view you're switching from
    if (newViewMode === "PO") {
      setSearchTermLineItems("");
    } else {
      setSearchTermPOs("");
    }
  };

  // Handle image preview icon click
  const handleImageClick = (images, poNumber, itemNumber, itemDescription) => {
    setLoadingImages(true); // Start loading state
    setSelectedImages(images); // Set images
    setSelectedItem({ itemNumber, itemDescription }); // Set item details
    setSelectedPoNumber(poNumber); // Set the PO number for the image modal
    setImageModalVisible(true);

    setTimeout(() => {
      setLoadingImages(false); // Simulate a delay before loading stops
    }, 500);
  };

  const handleImageModalClose = () => {
    setSelectedImages(null); // Reset images on modal close
    setImageModalVisible(false);
  };

  // Define columns for POs, conditionally adding "Ship To Address"
  const columnsPO = [
    // Conditionally add "Ship To Address" as the first column
    ...(hasShipToAddressPO
      ? [
          {
            title: "Ship To Address",
            dataIndex: "shipToAddress",
            key: "shipToAddress",
            filters: [
              // Generate unique shipToAddress values for filtering
              ...Array.from(
                new Set(
                  allPOs
                    .map((po) => po.shipToAddress)
                    .filter((addr) => addr && addr.trim() !== "")
                )
              ).map((addr) => ({ text: addr, value: addr })),
            ],
            onFilter: (value, record) => record.shipToAddress === value,
            render: (text) => text || "N/A",
          },
        ]
      : []),
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
    {
      title: "Received On",
      dataIndex: "dateReceived",
      key: "dateReceived",
      render: (text) => (text ? dayjs(text).format("MM/DD/YYYY") : "N/A"),
      sorter: (a, b) => dayjs(a.dateReceived).diff(dayjs(b.dateReceived)),
      defaultSortOrder: "descend",
    },
    {
      title: "Line Items Received",
      dataIndex: "itemsReceived",
      key: "itemsReceived",
      sorter: (a, b) =>
        parseInt(a.itemsReceived.split(" / ")[0]) -
        parseInt(b.itemsReceived.split(" / ")[0]),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => getVendorName(text, vendors),
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      render: (text) => getProjectName(text, projects),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (text) => getFirstNameFromEmail(text),
    },
  ];

  // Define columns for LineItems, conditionally adding "Ship To Address"
  const columnsLineItems = [
    // Conditionally add "Ship To Address" as the first column
    ...(hasShipToAddressLineItem
      ? [
          {
            title: "Ship To Address",
            dataIndex: "shipToAddress",
            key: "shipToAddress",
            filters: [
              // Generate unique shipToAddress values for filtering
              ...Array.from(
                new Set(
                  allLineItems
                    .map((item) => item.shipToAddress)
                    .filter((addr) => addr && addr.trim() !== "")
                )
              ).map((addr) => ({ text: addr, value: addr })),
            ],
            onFilter: (value, record) => record.shipToAddress === value,
            render: (text) => text || "N/A",
          },
        ]
      : []),
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
    },
    {
      title: "Item Description",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Received On",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (text) => (text ? dayjs(text).format("MM/DD/YYYY") : "N/A"),
      sorter: (a, b) => dayjs(a.receivedDate).diff(dayjs(b.receivedDate)),
      defaultSortOrder: "descend",
    },
    {
      title: "Qty Received",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {record.quantity}
          {record.images.length > 0 && (
            <FontAwesomeIcon
              icon={faImage}
              style={{
                marginLeft: 8,
                color: COLORS.PRIMARY,
                cursor: "pointer",
                fontSize: 14,
              }}
              onClick={() =>
                handleImageClick(
                  record.images,
                  record.poNumber,
                  record.itemId,
                  record.item
                )
              } // Pass itemId, poNumber, and itemDescription for image preview
            />
          )}
        </div>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => getVendorName(text, vendors),
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      render: (text) => getProjectName(text, projects),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      render: (text) => getFirstNameFromEmail(text),
    },
  ];

  const onRowClick = (record) => {
    // Find the full PO object
    const selectedPO = allPOs.find((po) => po.poNumber === record.poNumber);
    setSelectedRecord(selectedPO);
    setModalVisible(true);
  };

  return (
    <>
      <Card
        title={
          <div>
            <Radio.Group
              value={viewMode}
              onChange={handleViewModeChange}
              style={{ marginRight: 8 }}
            >
              <Radio.Button value="PO">{allPOs.length} POs</Radio.Button>
              <Radio.Button value="LineItems">
                {allLineItems.length} Line Items
              </Radio.Button>
            </Radio.Group>
            Received this week
          </div>
        }
      >
        {viewMode === "PO" && (
          <>
            <SearchInput
              placeholder="Search POs"
              onChange={handleSearchPOs}
              value={searchTermPOs}
              allowClear
              style={{ marginBottom: 16 }}
              resultsLength={filteredPOs.length}
            />
            <Table
              dataSource={filteredPOs.sort((a, b) =>
                dayjs(b.dateReceived).diff(dayjs(a.dateReceived))
              )}
              columns={columnsPO}
              rowKey="poNumber"
              pagination={false}
              rowClassName={"clickable-row"}
              className="small-table"
              size="small"
              scroll={{ y: 300 }}
              onRow={(record) => ({
                onClick: () => onRowClick(record),
              })}
            />
          </>
        )}
        {viewMode === "LineItems" && (
          <>
            <SearchInput
              placeholder="Search Line Items"
              onChange={handleSearchLineItems}
              value={searchTermLineItems}
              allowClear
              style={{ marginBottom: 16 }}
              resultsLength={filteredLineItems.length}
            />
            <Table
              dataSource={filteredLineItems.sort((a, b) =>
                dayjs(b.receivedDate).diff(dayjs(a.receivedDate))
              )}
              columns={columnsLineItems}
              rowKey="key"
              pagination={false}
              className="small-table"
              size="small"
              scroll={{ y: 300 }}
              onRow={(record) => ({
                onClick: () => onRowClick(record),
              })}
            />
          </>
        )}
      </Card>

      {selectedRecord && viewMode === "PO" && (
        <PurchaseOrderModal
          purchaseOrder={selectedRecord}
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          vendors={vendors}
          projects={projects}
        />
      )}

      {/* Image Preview Modal */}
      <Modal
        title={`Received Items for ${selectedPoNumber}`} // Use selectedPoNumber here
        centered
        open={imageModalVisible}
        onCancel={handleImageModalClose}
        footer={null}
      >
        {loadingImages ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Spin />
          </div>
        ) : (
          <>
            <p>
              <strong>Item Description:</strong> {selectedItem.itemDescription}
            </p>
            <Image.PreviewGroup>
              {selectedImages?.map((img, index) => (
                <Image
                  key={index}
                  width={200}
                  src={img}
                  alt={`Preview ${index + 1}`}
                  style={{ marginBottom: 16 }}
                />
              ))}
            </Image.PreviewGroup>
          </>
        )}
      </Modal>
    </>
  );
};

export default ReceivedTableCard;
