// src/Functions/sendEmail.js

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/aria/sendEmail";

export const sendEmail = async ({
  subject,
  htmlBody,
  emailAddresses,
  attachmentUrls,
  sender,
  manager = null, // New optional parameter
}) => {
  try {
    const payload = {
      subject,
      htmlBody,
      emailAddresses,
      attachmentUrls,
      sender,
    };

    // Include manager if provided
    if (manager) {
      payload.manager = manager;
    }

    const response = await axios.post(API_BASE_URL, payload);

    if (response.status === 200) {
      console.log("Email sent successfully!");
      return {
        success: true,
        message: "Email sent successfully!",
      };
    } else {
      throw new Error("Failed to send the email.");
    }
  } catch (error) {
    console.error("Error sending email:", error);
    return {
      success: false,
      message: "Failed to send the email.",
      error: error.message,
    };
  }
};
