// src/utils/phoneUtils.js

import { parsePhoneNumberFromString } from "libphonenumber-js";

/**
 * Formats a phone number string to E.164 format.
 *
 * @param {string} phoneNumberString - The phone number string to format.
 * @param {string} defaultCountry - The default country code (ISO 3166-1 alpha-2, e.g., 'US').
 * @returns {string} - The formatted phone number in E.164 format or the original string if invalid.
 */
export function formatPhoneNumberToE164(
  phoneNumberString,
  defaultCountry = "US"
) {
  if (!phoneNumberString) return "";

  // Remove common formatting characters
  const cleanedInput = phoneNumberString.replace(/[()\-\s]/g, "");

  // Attempt to parse the phone number
  const phoneNumber = parsePhoneNumberFromString(
    cleanedInput,
    defaultCountry.toUpperCase()
  );

  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.format("E.164"); // e.g., '+12812961570'
  }

  // If parsing fails, return the original input or handle as needed
  return phoneNumberString;
}
