// NavigationHeader.jsx

import React, { useState, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Badge, Alert, Tag, Modal } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  CalculatorOutlined,
  HomeOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { UserButton, useUser } from "@clerk/clerk-react";
import IconOnly from "./images/iconOnly.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faMoneyBill1,
  faTag,
  faFilter,
  faChartLine,
  faPersonWalkingArrowLoopLeft,
  faBoxesPacking,
  faTicket,
  faClockRotateLeft,
  faFileInvoice,
  faCommentDollar,
  faHammer,
  faListCheck,
  faDiagramProject,
  faPenRuler,
  faCompassDrafting,
  faBuilding,
  faCity,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTickets,
  useReminders,
  usePurchaseOrders,
  useVendors,
} from "./Contexts/useSpecificData";
import HelpMenu from "./Components/Support/HelpMenu";
import { useMediaQueryContext } from "./Contexts/MediaQueryContext";
import { FaRegHandshake, FaUps, FaUsps } from "react-icons/fa";
import dayjs from "dayjs";
import Marquee from "react-fast-marquee";
import { COLORS } from "./constants";
import Reminders from "./Pages/reminders/Reminders"; // Import Reminders component

const { Header } = Layout;

function NavigationHeader({ roles = [] }) {
  const location = useLocation();
  const navigate = useNavigate(); // For navigation
  const path = location.pathname.replace(/\/$/, ""); // Normalize path by removing trailing slash
  const currentModule = path.split("/")[1] || ""; // Ensure there's a module
  const { data: tickets = [] } = useTickets(); // Default to empty array
  const { user } = useUser(); // Get the current logged-in user
  const userEmail = user?.primaryEmailAddress?.emailAddress || ""; // Default to empty string

  const [errorLogs, setErrorLogs] = useState([]); // State for capturing logs

  const { isDesktop, isMobile } = useMediaQueryContext();

  // Provide default empty arrays to prevent undefined values
  const { data: reminders = [] } = useReminders();

  // Get today's date in 'MM/DD/YYYY' format
  const today = dayjs().format("MM/DD/YYYY");

  // Filter reminders for the current module, active, and date matching today
  const moduleRemindersForToday = reminders.filter(
    (reminder) =>
      reminder.active &&
      reminder.module === currentModule &&
      reminder.date <= today
  );

  // Get active reminders for current module
  const moduleActiveReminders = reminders.filter(
    (reminder) => reminder.active && reminder.module === currentModule
  );

  const remindersCount = moduleActiveReminders.length;

  // Handler for bell icon click
  const handleBellClick = () => {
    setIsModalVisible(true); // Open the modal instead of navigating
  };

  // List of users who can view preview items
  const previewUsers = [
    "cade.slayton@bmarko.com",
    "antony.kountouris@bmarko.com",
  ];
  const canViewPreview = previewUsers.includes(userEmail);

  // Determine if the user is a "sterling" user
  const isSterlingUser = useMemo(() => {
    return userEmail.toLowerCase().includes("sterling");
  }, [userEmail]);

  // Filter tickets for the current logged-in user
  const userTickets = useMemo(() => {
    return Array.isArray(tickets)
      ? tickets.filter((ticket) => ticket.userEmail === userEmail)
      : [];
  }, [tickets, userEmail]);

  // Define dynamic menu items based on current module, roles, and preview access
  const menuItems = useMemo(() => {
    let items = [];

    // ADMIN MODULE ****************************************************

    if (currentModule === "admin" && roles.includes("admin")) {
      items.push(
        {
          key: "/admin/users",
          label: "Users",
          icon: <UserOutlined />,
        },
        {
          key: "/admin/tickets",
          label: "Tickets",
          icon: <FontAwesomeIcon style={{ marginRight: 4 }} icon={faTicket} />,
        },
        {
          key: "/admin/manufacturing-locations",
          label: "Manufacturing Locations",
          icon: <FontAwesomeIcon style={{ marginRight: 4 }} icon={faCity} />,
        },
      );

      //ESTIMATING MODULES ****************************************************
    } else if (currentModule === "estimating") {
      if (roles.includes("estimator") || roles.includes("admin")) {
        items.push(
          {
            key: "/estimating/estimates",
            label: "Estimates",
            icon: <CalculatorOutlined style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/assemblies",
            label: "Assemblies",
            icon: <SettingOutlined style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/customers",
            label: "Customers",
            icon: <FaRegHandshake style={{ marginRight: 4, fontSize: 16 }} />,
          },
          {
            key: "/estimating/vendors",
            label: "Vendors",
            icon: <FontAwesomeIcon icon={faStore} style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/aria-items",
            label: "Aria Items",
            icon: <FontAwesomeIcon icon={faTag} style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/custom-items",
            label: "Custom Items",
            icon: <FontAwesomeIcon icon={faTag} style={{ marginRight: 4 }} />,
          }
        );
      }

      //operations MODULES ****************************************************
    } else if (currentModule === "operations") {
      if (roles.includes("admin") || roles.includes("operations")) {
        items.push(

        );

      }

            //operations MODULES ****************************************************
    } else if (currentModule === "project-management") {
      if (roles.includes("admin") || roles.includes("projectmgmt")) {
        items.push(
          {
            key: "/project-management/preconstruction",
            label: "Preconstruction",
            icon: <FontAwesomeIcon icon={faCompassDrafting} style={{ marginRight: 4 }} />,
          },
          {
            key: "/project-management/projects",
            label: "Projects",
            icon: <FontAwesomeIcon icon={faPenRuler} style={{ marginRight: 4 }} />,
          },
          {
            key: "/project-management/customers",
            label: "Customers",
            icon: <FontAwesomeIcon icon={faHandshake} style={{ marginRight: 4 }} />,
          },
        );

      }

      //PURCHASING MODULES ****************************************************
    
          }  else if (currentModule === "purchasing") {
      if (roles.includes("purchaser") || roles.includes("admin")) {
        items.push(
          {
            key: "/purchasing/purchase",
            label: "Purchase",
            icon: (
              <FontAwesomeIcon
                icon={faMoneyBill1}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/returns",
            label: "Returns",
            icon: (
              <FontAwesomeIcon
                icon={faPersonWalkingArrowLoopLeft}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/vendors",
            label: "Vendors",
            icon: <FontAwesomeIcon icon={faStore} style={{ marginRight: 4 }} />,
          },
          {
            key: "/purchasing/items",
            label: "Items",
            icon: <FontAwesomeIcon icon={faTag} style={{ marginRight: 4 }} />,
          },
          {
            key: "/purchasing/reporting",
            label: "Reporting",
            icon: (
              <FontAwesomeIcon icon={faFilter} style={{ marginRight: 4 }} />
            ),
          },
          {
            key: "/purchasing/dashboard",
            label: "Dashboard",
            icon: (
              <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 4 }} />
            ),
          },
          {
            key: "/purchasing/invoices",
            label: "Invoices",
            icon: (
              <FontAwesomeIcon
                icon={faFileInvoice}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/transactions",
            label: "Transactions",
            preview: true, // Only visible to preview users
            icon: (
              <FontAwesomeIcon
                icon={faCommentDollar}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          }
        );
      }

      //RECEIVING MODULES ****************************************************
    } else if (currentModule === "receiving") {
      if (roles.includes("receiver") || roles.includes("admin")) {
        items.push(
          {
            key: "/receiving/receive/purchase-orders",
            label: "Receive",
            icon: (
              <FontAwesomeIcon
                icon={faBoxesPacking}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          // Conditionally include "Unreceive" menu item
          !isSterlingUser && {
            key: "/receiving/unreceive",
            label: "Unreceive",
            icon: (
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/receiving/dashboard",
            label: "Dashboard",
            icon: (
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          }
        );
      }
    }

    // Filter out preview items if the user is not allowed to view them
    return items.filter((item) => !item.preview || canViewPreview);
  }, [currentModule, roles, canViewPreview, isSterlingUser]);

  // Determine the selected key based on the current path
  const selectedKey = useMemo(() => {
    // Find the menu item key that is the longest matching prefix of the current path
    let matchedKey = "";
    menuItems.forEach((item) => {
      if (path.startsWith(item.key) && item.key.length > matchedKey.length) {
        matchedKey = item.key;
      }
    });
    return matchedKey;
  }, [path, menuItems]);

  // Check if there are any reminders (active or inactive) for the current module
  const hasModuleReminders = reminders.some(
    (reminder) => reminder.module === currentModule
  );

  // State to control the modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0 24px",
          backgroundColor: "#001529",
        }}
        className="box-shadow"
      >
        {/* Left Section: Logo and Menu */}
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <div
            className="logo-wrapper"
            style={{ marginRight: 16, height: "100%" }}
          >
            <img
              src={IconOnly}
              alt="Logo"
              className="logo minimized"
              style={{ width: 40 }}
            />
          </div>

          {/* Render your menu here */}
          {/* Since Drawer is removed, you need an alternative for mobile */}
          <Menu
            theme="dark"
            mode={isMobile ? "inline" : "horizontal"}
            selectedKeys={[selectedKey]}
            style={{ flex: 1 }}
          >
            {menuItems.map((item) => (
              <Menu.Item
                key={item.key} // Ensure key matches the path exactly
                style={{ display: "flex", alignItems: "center" }}
              >
                <Link to={item.key}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.icon}
                    <span style={{ marginLeft: 4 }}>{item.label}</span>
                  </div>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </div>

        {/* Right Section: HelpMenu and User Button */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: !isDesktop ? "16px" : "32px",
          }}
        >
          {/* Only show the Bell Icon if there are any reminders for the current module */}
          {hasModuleReminders && (
            <Badge size="small" count={remindersCount} overflowCount={99}>
              <BellOutlined
                style={{ fontSize: 22, color: "white", cursor: "pointer" }}
                onClick={handleBellClick}
              />
            </Badge>
          )}

          {path !== "/" && (
            <HelpMenu userTickets={userTickets} logs={errorLogs} />
          )}

          {path !== "/" && roles.length > 1 && (
            <Link to="/" style={{ display: "flex", alignItems: "center" }}>
              <HomeOutlined style={{ fontSize: 22, color: "white" }} />
            </Link>
          )}

          <UserButton
            appearance={{
              elements: {
                userButtonAvatarBox: { width: "32px", height: "32px" },
              },
            }}
          />
        </div>
      </Header>

      {/* Modal for Reminders */}
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="90%" // Set modal width to 90%
        destroyOnClose={true} // Destroy modal content on close to reset state
      >
        <Reminders />
      </Modal>

      {/* Alert with Marquee */}
      {moduleRemindersForToday.length > 0 && (
        <Alert
          onClick={handleBellClick}
          style={{ cursor: "pointer" }}
          banner
          type="info"
          icon={<BellOutlined style={{ fontSize: 22 }} />}
          message={
            <Marquee
              onClick={handleBellClick}
              pauseOnHover
              gradient={false}
              speed={60}
            >
              {moduleRemindersForToday.map((reminder, index) => (
                <div
                  key={reminder.id}
                  style={{
                    marginRight: 24,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tag>
                    <strong style={{ marginRight: 8, color: COLORS.PRIMARY }}>
                      REMINDER {index + 1}:
                    </strong>
                    {reminder.body && reminder.body.length > 0 ? (
                      reminder.body.map((item, idx) => (
                        <span key={idx} style={{ marginRight: 8 }}>
                          {item.key}: <strong>{item.value}</strong>
                        </span>
                      ))
                    ) : (
                      <span>No Details Available</span>
                    )}
                    {reminder.notes && (
                      <span style={{ marginLeft: 8 }}>
                        <strong>Notes:</strong>
                        <span style={{color:COLORS.PRIMARY, fontStyle:"italic", marginLeft:4}}>{reminder.notes}</span>
                      </span>
                    )}
                  </Tag>
                </div>
              ))}
            </Marquee>
          }
        />
      )}
    </>
  );
}

export default NavigationHeader;
