import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  Select,
  Switch,
  Row,
  Col,
  Table,
  Tag,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  calculateLabor,
  calculateCeilingSF,
  calculateCovebaseLF,
  calculateFloorSF,
  calculateFramedLF,
  calculateWallSF,
} from "./calculations";
import { useItems, useModuleOptions } from "../../../Contexts/useSpecificData";
import { updateById } from "../../../Functions/updateById";
import { getDefaultPriceItem } from "../../../utils/itemsHelpers";
import { StopOutlined } from "@ant-design/icons";
import PriceTag from "../../../Styled/PriceTag";
import { TABLES } from "../../../constants";
import { updateSaleAmountAndProfit } from "../updateSaleAmountAndProfit";

const { Option } = Select;

const AddModuleModal = ({
  children,
  module,
  onAddSuccess,
  title,
  estimate,
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  // Store rainProtection as an object with item (including price), quantity, material, labor, and total
  const [rainProtection, setRainProtection] = useState(null);

  // Store permitted as an object with labor
  const [permitted, setPermitted] = useState(null);

  // Update categoryCosts to include all selected categories
  const [categoryCosts, setCategoryCosts] = useState({});

  const { data: moduleOptions } = useModuleOptions();
  const { data: items } = useItems();

  const shippingContainers = useMemo(
    () => items?.filter((item) => item.category === "SHIPPING CONTAINER") || [],
    [items]
  );

  // Memoize groupedModuleOptions for performance
  const groupedModuleOptions = useMemo(() => {
    return moduleOptions?.reduce((acc, option) => {
      const { category } = option;
      if (!acc[category]) acc[category] = [];
      acc[category].push(option);
      return acc;
    }, {});
  }, [moduleOptions]);

  // Calculate various structure metrics based on form values
  const calculateStructureMetrics = useCallback(
    (values) => ({
      "Wall SF": calculateWallSF(
        values.structureWidth,
        values.structureHeight,
        values.structureLength,
        values.longWallRemoved,
        values.endWallRemoved
      ),
      "Floor SF": calculateFloorSF(
        values.structureWidth,
        values.structureLength
      ),
      "Ceiling SF": calculateCeilingSF(
        values.structureWidth,
        values.structureLength
      ),
      "Framed LF": calculateFramedLF(
        values.structureLength,
        values.structureWidth,
        values.partitionWalls,
        values.longWallRemoved,
        values.endWallRemoved
      ),
      "Covebase LF": calculateCovebaseLF(
        values.structureLength,
        values.structureWidth,
        values.partitionWalls,
        values.longWallRemoved,
        values.endWallRemoved
      ),
    }),
    []
  );

  // Calculate the quantity of a material based on its coverage area or length
  const calculateMaterialQuantity = useCallback(
    (materialItem, structureMetrics) => {
      const uoms = Array.isArray(materialItem.uom)
        ? materialItem.uom.flatMap((uomStr) =>
            uomStr.split(",").map((uom) => uom.trim())
          )
        : [];
      const applicableUOMs = uoms.map((uom) => structureMetrics[uom] || 0);
      const applicableAreaOrLength = applicableUOMs.reduce(
        (total, value) => total + (isNaN(value) ? 0 : value),
        0
      );

      let requiredQuantity = 0;
      if (materialItem.coverageArea) {
        if (materialItem.multiplier > 0) {
          requiredQuantity =
            (applicableAreaOrLength * materialItem.multiplier) /
            (materialItem.coverageArea || 1); // Prevent division by zero
        } else {
          requiredQuantity =
            applicableAreaOrLength / (materialItem.coverageArea || 1);
        }
      }

      return Math.ceil(requiredQuantity);
    },
    []
  );

  // Calculate the cost of materials based on quantity and unit price
  const calculateMaterialCost = useCallback(
    (materialItem, structureMetrics, defaultPrice) => {
      const quantity = calculateMaterialQuantity(
        materialItem,
        structureMetrics
      );
      const totalCost = quantity * (defaultPrice || 0);
      return { quantity, totalCost, unitPrice: defaultPrice || 0 };
    },
    [calculateMaterialQuantity]
  );

  // Dedicated function to recalculate costs
  const recalculateCosts = useCallback(
    (updatedSelectedOptions = selectedOptions) => {
      const allValues = form.getFieldsValue([
        "longWallRemoved",
        "endWallRemoved",
        "structureLength",
        "structureWidth",
        "structureHeight",
        "partitionWalls",
        "rainProtection",
        "permitted",
        "containerQuantity",
        "containerId",
      ]);

      const {
        rainProtection: isRainProtection,
        permitted: isPermitted,
        containerQuantity,
        structureLength,
        containerId,
      } = allValues;

      const metrics = calculateStructureMetrics(allValues);
      const updatedCategoryCosts = {};

      // Use containerQuantity or default to 1
      const qty = containerQuantity || 1;

      // Calculate costs for selected module options
      Object.keys(updatedSelectedOptions).forEach((category) => {
        const option = updatedSelectedOptions[category];
        if (option) {
          let materialCost = 0;
          let laborCost = 0;

          // Calculate Material Cost
          if (
            Array.isArray(option.materialItems) &&
            option.materialItems.length > 0
          ) {
            materialCost = option.materialItems.reduce((total, item) => {
              const itemData = items.find((itm) => itm.id === item.item);
              const itemCost = getDefaultPriceItem(itemData, items) || 0;
              const { totalCost } = calculateMaterialCost(
                item,
                metrics,
                itemCost
              );
              return total + totalCost;
            }, 0);
          }

          // Calculate Labor Cost
          if (
            option.laborCost !== undefined &&
            Array.isArray(option.laborUOM) &&
            option.laborUOM.length > 0
          ) {
            let laborQuantity = 0;
            option.laborUOM.forEach((uomStr) => {
              const uoms = uomStr.split(",").map((uom) => uom.trim());
              uoms.forEach((uom) => {
                if (metrics[uom] !== undefined) {
                  laborQuantity += metrics[uom];
                }
              });
            });

            if (laborQuantity !== undefined) {
              laborCost = calculateLabor(option.laborCost, laborQuantity);
            }
          }

          // Total Cost for this category, multiplied by containerQuantity
          const totalCost = (materialCost + laborCost) * qty;
          updatedCategoryCosts[category] = {
            materialCost: materialCost * qty,
            laborCost: laborCost * qty,
            totalCost,
            ExpenseId: option.ExpenseId || null,
            id: option.id || null,
          };
        }
      });

      // Ensure all selected categories are present in categoryCosts
      Object.keys(updatedSelectedOptions).forEach((category) => {
        if (!updatedCategoryCosts[category]) {
          updatedCategoryCosts[category] = {
            materialCost: 0,
            laborCost: 0,
            totalCost: 0,
            ExpenseId: null,
            id: null,
          };
        }
      });

      // **Store Permitting as an object with labor inside the module**
      if (isPermitted) {
        const permittedLabor = 4250 * qty;
        setPermitted({
          labor: permittedLabor,
        });
      } else {
        setPermitted(null);
      }

      // **Store Rain Protection as an object inside the module with item (including price), quantity, material, labor, and total**
      if (isRainProtection) {
        // Rain Material Item: based on item number "20-0012"
        const rainItem = items.find((item) => item.itemNumber === "20-0012");
        if (!rainItem) {
          console.warn(
            "Rain protection item with itemNumber '20-0012' not found."
          );
          setRainProtection(null);
        } else {
          const rainItemPrice = getDefaultPriceItem(rainItem, items) || 0;
          const rainQty = containerQuantity * 0.33; // e.g., 2 containers => 0.66
          const rainLabor = structureLength < 20 ? 80 * qty : 175 * qty; // Labor based on structure length
          const rainMaterial = rainQty * rainItemPrice;
          const rainTotal = rainMaterial + rainLabor;

          // rainProtection stored directly with relevant cost fields
          setRainProtection({
            ...rainItem,
            material: rainMaterial,
            labor: rainLabor,
            total: rainTotal,
            quantity: rainQty,
            price: rainItemPrice,
          });
        }
      } else {
        setRainProtection(null);
      }

      // **Handle Container Costs**
      const containerLaborCost = 350 * qty;
      const selectedContainer = shippingContainers.find(
        (item) => item.id === containerId
      );
      if (!selectedContainer) {
        console.warn(`Container with ID ${containerId} not found.`);
      }
      const containerPrice = getDefaultPriceItem(selectedContainer, items) || 0;
      const containerTotal = qty * (containerLaborCost + containerPrice);

      // Calculate materialTotal and laborTotal from categoryCosts
      const materialTotal = Object.values(updatedCategoryCosts).reduce(
        (sum, cost) => sum + (cost.materialCost || 0),
        0
      );
      const laborTotal = Object.values(updatedCategoryCosts).reduce(
        (sum, cost) => sum + (cost.laborCost || 0),
        0
      );

      // Update categoryCosts state
      setCategoryCosts(updatedCategoryCosts);

      // Update container object with new fields
      const updatedContainer = {
        ...selectedContainer,
        quantity: qty,
        price: containerPrice,
        containerLabor: containerLaborCost,
        total: containerTotal,
        materialTotal,
        laborTotal,
      };
    },
    [
      form,
      calculateStructureMetrics,
      calculateLabor,
      calculateMaterialCost,
      items,
      shippingContainers,
      selectedOptions, // Include selectedOptions in dependencies
    ]
  );

  // Handle changes in form values to recalculate costs
  const handleValuesChange = useCallback(
    (changedValues, allValues) => {
      const relevantFields = [
        "longWallRemoved",
        "endWallRemoved",
        "structureLength",
        "structureWidth",
        "structureHeight",
        "partitionWalls",
        "rainProtection",
        "permitted",
        "containerQuantity",
        "containerId",
      ];
      const isRelevantChange = Object.keys(changedValues).some((key) =>
        relevantFields.includes(key)
      );

      if (isRelevantChange) {
        recalculateCosts();
      }
    },
    [recalculateCosts]
  );

  // Handle selection of a module option
  const handleOptionSelect = useCallback(
    (category, optionId) => {
      setSelectedOptions((prev) => {
        const updatedOptions = { ...prev };

        if (optionId === "none") {
          // Remove the category from selectedOptions
          delete updatedOptions[category];
        } else {
          // Select new option
          const option =
            moduleOptions.find((opt) => opt.id === optionId) || null;
          updatedOptions[category] = option;
        }

        // After updating selectedOptions, call recalculateCosts with the updated options
        recalculateCosts(updatedOptions);

        return updatedOptions;
      });
    },
    [moduleOptions, recalculateCosts]
  );

  // Define table columns, memoized for performance
  const columns = useMemo(
    () => [
      {
        width: 120,
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: (a, b) => a.category.localeCompare(b.category),
        defaultSortOrder: "ascend",
      },
      {
        width: 320,
        title: "Module Option",
        dataIndex: "moduleOption",
        key: "moduleOption",
        render: (_, { category }) => (
          <div style={{ whiteSpace: "normal" }}>
            <Select
              value={selectedOptions[category]?.id || "none"}
              placeholder="Select Module Option"
              style={{
                width: "100%",
                whiteSpace: "normal",
                minHeight: 60,
              }}
              onChange={(value) => handleOptionSelect(category, value)}
              dropdownStyle={{ width: "320px", whiteSpace: "normal" }}
              popupMatchSelectWidth={false}
            >
              <Option key="none" value="none">
                None
              </Option>
              {groupedModuleOptions[category]?.map((option) => (
                <Option
                  key={option.id}
                  value={option.id}
                  style={{ whiteSpace: "normal" }}
                >
                  <span
                    style={{
                      width: 320,
                      whiteSpace: "normal",
                      paddingRight: 24,
                    }}
                  >
                    {option.moduleOptionDescription}
                  </span>
                </Option>
              ))}
            </Select>
          </div>
        ),
      },
      {
        width: 100,
        title: "Material Cost",
        dataIndex: "materialCost",
        key: "materialCost",
        render: (_, { category }) => {
          const cost = categoryCosts[category]?.materialCost;
          return cost !== undefined && !isNaN(cost) ? (
            <PriceTag amount={cost} />
          ) : (
            <StopOutlined style={{ color: "red" }} />
          );
        },
      },
      {
        width: 100,
        title: "Labor Cost",
        dataIndex: "laborCost",
        key: "laborCost",
        render: (_, { category }) => {
          const cost = categoryCosts[category]?.laborCost;
          return cost !== undefined && !isNaN(cost) ? (
            <PriceTag amount={cost} />
          ) : (
            <StopOutlined style={{ color: "red" }} />
          );
        },
      },
      {
        width: 100,
        title: "Total Cost",
        dataIndex: "totalCost",
        key: "totalCost",
        render: (_, { category }) => {
          const cost = categoryCosts[category]?.totalCost;
          return cost !== undefined && !isNaN(cost) ? (
            <PriceTag amount={cost} />
          ) : (
            <StopOutlined style={{ color: "red" }} />
          );
        },
      },
      {
        width: 400,
        title: "Materials",
        dataIndex: "materials",
        key: "materials",
        render: (_, { category }) => {
          const option = selectedOptions[category];
          if (!option) return <StopOutlined style={{ color: "red" }} />;

          if (
            !Array.isArray(option.materialItems) ||
            option.materialItems.length === 0
          ) {
            return <StopOutlined style={{ color: "red" }} />;
          }

          const metrics = calculateStructureMetrics(form.getFieldsValue());
          const containerQty = form.getFieldValue("containerQuantity") || 1;

          const materialData = option.materialItems
            .map((materialItem) => {
              const matchedItem = items.find(
                (itm) => itm.id === materialItem.item
              );
              const { quantity, unitPrice, totalCost } = calculateMaterialCost(
                materialItem,
                metrics,
                getDefaultPriceItem(matchedItem, items) || 0
              );
              if (!matchedItem) return null;

              return {
                key: matchedItem.id,
                name: materialItem.name || matchedItem.itemDescription || "N/A",
                itemNumber: matchedItem.itemNumber,
                unitPrice: unitPrice,
                quantity: quantity * containerQty, // Multiply by containerQuantity
                totalCost: totalCost * containerQty, // Multiply by containerQuantity
              };
            })
            .filter((item) => item !== null);

          if (materialData.length === 0) {
            return <StopOutlined style={{ color: "red" }} />;
          }

          const materialColumns = [
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
              render: (text) => (text ? <Tag color="blue">{text}</Tag> : "N/A"),
            },
            {
              title: "Item #",
              dataIndex: "itemNumber",
              key: "itemNumber",
              render: (text) => <Tag>{text}</Tag>,
            },
            {
              title: "Price",
              dataIndex: "unitPrice",
              key: "unitPrice",
              render: (text) => <PriceTag amount={text} />,
            },
            {
              title: "Qty",
              dataIndex: "quantity",
              key: "quantity",
            },
            {
              title: "Total",
              dataIndex: "totalCost",
              key: "totalCost",
              render: (text) => <PriceTag amount={text} />,
            },
          ];

          return (
            <Table
              columns={materialColumns}
              dataSource={materialData}
              pagination={false}
              size="small"
              className="small-table"
              style={{
                paddingLeft: 0,
                marginLeft: 0,
                width: "100%",
                marginTop: 12,
                marginBottom: 12,
              }} // inline style
            />
          );
        },
      },
    ],
    [
      categoryCosts,
      calculateMaterialCost,
      groupedModuleOptions,
      handleOptionSelect,
      selectedOptions,
      items,
      calculateStructureMetrics,
      form,
    ]
  );

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      values.id = module ? module.id : uuidv4();

      const selectedContainer = shippingContainers.find(
        (item) => item.id === values.containerId
      );
      if (!selectedContainer) {
        console.warn(`Container with ID ${values.containerId} not found.`);
      }

      const containerQty = values.containerQuantity || 1;
      const containerPrice = getDefaultPriceItem(selectedContainer, items) || 0;
      const containerLabor = 350 * containerQty;
      const containerTotal = containerLabor + containerPrice;
      const containerMaterial = containerPrice * containerQty;

      // Calculate the structure metrics
      const metrics = calculateStructureMetrics(values);

      // Recalculate `selectedOptions` with updated `qtyToOrder`
      const updatedSelectedOptions = {};
      Object.keys(selectedOptions).forEach((category) => {
        const option = selectedOptions[category];
        if (
          option &&
          Array.isArray(option.materialItems) &&
          option.materialItems.length > 0
        ) {
          const updatedMaterialItems = option.materialItems.map(
            (materialItem) => {
              const quantity =
                calculateMaterialQuantity(materialItem, metrics) * containerQty; // Adjust for container quantity
              return { ...materialItem, qtyToOrder: quantity };
            }
          );
          updatedSelectedOptions[category] = {
            ...option,
            materialItems: updatedMaterialItems,
          };
        } else if (option) {
          // Handle categories without material items
          updatedSelectedOptions[category] = {
            ...option,
            materialItems: [],
          };
        }
      });

      // Include container details and other module-related data
      const updatedModule = {
        ...values,
        quantity: containerQty,
        selectedOptions: updatedSelectedOptions,
        categoryCosts,
        container: {
          ...selectedContainer,
          quantity: containerQty,
          price: containerPrice,
          labor: containerLabor,
          material: containerMaterial,
          total: containerTotal,
          // You might want to include materialTotal and laborTotal if needed
        }, // Updated container with new fields
        rainProtection, // Stored as an object with item (including price), quantity, material, labor, and total
        permitted, // Stored as an object with labor
      };

      const modules = estimate.modules || [];
      if (module) {
        const moduleIndex = modules.findIndex((mod) => mod.id === module.id);
        if (moduleIndex >= 0) modules[moduleIndex] = updatedModule;
      } else {
        modules.push(updatedModule);
      }

      // Update the estimate with the new module data
      const updatedEstimate = {
        ...estimate,
        modules,
      };

      await updateById(TABLES.ESTIMATES, { ...updatedEstimate }, estimate.id);
      await updateSaleAmountAndProfit(updatedEstimate);

      setVisible(false);
      setLoading(false);
      onAddSuccess(updatedModule);
    } catch (error) {
      console.error("Validation Failed:", error);
      setLoading(false);
    }
  };

  // Initial setup when the modal is opened for editing or adding
  useEffect(() => {
    if (visible) {
      if (module) {
        // Initialize form fields with module data
        form.setFieldsValue({
          ...module,
          containerId: module.container?.id,
          containerQuantity: module.container?.quantity,
        });

        // Initialize selectedOptions with module's selected options
        const initialSelectedOptions = {};
        Object.keys(module.selectedOptions || {}).forEach((category) => {
          const selectedOptionId = module.selectedOptions[category]?.id;
          const selectedOption =
            moduleOptions?.find((opt) => opt.id === selectedOptionId) || null;
          if (selectedOption) {
            initialSelectedOptions[category] = selectedOption;
          }
        });
        setSelectedOptions(initialSelectedOptions);

        // Initialize categoryCosts with module's existing costs
        setCategoryCosts(module.categoryCosts || {});

        // Initialize rainProtection if present
        setRainProtection(module.rainProtection || null);

        // Initialize permitted if present
        setPermitted(module.permitted || null);
      } else {
        // If adding a new module
        form.resetFields();
        setSelectedOptions({});
        setCategoryCosts({});
        setRainProtection(null);
        setPermitted(null);
      }
    }
  }, [visible]); // Only run when visible changes

  // Recalculate category costs whenever form values change
  useEffect(() => {
    // Only recalculate if modal is visible
    if (visible) {
      const hasAllFields = form.getFieldsValue([
        "longWallRemoved",
        "endWallRemoved",
        "structureLength",
        "structureWidth",
        "structureHeight",
        "partitionWalls",
        "rainProtection",
        "permitted",
        "containerQuantity",
        "containerId",
      ]);

      const allFieldsFilled = Object.values(hasAllFields).every(
        (value) => value !== undefined && value !== null
      );

      if (allFieldsFilled) {
        recalculateCosts();
      }
    }
  }, [form, visible]);

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: () => setVisible(true) })
      )}
      <Modal
        title={title || (module ? "Edit Module" : "Add New Module")}
        open={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        confirmLoading={loading}
        style={{ minWidth: "90%" }}
        footer={[
          <Button
            key="back"
            onClick={() => setVisible(false)}
            disabled={loading}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {module ? "Save Changes" : "Add Module"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
          {/* Module Name and Description */}
          <Row gutter={16}>
            <Col span={3}>
              <Form.Item
                name="containerQuantity"
                label="# of Modules"
                rules={[{ required: true, message: "Missing quantity" }]}
              >
                <Input type="number" min={1} />
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item
                name="moduleName"
                label="Module Name"
                rules={[
                  { required: true, message: "Please enter the module name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: "Please enter the description" },
                ]}
              >
                <Input.TextArea autoSize />
              </Form.Item>
            </Col>

            {/* Container Selection and Quantity */}
            <Col span={9}>
              <Form.Item
                name="containerId"
                label="Container"
                rules={[{ required: true, message: "Missing container" }]}
              >
                <Select
                  placeholder="Select a container"
                  dropdownStyle={{ minWidth: 300 }}
                >
                  {shippingContainers.map((item) => (
                    <Option key={item.id} value={item.id}>
                      <Tag>{item.itemNumber}</Tag> {item.itemDescription}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Structure Inputs */}
            <Col span={3}>
              <Form.Item
                name="structureLength"
                label="Structure Length"
                rules={[
                  { required: true, message: "Please enter structure length" },
                ]}
              >
                <Input type="number" addonAfter="Feet" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="structureWidth"
                label="Structure Width"
                rules={[
                  { required: true, message: "Please enter structure width" },
                ]}
              >
                <Input type="number" addonAfter="Feet" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="structureHeight"
                label="Structure Height"
                rules={[
                  { required: true, message: "Please enter structure height" },
                ]}
              >
                <Input type="number" addonAfter="Feet" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="partitionWalls"
                label="# of Partition Walls"
                rules={[
                  {
                    required: true,
                    message: "Please enter number of partition walls",
                  },
                ]}
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>

            {/* Switches */}
            <Col span={3} align="center">
              <Form.Item
                name="rainProtection"
                label="Rain Protection"
                valuePropName="checked"
                initialValue={false}
                className="centered-label"
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col span={3} align="center">
              <Form.Item
                name="permitted"
                label="Permitted"
                valuePropName="checked"
                initialValue={false}
                className="centered-label"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={3} align="center">
              <Form.Item
                name="longWallRemoved"
                label="Long Wall Removed"
                valuePropName="checked"
                initialValue={false}
                className="centered-label"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={3} align="center">
              <Form.Item
                name="endWallRemoved"
                label="End Wall Removed"
                valuePropName="checked"
                initialValue={false}
                className="centered-label"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          {/* Module Options Table */}
          <Table
            columns={columns}
            dataSource={useMemo(
              () =>
                Object.keys(groupedModuleOptions || {}).map((category) => ({
                  key: `${category}-${selectedOptions[category]?.id || "none"}`,
                  category,
                })),
              [groupedModuleOptions, selectedOptions]
            )}
            pagination={false}
            rowKey="key"
            sticky
            size="small"
          />
        </Form>
      </Modal>
    </>
  );
};

export default AddModuleModal;
