import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Select } from "antd"; // Import Select from antd
import ExpectedTableCard from "../../Components/Dashboard/ExpectedTableCard";
import LateTableCard from "../../Components/Dashboard/LateTableCard";
import ReceivedTableCard from "../../Components/Dashboard/ReceivedTableCard";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { Loader } from "../../Styled/Loader";
import { useUser } from "@clerk/clerk-react";

const { Option } = Select;

const Dashboard = () => {
  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();

  const { user } = useUser();
  const email = user?.primaryEmailAddress?.emailAddress || "";

  // Define filter options
  const filterOptions = [
    { label: "All Addresses", value: "All" },
    {
      label: "2624 Weaver Way Suite 200, Doraville, GA 30340",
      value: "2624 Weaver Way",
    },
    {
      label: "6544 Warren Drive NW, Suite 106, Norcross GA 30093",
      value: "6544 Warren",
    },
  ];

  // Determine initial filter based on email
  const initialFilter = useMemo(() => {
    if (email.toLowerCase().includes("sterling")) {
      return "6544 Warren";
    } else {
      return "All";
    }
  }, [email]);

  // State variable for filter
  const [filter, setFilter] = useState(initialFilter);

  // Memoize the filtered purchase orders based on filter
  const filteredPurchaseOrders = useMemo(() => {
    if (!purchaseOrders) return [];

    if (filter === "All") {
      return purchaseOrders;
    } else {
      return purchaseOrders.filter((po) =>
        po.shipToAddress.toLowerCase().includes(filter.toLowerCase())
      );
    }
  }, [purchaseOrders, filter]);

  useEffect(() => {
    document.title = "Dashboard - Purchasing"; // Set the tab title
  }, []);

  if (poLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: 16 }}
        justify="space-between"
        align="middle"
      >
        <Col>
          <div
            style={{
              fontSize: 24,
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            Dashboard
          </div>
        </Col>
        <Col>
          <span style={{fontWeight:600}}>Ship to Address: </span>
          <Select
            value={filter}
            onChange={(value) => setFilter(value)}
            style={{ width: 360 }}
          >
            {filterOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ExpectedTableCard
            purchaseOrders={filteredPurchaseOrders}
            vendors={vendors}
            projects={projects}
          />
        </Col>
        <Col span={24}>
          <LateTableCard
            purchaseOrders={filteredPurchaseOrders}
            vendors={vendors}
            projects={projects}
          />
        </Col>
        <Col span={24}>
          <ReceivedTableCard
            purchaseOrders={filteredPurchaseOrders}
            vendors={vendors}
            projects={projects}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
