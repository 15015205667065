// src/Functions/generateHtmlString.js

import dayjs from "dayjs";
import { formatMoney } from "../../../Formatters/helpers";

export const generateHtmlString = ({
  groupedItems,
  singleItems,
  leadTime,
  quoteValidity,
  tax,
  taxType,
  transport,
  address,
  customerName,
  userName,
  estimate,
}) => {
  // Subtotal is always estimate.costs.saleAmount
  const subtotal = estimate.costs.saleAmount || 0;

  // Filter visible items
  const visibleGroupedItems = groupedItems.filter((item) => item.visible);
  const visibleSingleItems = singleItems.filter((item) => item.visible);

  // Calculate Grouped Items Total
  const groupedItemsTotal = visibleGroupedItems.length
    ? subtotal // Assuming grouped items sum up to subtotal
    : 0;

  // Calculate Single Items Total
  const singleItemsTotal = visibleSingleItems.reduce((acc, item) => {
    return acc + (item.price || 0);
  }, 0);

  // **Calculate Adjusted Grouped Items Total by Subtracting Single Items Total**
  const adjustedGroupedItemsTotal = groupedItemsTotal - singleItemsTotal;

  // Calculate Tax Amount
  let taxAmount = 0;
  if (taxType === "%") {
    taxAmount = ((subtotal + transport) * (tax || 0)) / 100;
  } else if (taxType === "$") {
    taxAmount = tax || 0;
  } else if (taxType === "TBD" || taxType === "EXEMPT") {
    taxAmount = taxType;
  }

  // Calculate Grand Total
  let finalTotal = subtotal + transport;
  if (taxType !== "TBD" && taxType !== "EXEMPT") {
    finalTotal += taxAmount;
  }

  // Generate HTML rows for grouped items with Adjusted Total
  let groupedItemsHtml = "";
  if (visibleGroupedItems.length > 0) {
    const numGroupedItems = visibleGroupedItems.length;
    groupedItemsHtml += `
      <tr>
        <td class="description-cell">${visibleGroupedItems[0].description}</td>
        <td class="amount-cell" rowspan="${numGroupedItems}">
          ${formatMoney(adjustedGroupedItemsTotal)}
        </td>
      </tr>
    `;
    for (let i = 1; i < numGroupedItems; i++) {
      groupedItemsHtml += `
        <tr>
          <td class="description-cell">${visibleGroupedItems[i].description}</td>
        </tr>
      `;
    }
  }

  // Generate HTML rows for single items
  const singleItemsHtml = visibleSingleItems
    .map(
      (item) => `
    <tr>
      <td class="description-cell">${item.description}</td>
      <td class="amount-cell">
        ${item.price !== undefined ? formatMoney(item.price) : ""}
      </td>
    </tr>
  `
    )
    .join("");

  // Get current date using dayjs for consistent formatting
  const today = dayjs().format("MM/DD/YYYY");
  const quoteValidityDate = quoteValidity
    ? dayjs(quoteValidity).format("MM/DD/YYYY")
    : "";
  const leadTimeFormatted = leadTime ? `${leadTime}` : "";

  //PREPARED BY

  //<div class="left"><span class="bold">Prepared By:</span> ${userName}</div>

  // Assemble the complete HTML string with cleaned-up styles
  return `
    <html>
      <head>
        <style>
          /* Calibri Font */
          @import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;500;600&display=swap');

          /* Scoped styles under .pdf-preview */
          .pdf-preview {
            font-family: 'Calibri', sans-serif;
            margin: 0;
            padding: 0;
            position: relative;
          }
          .pdf-preview .quote-body {
            font-size: 12px;
            padding: 20px;
          }
          .pdf-preview .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 16px;
          }
          .pdf-preview .divider {
            width: 100%;
            height: 2px;
            background-color: #ED8293;
            margin: 16px 0;
          }

          .pdf-preview .left-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
          }
          .pdf-preview .right-header {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 50%;
          }
          .pdf-preview .logo img {
            max-width: 280px;
            height: auto;
          }
          .pdf-preview .quote-title {
            font-size: 22px;
            font-weight: 600;
            text-align: right;
            margin-bottom: 8px;
          }
          .pdf-preview .quote-details {
            width: 100%;
          }
          .pdf-preview .details-table {
            text-align: right; /* Align the entire table to the left */
            border-collapse: collapse;
            width: 100%;
          }
          .pdf-preview .details-table td {
            font-size: 12px;
            padding: 2px 4px; /* Reduced padding */
            box-sizing: border-box; /* Include padding and border in width */
          }

          .pdf-preview .label-column {
            text-align: right;
            width: 30%; /* Reduced width to bring labels closer */
            font-weight: 500;
            padding-right: 8px; /* Adjusted padding */
            box-sizing: border-box; /* Include padding and border in width */
          }
          .pdf-preview .value-column {
            text-align: left; /* Align values to the left */
            width: 0%; /* Increased width to accommodate values */
            padding-left: 8px; /* Adjusted padding */
            box-sizing: border-box; /* Include padding and border in width */
          }

          .pdf-preview .company-lead-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            margin-bottom: 8px;
          }

          .pdf-preview .prepared-customer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
            font-size: 12px;
          }

          .pdf-preview .bold {
            font-weight: 600;
          }

          /* Quote Table Styles */
          .pdf-preview .quote-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
            table-layout: fixed; /* Enforce fixed layout */
          }
          .pdf-preview .quote-table th,
          .pdf-preview .quote-table td {
            border: 2px solid black;
            padding: 8px 16px;
            font-size: 12px;
            word-wrap: break-word;
            box-sizing: border-box; /* Include padding and border in width */
          }
          .pdf-preview .quote-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-align: left;
            text-transform: uppercase;
          }

          /* Specific class for Amount column to ensure width */
          .pdf-preview .quote-table .amount-cell {
            width: 110px; /* Fixed width without negative sign */
            text-align: center; /* Keeps the amount centered */
            box-sizing: border-box; /* Include padding and border in width */
          }

          .pdf-preview .quote-table .description-cell {
            /* Ensure Description column takes the remaining space */
            width: calc(100% - 110px);
                        box-sizing: border-box; /* Include padding and border in width */
          }

          /* Center-align the Description header */
.pdf-preview .quote-table th.description-cell {
  text-align: center;
}

/* Ensure Description data cells remain left-aligned */
.pdf-preview .quote-table td.description-cell {
  text-align: left;
}


          /* Totals Table Styles */
          .pdf-preview .totals-table {
            width: 100%;
            max-width: 220px;
            border-collapse: collapse;
            margin-left: auto;
          }
          .pdf-preview .totals-table th,
          .pdf-preview .totals-table td {
            border: 2px solid black;
            padding: 8px 16px;
            text-align: center; /* Default center alignment */
            font-size: 12px;
            word-wrap: break-word;
            box-sizing: border-box; /* Include padding and border in width */
          }
          .pdf-preview .totals-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
          }
          .pdf-preview .totals-table .description-column {
            width: 110px;
            text-align: right; /* Aligns the first column to the right */
            box-sizing: border-box; /* Include padding and border in width */
          }
          .pdf-preview .totals-table .price-column {
            width: 110px;
            text-align: center; /* Keeps the price centered */
            box-sizing: border-box; /* Include padding and border in width */
          }
          .pdf-preview .totals-table td {
            background-color: #f9f9f9;
            text-transform: uppercase;
          }
          .pdf-preview .totals-table tr:last-child td {
            font-weight: 600;
            background-color: #f0f0f0;
          }
        </style>
      </head>
      <body>
        <div class="pdf-preview">
          <!-- Content -->
          <div class="quote-body">
            <!-- Header Section -->
            <div class="header">
              <div class="left-header">
                <div class="logo">
                  <img src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png" alt="Logo" />
                </div>
              </div>
              <div class="right-header">
                <div class="quote-title">Quote</div>
                <div class="quote-details">
                  <table class="details-table">
                    <tr>
                      <td class="label-column"><span class="bold">Date:</span></td>
                      <td class="value-column">${today}</td>
                    </tr>
                    <tr>
                      <td class="label-column"><span class="bold">Quote Validity:</span></td>
                      <td class="value-column">${quoteValidityDate}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!-- Company Name and Lead Time on the Same Line -->
            <div class="company-lead-time">
              <div class="company-name">BMarko Structures, LLC</div>
              <div class="lead-time"><span class="bold">Lead Time:</span> ${leadTimeFormatted}</div>
            </div>
            <!-- Divider -->
            <div class="divider"></div>
            <!-- Prepared By and Customer Section -->
            <div class="prepared-customer">
              <div class="right"><span class="bold">Customer:</span> ${customerName}</div>

            </div>
            <div><span class="bold">Transportation to:</span> ${
              address.split(", USA")[0]
            }</div> 
            <!-- Divider -->
            <div class="divider"></div>
            <!-- Quote Items Table -->
            <table class="quote-table">
              <thead>
                <tr>
                  <th class="description-cell">Description</th>
                  <th class="amount-cell">Amount</th>
                </tr>
              </thead>
              <tbody>
                ${groupedItemsHtml}
                ${singleItemsHtml}
              </tbody>
            </table>
            <!-- Totals Table -->
            <table class="totals-table">
              <tbody>
                <tr>
                  <td class="description-column">Subtotal</td>
                  <td class="price-column">${formatMoney(subtotal)}</td>
                </tr>
                <tr>
                  <td class="description-column">Transport</td>
                  <td class="price-column">${formatMoney(transport)}</td>
                </tr>
                <tr>
                  <td class="description-column">Tax</td>
                  <td class="price-column">${
                    taxType === "TBD" || taxType === "EXEMPT"
                      ? taxType
                      : formatMoney(taxAmount)
                  }</td>
                </tr>
                <tr>
                  <td class="description-column">Grand Total</td>
                  <td class="price-column">${formatMoney(finalTotal)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </body>
    </html>
  `;
};
