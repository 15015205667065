// utils/getCustomerCompanyName.js

/**
 * Retrieves the company name of a customer based on their ID.
 *
 * @param {string} customerId - The ID of the customer.
 * @param {Array} customers - The array of customer objects.
 * @returns {string|null} - The company name if found, otherwise null.
 */
export function getCustomerCompanyName(customerId, customers) {
  if (!customerId || !customers) return null;

  const customer = customers.find((cust) => cust.id === customerId);
  return customer ? customer.companyName : null;
}
