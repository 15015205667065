import React, { useState, useMemo } from "react";
import { Select, Card, Button, Row, Col } from "antd";
import { StopOutlined } from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import dayjs from "dayjs";
import { getProjectName } from "../../Formatters/getProjectName";
import { Loader } from "../../Styled/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "@clerk/clerk-react";
import { COLORS } from "../../constants";

const { Option } = Select;

function ReceivePurchase() {
  const [selectedPO, setSelectedPO] = useState(null);
  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const { data: vendors, loading: vendorsLoading } = useVendors();
  const { data: projects } = useProjects();

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress || "";

  // Determine if the user is a "sterling" user
  const isSterlingUser = useMemo(() => {
    return userEmail.toLowerCase().includes("sterling");
  }, [userEmail]);

  // Determine filter based on user role
  const filter = useMemo(() => {
    if (isSterlingUser) {
      return "6544 Warren";
    } else {
      return "All";
    }
  }, [isSterlingUser]);

  // Utility function to extract numerical part from poNumber for sorting
  const extractNumber = (poNumber) => {
    const match = poNumber.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  // Filter purchaseOrders based on filter and lines with qtyReceived < qtyOrdered
  const filteredPurchaseOrders = useMemo(() => {
    if (!purchaseOrders) return [];

    // First, filter POs that have at least one line with qtyReceived < qtyOrdered
    let filteredPOs = purchaseOrders.filter((po) =>
      po.lines.some((line) => line.qtyReceived < line.qtyOrdered)
    );

    // Then, apply shipToAddress filter if necessary
    if (filter !== "All") {
      filteredPOs = filteredPOs.filter((po) =>
        po.shipToAddress.toLowerCase().includes(filter.toLowerCase())
      );
    }

    // Sort by poNumber descending
    filteredPOs.sort(
      (a, b) => extractNumber(b.poNumber) - extractNumber(a.poNumber)
    );

    return filteredPOs;
  }, [purchaseOrders, filter]);

  // Handle PO Select
  const handleSelect = (value) => {
    const po = filteredPurchaseOrders.find((po) => po.id === value);
    setSelectedPO(po);
  };

  if (poLoading || vendorsLoading) {
    return <Loader />;
  }

  return (
    <>
      {/* Purchase Order Selection Dropdown */}
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a Purchase Order"
            optionFilterProp="children"
            allowClear
            dropdownStyle={{ maxHeight: 400, overflowY: "auto" }} // Prevents page scrolling on mobile
            getPopupContainer={(triggerNode) => triggerNode.parentNode} // Ensures dropdown scrolls independently
            onSelect={handleSelect} // Handle selection
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            value={selectedPO ? selectedPO.id : undefined}
          >
            {filteredPurchaseOrders.map((po) => (
              <Option key={po.id} value={po.id}>
                {`${po.poNumber} - ${getVendorName(po.vendor, vendors)}`}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      {/* Display PO Details and Next Button */}
      {selectedPO && (
        <>
          <Card style={{ marginTop: 16 }}>
            <p>
              <strong>Address:</strong> {selectedPO.shipToAddress}
            </p>
            <p>
              <strong>Project:</strong>{" "}
              {getProjectName(selectedPO.projectNumber, projects)}
            </p>
            <p>
              <strong>Buyer:</strong> {selectedPO.buyer}
            </p>
            <p>
              <strong>Required Date:</strong>{" "}
              {dayjs(selectedPO.requiredDate).format("MM/DD/YYYY")}
            </p>
            <p>
              <strong>Notes:</strong>{" "}
              {selectedPO.notes || (
                <StopOutlined
                  style={{ color: COLORS.WARNING, marginLeft: 8 }}
                />
              )}
            </p>
          </Card>
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            href={`/receiving/receive/purchase-orders/${selectedPO.id}`}
            style={{ marginTop: 16, width: "100%" }}
          >
            Next
          </Button>
        </>
      )}
    </>
  );
}

export default ReceivePurchase;
