// src/Components/Estimating/Timeline/EstimateTimeline.jsx

import React from "react";
import { Timeline, Typography, Tooltip } from "antd";
import {
  CheckCircleFilled,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { COLORS } from "../../../constants";

const { Text } = Typography;

function EstimateTimeline({ estimate, dynamicAlign = false }) {
  const hasHistory = estimate?.history && estimate.history.length > 0;

  // Check if there are any events with destination "Estimating"
  const hasEstimatingEvents =
    hasHistory &&
    estimate.history.some((event) => event.destination === "Estimating");

  // Determine alignment based on dynamicAlign prop and presence of estimating events
  const shouldAlignLeft = dynamicAlign && !hasEstimatingEvents;

  const getIcon = (destination) => {
    switch (destination) {
      case "Sales":
        return <CheckCircleFilled style={{ fontSize: 24, color: "green" }} />;
      case "Estimating":
        return <ClockCircleOutlined style={{ fontSize: 24, color: "gray" }} />;
      case "Downloaded":
        return (
          <FilePdfOutlined style={{ fontSize: 24, color: COLORS.PRIMARY }} />
        );
      case "Created":
        return <InfoCircleOutlined style={{ fontSize: 24, color: "orange" }} />;
      default:
        return (
          <ExclamationCircleOutlined style={{ fontSize: 24, color: "gray" }} />
        );
    }
  };

  const getDisplayText = (destination) => {
    switch (destination) {
      case "Sales":
        return "Sent to Sales";
      case "Estimating":
        return "Sent back to Estimating";
      case "Downloaded":
        return "Estimate Downloaded";
      case "Created":
        return "Estimate Created";
      // Add more cases as needed
      default:
        return destination; // Fallback to the original destination if no match
    }
  };

  const creationEvent = {
    id: "creation-event",
    destination: "Created",
    notes: "",
    userName: estimate.requestor.fullName,
    timestamp: estimate.created,
    requestor: estimate.requestor,
    assignedTo: {},
  };

  const sortedHistory = hasHistory
    ? [...estimate.history].sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      )
    : [];

  const timelineEvents = [creationEvent, ...sortedHistory];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: shouldAlignLeft ? "flex-start" : "center",
        padding: "16px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          width: "100%",
          paddingTop: "32px",
          backgroundColor: "white",
          padding: "24px",
        }}
      >
        <Timeline
          mode={shouldAlignLeft ? "left" : "alternate"}
          style={{ margin: "0 auto", width: "100%" }}
        >
          {/* Creation Event */}
          <Timeline.Item
            key={creationEvent.id}
            dot={getIcon(creationEvent.destination)}
            color="orange"
          >
            <div style={{ textAlign: "left" }}>
              <Text
                strong
              >{`Created - Estimate ${estimate.estimateNumber}`}</Text>
              <div>
                <Text type="secondary">{`Created by ${creationEvent.userName}`}</Text>
              </div>
              <div>
                <Text type="secondary">
                  {dayjs(creationEvent.timestamp).format("MMMM D, YYYY h:mm A")}
                </Text>
              </div>
            </div>
          </Timeline.Item>

          {/* History Events */}
          {hasHistory &&
            sortedHistory.map((item) => {
              const isEstimating = item.destination === "Estimating";

              // Determine the position of the timeline item
              const itemPosition = shouldAlignLeft
                ? undefined // Defaults to left in "left" mode
                : isEstimating
                ? "right"
                : "left";

              // Determine text alignment based on position
              const textAlign = shouldAlignLeft
                ? "left"
                : itemPosition === "right"
                ? "left"
                : "right";

              // Determine alignment of content within the item
              const alignItems =
                shouldAlignLeft || itemPosition === "left"
                  ? "flex-start"
                  : "flex-end";

              return (
                <Timeline.Item
                  key={item.id}
                  dot={getIcon(item.destination)}
                  color={
                    item.destination === "Sales"
                      ? "green"
                      : item.destination === "Estimating"
                      ? "blue"
                      : item.destination === "Downloaded"
                      ? COLORS.PRIMARY
                      : "gray"
                  }
                  position={itemPosition}
                >
                  <div
                    style={{
                      textAlign: textAlign,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: alignItems,
                      marginRight: shouldAlignLeft ? "0px" : "8px",
                    }}
                  >
                    <Text strong>{getDisplayText(item.destination)}</Text>
                    <div>
                      <Tooltip title={`Performed by ${item.userName}`}>
                        <Text>{item.userName}</Text>
                      </Tooltip>
                    </div>
                    <div>
                      <Text type="secondary">
                        {dayjs(item.timestamp).format("MMMM D, YYYY h:mm A")}
                      </Text>
                    </div>
                    {item.notes && (
                      <div>
                        <Text>
                          <strong>Notes:</strong> {item.notes}
                        </Text>
                      </div>
                    )}
                  </div>
                </Timeline.Item>
              );
            })}
        </Timeline>
      </div>
    </div>
  );
}

export default EstimateTimeline;
