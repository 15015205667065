// QuotePDF.js
import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  Button,
  Modal,
  Form,
  message,
  Tag,
  Collapse,
  Card,
  Spin, // Import Spin for the spinner
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DragOutlined,
  FilePdfOutlined,
  SendOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { v4 as uuidv4 } from "uuid";
import debounce from "lodash.debounce";
import isEqual from "lodash.isequal";
import { useUser } from "@clerk/clerk-react";
import { useCustomers } from "../../../Contexts/useSpecificData";
import { getCustomerName } from "../../../Formatters/getCustomerName";
import dayjs from "dayjs";
import AddressAutocomplete from "../../Google/AddressAutocomplete";
import { updateById } from "../../../Functions/updateById";
import { COLORS, TABLES } from "../../../constants";
import { generateHtmlString } from "./generateHtmlString";
import { generatePdf } from "../../../Functions/generatePdf"; // Ensure generatePdf is correctly imported
import { quoteHelper } from "./quoteHelper"; // Ensure quoteHelper is imported
import styles from "./QuotePDF.module.css";
import { sendEmail } from "../../../Functions/sendEmail";
import { formatMoney } from "../../../Formatters/helpers";

// Utility constants
const { Option } = Select;
const { Panel } = Collapse;

// Color mapping for item types
const TYPE_COLOR_MAP = {
  "Custom Item": "blue",
  "Aria Item": "purple",
  Assembly: "red",
  Module: "orange",
  "Module Extra": "orange",
  "Module Option": "orange",
  "Line Item": "default",
  default: "default",
};

// Reusable LineItem component with Edit functionality
const LineItem = ({
  item,
  index,
  type,
  isEditing,
  editedValues,
  onEdit,
  onEditChange,
  onSave,
  onToggleVisibility,
  onDelete,
  isSaving,
  buttonDisabled,
  interactionDisabled, // New Prop
}) => {
  // Determine if buttons should be disabled
  const isButtonDisabled =
    isEditing || isSaving || buttonDisabled || interactionDisabled;
  
  const isCheckButtonnDisabled =  isSaving || buttonDisabled || interactionDisabled;

  return (
    <Draggable
      draggableId={item.id}
      index={index}
      isDragDisabled={isButtonDisabled} // Disable dragging if editing, saving, buttonDisabled, or interactionDisabled is true
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`${styles.draggableItem} ${
            snapshot.isDragging ? styles.dragging : ""
          }`}
          style={{
            userSelect: "none",
            padding: 8,
            marginBottom: 8,
            background:
              type === "Grouped"
                ? "#fff"
                : item.price == null || isNaN(item.price)
                ? "#ffe6e6" // Light red for invalid price
                : "#fff",
            border: "1px solid #ddd",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            ...provided.draggableProps.style,
          }}
        >
          <div
            {...provided.dragHandleProps}
            style={{
              marginRight: 8,
              cursor: isButtonDisabled ? "not-allowed" : "move",
            }}
          >
            <DragOutlined />
          </div>
          {item.source && (
            <Tag
              color={TYPE_COLOR_MAP[item.source] || TYPE_COLOR_MAP.default}
              style={{ marginRight: 8 }}
            >
              {item.source}
            </Tag>
          )}
          <Input.TextArea
            value={isEditing ? editedValues.description : item.description}
            autoSize
            onChange={(e) => onEditChange("description", e.target.value)}
            placeholder="Description"
            style={{ flex: 1, marginRight: 8 }}
            disabled={!isEditing || isSaving}
          />
          {type === "Single" && (
            <InputNumber
              value={isEditing ? editedValues.price : item.price}
              onChange={(value) => onEditChange("price", value)}
              type="number"
              prefix="$"
              style={{ width: "100px", marginRight: 8 }}
              disabled={!isEditing || isSaving}
            />
          )}
          <div style={{ display: "flex", gap: 4 }}>
            {/* Visibility Toggle */}
            <Button
              disabled={isButtonDisabled}
              type="text"
              size="small"
              icon={item.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onClick={() => onToggleVisibility(type, item.id)}
            />
            {/* Edit / Save Button */}
            {!item.isInitial &&
              (isEditing ? (
                <Button
                  type="text"
                  size="small"
                  icon={<CheckOutlined style={{ color: "green" }} />}
                  onClick={onSave}
                  loading={isSaving}
                  disabled={isCheckButtonnDisabled}
                />
              ) : (
                <Button
                  type="text"
                  size="small"
                  icon={<EditOutlined style={{ color: COLORS.PRIMARY }} />}
                  onClick={onEdit}
                  disabled={isSaving || buttonDisabled || interactionDisabled}
                />
              ))}
            {/* Delete Button */}
            {!item.isInitial && (
              <Button
                disabled={isButtonDisabled}
                type="text"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => onDelete(type, item.id)}
                danger
              />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

// Reusable AddLineItem component
const AddLineItem = ({ onAdd }) => (
  <Button
    type="dashed"
    block
    icon={<PlusOutlined />}
    onClick={onAdd}
    style={{ marginTop: 8 }}
  >
    Add Line Item
  </Button>
);

const QuotePDF = ({ estimate }) => {
  // Fetching user and customers
  const { user } = useUser();
  const { data: customers } = useCustomers();

  // User and customer details
  const userName = user?.fullName || "User";
  const userEmail =
    user?.primaryEmailAddress?.emailAddress || "user@example.com";
  const customerName =
    getCustomerName(estimate.customerId, customers) || "Customer";

  // Form state variables
  const [leadTime, setLeadTime] = useState(estimate.leadTime || "10-12 Weeks");

  // **New State for Quote Validity Number**
  const initialQuoteValidityNumber = estimate.quoteValidityNumber
    ? estimate.quoteValidityNumber
    : 30;
  const [quoteValidityNumber, setQuoteValidityNumber] = useState(
    initialQuoteValidityNumber
  );

  // **Updated State for Quote Validity Date**
  const [quoteValidity, setQuoteValidity] = useState(
    estimate.quoteValidity
      ? dayjs(estimate.quoteValidity)
      : dayjs().add(quoteValidityNumber, "day")
  );

  const [tax, setTax] = useState(estimate.costs?.tax?.value || 0);
  const [taxType, setTaxType] = useState(estimate.costs?.tax?.type || "%");
  const [transport, setTransport] = useState(estimate.costs?.transport || 0);
  const [address, setAddress] = useState(estimate.transportationAddress || "");
  const [sendToSalesNotes, setSendToSalesNotes] = useState("");
  const [sendToEstimatingNotes, setSendToEstimatingNotes] = useState("");
  const [salesLoading, setSalesLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // Modal state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addItemType, setAddItemType] = useState("Single");
  const [form] = Form.useForm();

  // State for line items
  const [groupedItems, setGroupedItems] = useState([]);
  const [singleItems, setSingleItems] = useState([]);

  // History and action destination
  const [history, setHistory] = useState(estimate.history || []);
  const [actionDestination, setActionDestination] = useState("Sales");

  // Refs for debounced functions and tracking
  const lastLineItems = useRef([]);
  const isDragging = useRef(false);

  // Confirmation Modal state
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  // States for editing items
  const [editingItemIds, setEditingItemIds] = useState([]);
  const [editedItems, setEditedItems] = useState({});
  const [savingItemIds, setSavingItemIds] = useState([]);

  // **New State for Temporarily Disabling Interactions**
  const [interactionDisabled, setInteractionDisabled] = useState(false);
  const interactionTimeoutRef = useRef(null);

  // **Effect to Update Quote Validity Date Based on Number of Days**
  useEffect(() => {
    const newQuoteValidity = dayjs().add(quoteValidityNumber, "day");
    setQuoteValidity(newQuoteValidity);
  }, [quoteValidityNumber]);

  // Handlers for editing
  const handleEdit = (item) => {
    setEditingItemIds((prev) => [...prev, item.id]);
    setEditedItems((prev) => ({
      ...prev,
      [item.id]: {
        description: item.description,
        price: item.price,
      },
    }));
  };

  const handleEditChange = (itemId, field, value) => {
    setEditedItems((prev) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [field]: value,
      },
    }));
  };

  const handleSave = async (itemId) => {
    const updatedValues = editedItems[itemId];
    setSavingItemIds((prev) => [...prev, itemId]);

    // Determine the type of item (Grouped or Single)
    const isGrouped = groupedItems.some((item) => item.id === itemId);
    if (isGrouped) {
      setGroupedItems((prev) =>
        prev.map((item) =>
          item.id === itemId ? { ...item, ...updatedValues } : item
        )
      );
    } else {
      setSingleItems((prev) =>
        prev.map((item) =>
          item.id === itemId ? { ...item, ...updatedValues } : item
        )
      );
    }

    try {
      // Update backend by calling updateLineItems with updated line items
      const allLineItems = [
        ...groupedItems.map((item, index) => ({
          ...item,
          itemType: "Grouped",
          order: index,
        })),
        ...singleItems.map((item, index) => ({
          ...item,
          itemType: "Single",
          order: groupedItems.length + index,
        })),
      ];

      // Replace the updated item
      const updatedLineItems = allLineItems.map((item) =>
        item.id === itemId ? { ...item, ...updatedValues } : item
      );

      await updateLineItems(updatedLineItems);
      message.success("Line item updated successfully!");
    } catch (error) {
      message.error("Failed to update line item.");
      console.error("Error updating line item:", error);
    }

    // Remove from editing and saving
    setEditingItemIds((prev) => prev.filter((id) => id !== itemId));
    setEditedItems((prev) => {
      const newEditedItems = { ...prev };
      delete newEditedItems[itemId];
      return newEditedItems;
    });
    setSavingItemIds((prev) => prev.filter((id) => id !== itemId));
  };

  // Function to update estimate details
  const updateEstimateQuoteDetails = async () => {
    try {
      const updatedEstimate = {
        ...estimate,
        costs: {
          ...estimate.costs,
          tax: {
            type: taxType,
            value: tax,
          },
          transport: transport,
        },
        transportationAddress: address,
        leadTime: leadTime,
        quoteValidity: quoteValidity ? quoteValidity.toISOString() : null,
        quoteValidityNumber: quoteValidityNumber, // Save the number as well
      };

      await updateById(TABLES.ESTIMATES, updatedEstimate, estimate.id);
      console.log("Estimate details updated successfully.");
    } catch (error) {
      console.error("Error updating estimate details:", error);
      message.error("Failed to update estimate details.");
    }
  };

  // Debounced update function
  const debouncedUpdate = useMemo(
    () => debounce(updateEstimateQuoteDetails, 100),
    [
      taxType,
      tax,
      transport,
      address,
      leadTime,
      quoteValidity,
      quoteValidityNumber,
    ]
  );

  // Trigger debounced update on relevant state changes
  useEffect(() => {
    debouncedUpdate();
  }, [
    taxType,
    tax,
    transport,
    address,
    leadTime,
    quoteValidity,
    quoteValidityNumber,
    debouncedUpdate,
  ]);

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  // Function to update line items in backend
  const updateLineItems = async (updatedLineItems) => {
    try {
      const updatedEstimate = {
        ...estimate,
        quote: {
          ...estimate.quote,
          lines: updatedLineItems,
        },
      };

      await updateById(TABLES.ESTIMATES, updatedEstimate, estimate.id);
      console.log("Line items updated successfully.");
    } catch (error) {
      console.error("Error updating line items:", error);
      message.error("Failed to update line items.");
    }
  };

  // Debounced update for line items
  const debouncedUpdateLineItems = useMemo(
    () => debounce(updateLineItems, 500),
    [estimate]
  );

  // Reconcile initial and custom items
  useEffect(() => {
    setButtonDisabled(true);
    const existingLines = estimate.quote?.lines || [];
    const initialLineItems = quoteHelper(estimate); // Get dynamic items from quoteHelper

    console.log("Existing Lines:", existingLines);
    console.log("Initial Line Items:", initialLineItems);

    // Separate existing lines into initial and custom items
    const existingInitialItems = existingLines.filter(
      (line) => line.isInitial && line.itemType === "Grouped"
    );
    const existingCustomItems = existingLines.filter(
      (line) => !line.isInitial && line.itemType === "Grouped"
    );
    const existingSingleItems = existingLines.filter(
      (line) => line.itemType === "Single"
    );

    // Sort existing initial items by 'order' to preserve user-defined order
    const sortedExistingInitialItems = [...existingInitialItems].sort(
      (a, b) => a.order - b.order
    );

    // Reconcile existing initial items with dynamic initial items from quoteHelper
    const reconciledInitialItems = sortedExistingInitialItems
      .map((existingItem) => {
        const matchingDynamicItem = initialLineItems.find(
          (dynamicItem) => dynamicItem.id === existingItem.id
        );

        if (matchingDynamicItem) {
          // Merge existing initial item with dynamic data
          return {
            ...existingItem,
            ...matchingDynamicItem,
            visible: existingItem.visible, // Preserve visibility from existing item
          };
        } else {
          // If the dynamic item no longer exists, exclude it by returning null
          return null;
        }
      })
      .filter((item) => item !== null); // Remove nulls (items to be excluded)

    // Identify dynamic items that are not present in existing initial items
    const newDynamicItems = initialLineItems.filter(
      (dynamicItem) =>
        !existingInitialItems.some(
          (existingItem) => existingItem.id === dynamicItem.id
        )
    );

    // Sort new dynamic items by their 'order' to integrate them properly
    const sortedNewDynamicItems = [...newDynamicItems].sort(
      (a, b) => a.order - b.order
    );

    // Combine reconciled existing initial items with new dynamic items
    const combinedInitialItems = [
      ...reconciledInitialItems,
      ...sortedNewDynamicItems,
    ];

    // To maintain the overall order based on existingLines' order, map the order accordingly
    // Create a map of existing orders to handle ordering conflicts
    const orderMap = new Map();
    sortedExistingInitialItems.forEach((item) => {
      orderMap.set(item.id, item.order);
    });

    // Assign orders to new dynamic items that might not have an existing order
    sortedNewDynamicItems.forEach((item) => {
      if (!orderMap.has(item.id)) {
        // Assign the next available order number
        const maxOrder = Math.max(...orderMap.values(), 0);
        orderMap.set(item.id, maxOrder + 1);
      }
    });

    // Now, apply the order to the combined initial items
    const orderedInitialItems = combinedInitialItems
      .map((item) => ({
        ...item,
        order: orderMap.get(item.id) || item.order, // Prefer existing order, fallback to dynamic item's order
      }))
      .sort((a, b) => a.order - b.order); // Final sort by order

    // **Integrate Custom Items While Preserving Their Order**
    // To maintain the relative order of custom items, we'll interleave them based on their original positions.
    // Here's one approach:

    // 1. Create a combined list with both ordered initial items and custom items.
    // 2. Sort the combined list by the original order from existingLines, giving priority to existingLines' order.

    // First, map existingLines to determine the preferred overall order
    const combinedGroupedLines = existingLines.filter(
      (line) => line.itemType === "Grouped"
    );

    // Create a map for initial items and custom items
    const initialItemsMap = new Map();
    orderedInitialItems.forEach((item) => initialItemsMap.set(item.id, item));

    const customItemsMap = new Map();
    existingCustomItems.forEach((item) => customItemsMap.set(item.id, item));

    // Reconstruct the grouped items list based on existingLines' order, inserting new dynamic items appropriately
    const finalGroupedItems = [];

    combinedGroupedLines.forEach((line) => {
      if (line.isInitial) {
        const item = initialItemsMap.get(line.id);
        if (item) {
          finalGroupedItems.push(item);
          initialItemsMap.delete(line.id);
        }
      } else {
        const customItem = customItemsMap.get(line.id);
        if (customItem) {
          finalGroupedItems.push(customItem);
          customItemsMap.delete(line.id);
        }
      }
    });

    // Add any remaining initial items that were not present in existingLines (new dynamic items)
    const remainingInitialItems = Array.from(initialItemsMap.values()).sort(
      (a, b) => a.order - b.order
    );
    finalGroupedItems.push(...remainingInitialItems);

    // Add any remaining custom items that were not present in existingLines (if any)
    const remainingCustomItems = Array.from(customItemsMap.values()).sort(
      (a, b) => a.order - b.order
    );
    finalGroupedItems.push(...remainingCustomItems);

    // **Final State Updates**
    setGroupedItems(finalGroupedItems);

    // Set single items state (preserved as-is)
    setSingleItems(existingSingleItems);
    setButtonDisabled(false);
  }, [estimate]);

  useEffect(() => {
    const lastRelevantAction = history.findLast(
      (action) =>
        action.destination === "Sales" || action.destination === "Estimating"
    );

    if (lastRelevantAction) {
      setActionDestination(
        lastRelevantAction.destination === "Sales" ? "Estimating" : "Sales"
      );
    } else {
      setActionDestination("Sales");
    }
  }, [history]);

  // Handler for tax type change
  const handleTaxTypeChange = (value) => {
    setTaxType(value);
    if (value === "TBD" || value === "EXEMPT") {
      setTax(null);
    }
  };

  // Debounced update for line items
  useEffect(() => {
    const allLineItems = [
      ...groupedItems.map((item, index) => ({
        ...item,
        itemType: "Grouped",
        order: index,
      })),
      ...singleItems.map((item, index) => ({
        ...item,
        itemType: "Single",
        order: groupedItems.length + index,
      })),
    ];

    if (!isEqual(allLineItems, lastLineItems.current)) {
      lastLineItems.current = allLineItems;
      debouncedUpdateLineItems(allLineItems);
    }
  }, [groupedItems, singleItems, debouncedUpdateLineItems]);

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedUpdateLineItems.cancel();
    };
  }, [debouncedUpdateLineItems]);

  // Drag and drop handlers
  const onDragStart = () => {
    isDragging.current = true;
  };

  const onDragEnd = (result) => {
    isDragging.current = false;
    const { destination, source } = result;

    if (!destination) return;

    // Prevent dragging grouped items to single items and vice versa
    if (
      (source.droppableId === "groupedItems" &&
        destination.droppableId === "singleItems") ||
      (source.droppableId === "singleItems" &&
        destination.droppableId === "groupedItems")
    ) {
      message.error("Cannot move items between Grouped and Single Items.");
      return;
    }

    // If dropped in the same place, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    // Determine source and destination lists
    let sourceList, setSource;
    if (source.droppableId === "groupedItems") {
      sourceList = groupedItems;
      setSource = setGroupedItems;
    } else {
      sourceList = singleItems;
      setSource = setSingleItems;
    }

    // Reordering within the same list
    const reordered = reorder(sourceList, source.index, destination.index);
    setSource(reordered);

    // **Disable buttons for 1.5 seconds after drag end**
    setInteractionDisabled(true);
    if (interactionTimeoutRef.current) {
      clearTimeout(interactionTimeoutRef.current);
    }
    interactionTimeoutRef.current = setTimeout(() => {
      setInteractionDisabled(false);
    }, 2100);
  };

  // Handlers for adding a new line item
  const showAddModal = (type) => {
    setAddItemType(type);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        const newItem = {
          id: uuidv4(),
          description: values.description,
          price:
            addItemType === "Single" ? parseFloat(values.price) : undefined,
          source: "Line Item",
          visible: true,
          isInitial: false, // Custom items are not initial
          itemType: addItemType,
          // 'order' will be set in useEffect based on index
        };
        if (addItemType === "Grouped") {
          setGroupedItems([...groupedItems, newItem]);
        } else {
          setSingleItems([...singleItems, newItem]);
        }
        message.success("Line item added successfully!");
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Handler for deleting a line item
  const handleDelete = (type, id) => {
    if (type === "Grouped") {
      setGroupedItems(groupedItems.filter((item) => item.id !== id));
    } else {
      setSingleItems(singleItems.filter((item) => item.id !== id));
    }
    message.success("Line item deleted successfully!");
  };

  const handleToggleVisibility = async (type, id) => {
    try {
      if (type === "Grouped") {
        setGroupedItems((prevGroupedItems) =>
          prevGroupedItems.map((item) =>
            item.id === id ? { ...item, visible: !item.visible } : item
          )
        );

        // Find the updated item
        const updatedItem = groupedItems.find((item) => item.id === id);
        if (!updatedItem) throw new Error("Item not found");

        // Prepare the updated line item
        const updatedLineItem = {
          ...updatedItem,
          visible: !updatedItem.visible,
        };

        // Persist the change to the backend
        await updateById(
          TABLES.ESTIMATES,
          {
            ...estimate,
            quote: {
              ...estimate.quote,
              lines: estimate.quote.lines.map((line) =>
                line.id === id ? updatedLineItem : line
              ),
            },
          },
          estimate.id
        );
      } else {
        setSingleItems((prevSingleItems) =>
          prevSingleItems.map((item) =>
            item.id === id ? { ...item, visible: !item.visible } : item
          )
        );

        // Find the updated item
        const updatedItem = singleItems.find((item) => item.id === id);
        if (!updatedItem) throw new Error("Item not found");

        // Prepare the updated line item
        const updatedLineItem = {
          ...updatedItem,
          visible: !updatedItem.visible,
        };

        // Persist the change to the backend
        await updateById(
          TABLES.ESTIMATES,
          {
            ...estimate,
            quote: {
              ...estimate.quote,
              lines: estimate.quote.lines.map((line) =>
                line.id === id ? updatedLineItem : line
              ),
            },
          },
          estimate.id
        );
      }

      // **Disable buttons for 1.5 seconds after toggling visibility**
      setInteractionDisabled(true);
      if (interactionTimeoutRef.current) {
        clearTimeout(interactionTimeoutRef.current);
      }
      interactionTimeoutRef.current = setTimeout(() => {
        setInteractionDisabled(false);
      }, 2100);

      // message.success("Visibility toggled successfully!");
    } catch (error) {
      console.error("Error toggling visibility:", error);
      message.error("Failed to toggle visibility. Please try again.");

      // Optionally, revert the UI change if the backend update fails
      if (type === "Grouped") {
        setGroupedItems((prevGroupedItems) =>
          prevGroupedItems.map((item) =>
            item.id === id ? { ...item, visible: !item.visible } : item
          )
        );
      } else {
        setSingleItems((prevSingleItems) =>
          prevSingleItems.map((item) =>
            item.id === id ? { ...item, visible: !item.visible } : item
          )
        );
      }
    }
  };

  // Calculate totals
  const groupedTotal = useMemo(() => {
    return groupedItems.reduce(
      (sum, item) => sum + (item.price || 0) * (item.quantity || 1),
      0
    );
  }, [groupedItems]);

  const lineItemsTotal = useMemo(() => {
    return singleItems.reduce(
      (sum, item) => sum + (item.price || 0) * (item.quantity || 1),
      0
    );
  }, [singleItems]);

  const totalCost = useMemo(() => {
    return groupedTotal - lineItemsTotal;
  }, [groupedTotal, lineItemsTotal]);

  // Generate HTML string for PDF
  const htmlString = useMemo(
    () =>
      generateHtmlString({
        groupedItems,
        singleItems,
        leadTime,
        quoteValidity,
        tax,
        taxType,
        transport,
        address,
        customerName,
        userName,
        estimate,
        saleAmount: groupedTotal,
        lineItemsTotal: lineItemsTotal,
        totalCost: totalCost,
      }),
    [
      groupedItems,
      singleItems,
      leadTime,
      quoteValidity,
      tax,
      taxType,
      transport,
      address,
      customerName,
      userName,
      estimate,
      groupedTotal,
      lineItemsTotal,
      totalCost,
    ]
  );

  // Handler for generating PDF
  const handleGeneratePdf = async () => {
    setPdfLoading(true);
    try {
      // Determine the next revision number
      const currentQuoteFiles = estimate.quote?.files || [];
      const revision = currentQuoteFiles.length || 0;

      // Create a filename with revision and timestamp
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const fileName = `${estimate?.estimateNumber}-${customerName}-Quote-Rev${revision}.pdf`;

      // Generate the PDF
      const result = await generatePdf(htmlString, fileName);

      // Check if result contains the location URL
      if (result && result.location) {
        // Create the new file object
        const newFile = {
          name: fileName,
          url: result.location,
        };

        // Initialize quote and files if they don't exist
        const updatedQuote = estimate.quote || {};
        const updatedFiles = updatedQuote.files || [];

        // Append the new file to the files array
        const updatedFilesArray = [...updatedFiles, newFile];

        // **Create a New History Event for PDF Download**
        const downloadEvent = {
          id: uuidv4(), // Unique identifier
          userName: userName, // Active user's full name
          userEmail: userEmail, // Active user's email
          timestamp: new Date().toISOString(),
          destination: "Downloaded",
          notes: "", // Optional: Add any notes if necessary
          requestor: estimate.requestor,
          assignedTo: estimate.assignedTo,
        };

        // Append the new download event to the existing history
        const updatedHistory = [...history, downloadEvent];

        // Create the updated quote object with new file and updated history
        const updatedEstimate = {
          ...estimate,
          quote: {
            ...updatedQuote,
            files: updatedFilesArray,
          },
          history: updatedHistory, // Update history
        };

        // Persist the updated estimate to the backend
        await updateById(TABLES.ESTIMATES, updatedEstimate, estimate.id);

        // Update the local history state
        setHistory(updatedHistory);

        setPdfLoading(false);

        message.success("PDF generated and saved successfully!");

        // Optionally, open the PDF in a new tab
        // window.open(result.location, "_blank");
      } else {
        setPdfLoading(false);
        message.error("PDF generation failed. No location returned.");
      }
    } catch (error) {
      setPdfLoading(false);
      console.error("Error generating PDF:", error);
      message.error("Failed to generate PDF.");
    }
  };

  // Handler for confirming send action with Notes and Email functionality
  const handleConfirmSend = async () => {
    setSalesLoading(true);
    // Determine the current date
    const currentDate = dayjs().format("MMMM D, YYYY");

    // Collect the appropriate notes
    const notes =
      actionDestination === "Sales" ? sendToSalesNotes : sendToEstimatingNotes;

    // Construct the email subject
    const subject = `${estimate.estimateNumber} - ${currentDate} - ${
      actionDestination === "Sales"
        ? "Sent to Sales"
        : "Sent Back to Estimating"
    }`;

    // Construct the email body
    const htmlBody = `
      <p><strong>Estimate Number:</strong> ${estimate.estimateNumber}</p>
      <p><strong>Estimate Name:</strong> ${estimate.estimateName}</p>
      <p><strong>Description:</strong> ${estimate.estimateDescription}</p>
      <p><strong>Sale Amount:</strong> ${formatMoney(
        estimate?.costs.saleAmount
      )}</p>
      <p><strong>View Estimate:</strong> <a href="${
        window.location.href
      }">Click here</a></p>
      ${notes ? `<p><strong>Notes:</strong> ${notes}</p>` : ""}
    `;

    // Define recipient emails
    const emailAddresses = [
      estimate.requestor.email,
      estimate.assignedTo.email,
    ];

    console.log("Email addresses:", emailAddresses);

    // Define attachment URLs (assuming you want to attach the latest PDF)
    const attachmentUrls =
      estimate.quote?.files && estimate.quote.files.length > 0
        ? [estimate.quote.files[estimate.quote.files.length - 1].url]
        : [];

    // Define the sender (assuming current user)
    const sender = userEmail;

    const manager = "matt.mcconnell@bmarko.com";

    try {
      // Send the email
      const emailResult = await sendEmail({
        subject,
        htmlBody,
        emailAddresses,
        attachmentUrls,
        sender,
        manager: manager,
      });

      if (!emailResult.success) {
        throw new Error(emailResult.message);
      }

      // Determine the new estimate status
      const newStatus =
        actionDestination === "Sales" ? "Completed" : "Re-Quote Needed";

      // Create a new action entry
      const newAction = {
        id: uuidv4(),
        userName: userName,
        userEmail: userEmail,
        timestamp: new Date().toISOString(),
        destination: actionDestination,
        notes: notes, // Include notes in history
        requestor: estimate.requestor,
        assignedTo: estimate.assignedTo,
      };

      // Update local history state
      const updatedHistory = [...history, newAction];
      setHistory(updatedHistory);

      // Update the estimate in the backend
      const updatedEstimate = {
        ...estimate,
        estimateStatus: newStatus,
        history: updatedHistory,
      };

      await updateById(TABLES.ESTIMATES, updatedEstimate, estimate.id);
      message.success(
        `Estimate ${
          actionDestination === "Sales"
            ? "sent to Sales"
            : "sent back to Estimating"
        } successfully!`
      );

      // Reset notes fields
      setSendToSalesNotes("");
      setSendToEstimatingNotes("");
    } catch (error) {
      console.error("Error sending estimate:", error);
      message.error(
        `Failed to ${
          actionDestination === "Sales"
            ? "send to Sales"
            : "send back to Estimating"
        }. ${error.message}`
      );
    } finally {
      setSalesLoading(false);
      setIsConfirmModalVisible(false);
    }
  };

  // Handler for "Send to Sales" button
  const handleSendToSales = () => {
    // Open the confirmation modal
    setIsConfirmModalVisible(true);
  };

  // Function to check if any single item doesn't have a price
  const hasEmptyPrice = useMemo(
    () =>
      singleItems.some(
        (item) => item.price == null || item.price === "" || isNaN(item.price)
      ),
    [singleItems]
  );

  return (
    <>
      <Row gutter={[16, 16]} className={styles.container}>
        {/* Left Column: Form and Line Items */}
        <Col xs={24} lg={12}>
          {/* Quote Inputs Section */}
          <Collapse
            defaultActiveKey={["quoteInputs"]}
            className={styles.collapse}
            style={{ marginTop: 0, marginBottom: 8 }}
            size="small"
          >
            <Panel header="Quote Inputs" key="quoteInputs">
              <Row gutter={[16, 16]}>
                {/* Lead Time */}
                <Col xs={24} sm={12}>
                  <label htmlFor="leadTime">Lead Time</label>
                  <Input
                    id="leadTime"
                    placeholder="Enter Lead Time"
                    value={leadTime}
                    onChange={(e) => setLeadTime(e.target.value)}
                  />
                </Col>

                {/* Quote Validity Number */}
                <Col xs={24} sm={12}>
                  <label htmlFor="quoteValidityNumber">
                    Quote Validity (days)
                  </label>
                  <InputNumber
                    id="quoteValidityNumber"
                    min={1}
                    defaultValue={30}
                    value={quoteValidityNumber}
                    onChange={(value) => setQuoteValidityNumber(value)}
                    style={{ width: "100%" }}
                  />
                </Col>

                {/* Sales Tax */}
                <Col xs={24} sm={12}>
                  <label>Sales Tax</label>
                  <Row gutter={8}>
                    <Col span={6}>
                      <Select
                        value={taxType}
                        onChange={handleTaxTypeChange}
                        style={{ width: "100%" }}
                      >
                        <Option value="%">%</Option>
                        <Option value="$">$</Option>
                        <Option value="TBD">TBD</Option>
                        <Option value="EXEMPT">EXEMPT</Option>
                      </Select>
                    </Col>
                    <Col span={18}>
                      {taxType !== "TBD" && taxType !== "EXEMPT" ? (
                        <InputNumber
                          type="number"
                          placeholder={taxType === "%" ? "Tax (%)" : "Tax ($)"}
                          value={tax}
                          style={{ width: "100%" }}
                          onChange={(value) => setTax(parseFloat(value))}
                        />
                      ) : (
                        <Input
                          placeholder={taxType}
                          value={taxType}
                          style={{ width: "100%" }}
                          disabled
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                {/* Transport */}
                <Col xs={24} sm={12}>
                  <label htmlFor="transport">Transport</label>
                  <InputNumber
                    id="transport"
                    type="number"
                    placeholder="Enter Transport Cost"
                    prefix="$"
                    style={{ width: "100%" }}
                    value={transport}
                    onChange={(value) => setTransport(parseFloat(value))}
                  />
                </Col>

                {/* Transportation Address */}
                <Col span={24}>
                  <label htmlFor="transportAddress">
                    Transportation Address
                  </label>
                  <AddressAutocomplete
                    form={form}
                    value={address}
                    onChange={(value) => setAddress(value)}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>

          {/* Line Items Section */}
          <Collapse
            size="small"
            defaultActiveKey={["lineItems"]}
            className={styles.collapse}
            style={{ marginTop: 0 }}
          >
            <Panel header="Line Items" key="lineItems">
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                {/* Grouped Items */}
                <Card
                  title="Grouped Items"
                  size="small"
                  style={{ marginBottom: 16 }}
                >
                  <Droppable droppableId="groupedItems">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={styles.droppableArea}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "#e6f7ff"
                            : "#f0f0f0",
                          padding: "8px",
                          minHeight: "100px",
                        }}
                      >
                        {groupedItems.map((item, index) => (
                          <LineItem
                            key={item.id}
                            item={item}
                            index={index}
                            type="Grouped"
                            isEditing={editingItemIds.includes(item.id)}
                            editedValues={editedItems[item.id]}
                            onEdit={() => handleEdit(item)}
                            onEditChange={(field, value) =>
                              handleEditChange(item.id, field, value)
                            }
                            onSave={() => handleSave(item.id)}
                            onToggleVisibility={handleToggleVisibility}
                            onDelete={handleDelete}
                            isSaving={savingItemIds.includes(item.id)}
                            buttonDisabled={buttonDisabled}
                            interactionDisabled={interactionDisabled} // Pass the new state
                          />
                        ))}
                        {provided.placeholder}
                        {/* Add Line Item button within droppable area */}
                        <AddLineItem onAdd={() => showAddModal("Grouped")} />
                      </div>
                    )}
                  </Droppable>
                </Card>

                {/* Single Items */}
                <Card
                  title="Single Items"
                  size="small"
                  style={{ marginBottom: 16 }}
                >
                  <Droppable droppableId="singleItems">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={styles.droppableArea}
                        style={{
                          background: hasEmptyPrice ? "#ffe6e6" : "#f0f0f0",
                          padding: "8px",
                          minHeight: "100px",
                        }}
                      >
                        {singleItems.map((item, index) => (
                          <LineItem
                            key={item.id}
                            item={item}
                            index={index}
                            type="Single"
                            isEditing={editingItemIds.includes(item.id)}
                            editedValues={editedItems[item.id]}
                            onEdit={() => handleEdit(item)}
                            onEditChange={(field, value) =>
                              handleEditChange(item.id, field, value)
                            }
                            onSave={() => handleSave(item.id)}
                            onToggleVisibility={handleToggleVisibility}
                            onDelete={handleDelete}
                            isSaving={savingItemIds.includes(item.id)}
                            buttonDisabled={buttonDisabled}
                            interactionDisabled={interactionDisabled} // Pass the new state
                          />
                        ))}
                        {provided.placeholder}
                        {/* Add Line Item button within droppable area */}
                        <AddLineItem onAdd={() => showAddModal("Single")} />
                      </div>
                    )}
                  </Droppable>
                </Card>
              </DragDropContext>
            </Panel>
          </Collapse>
        </Col>
        {/* Right Column: HTML Preview */}
        <Col xs={24} lg={12} className={styles.stickyPreview}>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "16px" }}
          >
            <Col>
              <Button
                icon={<FilePdfOutlined />}
                onClick={handleGeneratePdf}
                disabled={hasEmptyPrice}
                loading={pdfLoading}
                type="primary"
              >
                Generate PDF
              </Button>
            </Col>
            <Col>
              <Button
                type={actionDestination === "Sales" ? "primary" : "default"}
                icon={
                  <SendOutlined
                    style={
                      actionDestination !== "Sales"
                        ? { transform: "scaleX(-1)" }
                        : {}
                    }
                  />
                }
                onClick={handleSendToSales}
                disabled={hasEmptyPrice}
                style={{
                  backgroundColor:
                    actionDestination === "Sales" ? "green" : "#fedc56",
                  color: actionDestination === "Sales" ? "white" : "black",
                }}
              >
                {actionDestination === "Sales"
                  ? "Send to Sales"
                  : "Send back to Estimating"}
              </Button>
            </Col>
          </Row>

          {/* PDF Display with Spinner */}
          <Spin spinning={pdfLoading} tip="Generating PDF...">
            <div
              className={styles.previewContainer}
              style={{ overflow: "hidden" }}
              dangerouslySetInnerHTML={{ __html: htmlString }}
            />
          </Spin>
        </Col>
        {/* Add Line Item Modal */}
        <Modal
          title="Add Line Item"
          visible={isModalVisible}
          onOk={handleAdd}
          onCancel={handleCancel}
          okText="Add"
        >
          <Form form={form} layout="vertical" name="add_line_item">
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: "Please enter the description!" },
              ]}
            >
              <Input placeholder="Enter Description" />
            </Form.Item>
            {addItemType === "Single" && (
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  { required: true, message: "Please enter the price!" },
                  {
                    type: "number",
                    min: 0,
                    message: "Price cannot be negative",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Enter Price"
                  prefix="$"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}
          </Form>
        </Modal>
        {/* Confirm Send Modal */}
        <Modal
          title={`Confirm ${
            actionDestination === "Sales"
              ? "Send to Sales"
              : "Send back to Estimating"
          }`}
          visible={isConfirmModalVisible}
          onOk={handleConfirmSend}
          onCancel={() => setIsConfirmModalVisible(false)}
          okText="Confirm"
          cancelText="Cancel"
          confirmLoading={salesLoading}
        >
          <p>
            Are you sure you want to{" "}
            {actionDestination === "Sales"
              ? "send to Sales"
              : "send back to Estimating"}
            ?
          </p>
          <Form layout="vertical">
            <Form.Item label={`Notes`}>
              <Input.TextArea
                rows={4}
                placeholder="Optional notes..."
                value={
                  actionDestination === "Sales"
                    ? sendToSalesNotes
                    : sendToEstimatingNotes
                }
                onChange={(e) =>
                  actionDestination === "Sales"
                    ? setSendToSalesNotes(e.target.value)
                    : setSendToEstimatingNotes(e.target.value)
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </Row>
    </>
  );
};

export default QuotePDF;
