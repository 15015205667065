import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getNextPoNumber = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/purchasing/getNextPoNumber`
    );
    return response.data.newPoNumber;
  } catch (error) {
    console.error("Error fetching estimate number:", error);
    throw error;
  }
};
