import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Table,
  Row,
  Col,
  message,
  Tag,
  Collapse,
} from "antd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import dayjs from "dayjs";
import { useProjects } from "../../Contexts/useSpecificData";
import { updateById } from "../../Functions/updateById";
import { TABLES, TAG_COLORS } from "../../constants";
import PriceTag from "../../Styled/PriceTag";
import { formatDate } from "../../Formatters/helpers";
import { StopOutlined } from "@ant-design/icons";

const COLUMNS = [
  {
    key: "drawing",
    title: "Drawing",
    plannedField: "plannedDrawingComplete",
    actualField: "actualDrawingComplete",
  },
  {
    key: "clientReview",
    title: "Client Review",
    plannedField: "plannedClientReviewComplete",
    actualField: "actualClientReviewComplete",
  },
  {
    key: "bom",
    title: "BOM",
    plannedField: "plannedBOMComplete",
    actualField: "actualBOMComplete",
  },
  {
    key: "materials",
    title: "Materials",
    plannedField: "plannedMaterialsComplete",
    actualField: "actualMaterialsComplete",
  },
  {
    key: "handoff",
    title: "Handoff",
    plannedField: "plannedHandoff",
    actualField: "actualHandoff",
  },
];

// Return the index of the column corresponding to the last completed actual date.
// If none completed, return 0 (Drawing).
const getCurrentColumnIndex = (precon) => {
  for (let i = COLUMNS.length - 1; i >= 0; i--) {
    const { actualField } = COLUMNS[i];
    if (precon[actualField]) {
      return i;
    }
  }
  return 0;
};

function getTagColorForProjectName(projectName) {
  const prefix = projectName.slice(0, 3).toUpperCase();
  const codeSum = prefix
    .split("")
    .reduce((sum, ch) => sum + ch.charCodeAt(0), 0);
  const colorIndex = codeSum % TAG_COLORS.length;
  return TAG_COLORS[colorIndex];
}

function PreconstructionModal({
  visible,
  onClose,
  onAdd,
  projects,
  refreshProjects,
}) {
  const [form] = Form.useForm();
  const noPreconProjects = projects.filter((p) => !p.preconstruction);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const preconData = {
        price: values.price,
        plannedDrawingComplete:
          values.plannedDrawingComplete.format("YYYY-MM-DD"),
        plannedClientReviewComplete:
          values.plannedClientReviewComplete.format("YYYY-MM-DD"),
        plannedBOMComplete: values.plannedBOMComplete.format("YYYY-MM-DD"),
        plannedMaterialsComplete:
          values.plannedMaterialsComplete.format("YYYY-MM-DD"),
        plannedHandoff: values.plannedHandoff.format("YYYY-MM-DD"),
        actualDrawingComplete: null,
        actualClientReviewComplete: null,
        actualBOMComplete: null,
        actualMaterialsComplete: null,
        actualHandoff: null,
      };

      const projectId = values.projectId;
      const productType = values.productType;

      const updatedProject = {
        productType,
        preconstruction: preconData,
        projectManagement: {
          history: [
            {
              action: "Moved to Drawing",
              date: new Date().toISOString(),
            },
          ],
        },
      };

      await updateById(TABLES.PROJECTS, updatedProject, projectId);
      message.success("Preconstruction added successfully!");
      onAdd();
      refreshProjects();
      onClose();
    } catch (err) {
      console.error(err);
      message.error("Failed to add preconstruction.");
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add Preconstruction"
      onCancel={onClose}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="projectId"
          label="Select Project"
          rules={[{ required: true, message: "Please select a project" }]}
        >
          <Select
            placeholder="Select a project without preconstruction"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {noPreconProjects.map((p) => (
              <Select.Option key={p.id} value={p.id}>
                {p.projectName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="productType"
          label="Product Type"
          rules={[{ required: true, message: "Please select a product type" }]}
        >
          <Select placeholder="Select product type">
            <Select.Option value="One Off">One Off</Select.Option>
            <Select.Option value="IPB">IPB</Select.Option>
            <Select.Option value="Production">Production</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="price"
          label="Project Price"
          rules={[{ required: true, message: "Please enter a price" }]}
        >
          <Input type="number" prefix="$" />
        </Form.Item>
        <Form.Item
          name="plannedDrawingComplete"
          label="Planned Drawing"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="plannedClientReviewComplete"
          label="Planned Client Review"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="plannedBOMComplete"
          label="Planned BOM"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="plannedMaterialsComplete"
          label="Planned Materials"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="plannedHandoff"
          label="Planned Handoff"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
}

function PreconstructionPanel({ project }) {
  const { preconstruction: precon } = project;

  const data = COLUMNS.map((col) => {
    const plannedDate = precon[col.plannedField];
    const actualDate = precon[col.actualField];

    return {
      key: col.key,
      milestone: col.title,
      planned: plannedDate ? formatDate(plannedDate) : <StopOutlined />,
      actual: actualDate ? formatDate(actualDate) : <StopOutlined />,
    };
  });

  const columns = [
    {
      title: "Milestone",
      dataIndex: "milestone",
      key: "milestone",
      width: "40%",
    },
    { title: "Planned", dataIndex: "planned", key: "planned", width: "30%" },
    { title: "Actual", dataIndex: "actual", key: "actual", width: "30%" },
  ];

  const tagColor = getTagColorForProjectName(project.projectName);
  const panelHeader = (
    <>
      <Tag color={tagColor} style={{ marginRight: 8 }}>
        {project.projectName}
      </Tag>
      <PriceTag amount={precon.price} />
    </>
  );

  return (
    <Collapse bordered={false} defaultActiveKey={[]}>
      <Collapse.Panel header={panelHeader} key="panel">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          bordered
        />
      </Collapse.Panel>
    </Collapse>
  );
}

function Preconstruction() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: projects, refresh: refreshProjects } = useProjects();

  // Local state to manage current displayed projects for drag & drop
  const [localProjects, setLocalProjects] = useState([]);
  const [preDragProjects, setPreDragProjects] = useState([]);

  const [dateModalVisible, setDateModalVisible] = useState(false);
  const [dateModalColumn, setDateModalColumn] = useState(null);
  const [dateModalProject, setDateModalProject] = useState(null);

  useEffect(() => {
    if (projects) {
      // Filter in progress projects
      const inProgress = projects
        .filter((p) => p.preconstruction && !p.preconstruction.actualHandoff)
        .sort((a, b) => a.projectName.localeCompare(b.projectName));
      setLocalProjects(inProgress);
    }
  }, [projects]);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const getColumnsDataMap = useCallback((projs) => {
    const map = {};
    COLUMNS.forEach((col) => {
      map[col.key] = [];
    });
    projs.forEach((p) => {
      const colIndex = getCurrentColumnIndex(p.preconstruction);
      const colKey = COLUMNS[colIndex].key;
      map[colKey].push(p);
    });
    return map;
  }, []);

  const columnsDataMap = getColumnsDataMap(localProjects);

  const onDragStart = () => {
    setPreDragProjects(JSON.parse(JSON.stringify(localProjects)));
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return; // no change
    }

    // Find the project being moved
    const projectIndex = localProjects.findIndex((p) => p.id === draggableId);
    if (projectIndex === -1) return;
    const project = localProjects[projectIndex];

    // Determine source and destination column indexes
    const sourceColIndex = COLUMNS.findIndex(
      (c) => c.key === source.droppableId
    );
    const destColIndex = COLUMNS.findIndex(
      (c) => c.key === destination.droppableId
    );

    if (sourceColIndex === -1 || destColIndex === -1) return;
    if (Math.abs(destColIndex - sourceColIndex) > 1) {
      message.error("Can't skip columns.");
      // revert
      setLocalProjects(preDragProjects);
      return;
    }

    // Move the project to the new column visually by adjusting actual fields in memory (without date)
    // Actually, we won't set a date here, just visually consider it in that column.
    // But since columns are determined by actual date, we must trick it somehow.

    // We'll temporarily store a pseudo state in project that indicates its column
    // Actually, we rely on actual dates to determine the column. We must store a temporary marker:
    // Let's add a temporary field `tempColumnIndex` to the project to place it visually.
    // Then getCurrentColumnIndex first checks `tempColumnIndex`.

    // Let's modify getCurrentColumnIndex to check a temp field if available:
    // Instead of modifying the original logic in code snippet, we do a quick hack:
    // We'll store a clone of project and set its actual fields so that it appears in the new column:
    // For forward move, set actual date of the current column to a placeholder date (we will set real date after user picks)
    // For backward move, remove the actual date from the column we were leaving.

    // Actually let's handle it by setting a fake actual date (the current time) for forward,
    // and removing date for backward. We'll finalize the date after user picks from modal.

    const updatedProjects = JSON.parse(JSON.stringify(localProjects));
    const updProject = updatedProjects[projectIndex];

    const sourceCol = COLUMNS[sourceColIndex];
    const destCol = COLUMNS[destColIndex];

    if (destColIndex > sourceColIndex) {
      // Forward move: set destCol actual date temporarily as today's date
      // We'll ask user for actual date after
      updProject.preconstruction[destCol.actualField] =
        new Date().toISOString();
    } else if (destColIndex < sourceColIndex) {
      // Backward move: remove actual date from source column
      updProject.preconstruction[sourceCol.actualField] = null;
    }

    setLocalProjects(updatedProjects);

    // Show date modal if moving forward
    // If backward, we also show modal? The user wants a date for each completion.
    // The user said "then i save and that saves the date" - implies we always pick a date when we land in a column that requires actual date.
    // For backward movement, we're removing a date from a future column - no date needed.
    // Actually, if we move backward, there's no new date to set. The requirement states "when i drop my card dont just add date and save leave it in the column and do a popup with add complete date"
    // This suggests we only need a date if we move forward (to complete the next milestone).
    // If we move backward, that means we removed a date, no new completion date needed. Just finalize as is.

    if (destColIndex > sourceColIndex) {
      // Show the date modal for setting the actual date for destCol
      setDateModalProject(updProject);
      setDateModalColumn(destCol);
      setDateModalVisible(true);
    } else {
      // backward move completed without needing a date
      // Update DB right away so DB reflects removal of date?
      // The instructions: "once i drop my card ... do a popup with add complete date"
      // Actually, if backward means removing a date, we should just update DB now since no date needed.
      // We'll update the DB to reflect the removed actual date.
      updateProjectInDB(updProject, destCol);
    }
  };

  const updateProjectInDB = async (project, column) => {
    // Add a history entry
    const now = new Date().toISOString();
    if (!project.projectManagement) project.projectManagement = {};
    if (!project.projectManagement.history)
      project.projectManagement.history = [];
    project.projectManagement.history.push({
      action: `Moved to ${column.title}`,
      date: now,
    });

    await updateById(TABLES.PROJECTS, project, project.id);
    message.success("Project updated");
    refreshProjects();
  };

  const handleDateModalOk = async (values) => {
    // User picked a date for the forward move
    const { actualDate } = values;
    const proj = { ...dateModalProject };
    const col = dateModalColumn;

    // Set chosen actual date
    proj.preconstruction[col.actualField] = actualDate.format(
      "YYYY-MM-DDT00:00:00.000Z"
    );

    // Update DB
    await updateProjectInDB(proj, col);

    // Close modal
    setDateModalVisible(false);
    setDateModalProject(null);
    setDateModalColumn(null);
  };

  const handleDateModalCancel = () => {
    // User cancelled choosing a date
    // Revert to pre-drag state
    setLocalProjects(preDragProjects);
    setDateModalVisible(false);
    setDateModalProject(null);
    setDateModalColumn(null);
  };

  return (
    <div style={{ margin: 0, padding: 0, width: "100%" }}>
      <Button type="primary" onClick={openModal} style={{ marginBottom: 16 }}>
        Add Preconstruction
      </Button>
      <PreconstructionModal
        visible={isModalVisible}
        onClose={closeModal}
        onAdd={refreshProjects}
        projects={projects || []}
        refreshProjects={refreshProjects}
      />

      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Row wrap={false} style={{ margin: 0, padding: 0, width: "100%" }}>
          {COLUMNS.map((col) => (
            <Col flex="1" key={col.key} style={{ marginRight: 16 }}>
              <Droppable droppableId={col.key}>
                {(provided, snapshot) => (
                  <div
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: 4,
                      padding: 8,
                      minHeight: 300,
                      height: "100%",
                      textAlign: "center",
                      borderBottom: "1px solid #ccc",
                      background: snapshot.isDraggingOver ? "#e6f7ff" : "#fff",
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <h3
                      style={{
                        borderBottom: "1px solid #ccc",
                        paddingBottom: 8,
                      }}
                    >
                      {col.title}
                    </h3>
                    {columnsDataMap[col.key].map((proj, index) => (
                      <Draggable
                        draggableId={proj.id}
                        index={index}
                        key={proj.id}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              marginBottom: 8,
                              ...provided.draggableProps.style,
                            }}
                          >
                            <PreconstructionPanel project={proj} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Col>
          ))}
        </Row>
      </DragDropContext>

      {/* Date Modal for setting actual completion date */}
      <Modal
        visible={dateModalVisible}
        title={`Add Complete Date for ${
          dateModalColumn ? dateModalColumn.title : ""
        } - ${dateModalProject ? dateModalProject.projectName : ""}`}
        onCancel={handleDateModalCancel}
        footer={null}
      >
        <Form onFinish={handleDateModalOk} layout="vertical" id="dateModalForm">
          <Form.Item
            name="actualDate"
            label="Complete Date"
            rules={[{ required: true, message: "Please select a date" }]}
          >
            <DatePicker
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>
        </Form>
        <div style={{ textAlign: "right", marginTop: 16 }}>
          <Button onClick={handleDateModalCancel} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            type="primary"
            form="dateModalForm"
            htmlType="submit"
            id="dateModalFormOkBtn"
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Preconstruction;
