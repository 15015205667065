// src/Components/Estimating/InternalQuote/InternalQuote.js
import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Table,
  InputNumber,
  Form,
  Descriptions,
  Tag,
  Empty,
} from "antd";
import PriceTag from "../../../Styled/PriceTag";
import { useItems } from "../../../Contexts/useSpecificData";
import { TABLES } from "../../../constants";
import { StopOutlined } from "@ant-design/icons";
import { updateById } from "../../../Functions/updateById";
import debounce from "lodash/debounce";
import { updateSaleAmountAndProfit } from "../updateSaleAmountAndProfit";

// Mapping origins to colors (unchanged)
const ORIGIN_COLORS = {
  "Custom Item": "blue",
  Assembly: "red",
  "Aria Item": "purple",
  Module: "orange",
};

// The InternalQuote component
function InternalQuote({ estimate }) {
  const { data: items } = useItems();
  const [form] = Form.useForm();

  // Initialize costs state from estimate.costs (excluding derived values)
  const [costs, setCosts] = useState({
    contingency: estimate.costs.contingency,
    profitMargin: estimate.costs.profitMargin,
    increaseMaterials: estimate.costs.increaseMaterials || [],
    increaseLabor: estimate.costs.increaseLabor || [],
  });

  // State to manage increases per Expense ID
  const [materialIncreases, setMaterialIncreases] = useState({});
  const [laborIncreases, setLaborIncreases] = useState({});

const debouncedSaveCosts = useMemo(
  () =>
    debounce(async (updatedCosts) => {
      try {
        // Create an updated estimate object locally
        const updatedEstimate = {
          ...estimate,
          costs: updatedCosts,
        };

        // Save the updated costs to the database
        await updateById(
          TABLES.ESTIMATES,
          { costs: updatedCosts },
          estimate.id
        );

        // Update sale amount and profit with the updated estimate
        await updateSaleAmountAndProfit(updatedEstimate);
      } catch (error) {
        console.error(
          "Failed to save costs or update sale amount and profit:",
          error
        );
        // Optionally, display an error message
        // message.error("Failed to save costs or update sale amount and profit.");
      }
    }, 300), // 300ms debounce
  [estimate]
);


  // Ensure debounced function is canceled on unmount to prevent memory leaks
  useEffect(() => {
    return () => {
      debouncedSaveCosts.cancel();
    };
  }, [debouncedSaveCosts]);

  // Initialize costs from estimate.costs directly without triggering saves
  useEffect(() => {
    const initializeCosts = () => {
      const existingCosts = estimate.costs;

      // Initialize state directly from existingCosts
      setCosts({
        contingency: existingCosts.contingency,
        profitMargin: existingCosts.profitMargin,
        increaseMaterials: existingCosts.increaseMaterials || [],
        increaseLabor: existingCosts.increaseLabor || [],
      });

      // Initialize form fields
      form.setFieldsValue({
        contingency: existingCosts.contingency,
        profitMargin: existingCosts.profitMargin,
      });

      // Initialize material increases
      const materialInc = {};
      (existingCosts.increaseMaterials || []).forEach(
        ({ ExpenseId, amount }) => {
          if (ExpenseId) materialInc[ExpenseId] = amount;
        }
      );
      setMaterialIncreases(materialInc);

      // Initialize labor increases
      const laborInc = {};
      (existingCosts.increaseLabor || []).forEach(({ ExpenseId, amount }) => {
        if (ExpenseId) laborInc[ExpenseId] = amount;
      });
    };

    if (estimate && estimate.costs) {
      initializeCosts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimate, form]);

  // Debounced handler creator using useCallback
  const createDebouncedHandler = useCallback(
    (updateFn) => (updatedCosts) => {
      debouncedSaveCosts(updatedCosts, estimate.id);
      updateFn();
    },
    [debouncedSaveCosts, estimate.id]
  );

  // Handler for material increases
  const handleMaterialIncrease = useCallback(
    (expenseId, value) => {
      setMaterialIncreases((prev) => {
        const updated =
          value === 0
            ? Object.fromEntries(
                Object.entries(prev).filter(([key]) => key !== expenseId)
              )
            : { ...prev, [expenseId]: value };

        // Prepare the updated increaseMaterials array for the backend
        const updatedIncreaseMaterials = Object.entries(updated).map(
          ([ExpenseId, amount]) => ({
            ExpenseId,
            amount,
          })
        );

        // Update costs state
        setCosts((prevCosts) => {
          const newCosts = {
            ...prevCosts,
            increaseMaterials: updatedIncreaseMaterials,
          };
          debouncedSaveCosts(newCosts, estimate.id);
          return newCosts;
        });

        return updated;
      });
    },
    [debouncedSaveCosts, estimate.id]
  );

  // Handler for labor increases
  const handleLaborIncrease = useCallback(
    (expenseId, value) => {
      setLaborIncreases((prev) => {
        const updated =
          value === 0
            ? Object.fromEntries(
                Object.entries(prev).filter(([key]) => key !== expenseId)
              )
            : { ...prev, [expenseId]: value };

        // Prepare the updated increaseLabor array for the backend
        const updatedIncreaseLabor = Object.entries(updated).map(
          ([ExpenseId, amount]) => ({
            ExpenseId,
            amount,
          })
        );

        // Update costs state
        setCosts((prevCosts) => {
          const newCosts = {
            ...prevCosts,
            increaseLabor: updatedIncreaseLabor,
          };
          debouncedSaveCosts(newCosts, estimate.id);
          return newCosts;
        });

        return updated;
      });
    },
    [debouncedSaveCosts, estimate.id]
  );

  // Handle changes in contingency and profitMargin fields
  const handleFormChange = useCallback(
    (changedValues, allValues) => {
      const { contingency, profitMargin } = changedValues;

      // Update local costs state with the latest form values
      setCosts((prev) => {
        const newCosts = {
          ...prev,
          contingency:
            contingency !== undefined ? contingency : prev.contingency,
          profitMargin:
            profitMargin !== undefined ? profitMargin : prev.profitMargin,
        };

        // Trigger debounced save with the updated costs
        debouncedSaveCosts(newCosts, estimate.id);

        return newCosts;
      });
    },
    [debouncedSaveCosts, estimate.id]
  );

  // Function to aggregate costs by ExpenseId, including detailed items
  const aggregatedData = useMemo(() => {
    if (!estimate) return [];

    const costMap = new Map();

    // Helper function to add costs and items to the map
    const addCost = (
      expenseId,
      material = 0,
      labor = 0,
      itemDetails = null
    ) => {
      if (!expenseId) return; // Skip if ExpenseId is undefined or null
      if (costMap.has(expenseId)) {
        const existing = costMap.get(expenseId);
        costMap.set(expenseId, {
          material: existing.material + material,
          labor: existing.labor + labor,
          items: itemDetails
            ? [...existing.items, itemDetails]
            : existing.items,
        });
      } else {
        costMap.set(expenseId, {
          material,
          labor,
          items: itemDetails ? [itemDetails] : [],
        });
      }
    };

    // Helper functions to add material and labor costs
    const addMaterialCost = (expenseId, amount, details) => {
      addCost(expenseId, amount, 0, details);
    };

    const addLaborCost = (expenseId, amount, details) => {
      addCost(expenseId, 0, amount, details);
    };

    // Process ariaItems (Material Cost only)
    if (estimate.ariaItems) {
      estimate.ariaItems.forEach((item) => {
        const { ExpenseId, price, quantity, itemNumber, itemDescription } =
          item;
        const totalMaterial = price * quantity;
        const itemDetails = {
          itemNumber,
          itemDescription,
          quantity,
          price,
          origin: "Aria Item",
          type: "Material",
        };
        addMaterialCost(ExpenseId, totalMaterial, itemDetails);
      });
    }

    // Process assemblies (Labor Cost and Material Cost)
    if (estimate.assemblies) {
      estimate.assemblies.forEach((assembly) => {
        const {
          ExpenseId: assemblyExpenseId,
          laborPrice,
          assemblyItems,
          quantity: assemblyQuantity,
          assemblyName,
        } = assembly;

        // Labor Cost
        const totalLaborCost = laborPrice * assemblyQuantity;
        const assemblyLaborDetails = {
          itemNumber: null, // Assemblies might not have item numbers
          itemDescription: `Assembly: ${assemblyName || "Unnamed Assembly"}`,
          quantity: assemblyQuantity,
          price: laborPrice,
          origin: "Assembly",
          type: "Labor",
        };
        addLaborCost(assemblyExpenseId, totalLaborCost, assemblyLaborDetails);

        // Material Costs from assemblyItems
        if (assemblyItems) {
          assemblyItems.forEach((item) => {
            const {
              ExpenseId: itemExpenseId,
              price,
              quantity: itemQuantity,
              itemNumber,
              itemDescription,
            } = item;
            const totalMaterial = price * itemQuantity * assemblyQuantity;
            const itemDetails = {
              itemNumber,
              itemDescription,
              quantity: itemQuantity * assemblyQuantity,
              price,
              origin: "Assembly",
              type: "Material",
            };
            addMaterialCost(itemExpenseId, totalMaterial, itemDetails);
          });
        }
      });
    }

    // Process customItems (Labor Cost and Material Cost)
    if (estimate.customItems) {
      estimate.customItems.forEach((item) => {
        const {
          expenseAccount,
          price,
          laborPrice,
          quantity,
          itemNumber,
          itemDescription,
        } = item;
        const totalMaterial = price * quantity;
        const totalLabor = laborPrice * quantity;

        const materialDetails = {
          itemNumber,
          itemDescription,
          quantity,
          price,
          origin: "Custom Item",
          type: "Material",
        };

        const laborDetails = {
          itemNumber,
          itemDescription,
          quantity,
          price: laborPrice,
          origin: "Custom Item",
          type: "Labor",
        };

        // Add Material Cost Only
        addMaterialCost(expenseAccount, totalMaterial, materialDetails);

        // Add Labor Cost Separately
        addLaborCost(expenseAccount, totalLabor, laborDetails);
      });
    }

    // Process modules (Only categoryCosts: Labor Cost and Material Cost)
    if (estimate.modules) {
      estimate.modules.forEach((module) => {
        const {
          categoryCosts,
          container,
          rainProtection,
          permitted,
          structureLength,

          quantity, // Ensure quantity is a number
        } = module;

        // Aggregate costs from categoryCosts
        if (categoryCosts) {
          Object.entries(categoryCosts).forEach(([categoryName, cost]) => {
            const { ExpenseId, materialCost = 0, laborCost = 0 } = cost;

            // Create item details for Material Cost
            const itemDetailsMaterial = {
              itemNumber: null, // Category costs might not have item numbers
              itemDescription: "Module Option: " + categoryName, // Use only the category name
              quantity: 1,
              price: materialCost,
              origin: "Module",
              type: "Material",
            };

            // Create item details for Labor Cost
            const itemDetailsLabor = {
              itemNumber: null,
              itemDescription: "Module Option: " + categoryName, // Use only the category name
              quantity: 1,
              price: laborCost,
              origin: "Module",
              type: "Labor",
            };

            // Add Material Cost to the cost map if applicable
            if (materialCost > 0) {
              addMaterialCost(ExpenseId, materialCost, itemDetailsMaterial);
            }

            // Add Labor Cost to the cost map if applicable
            if (laborCost > 0) {
              addLaborCost(ExpenseId, laborCost, itemDetailsLabor);
            }
          });
        }

        // Aggregate container cost (Material Cost only)
        if (container) {
          const {
            ExpenseId,
            price,
            quantity: containerQty,
            itemNumber,
            itemDescription,
          } = container;
          const totalMaterial = price * Number(containerQty);
          const containerMaterialDetails = {
            itemNumber,
            itemDescription,
            quantity: Number(containerQty),
            price,
            origin: "Module",
            type: "Material",
          };
          addMaterialCost(ExpenseId, totalMaterial, containerMaterialDetails);
        }

        // **Rain Protection Handling**
        if (rainProtection) {
          const {
            price,
            quantity: rainQty,
            labor,
            material,
            ExpenseId,
          } = rainProtection;

          // **Labor Cost: Use total labor directly (already totaled)**
          if (labor) {
            const rainLaborDetails = {
              itemNumber: null,
              itemDescription: "Rain Protection Labor",
              quantity: module.quantity, // Total labor, no need to multiply
              price: labor,
              origin: "Module",
              type: "Labor",
            };
            addLaborCost(ExpenseId, labor, rainLaborDetails);
          }

          // **Material Cost: Use price directly**
          if (material) {
            const rainMaterialDetails = {
              itemNumber: rainProtection.itemNumber || "20-0012",
              itemDescription:
                rainProtection.itemDescription || "Rain Protection Material",
              quantity: rainQty, // Use the quantity from rainProtection
              price: price, // Use the price directly from rainProtection
              origin: "Module",
              type: "Material",
            };
            const rainMaterialCost = price * rainQty;
            addMaterialCost(ExpenseId, rainMaterialCost, rainMaterialDetails);
          }
        }

        // **Permitted Labor Handling**
        if (permitted && permitted.labor) {
          const totalPermittedLabor = 4250 * module.quantity;
          const permittedDetails = {
            itemNumber: null,
            itemDescription: "Professional Fees for Permits",
            quantity: Number(module.quantity),
            price: 4250, // Labor per module
            origin: "Module",
            type: "Labor",
          };
          addLaborCost(
            "5050117 · Professional Fees",
            totalPermittedLabor,
            permittedDetails
          );
        }

        // **Add $350 per container.quantity to labor costs for "5050120 · Shipping Containers"**
        if (container && Number(container.quantity) > 0) {
          const additionalLabor = 350 * Number(container.quantity);
          const containerLaborDetails = {
            itemNumber: null,
            itemDescription: `Container Labor for ${
              module.moduleDescription || "Unnamed Module"
            }`,
            quantity: Number(container.quantity),
            price: 350,
            origin: "Module",
            type: "Labor",
          };
          addLaborCost(
            "5050120 · Shipping Containers",
            additionalLabor,
            containerLaborDetails
          );
        }
      });
    }

    // Convert the map to an array suitable for the main table
    const data = Array.from(costMap.entries()).map(
      ([expenseId, costs], index) => {
        // Apply material and labor increases if any
        const materialIncrease = materialIncreases[expenseId] || 0;
        const laborIncrease = laborIncreases[expenseId] || 0;

        return {
          key: index,
          expenseId,
          material: costs.material + materialIncrease,
          labor: costs.labor + laborIncrease,
          items: costs.items, // Detailed items for expandable rows
        };
      }
    );

    return data;
  }, [ materialIncreases, laborIncreases]);

  // Calculate totals based on aggregated data and form inputs
  const {
    totalMaterialCost,
    totalLaborCost,
    contingencyAmount,
    totalExpenses,
    saleAmount,
    profit,
    baseMaterialCost, // Base Material without increases
    baseLaborCost, // Base Labor without increases
    totalMaterialAfterIncrease, // base + increase
    totalLaborAfterIncrease, // base + increase
  } = useMemo(() => {
    let material = 0;
    let labor = 0;
    let baseMaterial = 0;
    let baseLabor = 0;
    let sumMaterialIncrease = 0;
    let sumLaborIncrease = 0;

    aggregatedData.forEach(({ material: m, labor: l, expenseId }) => {
      const materialIncrease = materialIncreases[expenseId] || 0;
      const laborIncrease = laborIncreases[expenseId] || 0;

      material += m;
      labor += l;

      baseMaterial += m - materialIncrease;
      baseLabor += l - laborIncrease;

      sumMaterialIncrease += materialIncrease;
      sumLaborIncrease += laborIncrease;
    });

    // Calculate contingency based on (baseMaterial + sumMaterialIncrease)
    const contingency =
      (baseMaterial + sumMaterialIncrease) * (costs.contingency / 100);

    // Total Material includes contingency
    const totalMaterial = baseMaterial + sumMaterialIncrease + contingency;

    // Total Expenses = totalMaterial + labor
    const totalExpensesCalc = totalMaterial + labor;

    // Calculate sale amount based on profit margin formula: COST / (1 - PROFIT MARGIN)
    const saleAmountCalc =
      costs.profitMargin >= 100
        ? null // Handle as undefined or invalid
        : totalExpensesCalc / (1 - costs.profitMargin / 100);

    // Calculate profit margin in dollars
    const profitCalc =
      saleAmountCalc === null ? null : saleAmountCalc - totalExpensesCalc;

    return {
      totalMaterialCost: totalMaterial,
      totalLaborCost: labor,
      contingencyAmount: contingency,
      totalExpenses: totalExpensesCalc,
      saleAmount: saleAmountCalc,
      profit: profitCalc,
      baseMaterialCost: baseMaterial,
      baseLaborCost: baseLabor,
      totalMaterialAfterIncrease: baseMaterial + sumMaterialIncrease,
      totalLaborAfterIncrease: baseLabor + sumLaborIncrease,
    };
  }, [aggregatedData, costs]);

  // Define the main table columns
  const columns = [
    {
      title: "Expense ID",
      dataIndex: "expenseId",
      key: "expenseId",
      sorter: (a, b) => a.expenseId.localeCompare(b.expenseId),
      render: (text) => <span>{text}</span>,
    },
    {
      width: 180,
      align: "right",
      title: "Material Cost",
      dataIndex: "material",
      key: "material",
      render: (text) => <PriceTag amount={text} />,
    },
    {
      title: "Increase Material",
      dataIndex: "increaseMaterial",
      key: "increaseMaterial",
      render: (_, record) => (
        <InputNumber
          min={0}
          value={materialIncreases[record.expenseId] || 0}
          onChange={(value) => handleMaterialIncrease(record.expenseId, value)}
          prefix="$"
          style={{ width: "100%" }}
        />
      ),
      width: 180,
    },
    {
      width: 180,
      align: "right",
      title: "Labor Cost",
      dataIndex: "labor",
      key: "labor",
      render: (text) => <PriceTag amount={text} />,
    },
    {
      title: "Increase Labor",
      dataIndex: "increaseLabor",
      key: "increaseLabor",
      render: (_, record) => (
        <InputNumber
          min={0}
          value={laborIncreases[record.expenseId] || 0}
          onChange={(value) => handleLaborIncrease(record.expenseId, value)}
          prefix="$"
          style={{ width: "100%" }}
        />
      ),
      width: 180,
    },
    {
      width: 400,
      title: "Total Cost",
      align: "right",
      key: "total",
      render: (_, record) => (
        <PriceTag amount={record.material + record.labor} />
      ),
    },
  ];

  return (
    <Form
      form={form}
      initialValues={{
        contingency: costs.contingency,
        profitMargin: costs.profitMargin,
      }}
      layout="vertical"
      onValuesChange={handleFormChange} // Attach the change handler here
    >
      <div>
        {/* Totals Display with Descriptions */}
        <Row
          justify="start"
          align="middle"
          style={{ marginBottom: 16, width: "100%" }}
        >
          <Col span={24}>
            <Descriptions
              bordered
              column={4} // Fixed to 4 columns to ensure two rows
              size="middle"
            >
              {/* First Row: Material Contingency, Material, Labor, Total Expenses */}
              <Descriptions.Item label="Material Contingency (%)" span={1}>
                <Form.Item
                  name="contingency"
                  rules={[
                    {
                      required: true,
                      message: "Please input the contingency percentage",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "Must be between 0% and 100%",
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <InputNumber
                    addonAfter="%"
                    min={0}
                    max={100}
                    style={{ width: 100 }}
                  />
                </Form.Item>
              </Descriptions.Item>

              {/* Material Cost */}
              <Descriptions.Item label="Material" span={1}>
                <PriceTag medium amount={totalMaterialCost} />{" "}
                {/* (Base + Increase) + Contingency */}
              </Descriptions.Item>

              {/* Labor Cost */}
              <Descriptions.Item label="Labor" span={1}>
                <PriceTag medium amount={totalLaborCost} />
              </Descriptions.Item>

              {/* Total Expenses */}
              <Descriptions.Item label="Total Costs" span={1}>
                <PriceTag medium amount={totalExpenses} />
              </Descriptions.Item>

              {/* Second Row: Profit Margin, Sale Amount, Profit */}
              <Descriptions.Item label="Profit Margin (%)" span={1}>
                <Form.Item
                  name="profitMargin"
                  rules={[
                    {
                      required: true,
                      message: "Please input the profit margin percentage",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 99.99, // Prevent 100%
                      message: "Must be between 0% and 99.99%",
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <InputNumber
                    addonAfter="%"
                    min={0}
                    max={99.99}
                    step={0.01}
                    style={{ width: 120 }}
                    formatter={(value) => {
                      if (value === undefined || value === null) return "";
                      const number = Number(value);
                      if (isNaN(number)) return "";
                      return Number.isInteger(number)
                        ? number.toString()
                        : number.toFixed(2);
                    }}
                    parser={(value) => {
                      const parsed = parseFloat(value.replace("%", ""));
                      return isNaN(parsed) ? 0 : parsed;
                    }}
                  />
                </Form.Item>
              </Descriptions.Item>

              {/* Sale Amount */}
              <Descriptions.Item label="Sale Amount" span={1}>
                <PriceTag
                  medium
                  bold
                  amount={
                    saleAmount !== null && saleAmount !== undefined
                      ? saleAmount
                      : 0
                  }
                />
              </Descriptions.Item>

              {/* Profit */}
              <Descriptions.Item label="Profit" span={1}>
                <PriceTag
                  large
                  bold
                  amount={profit !== null && profit !== undefined ? profit : 0}
                />
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        {/* Aggregated Costs Table with Expandable Rows */}
        <Table
          columns={columns}
          dataSource={aggregatedData}
          bordered
          pagination={false}
          expandable={{
            expandedRowRender: (record) => {
              const { items } = record;
              if (!items || items.length === 0) {
                return <Empty description="No details available." />;
              }

              // Define columns for the nested table with reordered fields
              const nestedColumns = [
                {
                  className: "expanded-row-header",
                  title: "Origin",
                  dataIndex: "origin",
                  key: "origin",
                  render: (text) => (
                    <Tag color={ORIGIN_COLORS[text] || "default"}>{text}</Tag>
                  ),
                },
                {
                  className: "expanded-row-header",
                  title: "Type",
                  dataIndex: "type",
                  key: "type",
                  render: (text) =>
                    text === "Material" ? (
                      <Tag color="blue">Material</Tag>
                    ) : (
                      <Tag color="red">Labor</Tag>
                    ),
                },
                {
                  className: "expanded-row-header",
                  title: "Item Number",
                  dataIndex: "itemNumber",
                  key: "itemNumber",
                  render: (text) =>
                    text ? (
                      <Tag>{text}</Tag>
                    ) : (
                      <StopOutlined style={{ color: "red" }} />
                    ),
                },
                {
                  className: "expanded-row-header",
                  title: "Item Description",
                  dataIndex: "itemDescription",
                  key: "itemDescription",
                  width: 500,
                  render: (text) =>
                    text || <StopOutlined style={{ color: "red" }} />,
                },
                {
                  className: "expanded-row-header",
                  title: "Quantity",
                  dataIndex: "quantity",
                  key: "quantity",
                  render: (text) =>
                    text || <StopOutlined style={{ color: "red" }} />,
                },
                {
                  className: "expanded-row-header",
                  title: "Price",
                  dataIndex: "price",
                  key: "price",
                  render: (text) => <PriceTag amount={text} />,
                },
                {
                  className: "expanded-row-header",
                  title: "Total",
                  key: "total",
                  render: (_, record) => (
                    <PriceTag amount={record.price * record.quantity} />
                  ),
                },
              ];

              return (
                <Table
                  columns={nestedColumns}
                  style={{ marginTop: 8, marginBottom: 8 }}
                  dataSource={items.map((item, idx) => ({
                    key: idx,
                    ...item,
                  }))}
                  pagination={false}
                  size="small"
                />
              );
            },
            rowExpandable: (record) => record.items && record.items.length > 0,
          }}
          summary={() => {
            // Calculate Base and Increased Costs
            let baseMaterial = 0;
            let increasedMaterial = 0;
            let baseLabor = 0;
            let increasedLabor = 0;

            aggregatedData.forEach((record) => {
              const { material, labor, expenseId } = record;
              const materialIncrease = materialIncreases[expenseId] || 0;
              const laborIncrease = laborIncreases[expenseId] || 0;

              baseMaterial += material - materialIncrease;
              increasedMaterial += materialIncrease;

              baseLabor += labor - laborIncrease;
              increasedLabor += laborIncrease;
            });

            const totalBaseCost = baseMaterial + baseLabor;
            const totalAfterIncrease =
              baseMaterial + increasedMaterial + baseLabor + increasedLabor;

            return (
              <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                <Table.Summary.Cell
                  index={0}
                  style={{ fontWeight: "bold", fontSize: 16 }}
                >
                  Totals
                </Table.Summary.Cell>
                {/* Empty cells to align summary */}
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="right">
                  <PriceTag medium bold amount={baseMaterial} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  <PriceTag medium bold amount={increasedMaterial} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  <PriceTag medium bold amount={baseLabor} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  <PriceTag medium bold amount={increasedLabor} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="right">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 4,
                      justifyContent: "flex-end",
                    }}
                  >
                    <span style={{ marginRight: 8 }}>Base Total</span>
                    <PriceTag medium bold amount={totalBaseCost} />

                    <span style={{ marginRight: 8 }}>After Increase</span>
                    <PriceTag medium bold amount={totalAfterIncrease} />
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    </Form>
  );
}

export default InternalQuote;
