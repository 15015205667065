// src/Components/PhoneNumberInput.jsx

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import default styles
import { parsePhoneNumberFromString } from "libphonenumber-js";

const PhoneNumberInput = ({
  name,
  label,
  rules = [],
  initialValue = "",
  defaultCountry = "us",
  disableDropdown = false,
  ...rest
}) => {
  const [phone, setPhone] = useState("");

  // Format initial value to E.164 if possible
  useEffect(() => {
    if (initialValue) {
      const parsedNumber = parsePhoneNumberFromString(initialValue);
      if (parsedNumber && parsedNumber.isValid()) {
        setPhone(parsedNumber.format("E.164"));
      } else {
        setPhone(initialValue);
      }
    }
  }, [initialValue]);

  // Handle changes in the phone input
  const handleOnChange = (value, country, e, formattedValue) => {
    // Ensure the value starts with the country code
    if (!value.startsWith(`+${country.dialCode}`)) {
      value = `+${country.dialCode}${value.replace(/^\+?\d*/, "")}`;
    }

    // Validate and format the phone number
    const parsedNumber = parsePhoneNumberFromString(value);
    if (parsedNumber && parsedNumber.isValid()) {
      setPhone(parsedNumber.format("E.164"));
    } else {
      setPhone(value);
    }
  };

  return (
    <Form.Item name={name} label={label} rules={rules} initialValue={phone}>
      <PhoneInput
        country={defaultCountry}
        value={phone}
        onChange={handleOnChange}
        disableDropdown={disableDropdown}
        enableSearch={true}
        inputStyle={{
          width: "100%",
          paddingLeft: "48px", // Adjust based on flag icon width
          border: "1px solid #d9d9d9",
          borderRadius: "4px",
          height: "40px",
          boxShadow: "none",
        }}
        buttonStyle={{
          border: "1px solid #d9d9d9",
          backgroundColor: "#fff",
        }}
        dropdownStyle={{
          zIndex: 1000, // Ensure dropdown appears above other elements
        }}
        inputProps={{
          name: name || "phone",
          required: rest.required || false,
          autoFocus: rest.autoFocus || false,
          "aria-label": rest["aria-label"] || "Phone Number",
        }}
        {...rest}
      />
    </Form.Item>
  );
};

PhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired, // Required for Form.Item
  label: PropTypes.string.isRequired, // Required for Form.Item
  rules: PropTypes.array, // Validation rules
  initialValue: PropTypes.string, // Initial phone number value
  defaultCountry: PropTypes.string, // Default country code
  disableDropdown: PropTypes.bool, // Disable country dropdown
};

export default PhoneNumberInput;
