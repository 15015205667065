import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Table,
  InputNumber,
  message,
  Row,
  Col,
  Tag,
  Typography,
  Input,
  Badge,
  Card,
  Descriptions,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useItems, useEstimates } from "../../../Contexts/useSpecificData";
import { updateById } from "../../../Functions/updateById";
import { formatMoney } from "../../../Formatters/helpers";
import { TABLES } from "../../../constants";
import PriceTag from "../../../Styled/PriceTag";
import { updateSaleAmountAndProfit } from "../updateSaleAmountAndProfit";
import { debounce } from "lodash"; // Import debounce from lodash
import { getPreferredPrice } from "../../../Formatters/getPreferredPrice";

const { Text } = Typography;

const AriaItems = ({ estimate }) => {
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [quantities, setQuantities] = useState({});
  const [ariaItems, setAriaItems] = useState([]);

  const { data: items, loading: itemsLoading } = useItems();
  const { refresh: refetchEstimates } = useEstimates();

  useEffect(() => {
    if (estimate.ariaItems && Array.isArray(estimate.ariaItems)) {
      setAriaItems(estimate.ariaItems);
    } else {
      setAriaItems([]);
    }
  }, [estimate]);

  const saveDebounced = useCallback(
    debounce(async (updatedItems) => {
      try {
        const updatedEstimate = {
          ...estimate,
          ariaItems: updatedItems,
        };

        await updateById(
          TABLES.ESTIMATES,
          { ariaItems: updatedItems },
          estimate.id
        );

        await updateSaleAmountAndProfit(updatedEstimate);

      } catch (error) {
        message.error("Failed to update Aria items: " + error.message);
      }
    }, 500), // 500ms debounce
    [estimate, refetchEstimates]
  );

  const handleSave = useCallback(
    async (updatedItems) => {
      saveDebounced(updatedItems);
    },
    [saveDebounced]
  );

  const handleAddExistingItem = async (itemId, quantity) => {
    const item = items.find((i) => i.id === itemId);
    if (!item) {
      message.error("Item not found.");
      return;
    }
    if (!quantity || quantity < 1) {
      message.error("Please specify a valid quantity.");
      return;
    }

    const preferredPrice = getPreferredPrice(item);
    const newItem = {
      ...item,
      price: preferredPrice,
      quantity: quantity,
      type: "aria",
      dateAdded: new Date().toISOString(),
    };
    const updatedItems = [...ariaItems, newItem];
    setAriaItems(updatedItems);
    handleSave(updatedItems);
    message.success(`Added Aria item: ${item.itemDescription}`);
    setQuantities({ ...quantities, [itemId]: null });
  };

  const handleQuantityChange = (value, itemId) => {
    if (value < 1) {
      message.error("Quantity must be at least 1.");
      return;
    }
    const updatedItems = ariaItems.map((ai) =>
      ai.id === itemId ? { ...ai, quantity: value } : ai
    );
    setAriaItems(updatedItems);
    handleSave(updatedItems);
  };

  const handleRemove = async () => {
    if (!itemToRemove) {
      message.error("No item selected for removal.");
      setConfirmVisible(false);
      return;
    }
    const updatedItems = ariaItems.filter(
      (item) => item.id !== itemToRemove.id
    );
    setAriaItems(updatedItems);
    try {
      handleSave(updatedItems);
      message.success(`Removed item: ${itemToRemove.itemDescription}`);
    } catch (error) {
      // handleSave already shows message on error
    } finally {
      setConfirmVisible(false);
      setItemToRemove(null);
    }
  };

  const filteredAssemblies = items.filter((item) => {
    const matchesSearchTerm =
      !searchTerm ||
      item.itemDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.manufacturer.toLowerCase().includes(searchTerm.toLowerCase());
    const notAlreadyAdded = !ariaItems.find(
      (ariaItem) => ariaItem.id === item.id
    );
    return matchesSearchTerm && notAlreadyAdded;
  });

  /**
   * Table Columns Definition for Aria Items in Estimate
   */
  const estimateColumns = [
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <Tag color="green">{formatMoney(text)}</Tag>,
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          value={record.quantity}
          onChange={(value) => handleQuantityChange(value, record.id)}
        />
      ),
    },
    {
      title: "Total",
      key: "total",
      render: (text, record) => (
        <Tag color="green" style={{ fontWeight: 600 }}>
          {formatMoney(record.price * record.quantity)}
        </Tag>
      ),
      sorter: (a, b) => a.price * a.quantity - b.price * b.quantity,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            setItemToRemove(record);
            setConfirmVisible(true);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  /**
   * Table Columns Definition for Adding Existing Aria Items
   */
  const addColumns = [
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber",
    },
    {
      title: "Price",
      key: "price",
      render: (text, record) => formatMoney(getPreferredPrice(record)),
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          placeholder="Qty"
          value={quantities[record.id]}
          onChange={(value) =>
            setQuantities((prev) => ({
              ...prev,
              [record.id]: value,
            }))
          }
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() =>
            handleAddExistingItem(record.id, quantities[record.id])
          }
          disabled={!quantities[record.id] || quantities[record.id] < 1}
        >
          Add
        </Button>
      ),
    },
  ];

  const getTotalCost = () =>
    ariaItems.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: 18, marginRight: 12, fontWeight: 500 }}>
              Total Aria Items Cost:
            </span>
            <PriceTag bold large amount={getTotalCost()} />
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setVisible(true)}
          >
            Add Aria Items
          </Button>
        </Col>
      </Row>

      {/* Aria Items Display Section as Table */}
      {ariaItems.length > 0 && (
        <Card
          className="box-shadow"
          size="small"
          style={{ boxShadow: "0 0 5px 1px #E3ECF8", marginTop: 16 }}
        >
          <div
            className="box-title"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 16,
              fontWeight: 600,
              marginBottom: 12,
            }}
          >
            <Badge count={ariaItems.length} />
            <span style={{ marginLeft: 8 }}>Aria Items</span>
          </div>
          <Table
            columns={estimateColumns}
            dataSource={ariaItems}
            rowKey="id"
            pagination={false}
            size="small"
          />
        </Card>
      )}

      {/* Manage Aria Items Modal */}
      <Modal
        title="Manage Aria Items"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width="100%"
        loading={itemsLoading}
      >
        <Input
          placeholder="Search Items"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "100%", marginBottom: 16 }}
        />
        <Table
          columns={addColumns}
          dataSource={filteredAssemblies}
          rowKey="id"
          pagination={{
            defaultPageSize:10,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
          loading={itemsLoading}
        />
      </Modal>

      {/* Confirmation Modal for Removal */}
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <ExclamationCircleOutlined
              style={{ color: "#faad14", marginRight: 8 }}
            />
            <span>Confirm Removal</span>
          </div>
        }
        open={confirmVisible}
        onOk={handleRemove}
        onCancel={() => setConfirmVisible(false)}
        okText="Yes"
        cancelText="No"
        okButtonProps={{ danger: true }}
      >
        {itemToRemove ? (
          <div>
            <Typography.Text strong>
              Are you sure you want to remove this item?
            </Typography.Text>

            {/* Updated to use Descriptions */}
            <Descriptions
              bordered
              column={1}
              size="small"
              style={{ marginTop: 16 }}
            >
              <Descriptions.Item label="Item Description">
                {itemToRemove.itemDescription}
              </Descriptions.Item>
              <Descriptions.Item label="Manufacturer">
                {itemToRemove.manufacturer}
              </Descriptions.Item>
              <Descriptions.Item label="Category">
                {itemToRemove.category}
              </Descriptions.Item>
              <Descriptions.Item label="Item Number">
                {itemToRemove.itemNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Price">
                {formatMoney(itemToRemove.price)}
              </Descriptions.Item>
              <Descriptions.Item label="Quantity">
                {itemToRemove.quantity}
              </Descriptions.Item>
              <Descriptions.Item label="Total">
                {formatMoney(itemToRemove.price * itemToRemove.quantity)}
              </Descriptions.Item>
            </Descriptions>
          </div>
        ) : (
          <Typography.Text>No item selected for removal.</Typography.Text>
        )}
      </Modal>
    </div>
  );
};

export default AriaItems;
