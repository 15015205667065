export const formatBuyerName = (buyer) => {
  if (!buyer?.includes) {
    return "";
  }

  if (buyer.includes(".com")) {
    const emailParts = buyer.split("@")[0].split(".");
    return emailParts[0].charAt(0).toUpperCase() + emailParts[0].slice(1);
  } else {
    const nameParts = buyer.split(" ");
    return nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1);
  }
};
