/**
 * Function to get the preferred price of an item.
 * It returns the last purchased price if available,
 * otherwise, it returns the highest vendor price.
 * @param {Object} item - The item object containing vendors and lastPurchased details.
 * @returns {number} - The preferred price.
 */
export const getPreferredPrice = (item) => {
  // Check if the lastPurchased price is available
  if (item.lastPurchased && item.lastPurchased.price) {
    return item.lastPurchased.price;
  }

  // Find the highest vendor price
  const highestVendor = item.vendors.reduce(
    (max, vendor) => (vendor.price > max.price ? vendor : max),
    { price: 0 }
  );

  return highestVendor.price;
};

