import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import { message } from "antd";

/**
 * Calculates saleAmount and profit based on the provided estimate.
 *
 * @param {Object} estimate - The estimate object containing all necessary data.
 * @returns {Object} - Contains saleAmount, profit, and other calculated values.
 */
const calculateSaleAmountAndProfit = (estimate) => {
  if (!estimate) {
    return {
      saleAmount: 0,
      profit: 0,
      totalMaterialCost: 0,
      totalLaborCost: 0,
      contingencyAmount: 0,
      totalExpenses: 0,
      baseMaterialCost: 0,
      baseLaborCost: 0,
      totalMaterialAfterIncrease: 0,
      totalLaborAfterIncrease: 0,
    };
  }

  const costMap = new Map();

  const addCost = (expenseId, material = 0, labor = 0, itemDetails = null) => {
    if (!expenseId) return;
    if (costMap.has(expenseId)) {
      const existing = costMap.get(expenseId);
      costMap.set(expenseId, {
        material: existing.material + material,
        labor: existing.labor + labor,
        items: itemDetails ? [...existing.items, itemDetails] : existing.items,
      });
    } else {
      costMap.set(expenseId, {
        material,
        labor,
        items: itemDetails ? [itemDetails] : [],
      });
    }
  };

  const addMaterialCost = (expenseId, amount, details) => {
    addCost(expenseId, amount, 0, details);
  };

  const addLaborCost = (expenseId, amount, details) => {
    addCost(expenseId, 0, amount, details);
  };

  const { costs = {} } = estimate;
  const {
    contingency = 0,
    profitMargin = 0,
    increaseMaterials = [],
    increaseLabor = [],
  } = costs;

  const materialIncreases = increaseMaterials.reduce(
    (acc, { ExpenseId, amount }) => {
      if (ExpenseId) acc[ExpenseId] = amount;
      return acc;
    },
    {}
  );

  const laborIncreases = increaseLabor.reduce((acc, { ExpenseId, amount }) => {
    if (ExpenseId) acc[ExpenseId] = amount;
    return acc;
  }, {});

  // Process ariaItems (Material Cost only)
  if (estimate.ariaItems) {
    estimate.ariaItems.forEach((item) => {
      const { ExpenseId, price, quantity, itemNumber, itemDescription } = item;
      const totalMaterial = price * quantity;
      const itemDetails = {
        itemNumber,
        itemDescription,
        quantity,
        price,
        origin: "Aria Item",
        type: "Material",
      };
      addMaterialCost(ExpenseId, totalMaterial, itemDetails);
    });
  }

  // Process assemblies (Labor Cost and Material Cost)
  if (estimate.assemblies) {
    estimate.assemblies.forEach((assembly) => {
      const {
        ExpenseId: assemblyExpenseId,
        laborPrice,
        assemblyItems,
        quantity: assemblyQuantity,
        assemblyName,
      } = assembly;

      // Labor Cost
      const totalLaborCost = laborPrice * assemblyQuantity;
      const assemblyLaborDetails = {
        itemNumber: null,
        itemDescription: `Assembly: ${assemblyName || "Unnamed Assembly"}`,
        quantity: assemblyQuantity,
        price: laborPrice,
        origin: "Assembly",
        type: "Labor",
      };
      addLaborCost(assemblyExpenseId, totalLaborCost, assemblyLaborDetails);

      // Material Costs from assemblyItems
      if (assemblyItems) {
        assemblyItems.forEach((item) => {
          const {
            ExpenseId: itemExpenseId,
            price,
            quantity: itemQuantity,
            itemNumber,
            itemDescription,
          } = item;
          const totalMaterial = price * itemQuantity * assemblyQuantity;
          const itemDetails = {
            itemNumber,
            itemDescription,
            quantity: itemQuantity * assemblyQuantity,
            price,
            origin: "Assembly",
            type: "Material",
          };
          addMaterialCost(itemExpenseId, totalMaterial, itemDetails);
        });
      }
    });
  }

  // Process customItems (Labor Cost and Material Cost)
  if (estimate.customItems) {
    estimate.customItems.forEach((item) => {
      const {
        expenseAccount,
        price,
        laborPrice,
        quantity,
        itemNumber,
        itemDescription,
      } = item;
      const totalMaterial = price * quantity;
      const totalLabor = laborPrice * quantity;

      const materialDetails = {
        itemNumber,
        itemDescription,
        quantity,
        price,
        origin: "Custom Item",
        type: "Material",
      };

      const laborDetails = {
        itemNumber,
        itemDescription,
        quantity,
        price: laborPrice,
        origin: "Custom Item",
        type: "Labor",
      };

      // Add Material Cost Only
      addMaterialCost(expenseAccount, totalMaterial, materialDetails);

      // Add Labor Cost Separately
      addLaborCost(expenseAccount, totalLabor, laborDetails);
    });
  }

  // Process modules (Only categoryCosts: Labor Cost and Material Cost)
  if (estimate.modules) {
    estimate.modules.forEach((module) => {
      const { categoryCosts, container, rainProtection, permitted } = module;

      // Aggregate costs from categoryCosts
      if (categoryCosts) {
        Object.entries(categoryCosts).forEach(([categoryName, cost]) => {
          const { ExpenseId, materialCost = 0, laborCost = 0 } = cost;

          // Create item details for Material Cost
          const itemDetailsMaterial = {
            itemNumber: null,
            itemDescription: "Module Option: " + categoryName,
            quantity: 1,
            price: materialCost,
            origin: "Module",
            type: "Material",
          };

          // Create item details for Labor Cost
          const itemDetailsLabor = {
            itemNumber: null,
            itemDescription: "Module Option: " + categoryName,
            quantity: 1,
            price: laborCost,
            origin: "Module",
            type: "Labor",
          };

          // Add Material Cost to the cost map if applicable
          if (materialCost > 0) {
            addMaterialCost(ExpenseId, materialCost, itemDetailsMaterial);
          }

          // Add Labor Cost to the cost map if applicable
          if (laborCost > 0) {
            addLaborCost(ExpenseId, laborCost, itemDetailsLabor);
          }
        });
      }

      // Aggregate container cost (Material Cost only)
      if (container) {
        const {
          ExpenseId,
          price,
          quantity: containerQty,
          itemNumber,
          itemDescription,
        } = container;
        const totalMaterial = price * Number(containerQty);
        const containerMaterialDetails = {
          itemNumber,
          itemDescription,
          quantity: Number(containerQty),
          price,
          origin: "Module",
          type: "Material",
        };
        addMaterialCost(ExpenseId, totalMaterial, containerMaterialDetails);
      }

      // Rain Protection Handling
      if (rainProtection) {
        const {
          price,
          quantity: rainQty,
          labor,
          material,
          ExpenseId,
        } = rainProtection;

        // Labor Cost: Use total labor directly (already totaled)
        if (labor) {
          const rainLaborDetails = {
            itemNumber: null,
            itemDescription: "Rain Protection Labor",
            quantity: module.quantity, // Total labor, no need to multiply
            price: labor,
            origin: "Module",
            type: "Labor",
          };
          addLaborCost(ExpenseId, labor, rainLaborDetails);
        }

        // Material Cost: Use price directly
        if (material) {
          const rainMaterialDetails = {
            itemNumber: rainProtection.itemNumber || "20-0012",
            itemDescription:
              rainProtection.itemDescription || "Rain Protection Material",
            quantity: rainQty, // Use the quantity from rainProtection
            price: price, // Use the price directly from rainProtection
            origin: "Module",
            type: "Material",
          };
          const rainMaterialCost = price * rainQty;
          addMaterialCost(ExpenseId, rainMaterialCost, rainMaterialDetails);
        }
      }

      // Permitted Labor Handling
      if (permitted && permitted.labor) {
        const totalPermittedLabor = 4250 * module.quantity;
        const permittedDetails = {
          itemNumber: null,
          itemDescription: "Professional Fees for Permits",
          quantity: Number(module.quantity),
          price: 4250, // Labor per module
          origin: "Module",
          type: "Labor",
        };
        addLaborCost(
          "5050117 · Professional Fees",
          totalPermittedLabor,
          permittedDetails
        );
      }

      // Add $350 per container.quantity to labor costs for "5050120 · Shipping Containers"
      if (container && Number(container.quantity) > 0) {
        const additionalLabor = 350 * Number(container.quantity);
        const containerLaborDetails = {
          itemNumber: null,
          itemDescription: `Container Labor for ${
            module.moduleDescription || "Unnamed Module"
          }`,
          quantity: Number(container.quantity),
          price: 350,
          origin: "Module",
          type: "Labor",
        };
        addLaborCost(
          "5050120 · Shipping Containers",
          additionalLabor,
          containerLaborDetails
        );
      }
    });
  }

  // Convert the map to an array suitable for the main table
  const aggregatedData = Array.from(costMap.entries()).map(
    ([expenseId, costs], index) => {
      const materialIncrease = materialIncreases[expenseId] || 0;
      const laborIncrease = laborIncreases[expenseId] || 0;

      return {
        key: index,
        expenseId,
        material: costs.material + materialIncrease,
        labor: costs.labor + laborIncrease,
        items: costs.items, // Detailed items for expandable rows
      };
    }
  );

  // Calculate totals based on aggregated data and form inputs
  let material = 0;
  let labor = 0;
  let baseMaterial = 0;
  let baseLabor = 0;
  let sumMaterialIncrease = 0;
  let sumLaborIncrease = 0;

  aggregatedData.forEach(({ material: m, labor: l, expenseId }) => {
    const materialIncrease = materialIncreases[expenseId] || 0;
    const laborIncrease = laborIncreases[expenseId] || 0;

    material += m;
    labor += l;

    baseMaterial += m - materialIncrease;
    baseLabor += l - laborIncrease;

    sumMaterialIncrease += materialIncrease;
    sumLaborIncrease += laborIncrease;
  });

  // Calculate contingency based on (baseMaterial + sumMaterialIncrease)
  const contingencyAmount =
    (baseMaterial + sumMaterialIncrease) * (contingency / 100);

  // Total Material includes contingency
  const totalMaterialCost =
    baseMaterial + sumMaterialIncrease + contingencyAmount;

  // Total Expenses = totalMaterial + labor
  const totalExpenses = totalMaterialCost + labor;

  // Calculate sale amount based on profit margin formula: COST / (1 - PROFIT MARGIN)
  let saleAmount = null;
  if (profitMargin < 100) {
    saleAmount = totalExpenses / (1 - profitMargin / 100);
  }

  // Calculate profit margin in dollars
  const profit = saleAmount === null ? null : saleAmount - totalExpenses;

  return {
    saleAmount,
    profit,
  };
};

/**
 * Updates the saleAmount and profit of an estimate.
 *
 * @param {Object} estimate - The estimate object to update.
 * @returns {Promise<Object>} - Resolves to the updated estimate or status information.
 */
export const updateSaleAmountAndProfit = async (estimate) => {
  if (!estimate) {
    message.error("Estimate is undefined or null.");
    return;
  }

  try {
    // Calculate the new saleAmount and profit
    const { saleAmount, profit } = calculateSaleAmountAndProfit(estimate);

    // Prepare the updated costs object
    const updatedCosts = {
      ...estimate.costs,
      saleAmount,
      profit,
    };

    // Perform the update
    await updateById(TABLES.ESTIMATES, { costs: updatedCosts }, estimate.id);

    console.log("Sale Amount and Profit updated successfully.");


    return { status: "success", data: updatedCosts };
  } catch (error) {
    console.error("Error updating Sale Amount and Profit:", error);

    return { status: "error", error };
  }
};
