import React, { useState, useEffect } from "react";
import dayjs from "dayjs"; // Import dayjs
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import { useCustomers } from "../../Contexts/useSpecificData";
import {
  Modal,
  Form,
  InputNumber,
  Input,
  Select,
  Switch,
  Button,
  DatePicker,
  Row,
  Col,
  Typography,
  Upload,
  message,
  Card,
  Collapse,
  Table,
  Tag,
} from "antd";
import { InboxOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { s3Upload } from "../../Functions/s3Upload";
import PriceTag from "../../Styled/PriceTag";
import HeaderText from "../../Styled/HeaderText";
import FileIconComponent from "../../Styled/FileIconComponent";
import { formatDate } from "../../Formatters/helpers";

const { Title, Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;

function PurchaseAndChangeOrders({ project }) {
  const { data: customers } = useCustomers();
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editType, setEditType] = useState("purchaseOrder");
  const [formType, setFormType] = useState("Purchase Order");
  const [formData] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null); // For change orders if editing a specific one

  const hasPurchaseOrder = !!project.purchaseOrder;
  const customer = customers?.find((c) => c.id === project.customerId);
  const defaultNetTerms = customer?.netTerms ?? 0;

  const getTypeTag = (type, isChangeOrder = false) => {
    if (isChangeOrder) {
      return <Tag color="orange">Change Order</Tag>;
    }
    if (type === "Purchase Order") {
      return <Tag color="blue">Purchase Order</Tag>;
    }
    return <Tag color="red">Proposal</Tag>;
  };

  const renderFiles = (files) => {
    if (!files || files.length === 0) {
      return <div>No files attached.</div>;
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {files.map((file) => {
          const { url, originalName } = file;
          return (
            <div
              key={url}
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                padding: "8px",
                borderRadius: "8px",
              }}
            >
              <FileIconComponent file={file} style={{ width: 50 }} />
              <div style={{ marginLeft: "10px" }}>{originalName}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderLines = (lines) => {
    if (!lines || lines.length === 0) {
      return <Text>No lines available.</Text>;
    }

    const columns = [
      {
        title: "Line #",
        dataIndex: "lineNumber",
        key: "lineNumber",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount) => <PriceTag amount={amount || 0} />,
      },
      {
        title: "Date to Bill",
        dataIndex: "dateToBill",
        key: "dateToBill",
        render: (dateToBill) => (dateToBill ? formatDate(dateToBill) : "N/A"),
      },
      {
        title: "Tax",
        dataIndex: "tax",
        key: "tax",
        render: (tax) => <PriceTag amount={tax || 0} />,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (total) => <PriceTag amount={total || 0} />,
      },
    ];

    return (
      <div style={{ marginTop: "8px" }}>
        <Table
          size="small"
          bordered
          dataSource={lines}
          columns={columns}
          pagination={false}
          rowKey={(record, index) => index}
        />
      </div>
    );
  };

  useEffect(() => {
    if (isEditing && modalVisible) {
      if (editType === "purchaseOrder" && project.purchaseOrder) {
        const po = project.purchaseOrder;
        formData.setFieldsValue({
          type: po.type || "Purchase Order",
          number: po.number || null,
          taxExempt: po.taxExempt || false,
          taxPercentage: po.taxExempt ? 0 : po.taxPercentage,
          netTerms: po.netTerms || defaultNetTerms,
          lines: (po.lines || []).map((line) => ({
            ...line,
            dateToBill: line.dateToBill ? dayjs(line.dateToBill) : null,
          })),
          files: (po.files || []).map((f) => ({
            url: f.url,
            name: f.originalName || f.name,
            status: "done",
            uid: f.name,
            originalName: f.originalName || f.name,
          })),
        });
        setFormType(po.type || "Purchase Order");
      } else if (editType === "changeOrder" && project.changeOrders) {
        const idx = editingIndex !== null ? editingIndex : 0;
        const co = project.changeOrders[idx];
        formData.setFieldsValue({
          type: co.type || "Change Order",
          number: co.number || null,
          taxExempt: co.taxExempt || false,
          taxPercentage: co.taxExempt ? 0 : co.taxPercentage,
          netTerms: co.netTerms || defaultNetTerms,
          lines: (co.lines || []).map((line) => ({
            ...line,
            dateToBill: line.dateToBill ? dayjs(line.dateToBill) : null,
          })),
          files: (co.files || []).map((f) => ({
            url: f.url,
            name: f.name,
            status: "done",
            uid: f.name,
            originalName: f.originalName || f.name,
          })),
        });
        setFormType(co.type || "Change Order");
      }
    } else if (!isEditing && modalVisible) {
      // Adding a new PO or CO
      const defaultType =
        editType === "purchaseOrder" ? "Purchase Order" : "Change Order";
      formData.resetFields();
      formData.setFieldsValue({
        type: defaultType,
        netTerms: defaultNetTerms,
        taxExempt: false,
        lines: [{ lineNumber: null, amount: null, dateToBill: null }],
        files: [],
      });
      setFormType(defaultType);
    }
  }, [
    isEditing,
    modalVisible,
    project,
    defaultNetTerms,
    editType,
    formData,
    editingIndex,
  ]);

  const handleAddClick = (type) => {
    // Only allow adding change orders if purchase order exists
    if (type === "changeOrder" && !hasPurchaseOrder) {
      message.warning(
        "You must have a Purchase Order before adding Change Orders."
      );
      return;
    }
    setEditType(type);
    setIsEditing(false);
    setEditingIndex(null);
    setModalVisible(true);
  };

  const handleEditClick = (type, index = null) => {
    setEditType(type);
    setIsEditing(true);
    setEditingIndex(index);
    setModalVisible(true);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await formData.validateFields();
      const { taxExempt, taxPercentage, lines = [], files } = values;

      // Upload files after validation
      const uploadedFiles = [];
      for (let fileObj of files) {
        if (!fileObj.url) {
          // New file
          const originalFile = fileObj.originFileObj;
          const uniqueName = `${project.projectName}-${
            project.projectDescription
          }-${formType}-${new Date().toISOString()}-${originalFile.name}`;
          const url = await s3Upload(originalFile, uniqueName);
          uploadedFiles.push({
            name: uniqueName,
            url,
            originalName: originalFile.name,
            created: new Date().toISOString(),
          });
        } else {
          // Existing file - do not re-upload and preserve originalName
          uploadedFiles.push({
            name: fileObj.name,
            url: fileObj.url,
            originalName: fileObj.originalName,
            // Keep the existing created if it exists, else now (to ensure we always have a created)
            created: fileObj.created || new Date().toISOString(),
          });
        }
      }

      // Calculate totals
      const updatedLines = (lines || []).map((line) => {
        const lineAmount = line?.amount || 0;
        const lineTax = taxExempt ? 0 : lineAmount * (taxPercentage / 100);
        const lineTotal = lineAmount + lineTax;
        return {
          ...line,
          tax: lineTax,
          total: lineTotal,
        };
      });

      const overallTotal = updatedLines.reduce(
        (acc, cur) => acc + (cur.total || 0),
        0
      );

      const updatedProject = { ...project };

      // Build new data with created/updated logic
      let updatedData = {
        type: values.type,
        number: values.number,
        taxExempt: values.taxExempt,
        taxPercentage: values.taxExempt ? 0 : values.taxPercentage,
        netTerms: values.netTerms,
        lines: updatedLines,
        files: uploadedFiles,
        total: overallTotal,
      };

      const now = new Date().toISOString();

      if (editType === "purchaseOrder") {
        const existing = updatedProject.purchaseOrder || {};
        if (isEditing) {
          // Preserve created if it exists
          updatedData.created = existing.created || now;
          // Update updated timestamp
          updatedData.updated = now;
        } else {
          // New PO
          updatedData.created = now;
        }
        updatedProject.purchaseOrder = updatedData;
      } else {
        let changeOrders = [...(updatedProject.changeOrders || [])];
        if (isEditing && editingIndex !== null) {
          const existing = changeOrders[editingIndex] || {};
          // Preserve created if it exists
          updatedData.created = existing.created || now;
          // Update updated timestamp
          updatedData.updated = now;
          changeOrders[editingIndex] = updatedData;
        } else {
          // New Change Order
          updatedData.created = now;
          changeOrders.push(updatedData);
        }
        updatedProject.changeOrders = changeOrders;
      }

      await updateById(TABLES.PROJECTS, updatedProject, project.id);

      message.success("Form saved successfully!");
      formData.resetFields();
      setModalVisible(false);
    } catch (err) {
      console.error("Error saving form:", err);
      message.error("Error saving form.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    formData.resetFields();
    setModalVisible(false);
  };

  const handleFormTypeChange = (value) => {
    setFormType(value);
  };

  const uploadProps = {
    multiple: true,
    showUploadList: true,
    beforeUpload: () => false, // prevent automatic upload
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return (
      e?.fileList?.map((file) => ({
        ...file,
        status: "done",
      })) || []
    );
  };

  const buildTitle = (data, isChangeOrder = false) => {
    const {
      type,
      number,
      netTerms,
      taxExempt,
      taxPercentage,
      created,
      updated,
    } = data;
    const tag = getTypeTag(type, isChangeOrder);
    const taxLabel = taxExempt ? "Exempt" : `${taxPercentage || 0}%`;
    return (
      <>
        {tag} #{number}
        <span style={{ opacity: 0.3, marginLeft: 8, marginRight: 8 }}>|</span>
        Net Terms: {netTerms}
        <span style={{ opacity: 0.3, marginLeft: 8, marginRight: 8 }}>|</span>
        Tax: {taxLabel}
        <span style={{ opacity: 0.3, marginLeft: 8, marginRight: 8 }}>|</span>
        Created: {created ? formatDate(created) : "N/A"}
        {updated && (
          <>
            <span style={{ opacity: 0.3, marginLeft: 8, marginRight: 8 }}>
              |
            </span>
            Updated: {formatDate(updated)}
          </>
        )}
        <span style={{ opacity: 0.3, marginLeft: 8, marginRight: 8 }}>|</span>
        <PriceTag label="Total: " amount={data.total} />
      </>
    );
  };

  const typeOptions =
    editType === "changeOrder"
      ? [
          <Option key="Change Order" value="Change Order">
            Change Order
          </Option>,
          <Option key="Proposal" value="Proposal">
            Proposal
          </Option>,
        ]
      : [
          <Option key="Purchase Order" value="Purchase Order">
            Purchase Order
          </Option>,
          <Option key="Proposal" value="Proposal">
            Proposal
          </Option>,
        ];

  // Determine button text based on whether we have a Purchase Order
  const buttonText = hasPurchaseOrder
    ? "Add Change Order"
    : "Add Purchase Order";
  const buttonAction = hasPurchaseOrder
    ? () => handleAddClick("changeOrder")
    : () => handleAddClick("purchaseOrder");

  return (
    <div>
      {/* Header for Purchase Order with button on same line */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <HeaderText text={"Purchase Order"} />
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined/>} onClick={buttonAction}>
            {buttonText}
          </Button>
        </Col>
      </Row>

      {hasPurchaseOrder ? (
        <Card
          title={buildTitle(project.purchaseOrder, false)}
          style={{ marginBottom: "20px" }}
          extra={
            <EditOutlined
              style={{ cursor: "pointer" }}
              onClick={() => handleEditClick("purchaseOrder")}
            />
          }
        >
          <Row gutter={16}>
            <Col span={16}>
              <Collapse defaultActiveKey={["lines"]}>
                <Panel header="View Lines" key="lines">
                  {renderLines(project.purchaseOrder.lines)}
                </Panel>
              </Collapse>
            </Col>
            <Col span={8}>
              <Card title={null} bodyStyle={{ padding: "8px" }}>
                {renderFiles(project.purchaseOrder.files)}
              </Card>
            </Col>
          </Row>
        </Card>
      ) : (
        <div style={{ marginBottom: 20 }}>
          <Text>No Purchase Order Found</Text>
        </div>
      )}

      {hasPurchaseOrder && (
        <>
          {/* Change Orders Header */}
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: "32px", marginBottom: "20px" }}
          >
            <Col>
              <HeaderText text={"Change Orders"} />
            </Col>
          </Row>

          {project.changeOrders && project.changeOrders.length > 0 ? (
            <Row gutter={[16, 16]}>
              {project.changeOrders.map((co, idx) => (
                <Col span={24} key={idx}>
                  <Card
                    title={buildTitle(co, true)}
                    extra={
                      <EditOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditClick("changeOrder", idx)}
                      />
                    }
                  >
                    <Row gutter={16}>
                      <Col span={16}>
                        <Collapse defaultActiveKey={["lines"]}>
                          <Panel header="View Lines" key="lines">
                            {renderLines(co.lines)}
                          </Panel>
                        </Collapse>
                      </Col>
                      <Col span={8}>
                        <Card title={null} bodyStyle={{ padding: "8px" }}>
                          {renderFiles(co.files)}
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : null}
        </>
      )}

      <Modal
        open={modalVisible}
        title={
          editType === "purchaseOrder"
            ? isEditing
              ? "Edit Purchase Order"
              : "Add Purchase Order"
            : isEditing
            ? "Edit Change Order"
            : "Add Change Order"
        }
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        confirmLoading={loading}
        okText={isEditing ? "Save" : "Create"}
        cancelText="Cancel"
      >
        <Form form={formData} layout="vertical">
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: "Type is required" }]}
              >
                <Select onChange={handleFormTypeChange}>{typeOptions}</Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="number"
                label={`${formType} Number`}
                rules={[{ required: true, message: "Number is required" }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="netTerms"
                label="Net Terms"
                rules={[{ required: true, message: "Net Terms is required" }]}
              >
                <Select allowClear>
                  {[0, 15, 30, 45, 60, 90].map((term) => (
                    <Option key={term} value={term}>
                      {term}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="taxExempt"
                label="Tax Exempt"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  const exempt = formData.getFieldValue("taxExempt");
                  return !exempt ? (
                    <Form.Item
                      name="taxPercentage"
                      label="Tax Percentage"
                      rules={[
                        {
                          required: true,
                          message: "Tax Percentage is required if not exempt",
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        addonAfter="%"
                        style={{ width: "100%" }}
                        type="number"
                      />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>
            </Col>
          </Row>

          <Title level={5} style={{ marginTop: 20 }}>
            Lines
          </Title>
          <Form.List
            name="lines"
            rules={[
              {
                validator: async (_, lines) => {
                  if (!lines || lines.length === 0) {
                    return Promise.reject(
                      new Error("At least one line is required")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey }) => (
                  <Row
                    key={key}
                    gutter={16}
                    style={{
                      marginBottom: 10,
                      borderBottom: "1px solid #eee",
                      paddingBottom: 10,
                    }}
                  >
                    <Col span={3}>
                      <Form.Item
                        name={[name, "lineNumber"]}
                        label="Line #"
                        fieldKey={[fieldKey, "lineNumber"]}
                        rules={[
                          { required: true, message: "Line number required" },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={[name, "amount"]}
                        label="Amount"
                        fieldKey={[fieldKey, "amount"]}
                        rules={[{ required: true, message: "Amount required" }]}
                      >
                        <InputNumber
                          prefix="$"
                          min={0}
                          style={{ width: "100%" }}
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[name, "dateToBill"]}
                        label="Date to Bill"
                        fieldKey={[fieldKey, "dateToBill"]}
                        rules={[
                          { required: true, message: "Date is required" },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label="Net Terms">
                        <Form.Item noStyle shouldUpdate>
                          {() => {
                            const netTerms = formData.getFieldValue("netTerms");
                            return <Input value={netTerms || 0} disabled />;
                          }}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label="Tax">
                        <Form.Item noStyle shouldUpdate>
                          {() => {
                            const taxExempt =
                              formData.getFieldValue("taxExempt");
                            const taxPct = taxExempt
                              ? 0
                              : formData.getFieldValue("taxPercentage") || 0;
                            const amt =
                              formData.getFieldValue([
                                "lines",
                                name,
                                "amount",
                              ]) || 0;
                            const lineTax = amt * (taxPct / 100);
                            return <Input value={lineTax} disabled />;
                          }}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label="Total">
                        <Form.Item noStyle shouldUpdate>
                          {() => {
                            const taxExempt =
                              formData.getFieldValue("taxExempt");
                            const taxPct = taxExempt
                              ? 0
                              : formData.getFieldValue("taxPercentage") || 0;
                            const amt =
                              formData.getFieldValue([
                                "lines",
                                name,
                                "amount",
                              ]) || 0;
                            const lineTax = amt * (taxPct / 100);
                            const lineTotal = amt + lineTax;
                            return <Input value={lineTotal} disabled />;
                          }}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col
                      span={2}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {fields.length > 1 && (
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => remove(name)}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ marginBottom: 20 }}
                >
                  Add Line
                </Button>
              </>
            )}
          </Form.List>

          <Form.Item noStyle shouldUpdate>
            {() => {
              const lines = formData.getFieldValue("lines") || [];
              const taxExempt = formData.getFieldValue("taxExempt");
              const taxPercentage = taxExempt
                ? 0
                : formData.getFieldValue("taxPercentage") || 0;
              const overallTotal = lines.reduce((sum, line) => {
                const amount = line?.amount || 0;
                const lineTax = amount * (taxPercentage / 100);
                return sum + amount + lineTax;
              }, 0);
              return (
                <div style={{ marginTop: 20 }}>
                  <Title level={5}>
                    <span>Total: </span>
                    <PriceTag
                      medium
                      style={{ marginLeft: 4 }}
                      amount={overallTotal}
                    />
                  </Title>
                </div>
              );
            }}
          </Form.Item>

          <Title level={5} style={{ marginTop: 20 }}>
            Files (At least one required)
          </Title>
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "At least one file is required.",
              },
            ]}
          >
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag files to this area to select
              </p>
              <p className="ant-upload-hint">
                The files won't be uploaded until the form is submitted.
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default PurchaseAndChangeOrders;
