import React from "react";
import { Card, Row, Col } from "antd";
import {
  DollarOutlined,
  CalculatorOutlined,
  UserOutlined,
  DollarCircleFilled,
  CalculatorFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { COLORS } from "../constants";
import bmarkoLogo from "../images/Large - Black Full Logo TM.png";
import { useUser } from "@clerk/clerk-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxesPacking, faCompassDrafting, faHammer, faHelmetSafety, faNewspaper, faScrewdriverWrench, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { useMediaQueryContext } from "../Contexts/MediaQueryContext";



const Home = () => {
  const { user } = useUser();
  const roles = user.publicMetadata.roles || [];
  const { isDesktop } = useMediaQueryContext(); // Use media query context to determine the screen size

  const cardStyle = {
    width: "150px",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    background: COLORS.SECONDARY,
    textAlign: "center",
    position: "relative",
  };

  const titleStyle = {
    color: "white",
    fontSize: "18px",
    marginTop: "10px",
  };

  const iconStyle = {
    fontSize: "36px",
    color: "white",
  };

  const availableModules = [
    roles.includes("estimator") && {
      path: "/estimating/estimates",
      icon: <CalculatorFilled style={iconStyle} />,
      title: "Estimating",
    },
    roles.includes("purchaser") && {
      path: "/purchasing/purchase",
      icon: <DollarCircleFilled style={iconStyle} />,
      title: "Purchasing",
    },
    roles.includes("receiver") && {
      path: "/receiving/receive/purchase-orders",
      desktopPath: "/receiving/dashboard",
      icon: <FontAwesomeIcon icon={faBoxesPacking} style={iconStyle} />,
      title: "Receiving",
    },

    roles.includes("admin") && {
      path: "/admin/users",
      icon: <FontAwesomeIcon icon={faUserTie} style={iconStyle} />,
      title: "Admin",
    },
    roles.includes("projectmgmt") && {
      path: "/project-management/preconstruction",
      icon: <FontAwesomeIcon icon={faCompassDrafting} style={iconStyle} />,
      title: "Project Management",
    },
    // roles.includes("production") && {
    //   path: "/production/production",
    //   icon: <FontAwesomeIcon icon={faScrewdriverWrench} style={iconStyle} />,
    //   title: "Production",
    // },
    // roles.includes("operations") && {
    //   path: "/operations/projects",
    //   icon: <FontAwesomeIcon icon={faHelmetSafety} style={iconStyle} />,
    //   title: "Operations",
    // },
  ]
    .filter(Boolean)
    .sort((a, b) => a.title.localeCompare(b.title)); // Sort alphabetically by title

  if (roles.length === 1 && roles.includes("receiver")) {
    if (isDesktop) {
      // Redirect to the dashboard on desktop
      window.location.replace("/receiving/dashboard");
    } else {
      // Redirect to purchase orders on tablet/mobile
      window.location.replace("/receiving/receive/purchase-orders");
    }
    return null; // Render nothing as the user is being redirected
  }

  // Handle redirection for users without "admin" role and only one available module
  if (!roles.includes("admin") && availableModules.length === 1) {
    window.location.replace(availableModules[0].path);
    return null; // Render nothing as the user is being redirected
  }

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "16px",
      }}
    >
      <img
        src={bmarkoLogo}
        alt="Bmarko Logo"
        style={{
          height: "80px",
          marginBottom: "32px",
        }}
      />
      <Row
        gutter={[16, 16]}
        justify="center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap", // Ensure that items wrap on smaller screens
          width: "100%",
        }}
      >
        {availableModules.map((module, index) => (
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            key={index}
            xs={12} // Each tile takes up half the screen width on tablet/mobile
            sm={12}
            md={6} // Medium screens: 3 per row
            lg={3} // Large screens and up: 4 per row
          >
            <Link to={module.path}>
              <Card hoverable className="box-shadow" style={cardStyle}>
                {module.icon}
                <div style={titleStyle}>{module.title}</div>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Home;
