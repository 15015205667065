// constants.js
export const TABLES = {
  ASSEMBLIES: `aria-assemblies${process.env.REACT_APP_STAGE}`,
  ESTIMATES: `aria-estimates${process.env.REACT_APP_STAGE}`,
  ITEMS: `aria-items${process.env.REACT_APP_STAGE}`,
  PROJECTS: `aria-projects${process.env.REACT_APP_STAGE}`,
  PURCHASE_ORDERS: `aria-purchase-orders${process.env.REACT_APP_STAGE}`,
  VENDORS: `aria-vendors${process.env.REACT_APP_STAGE}`,
  CUSTOMERS: `aria-customers${process.env.REACT_APP_STAGE}`,
  TICKETS: `aria-tickets${process.env.REACT_APP_STAGE}`,
  ESTIMATING_CUSTOM_ITEMS: `aria-estimating-custom-items${process.env.REACT_APP_STAGE}`,
  TRANSACTIONS: `aria-transactions${process.env.REACT_APP_STAGE}`,
  MODULE_OPTIONS: `aria-module-options${process.env.REACT_APP_STAGE}`,
  REMINDERS: `aria-reminders${process.env.REACT_APP_STAGE}`,
  MANUFACTURING_LOCATIONS: `aria-manufacturing-locations${process.env.REACT_APP_STAGE}`,
};

export const COLORS = {
  PRIMARY: "#557FCB",
  SECONDARY: "#001529",
  SUCCESS: "#52c41a",
  WARNING: "#f10000",
  ERROR: "#f5222d",
};

export const ASSEMBLY_CATEGORIES = {
  EXTERIOR_DOORS: "Exterior Doors",
  INTERIOR_DOORS: "Interior Doors",
  STOREFRONT: "Storefront",
  WINDOWS: "Windows",
  FRAMING: "Framing",
  DRYWALL: "Drywall",
  TRIM: "Trim",
  FLOORING: "Flooring",
  EXTERIOR_PAINT: "Exterior Paint",
  BARD_HVAC: "Bard HVAC",
  OTHER_HVAC: "Other HVAC",
  ELECTRICAL: "Electrical",
  PLUMBING: "Plumbing",
  EQUIPMENT: "Equipment",
};

export const ADDRESSES = [
  "2624 Weaver Way Suite 200, Doraville, GA 30340",
  "6544 Warren Drive NW, Suite 106, Norcross GA 30093",
];


export const ITEM_CATEGORIES = {
  APPLIANCES: { prefix: "01-", expenseAccount: "5050110 · Equipment" },
  BLINDS: { prefix: "02-", expenseAccount: "5050110 · Equipment" },
  "BUILDING MATERIALS": {
    prefix: "20-",
    expenseAccount: "5050110 · Equipment",
  },
  CABINETS: { prefix: "15-", expenseAccount: "5050148 · Cabinets" },
  CLEANING: { prefix: "03-", expenseAccount: "5050127 · Cleaning" },
  DECOR: { prefix: "04-", expenseAccount: "5050110 · Equipment" },
  "DOORS & WINDOWS": { prefix: "10-", expenseAccount: "" },
  DRYWALL: { prefix: "21-", expenseAccount: "5050107 · Drywall" },
  ELECTRICAL: { prefix: "30-", expenseAccount: "5050109 · Electrical" },
  FASTENERS: { prefix: "22-", expenseAccount: "5050112 · Framing" },
  FIRE: { prefix: "05-", expenseAccount: "5050174 · Fire Protection" },
  FLOORING: { prefix: "40-", expenseAccount: "5050111 · Flooring" },
  HARDWARE: { prefix: "29-", expenseAccount: "5050110 · Equipment" },
  HVAC: { prefix: "50-", expenseAccount: "5050101 · ACS" },
  INSULATION: { prefix: "23-", expenseAccount: "5050113 · Insulation" },
  LUMBER: { prefix: "24-", expenseAccount: "5050112 · Framing" },
  MILLWORK: { prefix: "25-", expenseAccount: "5050110 · Equipment" },
  OFFICE: { prefix: "94-", expenseAccount: "6070 · Office Supplies" },
  PAINT: { prefix: "60-", expenseAccount: "5050115 · Paint" },
  PLUMBING: { prefix: "70-", expenseAccount: "5050116 · Plumbing" },
  ROOFING: { prefix: "26-", expenseAccount: "5050110 · Equipment" },
  SAFETY: { prefix: "80-", expenseAccount: "5050110 · Equipment" },
  "SHIPPING CONTAINER": {
    prefix: "90-",
    expenseAccount: "5050120 · Shipping Containers",
  },
  // SIDING: { prefix: "27-", expenseAccount: "" }, Removed by Antony 10/22/2024
  STEEL: { prefix: "31-", expenseAccount: "5050124 · Welding" },
  "STRUCTURAL HARDWARE": {
    prefix: "28-",
    expenseAccount: "5050110 · Equipment",
  },
  TOOLS: { prefix: "93-", expenseAccount: "6030 · Tools & Equipment" },
  TRANSPORTATION: {prefix:"12-", expenseAccount: ""}
};

export const EXPENSE_ACCOUNTS = [
  "5050101 · ACS",
  "5050107 · Drywall",
  "5050109 · Electrical",
  "5050110 · Equipment",
  "5050111 · Flooring",
  "5050112 · Framing",
  "5050113 · Insulation",
  "5050114 · Metal Doors",
  "5050115 · Paint",
  "5050116 · Plumbing",
  "5050117 · Professional Fees",
  "5050118 · Rain Protection",
  "5050120 · Shipping Containers",
  "5050121 · Storefront Glass",
  "5050122 · Transportation",
  "5050123 · Trim",
  "5050124 · Welding",
  "5050125 · Windows",
  "5050127 · Cleaning",
  "5050148 · Cabinets",
  "5050150 · Countertops",
  "5050156 · Base Frame",
  "5050157 · Floor Bulkheads",
  "5050158 · Interlocking Panels",
  "5050159 · Wall Cap",
  "5050174 · Fire Protection",
  "5050351 · Inbound Transportation",
  "5050352 · Outbound Transportation",
  "6030 · Tools & Equipment",
  "6032 · Waste Management",
  "6033-02 · Space Improvements",
  "6033-03 · Moving",
  "6040 · Repairs",
  "6041 · Janitorial & Maint Supplies",
  "6042 · Utilities",
  "6045 · Software & IT Services",
  "6050 · Professional Fees",
  "6070 · Office Supplies",
];


// rValues.js
export const WALL_R_VALUES = [
  { label: "(None)", value: false },
  { label: "R-13 Fiberglass Batt", value: "R-13 Fiberglass Batt" },
  { label: "R-15 Fiberglass Batt", value: "R-15 Fiberglass Batt" },
  { label: "R-19 Fiberglass Batt", value: "R-19 Fiberglass Batt" },
  {
    label: "R-13 Closed Cell Spray Foam",
    value: "R-13 Closed Cell Spray Foam",
  },
  {
    label: "R-15 Closed Cell Spray Foam",
    value: "R-15 Closed Cell Spray Foam",
  },
  {
    label: "R-19 Closed Cell Spray Foam",
    value: "R-19 Closed Cell Spray Foam",
  },
  { label: "R-15 Mineral Wool", value: "R-15 Mineral Wool" },
];

export const FLOOR_R_VALUES = [
  { label: "(None)", value: false },
  { label: "R-7 Closed Cell Spray Foam", value: "R-7 Closed Cell Spray Foam" },
  {
    label: "R-19 Closed Cell Spray Foam",
    value: "R-19 Closed Cell Spray Foam",
  },
];

export const CEILING_R_VALUES = [
  { label: "(None)", value: false },
  { label: "R-30 Fiberglass Batt", value: "R-30 Fiberglass Batt" },
  { label: "R-30C Fiberglass Batt", value: "R-30C Fiberglass Batt" },
  {
    label: "R-30 Closed Cell Spray Foam",
    value: "R-30 Closed Cell Spray Foam",
  },
  {
    label: "R-38 Closed Cell Spray Foam",
    value: "R-38 Closed Cell Spray Foam",
  },
  { label: "R-15 Mineral Wool", value: "R-15 Mineral Wool" },
];

export const WALL_FINISH_VALUES = [
  { label: "(None)", value: false },
  { label: "Unpainted Corrugation", value: "Unpainted Corrugation" },
  { label: "Painted Corrugation", value: "Painted Corrugation" },
  { label: "Framed", value: "Framed" },
  { label: '1/2" Vinyl Wrapped Drywall', value: '1/2" Vinyl Wrapped Drywall' },
  { label: '7/16" OSB with FRP', value: '7/16" OSB with FRP' },
  { label: '1/2" AC Plywood', value: '1/2" AC Plywood' },
];

export const FRAMING_VALUES = [
  { label: "(None)", value: false },
  { label: "2x4 Wood Studs", value: "2x4 Wood Studs" },
  { label: '1-5/8" 20ga. CFS', value: '1-5/8" 20ga. CFS' },
  { label: '2-1/2" 20ga. CFS', value: '2-1/2" 20ga. CFS' },
  { label: '3-5/8" 20ga. CFS', value: '3-5/8" 20ga. CFS' },
];


export const ESTIMATE_STATUS = [
  { value: "Pre-Hand off", label: "Pre-Hand off", color: "yellow" },
  { value: "Estimating", label: "Estimating", color: "orange" },
  { value: "Estimated", label: "Estimated", color: "green" },
  { value: "Re-quote needed", label: "Re-quote needed", color: "red" },
];

export const EMAILBODY = {
  PURCHASE_ORDER: ({
    poNum,
    vendor,
    vendorFirstName,
    capFirstName,
    reqDateFormatted,
  }) => ({
    subject: `Purchase Order ${poNum} - ${vendor} / Bmarko Structures`,
    body: `<div style='border-radius:20px;background:#DFDFDF;max-width:800px;font-size:16px;font-weight:bold;'>
             <div style='margin:0 auto;padding:40px;width:90%;'>
               <div style='text-align:left;'>
                 <a href="www.bmarkostructures.com">
                   <img style='padding-bottom:50px;max-width:300px;margin-bottom:0px;' src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png">
                 </a>
                 <br>
               </div>
               Hello ${vendorFirstName},<br>
               <hr style='border-color:red'>
               Please see attached the Purchase Order for ${poNum}<br><br>
               Please fulfill this order no later than ${reqDateFormatted}.<br>
               <hr style='border-color:red'>
               Sincerely,<br> ${capFirstName}
             </div>
           </div>`,
  }),

  REVISED_PURCHASE_ORDER: ({
    poNum,
    vendor,
    vendorFirstName,
    capFirstName,
    reqDateFormatted,
    originalDate,
  }) => ({
    subject: `[REVISED] Purchase Order ${poNum} - ${vendor} / Bmarko Structures`,
    body: `<div style='border-radius:20px;background:#DFDFDF;max-width:800px;font-size:16px;font-weight:bold;'>
             <div style='margin:0 auto;padding:40px;width:90%;'>
               <div style='text-align:left;'>
                 <a href="www.bmarkostructures.com">
                   <img style='padding-bottom:50px;max-width:300px;margin-bottom:0px;' src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png">
                 </a>
                 <br>
               </div>
               Hello ${vendorFirstName},<br>
               <hr style='border-color:red'>
               Please see attached the Revised Purchase Order for ${poNum}. This supersedes the PO sent on ${originalDate}.<br><br>
               Please fulfill this order no later than ${reqDateFormatted}.<br>
               <hr style='border-color:red'>
               Sincerely,<br> ${capFirstName}
             </div>
           </div>`,
  }),

  REQUEST_FOR_QUOTE: ({
    vendor,
    vendorFirstName,
    capFirstName,
    reqDateFormatted,
  }) => ({
    subject: `Request for Quote - ${vendor} / Bmarko Structures`,
    body: `<div style='border-radius:20px;background:#DFDFDF;max-width:800px;font-size:16px;font-weight:bold;'>
             <div style='margin:0 auto;padding:40px;width:90%;'>
               <div style='text-align:left;'>
                 <a href="www.bmarkostructures.com">
                   <img style='padding-bottom:50px;max-width:300px;margin-bottom:0px;' src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png">
                 </a>
                 <br>
               </div>
               Hello ${vendorFirstName},<br>
               <hr style='border-color:red'>
               Please see the attached request for quote.<br><br>
               Feel free to send us back the quote with your pricing no later than ${reqDateFormatted}.<br>
               <hr style='border-color:red'>
               Sincerely,<br> ${capFirstName}
             </div>
           </div>`,
  }),

  RETURN_ORDER: ({
    poNum,
    vendor,
    vendorFirstName,
    capFirstName,
    reqDateFormatted,
  }) => ({
    subject: `Return Order ${poNum} - ${vendor} / Bmarko Structures`,
    body: `<div style='border-radius:20px;background:#DFDFDF;max-width:800px;font-size:16px;font-weight:bold;'>
             <div style='margin:0 auto;padding:40px;width:90%;'>
               <div style='text-align:left;'>
                 <a href="www.bmarkostructures.com">
                   <img style='max-width:300px;margin-bottom:0px;' src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png">
                 </a>
                 <br>
               </div>
               Hello ${vendorFirstName},<br>
               <hr style='border-color:red'>
               Please see attached the Return Order for ${poNum}.<br><br>
               Please complete this return no later than ${reqDateFormatted}.<br>
               <hr style='border-color:red'>
               Sincerely,<br> ${capFirstName}
             </div>
           </div>`,
  }),

  REVISED_RETURN_ORDER: ({
    poNum,
    vendor,
    vendorFirstName,
    capFirstName,
    reqDateFormatted,
    originalDate,
  }) => ({
    subject: `[REVISED] Return Order ${poNum} - ${vendor} / Bmarko Structures`,
    body: `<div style='border-radius:20px;background:#DFDFDF;max-width:800px;font-size:16px;font-weight:bold;'>
             <div style='margin:0 auto;padding:40px;width:90%;'>
               <div style='text-align:left;'>
                 <a href="www.bmarkostructures.com">
                   <img style='padding-bottom:50px;max-width:300px;margin-bottom:0px;' src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png">
                 </a>
                 <br>
               </div>
               Hello ${vendorFirstName},<br>
               <hr style='border-color:red'>
               Please see attached the Revised Return Order for ${poNum}. This supersedes the Return Order sent on ${originalDate}.<br><br>
               <hr style='border-color:red'>
               Sincerely,<br> ${capFirstName}
             </div>
           </div>`,
  }),

  MULTIPLE_PURCHASE_ORDERS: ({
    purchaseOrders,
    vendorFirstName,
    capFirstName,
    vendor,
  }) => {
    // Convert date strings back to dates for sorting
    const sortedPurchaseOrders = purchaseOrders.sort(
      (a, b) => new Date(a.reqDateFormatted) - new Date(b.reqDateFormatted)
    );

    const ordersList = sortedPurchaseOrders
      .map(
        (order) =>
          `Purchase Order ${order.poNum} - Required Date: ${order.reqDateFormatted}`
      )
      .join("<br>");

    const poNumbers = sortedPurchaseOrders
      .map((order) => order.poNum)
      .join(", ");

    return {
      subject: `Multiple Purchase Orders - ${poNumbers} - ${vendor} / Bmarko Structures`,
      body: `<div style='border-radius:20px;background:#DFDFDF;max-width:800px;font-size:16px;font-weight:bold;'>
             <div style='margin:0 auto;padding:40px;width:90%;'>
               <div style='text-align:left;'>
                 <a href="www.bmarkostructures.com">
                   <img style='padding-bottom:50px;max-width:300px;margin-bottom:0px;' src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png">
                 </a>
                 <br>
               </div>
               Hello ${vendorFirstName},<br>
               <hr style='border-color:red'>
               Please see attached the following Purchase Orders:<br><br>
               ${ordersList}<br><br>
               Please fulfill these orders no later than their respective required dates.<br>
               <hr style='border-color:red'>
               Sincerely,<br> ${capFirstName}
             </div>
           </div>`,
    };
  },

  CANCELLED_PURCHASE_ORDER: ({
    poNum,
    vendor,
    vendorFirstName,
    capFirstName,
    reqDateFormatted,
    originalDate,
  }) => ({
    subject: `[CANCELLATION] Purchase Order ${poNum} - ${vendor} / Bmarko Structures`,
    body: `
  <div style="border-radius: 20px; background: #DFDFDF; max-width: 800px; font-size: 18px; font-weight: bold;">
    <div style="margin: 0 auto; padding: 40px; width: 90%;">
      <div style="text-align: left;">
        <a href="https://www.bmarkostructures.com">
          <img 
            style="padding-bottom: 50px; max-width: 300px; margin-bottom: 0px;" 
            src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png" 
            alt="Bmarko Structures Logo"
          />
        </a>
        <br>
      </div>
      Hello ${vendorFirstName},<br>
      <hr style="border-color: red;">
      Please note that we are cancelling PO ${poNum}, originally sent on ${originalDate}.<br>
      <hr style="border-color: red;">
      Sincerely,<br>
      ${capFirstName}
    </div>
  </div>
`,
  }),
  CANCELLED_RETURN_ORDER: ({
    poNum,
    vendor,
    vendorFirstName,
    capFirstName,
    reqDateFormatted,
    originalDate,
  }) => ({
    subject: `[CANCELLATION] Return Order ${poNum} - ${vendor} / Bmarko Structures`,
    body: `
  <div style="border-radius: 20px; background: #DFDFDF; max-width: 800px; font-size: 18px; font-weight: bold;">
    <div style="margin: 0 auto; padding: 40px; width: 90%;">
      <div style="text-align: left;">
        <a href="https://www.bmarkostructures.com">
          <img 
            style="padding-bottom: 50px; max-width: 300px; margin-bottom: 0px;" 
            src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png" 
            alt="Bmarko Structures Logo"
          />
        </a>
        <br>
      </div>
      Hello ${vendorFirstName},<br>
      <hr style="border-color: red;">
      Please note that we are cancelling Return Order ${poNum}, originally sent on ${originalDate}.<br>
      <hr style="border-color: red;">
      Sincerely,<br>
      ${capFirstName}
    </div>
  </div>
`,
  }),
};

export const SLACK_CHANNELS = {
  RECEIVING_DEV: "C07JY71K1D4", //dev-orders-receiving-doraville
  RECEIVING_PROD: "G01E2KR043S", //orders-receiving-doraville
  TICKETS_DEV: "C07MQCLQNTX", //aria-tickets-dev
  TICKETS_PROD: "C07MQCP7QG5", //aria-tickets-prod
  NORCROSS_RECEIVING_DEV: "C081Q5WJFJR", //dev-orders-receiving-norcross
  NORCROSS_RECEIVING_PROD: "C081Q4CKL7P", //orders-receiving-norcross
};

export const TAG_COLORS = [
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];