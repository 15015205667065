// src/components/DeliveryRoute.js
import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  useLoadScript,
} from "@react-google-maps/api";
import { Spin, Typography, Steps } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { COLORS } from "../../constants"; // Adjust the import path as necessary
import {
  faBuilding,
  faHammer,
  faT,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bmarkoLogo from "../../images/Small - Black Icon.png";

const { Text } = Typography;
const { Step } = Steps;

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const libraries = ["places"];

function DeliveryRoute({ originPlaceId, destinationPlaceId }) {
  const [directions, setDirections] = useState(null);
  const [mapError, setMapError] = useState(null);
  const [originAddress, setOriginAddress] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [loadingAddresses, setLoadingAddresses] = useState(true);

  // New state variables for total miles and days
  const [totalMiles, setTotalMiles] = useState(null);
  const [daysNeeded, setDaysNeeded] = useState(null);

  // Reference to the map instance
  const mapRef = useRef(null);

  // Load the Google Maps script
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY, // Ensure this environment variable is correctly set
    libraries,
  });

  // Callback to handle directions response
  const directionsCallback = useCallback(
    (response) => {
      if (response !== null) {
        if (response.status === "OK") {
          setDirections(response);

          // Extract total distance in miles
          const route = response.routes[0];
          let distanceInMeters = 0;
          if (route.legs && route.legs.length > 0) {
            route.legs.forEach((leg) => {
              distanceInMeters += leg.distance.value;
            });
          }
          const distanceInMiles = distanceInMeters / 1609.34; // Convert meters to miles
          setTotalMiles(distanceInMiles.toFixed(2));

          // Calculate days needed at 500 miles per day, rounding up
          const days = Math.ceil(distanceInMiles / 500);
          setDaysNeeded(days);
        } else {
          setMapError("Could not fetch directions. " + response.status);
        }
      }
    },
    [] // Empty dependency array ensures this callback is stable
  );

  // Function to fetch address from Place ID
  const fetchAddress = useCallback((placeId, setAddress) => {
    if (!mapRef.current || !placeId) {
      setAddress("N/A");
      return;
    }

    const service = new window.google.maps.places.PlacesService(mapRef.current);
    const request = {
      placeId: placeId,
      fields: ["formatted_address"],
    };

    service.getDetails(request, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.formatted_address
      ) {
        setAddress(place.formatted_address);
      } else {
        setAddress("Address not found");
        console.error("Place details request failed due to " + status);
      }
    });
  }, []);

  // Fetch addresses when the map is loaded and Place IDs change
  useEffect(() => {
    if (isLoaded && originPlaceId && destinationPlaceId) {
      // Initialize a dummy map to use PlacesService
      const dummyMap = new window.google.maps.Map(
        document.createElement("div")
      );
      mapRef.current = dummyMap;

      // Fetch origin address
      fetchAddress(originPlaceId, setOriginAddress);

      // Fetch destination address
      fetchAddress(destinationPlaceId, setDestinationAddress);

      setLoadingAddresses(false);
    }
  }, [isLoaded, originPlaceId, destinationPlaceId, fetchAddress]);

  if (loadError) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <StopOutlined style={{ fontSize: "48px", color: COLORS.WARNING }} />
        <Text level={4}>Error loading maps</Text>
      </div>
    );
  }

  if (!isLoaded || loadingAddresses) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  // Validate that both origin and destination are provided
  if (!originPlaceId || !destinationPlaceId) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Text type="warning">
          Insufficient location data to display the delivery route.
        </Text>
      </div>
    );
  }

  return (
      <div>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        // The center and zoom will be automatically adjusted by DirectionsRenderer
        center={{
          lat: 0,
          lng: 0,
        }}
              zoom={2} // Initial zoom; will be overridden by DirectionsRenderer

      >
        {/* Directions Service - only render if directions are not yet set */}
        {!directions && (
          <DirectionsService
            options={{
              destination: { placeId: destinationPlaceId },
              origin: { placeId: originPlaceId },
              travelMode: "DRIVING",
            }}
            callback={directionsCallback}
          />
        )}

        {/* Directions Renderer - renders directions once they are available */}
        {directions && (
          <DirectionsRenderer
            options={{
              directions: directions,
              // Automatically adjust map bounds to fit the route
              suppressMarkers: false, // Show default markers
            }}
          />
        )}
              </GoogleMap>


      {/* Steps Below the Map */}
      <div style={{ marginTop: "20px" }}>
        <Steps direction="horizontal" current={2}>
          <Step
            title="Start"
            icon={
              <img
                src={bmarkoLogo}
                alt="Start Icon"
                style={{ width: 32, height: 32 }}
              />
            }
            description={originAddress}
          />
          <Step
            title="Route"
            icon={<FontAwesomeIcon icon={faTruck} />}
            description={
              totalMiles && daysNeeded ? (
                <div style={{ marginTop: "10px" }}>
                  <Text>
                    <strong>Distance:</strong> {totalMiles} miles
                  </Text>
                  <br />
                  <Text style={{fontStyle: "italic"}}>
                    500 miles per day
                  </Text>
                  <br />
                  <Text>
                    <strong>Estimated Days:</strong> {daysNeeded}
                  </Text>
                </div>
              ) : (
                "Calculating..."
              )
            }
          />
          <Step
            title="End"
            icon={<FontAwesomeIcon icon={faBuilding} />}
            description={destinationAddress}
          />
        </Steps>
      </div>


      {/* Display map errors if any */}
      {mapError && (
        <Text type="danger" style={{ marginTop: "10px", display: "block" }}>
          {mapError}
        </Text>
      )}
    </div>
  );
}

DeliveryRoute.propTypes = {
  originPlaceId: PropTypes.string.isRequired,
  destinationPlaceId: PropTypes.string.isRequired,
};

export default DeliveryRoute;
