// src/Components/AddCustomerModal.jsx

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  message,
  Space,
  Switch,
  Tooltip,
  Row,
  Col,
  Card,
  Select
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid"; // For generating UUIDs
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import PhoneNumberInput from "../../Formatters/PhoneNumberInput";
import { formatPhoneNumberToE164 } from "../../utils/phoneUtils"; // Import the helper function
import { useCustomers } from "../../Contexts/useSpecificData";


const AddCustomerModal = ({
  children,
  customer,
  onAddSuccess,
  title,
  visible: propVisible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data: customers } = useCustomers();

  // State to manage the primary contact ID
  const [primaryContactId, setPrimaryContactId] = useState(null);

  // Determine if the component is controlled externally
  const isControlled = propVisible !== undefined;
  const modalVisible = isControlled ? propVisible : visible;

  useEffect(() => {
    if (modalVisible) {
      if (customer) {
        // Edit Mode
        // Extract contacts from customer data
        const existingContacts = customer.contacts || [];

        // Set primaryContactId if a primary contact exists
        const primaryContact = existingContacts.find((c) => c.primary);
        if (primaryContact) {
          setPrimaryContactId(primaryContact.id);
        }

        // Format phone numbers and set form fields with existing customer data
        const formattedContacts = existingContacts.map((contact) => ({
          ...contact,
          phone: formatPhoneNumberToE164(contact.phone, "US"), // Ensure 'phone' field is formatted correctly
        }));

        form.setFieldsValue({
          companyName: customer.companyName,
          prefix: customer.prefix || "", // Set prefix in edit mode
          contacts:
            formattedContacts.length > 0
              ? formattedContacts
              : [
                  {
                    id: uuidv4(),
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                  },
                ],
        });
      } else {
        // Add Mode
        form.resetFields();
        // Initialize with one contact
        const newContactId = uuidv4();
        form.setFieldsValue({
          companyName: "",
          prefix: "", // Initialize prefix in add mode
          contacts: [
            {
              id: newContactId,
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
            },
          ],
        });
        // Automatically set the first contact as primary
        setPrimaryContactId(newContactId);
      }
    } else {
      // Reset primaryContactId when modal is closed
      setPrimaryContactId(null);
    }
  }, [customer, form, modalVisible]);

  const handleOk = async () => {
    setLoading(true);
    try {
      // Validate form fields
      const values = await form.validateFields();

      const { companyName, prefix, contacts, netTerms } = values;

      if (!contacts || contacts.length === 0) {
        message.error("At least one contact is required.");
        setLoading(false);
        return;
      }

      // Ensure all contacts have an ID
      const processedContacts = contacts.map((contact) => ({
        ...contact,
        id: contact.id || uuidv4(),
      }));

      // Assign the primary flag
      const finalContacts = processedContacts.map((contact) => ({
        ...contact,
        primary: contact.id === primaryContactId,
      }));

      const payload = {
        ...customer,
        companyName,
        prefix,
        contacts: finalContacts,
      };

      // Handle netTerms explicitly
      if (netTerms !== undefined) {
        payload.netTerms = netTerms; // Set the value if it exists
      } else {
        payload.netTerms = null; // Explicitly set to null if cleared
      }

      if (customer && customer.id) {
        // Update existing customer
        await updateById(TABLES.CUSTOMERS, payload, customer.id);
        message.success("Customer updated successfully!");
        payload.id = customer.id; // Ensure the ID is set for onAddSuccess
      } else {
        // Add new customer
        const newCustomer = await addItemsToTable(TABLES.CUSTOMERS, payload);
        message.success("Customer added successfully!");
        payload.id = newCustomer.id;
      }

      onAddSuccess(payload);
      setLoading(false);
      if (!isControlled) {
        setVisible(false);
      }
    } catch (error) {
      console.error("Submission Failed:", error);
      // Display specific error messages based on validation
      if (error && error.errorFields) {
        // Ant Design validation errors
        // Errors are already displayed by Form.Item, no need to show additional messages
      } else if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "Please ensure all required fields are filled correctly."
        );
      }
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setPrimaryContactId(null);
    if (onCancel) {
      onCancel();
    }
    if (!isControlled) {
      setVisible(false);
    }
  };

  const showModal = () => {
    if (!isControlled) {
      setVisible(true);
    }
  };

  // Handle primary contact selection
  const handlePrimaryChange = (contactId) => {
    setPrimaryContactId(contactId);
  };

  /**
   * Custom validator to ensure prefix uniqueness
   * @param {Rule} rule
   * @param {string} value
   * @returns {Promise}
   */
  const validatePrefixUniqueness = async (_, value) => {
    if (!value) {
      // Required rule will handle this
      return Promise.resolve();
    }

    const trimmedValue = value.trim().toUpperCase();

    // Only proceed if the prefix is exactly three letters
    if (!/^[A-Z]{3}$/.test(trimmedValue)) {
      // Pattern rule will handle this
      return Promise.resolve();
    }

    // Check if the prefix exists for another customer
    const existingCustomer = customers.find(
      (c) => c.prefix === trimmedValue && (!customer || c.id !== customer.id)
    );

    if (existingCustomer) {
      return Promise.reject(
        new Error(`Prefix already exists for ${existingCustomer.companyName}`)
      );
    }

    return Promise.resolve();
  };

  return (
    <>
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, { onClick: showModal })
        )}
      <Modal
        title={title || (customer ? "Edit Customer" : "Add New Customer")}
        visible={modalVisible}
        onOk={handleOk}
        width={800}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {customer ? "Save Changes" : "Add Customer"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          {/* Company Name */}
          <Form.Item
            name="companyName"
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please enter the company name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Prefix */}
          <Form.Item
            name="prefix"
            label={<div style={{ display: "flex", alignItems:'center' }}><div>Project Prefix</div><div style={{opacity:0.8, fontStyle: "italic", marginLeft:4, fontSize:13}}>(ex: VTV, CAP)</div></div>}
            rules={[
              {
                required: true,
                message: "Please enter a prefix",
              },
              {
                pattern: /^[A-Z]{3}$/,
                message: "Prefix must be exactly three letters",
              },
              {
                validator: validatePrefixUniqueness,
              },
            ]}
            normalize={(value) => (value ? value.toUpperCase() : value)}
          >
            <Input maxLength={3} />
          </Form.Item>

          {/* Net Terms */}
          <Form.Item
            name="netTerms"
            label="Net Terms"
            initialValue={customer ? customer.netTerms : ""}
          >
            <Select allowClear onClear={() => form.setFieldsValue({ netTerms: null })}>
              <Select.Option value={0}>Due on Receipt</Select.Option>
              <Select.Option value={15}>15 Days</Select.Option>
              <Select.Option value={30}>30 Days</Select.Option>
              <Select.Option value={45}>45 Days</Select.Option>
              <Select.Option value={60}>60 Days</Select.Option>
              <Select.Option value={90}>90 Days</Select.Option>
              </Select >
              
              </Form.Item>

          {/* Contacts */}
          <Form.List name="contacts">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => {
                  const contact = form.getFieldValue(["contacts", index]);

                  return (
                    <Card
                      key={field.key}
                      title={`Contact ${index + 1}`}
                      style={{ marginBottom: 16 }}
                      extra={
                        customer && fields.length > 1 ? (
                          <Tooltip title="Remove Contact">
                            <MinusCircleOutlined
                              onClick={() => {
                                // If the contact being removed is primary, prevent removal
                                if (contact.id === primaryContactId) {
                                  message.warning(
                                    "Primary contact cannot be removed. Please set another contact as primary first."
                                  );
                                } else {
                                  remove(field.name);
                                }
                              }}
                              style={{ color: "red" }}
                            />
                          </Tooltip>
                        ) : null
                      }
                    >
                      <Row gutter={[16, 0]}>
                        {/* Primary Contact Toggle */}
                        {customer && fields.length > 1 && (
                          <Col span={24}>
                            <Form.Item label="">
                              <Switch
                                checked={contact.id === primaryContactId}
                                onChange={(checked) => {
                                  if (checked) {
                                    handlePrimaryChange(contact.id);
                                  } else {
                                    // Prevent unchecking the primary contact without selecting another
                                    message.warning(
                                      "At least one contact must be primary."
                                    );
                                  }
                                }}
                              />{" "}
                              {contact.id === primaryContactId
                                ? "Primary Contact"
                                : "Set as Primary Contact"}
                            </Form.Item>
                          </Col>
                        )}

                        {/* First Name */}
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, "firstName"]}
                            fieldKey={[field.fieldKey, "firstName"]}
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the first name",
                              },
                            ]}
                          >
                            <Input placeholder="First Name" />
                          </Form.Item>
                        </Col>

                        {/* Last Name */}
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, "lastName"]}
                            fieldKey={[field.fieldKey, "lastName"]}
                            label="Last Name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the last name",
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </Form.Item>
                        </Col>

                        {/* Email */}
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, "email"]}
                            fieldKey={[field.fieldKey, "email"]}
                            label="Email"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the email",
                              },
                              {
                                type: "email",
                                message: "Enter a valid email",
                              },
                            ]}
                          >
                            <Input placeholder="Email" />
                          </Form.Item>
                        </Col>

                        {/* Phone Number */}
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, "phone"]}
                            fieldKey={[field.fieldKey, "phone"]}
                            label="Phone Number"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the phone number",
                              },
                            ]}
                          >
                            <PhoneNumberInput
                              defaultCountry="us" // Set default country
                              disableDropdown={false} // Allow changing country
                              placeholder="Phone Number"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}

                {/* Add Contact Button (Only in Edit Mode) */}
                {customer && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          id: uuidv4(),
                          firstName: "",
                          lastName: "",
                          email: "",
                          phone: "",
                        })
                      }
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Contact
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
