import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Table,
  InputNumber,
  Select,
  Modal,
  Tag,
  Popover,
  message,
  DatePicker,
  Divider,
  Spin,
  Card,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  RocketOutlined,
  StopOutlined,
  CopyOutlined,
  BellOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import AddressAutocomplete from "../Google/AddressAutocomplete";
import {
  useItems,
  usePurchaseOrders,
  useVendors,
  useProjects,
  useReminders,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { COLORS, ADDRESSES, TABLES, EMAILBODY } from "../../constants";
import { formatMoney } from "../../Formatters/helpers";
import PhoneInput from "react-phone-input-2";
import AdditionalTable from "./AdditionalTable";
import { s3Upload } from "../../Functions/s3Upload";
import { generatePdfBlob } from "./PurchaseOrderPDF";
import { incrementPONumber } from "../../Formatters/incrementPoNumber";
import { useUser } from "@clerk/clerk-react";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { v4 as uuidv4 } from "uuid";
import { sendEmail } from "../../Functions/sendEmail";
import { getProjectName } from "../../Formatters/getProjectName";
import { formatPhoneNumber } from "../../Formatters/formatPhoneNumber";
import DuplicatePOModal from "./DuplicatePOModal";
import { getPoRevisionNumber } from "../../Formatters/getPoRevisionNumber";
import { getNextPoNumber } from "../../Formatters/getNextPoNumber";

const { Option } = Select;

// Helper function to deduplicate emails
const deduplicateEmails = (emails) => {
  const seen = new Set();
  const uniqueEmails = [];

  for (const email of emails) {
    const lowerCaseEmail = email.toLowerCase();
    if (!seen.has(lowerCaseEmail)) {
      seen.add(lowerCaseEmail);
      uniqueEmails.push(email);
    }
  }

  return uniqueEmails;
};

function AddPurchaseOrder() {
  const [form] = Form.useForm();
  const { data: vendors } = useVendors();
  const { data: itemsData } = useItems();
  const { data: projects } = useProjects();
  const { data: purchaseOrders, refresh: refreshPurchaseOrders } =
    usePurchaseOrders();
  const { data: reminders } = useReminders();
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [requiredDate, setRequiredDate] = useState(null);
  const [poLines, setPoLines] = useState([]);
  const [shipping, setShipping] = useState(0);
  const [other, setOther] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shipToAddress, setShipToAddress] = useState(ADDRESSES[0]);
  const [isOtherAddress, setIsOtherAddress] = useState(false);

  const [notes, setNotes] = useState("");
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [additionalTables, setAdditionalTables] = useState([]);
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [poNumber, setPoNumber] = useState(""); // Purchase Order number state
  const user = useUser();
  const userEmail = user.user?.primaryEmailAddress?.emailAddress;
  const userName = user.user?.fullName;
  const [loading, setLoading] = useState(false);
  const [existingOrder, setExistingOrder] = useState(null);
  const navigate = useNavigate();
  const [groupPOs, setGroupPOs] = useState([]);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  // Reminder Modal States
  const [reminderModalVisible, setReminderModalVisible] = useState(false);
  const [currentReminderPO, setCurrentReminderPO] = useState({
    type: "main", // 'main' or 'additional'
    index: null, // index of the additional table if type is 'additional'
  });
  const [reminderDate, setReminderDate] = useState(null);
  const [reminderNotes, setReminderNotes] = useState("");

  // State to manage reminders
  const [mainReminder, setMainReminder] = useState(null);
  const [additionalReminders, setAdditionalReminders] = useState({});

  // **New State to Store Reminders Before Submission (Non-Edit Mode)**
  const [pendingReminders, setPendingReminders] = useState({
    main: null,
    additional: {},
  });

  // Handle opening and closing of Duplicate Modal
  const handleOpenDuplicateModal = () => {
    setIsDuplicateModalVisible(true);
  };

  const handleCloseDuplicateModal = () => {
    setIsDuplicateModalVisible(false);
  };

  // Handle duplicating items from an existing PO
  const handleDuplicateItems = (lines, vendorId, po) => {
    const vendorObj = vendors.find((v) => v.id === vendorId);

    if (!vendorObj) {
      console.error(`Vendor with ID ${vendorId} not found.`);
      return;
    }

    const vendorEmail = vendorObj.vendorEmail || "";
    const additionalEmails = vendorObj.additionalEmails || [];

    const combinedEmails = vendorEmail
      ? [vendorEmail, ...additionalEmails]
      : additionalEmails;

    if (po.emailAddresses && po.emailAddresses.length > 0) {
      setEmails(deduplicateEmails(po.emailAddresses));
    } else {
      setEmails(deduplicateEmails(combinedEmails));
    }

    // Reset qtyReceived to 0 for each duplicated line
    const linesWithResetQtyReceived = lines.map((line) => ({
      ...line,
      qtyReceived: 0, // Ensures qtyReceived is reset to zero
    }));

    setPoLines(convertLinesToState(linesWithResetQtyReceived));
    setSelectedVendor(vendorId); // Since selectedVendor expects a vendor ID
  };

  // Clear the entire form
  const clearForm = () => {
    form.resetFields();
    setSelectedVendor(null);
    setSelectedProject(null);
    setRequiredDate(null);
    setPoLines([]);
    setShipping(0);
    setOther(0);
    setDiscount(0);
    setShipToAddress(ADDRESSES[0]);
    setIsOtherAddress(false);
    setNotes("");
    setEmails([]);
    setNewEmail("");
    setPopoverVisible(false);
    setAdditionalTables([]);
    setPoNumber("");
    setExistingOrder(null);
    setGroupPOs([]);
    setIsDuplicateModalVisible(false);
    setReminderDate(null);
    setReminderNotes("");
    setReminderModalVisible(false);
    setCurrentReminderPO({ type: "main", index: null });
    setMainReminder(null);
    setAdditionalReminders({});
    setPendingReminders({ main: null, additional: {} }); // **Reset Pending Reminders**
  };

  // Overlay style for loading spinner
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent background
    zIndex: 1000, // Ensures it's on top of other elements
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  // Convert lines to state-friendly format
  function convertLinesToState(lines) {
    return lines.map((line) => ({
      key: line.itemId,
      itemId: line.itemId,
      category: line.category,
      itemNumber: line.itemNumber,
      itemDescription: line.itemDescription,
      manufacturer: line.manufacturer,
      manufacturerId: line.manufacturerId,
      orderBatchSize: line.orderBatchSize,
      uom: line.uom,
      qty:
        typeof line.qtyOrdered === "string"
          ? parseInt(line.qtyOrdered, 10)
          : line.qtyOrdered,
      qtyReceived:
        typeof line.qtyReceived === "string"
          ? parseInt(line.qtyReceived, 10)
          : line.qtyReceived || 0,
      price:
        typeof line.itemPrice === "string"
          ? parseFloat(line.itemPrice)
          : line.itemPrice,
      total:
        (typeof line.qtyOrdered === "string"
          ? parseInt(line.qtyOrdered, 10)
          : line.qtyOrdered) *
        (typeof line.itemPrice === "string"
          ? parseFloat(line.itemPrice)
          : line.itemPrice),
      receivedDate: line.receivedDate || undefined,
      receivedBy: line.receivedBy || undefined,
    }));
  }

  // Load existing purchase order if in edit mode
  useEffect(() => {
    if (id) {
      const existingOrder = purchaseOrders.find((order) => order.id === id);

      document.title = `Edit Purchase Order ${existingOrder?.poNumber}`;

      if (existingOrder) {
        setExistingOrder(existingOrder);
        setSelectedVendor(existingOrder.vendor);
        setSelectedProject(existingOrder.projectNumber);
        setRequiredDate(dayjs(existingOrder.requiredDate));
        setPoLines(convertLinesToState(existingOrder.lines));
        setShipping(
          typeof existingOrder.shippingCosts === "string"
            ? parseFloat(existingOrder.shippingCosts) || 0
            : existingOrder.shippingCosts || 0
        );
        setOther(
          typeof existingOrder.other === "string"
            ? parseFloat(existingOrder.other) || 0
            : existingOrder.other || 0
        );
        setDiscount(
          typeof existingOrder.discount === "string"
            ? parseFloat(existingOrder.discount) || 0
            : existingOrder.discount || 0
        );
        setShipToAddress(existingOrder.shipToAddress);
        setIsOtherAddress(existingOrder.shipToAddress === "other");
        setNotes(existingOrder.poNotes);
        setIsEdit(true);
        setPoNumber(existingOrder.poNumber);
        setEmails(deduplicateEmails(existingOrder.emailAddresses || []));

        if (existingOrder.groupNumber) {
          const groupPOs = purchaseOrders.filter(
            (po) => po.groupNumber === existingOrder.groupNumber && po.id !== id
          );
          setGroupPOs(groupPOs);
        }

        const vendor = vendors.find(
          (vendor) => vendor.id === existingOrder.vendor
        );

        const vendorEmail = vendor?.vendorEmail;
        const additionalEmails = vendor?.additionalEmails || [];

        // Combine and deduplicate emails
        const combinedEmails = existingOrder.emailAddresses
          ? existingOrder.emailAddresses
          : vendorEmail
          ? [vendorEmail]
          : [];

        setEmails(deduplicateEmails(combinedEmails));

        // If additionalEmails exist, append them to the existing emails
        if (additionalEmails.length > 0) {
          setEmails((prevEmails) =>
            deduplicateEmails([...prevEmails, ...additionalEmails])
          );
        }

        // Set reminders
        const existingReminders = reminders?.filter(
          (reminder) =>
            reminder.typeId === existingOrder.id &&
            reminder.type === "purchaseOrder" &&
            !reminder.deleted
        );

        if (existingReminders && existingReminders.length > 0) {
          // Assuming only one reminder exists per PO
          const reminder = existingReminders[0];
          setMainReminder({
            ...reminder,
            date: dayjs(reminder.date, "MM/DD/YYYY"), // Ensure date is a dayjs object
          });
        }

        // Set reminders for additional tables if any
        const additionalPOs = purchaseOrders.filter(
          (po) => po.groupNumber === existingOrder.groupNumber && po.id !== id
        );

        const remindersMap = {};
        additionalPOs.forEach((po, idx) => {
          const poReminders = reminders?.filter(
            (reminder) =>
              reminder.typeId === po.id &&
              reminder.type === "purchaseOrder" &&
              !reminder.deleted
          );
          if (poReminders && poReminders.length > 0) {
            remindersMap[idx] = {
              ...poReminders[0],
              date: dayjs(poReminders[0].date, "MM/DD/YYYY"),
            };
          }
        });
        setAdditionalReminders(remindersMap);

        form.setFieldsValue({
          ...existingOrder,
          requiredDate: dayjs(existingOrder.requiredDate),
        });
      } else {
        // Handle case where purchase order is not found
        // message.error("Purchase order not found.");
        // navigate("/purchasing/purchase/edit-purchase-orders");
      }
    }
  }, [id, purchaseOrders, form, vendors, reminders, navigate]);

  // Handle Vendor Change
  const handleVendorChange = (value) => {
    const vendor = vendors.find((vendor) => vendor.id === value);
    const vendorEmail = vendor?.vendorEmail || "";
    const additionalEmails = vendor?.additionalEmails || [];

    const combinedEmails = vendorEmail
      ? [vendorEmail, ...additionalEmails]
      : additionalEmails;

    const uniqueCombinedEmails = deduplicateEmails(combinedEmails);

    if (poLines.length > 0 || additionalTables.length > 0) {
      Modal.confirm({
        title: "Change Vendor",
        content: "Changing the vendor will reset the order. Continue?",
        onOk: () => {
          form.resetFields();
          setSelectedVendor(value);
          setPoLines([]);
          setEmails(uniqueCombinedEmails);
          setAdditionalTables([]);
          setMainReminder(null);
          setAdditionalReminders({});
          setPendingReminders({ main: null, additional: {} }); // **Reset Pending Reminders**
        },
      });
    } else {
      setSelectedVendor(value);
      setEmails(uniqueCombinedEmails);
    }
  };

  // Handle Project Change
  const handleProjectChange = (value) => {
    setSelectedProject(value);
  };

  // Handle Item Selection
  const handleItemSelect = (
    itemId,
    setPoLinesFunc,
    isAdditionalTable = false,
    tableIndex = null
  ) => {
    const selectedItem = itemsData.find((item) => item.id === itemId);
    if (!selectedItem) {
      console.error("Selected item not found");
      return;
    }

    // Get vendorItemId first
    const vendorItemId = selectedItem.vendors.find(
      (vendor) => vendor.id === selectedVendor
    )?.itemId;

    // Check if the item already exists in the corresponding table by comparing vendorItemId
    const alreadyExists =
      isAdditionalTable && tableIndex !== null
        ? additionalTables[tableIndex].poLines.some(
            (line) => line.itemId === vendorItemId
          )
        : poLines.some((line) => line.itemId === vendorItemId);

    if (alreadyExists) {
      message.error("This item has already been added to the order.");
      return;
    }

    const vendorPrice =
      selectedItem.vendors.find((vendor) => vendor.id === selectedVendor)
        ?.price || 0;

    const newPoLine = {
      key:
        isAdditionalTable && tableIndex !== null
          ? `${tableIndex}-${itemId}`
          : `main-${itemId}`,
      itemId: vendorItemId,
      category: selectedItem.category,
      itemNumber: selectedItem.itemNumber,
      itemDescription: selectedItem.itemDescription,
      manufacturer: selectedItem.manufacturer,
      manufacturerId: selectedItem.manufacturerId,
      orderBatchSize: selectedItem.orderBatchSize,
      uom: selectedItem.uom,
      qty: 0,
      price: vendorPrice,
      total: 0,
    };

    if (isAdditionalTable && tableIndex !== null) {
      setAdditionalTables((prevTables) =>
        prevTables.map((table, idx) => {
          if (idx === tableIndex) {
            const newPoLines = [...table.poLines, newPoLine];
            return { ...table, poLines: newPoLines };
          }
          return table;
        })
      );
    } else {
      setPoLinesFunc((prev) => [...prev, newPoLine]);
    }
  };

  // Handle Deleting Purchase Order
  const handleDeletePurchaseOrder = async () => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to cancel this purchase order?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Find the purchase order and get the original date from the last document
          const existingOrder = purchaseOrders.find((order) => order.id === id);
          const lastDocument =
            existingOrder.documents && existingOrder.documents.length > 0
              ? existingOrder.documents[existingOrder.documents.length - 1]
              : null;
          const originalDate = lastDocument ? lastDocument.dateSubmitted : "";
          const documentUrl = lastDocument ? lastDocument.documentUrl : "";

          // Update the purchase order by setting `deleted` to true
          const updatedPurchaseOrder = {
            deleted: true,
          };

          await updateById(TABLES.PURCHASE_ORDERS, updatedPurchaseOrder, id);

          // Send the cancellation email
          const emailBody = EMAILBODY.CANCELLED_PURCHASE_ORDER({
            poNum: existingOrder.poNumber,
            vendor: getVendorName(existingOrder.vendor, vendors),
            vendorFirstName:
              getVendorDetails(existingOrder.vendor).firstName || "",
            capFirstName: userName.split(" ")[0],
            originalDate,
          });

          await sendEmail({
            subject: emailBody.subject,
            htmlBody: emailBody.body,
            emailAddresses: existingOrder.emailAddresses,
            attachmentUrls: [documentUrl],
            sender: userEmail,
          });

          // **Do NOT update reminders here** as per your instruction
          // Reminders are only created via the modal

          message.success("Purchase order deleted and email sent successfully");
          navigate("/purchasing/purchase/edit-purchase-orders");
          refreshPurchaseOrders();
        } catch (error) {
          console.error("Error deleting purchase order:", error);
          message.error("Failed to delete purchase order");
        }
      },
    });
  };

  // Handle Quantity Change
  const handleQtyChange = (key, value, setPoLinesFunc, tableIndex = null) => {
    if (tableIndex !== null) {
      setAdditionalTables((prevTables) =>
        prevTables.map((table, idx) => {
          if (idx === tableIndex) {
            return {
              ...table,
              poLines: table.poLines.map((line) =>
                line.key === key
                  ? { ...line, qty: value, total: value * line.price }
                  : line
              ),
            };
          }
          return table;
        })
      );
    } else {
      setPoLinesFunc((prev) =>
        prev.map((line) =>
          line.key === key
            ? { ...line, qty: value, total: value * line.price }
            : line
        )
      );
    }
  };

  // Handle Removing an Item
  const handleRemoveItem = (key, setPoLinesFunc, tableIndex = null) => {
    if (tableIndex !== null) {
      setAdditionalTables((prevTables) =>
        prevTables.map((table, idx) => {
          if (idx === tableIndex) {
            return {
              ...table,
              poLines: table.poLines.filter((line) => line.key !== key),
            };
          }
          return table;
        })
      );
    } else {
      setPoLinesFunc((prev) => prev.filter((line) => line.key !== key));
    }
  };

  // Handle Adding an Email
  const handleAddEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(newEmail)) {
      const updatedEmails = deduplicateEmails([...emails, newEmail]);
      if (updatedEmails.length === emails.length) {
        message.warning("This email is already added.");
      } else {
        setEmails(updatedEmails);
        setNewEmail("");
        setPopoverVisible(false);
      }
    } else {
      message.error("Please enter a valid email address.");
    }
  };

  // Handle Removing an Email
  const handleRemoveEmail = (email) => {
    setEmails((prev) => prev.filter((e) => e !== email));
  };

  // Handle Required Date Change
  const handleDateChange = (date) => {
    setRequiredDate(date && date.isValid() ? date : null);
  };

  // Handle Additional Required Date Change
  const handleAdditionalDateChange = (index, date) => {
    setAdditionalTables((prev) =>
      prev.map((table, i) => {
        if (i === index) {
          return { ...table, requiredDate: date };
        }
        return table;
      })
    );
  };

  // Handle Removing an Additional Table
  const handleRemoveAdditionalTable = (index) => {
    setAdditionalTables((prev) => {
      const updatedTables = prev.filter((_, i) => i !== index);
      const updatedReminders = { ...additionalReminders };
      delete updatedReminders[index];
      setAdditionalReminders(updatedReminders);
      return updatedTables;
    });
    setPendingReminders((prev) => {
      const updatedPending = { ...prev.additional };
      delete updatedPending[index];
      return { ...prev, additional: updatedPending };
    });
  };

  // Handle Adding an Additional Required Date (i.e., another PO)
  const handleAddAdditionalRequiredDate = () => {
    Modal.confirm({
      title: "Copy Items?",
      okText: "Yes",
      cancelText: "No",
      content: "Would you like to copy the items from the current table?",
      onOk: () => {
        setAdditionalTables((prev) => [
          ...prev,
          {
            requiredDate: null,
            poLines: [...poLines],
            shipping,
            other,
            discount,
            notes,
          },
        ]);
      },
      onCancel: () => {
        setAdditionalTables((prev) => [
          ...prev,
          {
            requiredDate: null,
            poLines: [],
            shipping: 0,
            other: 0,
            discount: 0,
            notes: "",
          },
        ]);
      },
    });
  };

  // Filter and sort vendors
  const filteredVendors = vendors.filter((vendor) =>
    itemsData.some((item) =>
      item.vendors.some((itemVendor) => itemVendor.id === vendor.id)
    )
  );

  const sortedVendors = filteredVendors.sort((a, b) =>
    getVendorName(a.id, vendors).localeCompare(getVendorName(b.id, vendors))
  );

  // Get vendor details
  const getVendorDetails = (vendorId) => {
    const vendor = vendors.find((v) => v.id === vendorId);
    if (!vendor) {
      return { contactName: "", phoneNumber: "", email: "" };
    }
    return {
      contactName: `${vendor.vendorFirstName || ""} ${
        vendor.vendorLastName || ""
      }`.trim(),
      firstName: vendor.vendorFirstName || "",
      lastName: vendor.vendorLastName || "",
      phoneNumber: vendor.vendorPhone || "",
      email: vendor.vendorEmail || "",
    };
  };

  // Table columns definition
  const columns = (setPoLinesFunc, tableIndex = null) => [
    {
      title: "Line",
      dataIndex: "key",
      key: "line",
      render: (text, record, index) => String(index + 1).padStart(2, "0"),
      width: 40,
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      render: (text) => <span>{text}</span>,
    },
    { title: "Manufacturer", dataIndex: "manufacturer", key: "manufacturer" },
    { title: "Mfg ID", dataIndex: "manufacturerId", key: "manufacturerId" },
    {
      title: "UOM",
      dataIndex: "orderBatchSize",
      key: "orderBatchSize",
      render: (text, record) => `${text} ${record.uom}`,
    },
    ...(isEdit
      ? [
          {
            title: "Received",
            dataIndex: "qtyReceived",
            key: "qtyReceived",
            render: (text) => <span>{text}</span>,
          },
        ]
      : []),
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (text, record) => (
        <InputNumber
          style={{ width: 80 }}
          min={isEdit ? record.qtyReceived : 0} // Enforce minimum when in edit mode
          value={record.qty}
          onChange={(value) => {
            handleQtyChange(record.key, value, setPoLinesFunc, tableIndex);
          }}
          type="number"
          className={record.qty === 0 || record.qty === null ? "zero-qty" : ""}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => formatMoney(text),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => formatMoney(text),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) =>
        record.qtyReceived > 0 ? (
          <StopOutlined />
        ) : (
          <Button
            onClick={() =>
              handleRemoveItem(record.key, setPoLinesFunc, tableIndex)
            }
            danger
            disabled={loading}
            type="link"
            icon={<DeleteOutlined />}
          />
        ),
    },
  ];

  // Calculate Subtotal and Total
  const subtotal = poLines.reduce((sum, line) => sum + line.total, 0);
  const totalPO = subtotal + shipping + other - discount;

  // Compute Bulk Purchase Total
  const totalBulkPurchase =
    totalPO +
    additionalTables.reduce((sum, table) => {
      const tableSubtotal = table.poLines.reduce(
        (lineSum, line) => lineSum + line.total, // Correct: using 'lineSum'
        0
      );
      const tableTotal =
        tableSubtotal + table.shipping + table.other - table.discount;
      return sum + tableTotal;
    }, 0);

  // Check if any items have quantity > 0
  const hasItemsWithQty = (poLines) => {
    return (
      Array.isArray(poLines) &&
      poLines.length > 0 &&
      poLines.some((line) => line.qty > 0)
    );
  };

  // Validate form
  const isFormValid = () => {
    const hasValidQty = (lines) => lines.every((line) => line.qty > 0);

    const isMainTableValid = poLines.length > 0 && hasValidQty(poLines);
    const isAdditionalTablesValid = isEdit
      ? true
      : additionalTables.every(
          (table) =>
            table.poLines.length > 0 &&
            hasValidQty(table.poLines) &&
            table.requiredDate
        );

    return (
      selectedVendor &&
      shipToAddress &&
      selectedProject &&
      requiredDate &&
      isMainTableValid &&
      isAdditionalTablesValid
    );
  };

  // Check if any items have been received
  const hasReceivedItems = () => {
    return poLines.some((line) => line.qtyReceived > 0);
  };

  // Email Content for Popover
  const emailContent = (
    <div>
      <Input
        value={newEmail}
        onChange={(e) => setNewEmail(e.target.value)}
        placeholder="Enter new email"
        style={{ width: 200, marginBottom: 8 }}
        type="email"
      />
      <Button disabled={loading} type="primary" onClick={handleAddEmail} block>
        Add
      </Button>
    </div>
  );

  // Handle Submit Purchase Order
  const handleSubmitPurchaseOrder = async () => {
    try {
      setLoading(true);

      // Refresh purchase orders to ensure the PO number is the next in line, but only if not editing
      // if (!isEdit) {
      //   await refreshPurchaseOrders();
      // }

      let generatedPoNumber = isEdit
        ? existingOrder.poNumber
        : await getNextPoNumber(); // Get the next available PO number

      // Resolve vendor and project details
      const vendorDetails = vendors.find((v) => v.id === selectedVendor);
      const projectDetails = projects.find((p) => p.id === selectedProject);

      const buyer = userEmail;

      // Determine the group number if there are multiple tables
      let groupNumber = null;
      if (additionalTables.length > 0) {
        const existingGroupNumbers = purchaseOrders
          .map((order) => order.groupNumber)
          .filter(Boolean); // Filter out null or undefined values

        if (existingGroupNumbers.length === 0) {
          groupNumber = "G-1";
        } else {
          // Extract the numeric part and find the highest existing group number
          const maxGroupNumber = Math.max(
            ...existingGroupNumbers.map((gn) =>
              parseInt(gn.replace("G-", ""), 10)
            )
          );
          groupNumber = `G-${maxGroupNumber + 1}`;
        }
      }

      // Initialize arrays to collect attachment URLs and PO info for emails
      let attachmentUrls = [];
      let purchaseOrdersInfo = [];

      // **Main Purchase Order Handling**
      // Generate and upload PDF for the main table
      const mainBlob = await generatePdfBlob(
        {
          poLines,
          requiredDate,
          notes,
          shipping,
          other,
          discount,
          subtotal,
          totalPO,
          additionalTables: [], // No additional tables for the main PDF
          poNumber: isEdit
            ? getPoRevisionNumber(
                existingOrder.poNumber,
                existingOrder.documents
              )
            : generatedPoNumber, // Only pass if isEdit is true
          selectedVendor: {
            name: vendorDetails ? getVendorName(vendorDetails.id, vendors) : "",
            contactName: vendorDetails
              ? `${vendorDetails.vendorFirstName} ${vendorDetails.vendorLastName}`
              : "",
            email: vendorDetails ? vendorDetails.vendorEmail : "",
            phone: vendorDetails
              ? formatPhoneNumber(vendorDetails.vendorPhone)
              : "",
          },
          shipToAddress,
          selectedProject: getProjectName(selectedProject, projects) || "",
          userEmail,
          userName,
        },
        isEdit,
        isEdit ? existingOrder.poNumber : undefined, // Only pass if isEdit is true
        isEdit ? existingOrder.orderedDate : undefined // Only pass if isEdit is true
      );

      const mainPdfFilename = isEdit
        ? `[REVISED] PO-${getPoRevisionNumber(
            existingOrder.poNumber,
            existingOrder.documents
          )}-Bmarko-${new Date().toISOString()}.pdf`
        : `PO-${generatedPoNumber}-Bmarko-${new Date().toISOString()}.pdf`;

      const mainUrl = await s3Upload(mainBlob, mainPdfFilename);

      // **Email Attachments and PO Info**
      attachmentUrls.push(mainUrl);
      purchaseOrdersInfo.push({
        poNum: generatedPoNumber,
        reqDateFormatted: requiredDate.format("MM/DD/YYYY"),
      });

      if (isEdit) {
        // **Edit Mode: Update Existing Purchase Order**
        const updatedPurchaseOrder = {
          ...existingOrder,
          documents: [
            ...(existingOrder.documents || []),
            {
              dateSubmitted: dayjs().format("MM/DD/YYYY h:mm:ss A"),
              documentUrl: mainUrl,
              number: (existingOrder.documents?.length || 0) + 1,
              uuid: uuidv4(),
            },
          ],
          lines: poLines.map((line, index) => ({
            category: line.category,
            itemDescription: line.itemDescription,
            itemId: line.itemId,
            itemNumber: line.itemNumber,
            itemPrice:
              typeof line.price === "string"
                ? parseFloat(line.price)
                : line.price,
            lineNumber: `${String(index + 1).padStart(2, "0")}`,
            lineStatus: "Open",
            manufacturer: line.manufacturer,
            manufacturerId: line.manufacturerId,
            orderBatchSize: line.orderBatchSize,
            qtyOrdered:
              typeof line.qty === "string" ? parseInt(line.qty, 10) : line.qty,
            qtyReceived:
              typeof line.qtyReceived === "string"
                ? parseInt(line.qtyReceived, 10)
                : line.qtyReceived || 0,
            uom: line.uom,
            vendor: selectedVendor,
          })),
          orderedDate: dayjs().format("MM/DD/YYYY"),
          other,
          poNotes: notes,
          projectNumber: selectedProject || "",
          requiredDate: requiredDate.format("MM/DD/YYYY"),
          shippingCosts: shipping,
          discount: discount,
          shipToAddress,
          emailAddresses: emails,
        };

        await updateById(TABLES.PURCHASE_ORDERS, updatedPurchaseOrder, id);

        // **In isEdit mode, DO NOT create reminders here**
        // Reminders should only be created via the modal
      } else {
        // **Add Mode: Create New Purchase Order**
        const mainPurchaseOrder = {
          id: uuidv4(),
          buyer,
          discount: discount,
          created: dayjs().format("MM/DD/YYYY h:mm:ss A"),
          documents: [
            {
              dateSubmitted: dayjs().format("MM/DD/YYYY h:mm:ss A"),
              documentUrl: mainUrl,
              number: 1,
              uuid: uuidv4(),
            },
          ],
          factory: "Doraville",
          lines: poLines.map((line, index) => ({
            category: line.category,
            itemDescription: line.itemDescription,
            itemId: line.itemId,
            itemNumber: line.itemNumber,
            itemPrice: line.price,
            lineNumber: `${String(index + 1).padStart(2, "0")}`,
            lineStatus: "Open",
            manufacturer: line.manufacturer,
            manufacturerId: line.manufacturerId,
            orderBatchSize: line.orderBatchSize,
            qtyOrdered: line.qty,
            qtyReceived: 0,
            uom: line.uom,
            vendor: selectedVendor,
          })),
          orderedDate: dayjs().format("MM/DD/YYYY"),
          other,
          poNotes: notes,
          poNumber: generatedPoNumber,
          poStatus: "Open",
          projectNumber: selectedProject || "",
          requiredDate: requiredDate.format("MM/DD/YYYY"),
          shippingCosts: shipping,
          shipToAddress,
          tenantId: "bmarko",
          vendor: selectedVendor,
          emailAddresses: emails,
          groupNumber: groupNumber || null,
        };

        await addItemsToTable(TABLES.PURCHASE_ORDERS, mainPurchaseOrder);

        // Handle Reminders: Create from Pending Reminders
        if (pendingReminders.main) {
          const newReminder = {
            ...pendingReminders.main,
            typeId: mainPurchaseOrder.id, // Correct typeId after PO creation
            body: pendingReminders.main.body.map((entry) => {
              if (entry.key === "PO Number") {
                return { ...entry, value: generatedPoNumber };
              }
              return entry;
            }),
          };

          await addItemsToTable(TABLES.REMINDERS, newReminder);
        }

        // **Handle Additional Tables as Separate Purchase Orders**
        for (let i = 0; i < additionalTables.length; i++) {
          // Increment PO number for each additional table
          const additionalPoNumber = incrementPONumber(generatedPoNumber, 1);
          generatedPoNumber = additionalPoNumber; // Update for next iteration if needed

          // Generate and upload PDF for the additional table
          const tableBlob = await generatePdfBlob({
            poLines: additionalTables[i].poLines,
            requiredDate: additionalTables[i].requiredDate,
            notes: additionalTables[i].notes,
            shipping: additionalTables[i].shipping,
            other: additionalTables[i].other,
            discount: additionalTables[i].discount,
            subtotal: additionalTables[i].poLines.reduce(
              (sum, line) => sum + line.total,
              0
            ),
            totalPO:
              additionalTables[i].poLines.reduce(
                (sum, line) => sum + line.total,
                0
              ) +
              additionalTables[i].shipping +
              additionalTables[i].other -
              additionalTables[i].discount,
            additionalTables: [],
            poNumber: additionalPoNumber,
            selectedVendor: {
              name: vendorDetails
                ? getVendorName(vendorDetails.id, vendors)
                : "",
              contactName: vendorDetails
                ? `${vendorDetails.vendorFirstName} ${vendorDetails.vendorLastName}`
                : "",
              email: vendorDetails ? vendorDetails.vendorEmail : "",
              phone: vendorDetails
                ? formatPhoneNumber(vendorDetails.vendorPhone)
                : "",
            },
            shipToAddress,
            selectedProject: getProjectName(selectedProject, projects) || "",
            userEmail,
            userName,
          });

          const tablePdfFilename = `PO-${additionalPoNumber}-Bmarko-${new Date().toISOString()}.pdf`;
          const tableUrl = await s3Upload(tableBlob, tablePdfFilename);

          attachmentUrls.push(tableUrl);
          purchaseOrdersInfo.push({
            poNum: additionalPoNumber,
            reqDateFormatted:
              additionalTables[i].requiredDate.format("MM/DD/YYYY"),
          });

          // **Create Additional Purchase Order Entry**
          const additionalPurchaseOrder = {
            id: uuidv4(),
            buyer,
            discount: additionalTables[i].discount,
            created: dayjs().format("MM/DD/YYYY h:mm:ss A"),
            documents: [
              {
                dateSubmitted: dayjs().format("MM/DD/YYYY h:mm:ss A"),
                documentUrl: tableUrl,
                number: 1,
                uuid: uuidv4(),
              },
            ],
            factory: "Doraville",
            lines: additionalTables[i].poLines.map((line, index) => ({
              category: line.category,
              itemDescription: line.itemDescription,
              itemId: line.itemId,
              itemNumber: line.itemNumber,
              itemPrice: line.price,
              lineNumber: `${String(index + 1).padStart(2, "0")}`,
              lineStatus: "Open",
              manufacturer: line.manufacturer,
              manufacturerId: line.manufacturerId,
              orderBatchSize: line.orderBatchSize,
              qtyOrdered: line.qty,
              qtyReceived: 0,
              uom: line.uom,
              vendor: selectedVendor,
            })),
            orderedDate: dayjs().format("MM/DD/YYYY"),
            other: additionalTables[i].other,
            poNotes: additionalTables[i].notes,
            poNumber: additionalPoNumber,
            poStatus: "Open",
            projectNumber: selectedProject || "",
            requiredDate: additionalTables[i].requiredDate.format("MM/DD/YYYY"),
            shippingCosts: additionalTables[i].shipping,
            shipToAddress,
            tenantId: "bmarko",
            vendor: selectedVendor,
            emailAddresses: emails,
            groupNumber: groupNumber || null,
          };

          await addItemsToTable(
            TABLES.PURCHASE_ORDERS,
            additionalPurchaseOrder
          );

          // **Handle Reminders for Additional POs: Create from Pending Reminders**
          if (pendingReminders.additional[i]) {
            const tableId = additionalPurchaseOrder.id; // Link to the newly created PO

            const updatedBody = pendingReminders.additional[i].body.map(
              (entry) => {
                if (entry.key === "PO Number") {
                  return { ...entry, value: additionalPoNumber };
                }
                return entry;
              }
            );

            const newReminder = {
              ...pendingReminders.additional[i],
              typeId: tableId, // Link to the correct PO
              body: updatedBody,
            };

            await addItemsToTable(TABLES.REMINDERS, newReminder);
          }
        }
      }

      // Update items with last purchased info
      const updateItemPromises = [];

      // Update items from main poLines
      for (const line of poLines) {
        // Find the item where item.itemNumber matches line.itemNumber
        const item = itemsData.find(
          (item) => item.itemNumber === line.itemNumber
        );
        if (item) {
          const updatedItem = {
            lastPurchasedPrice: line.price,
            lastPurchasedDate: new Date().toISOString(),
            lastPurchasedVendor: selectedVendor,
          };

          updateItemPromises.push(
            updateById(TABLES.ITEMS, updatedItem, item.id)
          );
        }
      }

      // Update items from additionalTables
      for (const table of additionalTables) {
        for (const line of table.poLines) {
          // Find the item where item.itemNumber matches line.itemNumber
          const item = itemsData.find(
            (item) => item.itemNumber === line.itemNumber
          );
          if (item) {
            const updatedItem = {
              lastPurchasedPrice: line.price,
              lastPurchasedDate: new Date().toISOString(),
              lastPurchasedVendor: selectedVendor,
            };
            updateItemPromises.push(
              updateById(TABLES.ITEMS, updatedItem, item.id)
            );
          }
        }
      }

      // Execute all item updates concurrently
      await Promise.all(updateItemPromises);

      // Prepare and send email
      let emailBody;

      let originalDate = "";
      if (
        isEdit &&
        existingOrder.documents &&
        existingOrder.documents.length > 0
      ) {
        const lastDocument =
          existingOrder.documents[existingOrder.documents.length - 1];
        originalDate = dayjs(lastDocument.dateSubmitted).format("MM/DD/YYYY");
      }

      if (!isEdit) {
        emailBody =
          purchaseOrdersInfo.length > 1
            ? EMAILBODY.MULTIPLE_PURCHASE_ORDERS({
                purchaseOrders: purchaseOrdersInfo,
                vendorFirstName: vendorDetails?.vendorFirstName || "",
                capFirstName: userName.split(" ")[0],
                vendor: vendorDetails?.companyName || "",
              })
            : EMAILBODY.PURCHASE_ORDER({
                poNum: purchaseOrdersInfo[0].poNum,
                vendor: vendorDetails?.companyName || "",
                vendorFirstName: vendorDetails?.vendorFirstName || "",
                capFirstName: userName.split(" ")[0],
                reqDateFormatted: purchaseOrdersInfo[0].reqDateFormatted,
              });
      } else {
        emailBody = EMAILBODY.REVISED_PURCHASE_ORDER({
          vendor: vendorDetails?.companyName || "",
          vendorFirstName: vendorDetails?.vendorFirstName || "",
          capFirstName: userName.split(" ")[0],
          reqDateFormatted: purchaseOrdersInfo[0].reqDateFormatted,
          poNum: existingOrder.poNumber,
          originalDate: originalDate,
        });
      }

      await sendEmail({
        subject: emailBody.subject,
        htmlBody: emailBody.body,
        emailAddresses: emails,
        attachmentUrls,
        sender: userEmail,
      });

      if (!isEdit) {
        clearForm();
      }

      setLoading(false);
      message.success("Purchase order saved and email sent successfully!");
    } catch (error) {
      setLoading(false);
      console.error("Error generating or uploading PDFs:", error);
      message.error("Failed to save purchase order or send email.");
    }
  };

  // Handle Saving Reminder
  const handleSaveReminder = async () => {
    if (!reminderDate) {
      message.error("Please select a reminder date.");
      return;
    }

    try {
      setLoading(true);

      if (isEdit) {
        if (currentReminderPO.type === "main") {
          // Handle main PO reminder in edit mode
          const newReminder = {
            date: reminderDate.format("MM/DD/YYYY"),
            notes: reminderNotes,
            active: true,
            module: "purchasing",
            type: "purchaseOrder",
            typeId: existingOrder.id,
            createdBy: userName,
            body: [
              {
                key: "Vendor",
                value: getVendorName(selectedVendor, vendors),
              },
              { key: "PO Number", value: poNumber },
              { key: "Created On", value: dayjs().format("MM/DD/YYYY") },
              { key: "Created By", value: userName || "N/A" },
            ],
          };

          await addItemsToTable(TABLES.REMINDERS, newReminder);

          // Update local state
          setMainReminder({
            ...newReminder,
            date: dayjs(newReminder.date, "MM/DD/YYYY"),
          });
        } else if (
          currentReminderPO.type === "additional" &&
          currentReminderPO.index !== null
        ) {
          // Handle additional table reminder in edit mode
          const tableIndex = currentReminderPO.index;
          const tableId = additionalTables[tableIndex].id;

          const newReminder = {
            date: reminderDate.format("MM/DD/YYYY"),
            notes: reminderNotes,
            active: true,
            module: "purchasing",
            type: "purchaseOrder",
            typeId: tableId,
            createdBy: userName,
            body: [
              {
                key: "Vendor",
                value: getVendorName(selectedVendor, vendors),
              },
              { key: "PO Number", value: poNumber },
              { key: "Created On", value: dayjs().format("MM/DD/YYYY") },
              { key: "Created By", value: userName || "N/A" },
            ],
          };

          await addItemsToTable(TABLES.REMINDERS, newReminder);

          // Update local state
          setAdditionalReminders((prev) => ({
            ...prev,
            [tableIndex]: {
              ...newReminder,
              date: dayjs(newReminder.date, "MM/DD/YYYY"),
            },
          }));
        }
      } else {
        // **Add Mode: Store Reminders in State to Save Upon Submission**

        if (currentReminderPO.type === "main") {
          setPendingReminders((prev) => ({
            ...prev,
            main: {
              date: reminderDate.format("MM/DD/YYYY"),
              notes: reminderNotes,
              active: true,
              module: "purchasing",
              type: "purchaseOrder",
              typeId: null, // Will be set upon submission
              createdBy: userName,
              body: [
                {
                  key: "Vendor",
                  value: getVendorName(selectedVendor, vendors),
                },
                { key: "PO Number", value: poNumber },
                { key: "Created On", value: dayjs().format("MM/DD/YYYY") },
                { key: "Created By", value: userName || "N/A" },
              ],
            },
          }));
        } else if (
          currentReminderPO.type === "additional" &&
          currentReminderPO.index !== null
        ) {
          const tableIndex = currentReminderPO.index;
          setPendingReminders((prev) => ({
            ...prev,
            additional: {
              ...prev.additional,
              [tableIndex]: {
                date: reminderDate.format("MM/DD/YYYY"),
                notes: reminderNotes,
                active: true,
                module: "purchasing",
                type: "purchaseOrder",
                typeId: null, // Will be set upon submission
                createdBy: userName,
                body: [
                  {
                    key: "Vendor",
                    value: getVendorName(selectedVendor, vendors),
                  },
                  { key: "PO Number", value: poNumber },
                  { key: "Created On", value: dayjs().format("MM/DD/YYYY") },
                  { key: "Created By", value: userName || "N/A" },
                ],
              },
            },
          }));
        }

        message.success("Reminder added successfully!");
      }

      // Close the modal
      setReminderModalVisible(false);
      // Reset reminder state
      setReminderDate(null);
      setReminderNotes("");
      setCurrentReminderPO({ type: "main", index: null });
    } catch (error) {
      console.error("Error saving reminder:", error);
      message.error("Failed to save reminder.");
    } finally {
      setLoading(false);
    }
  };

  // Render Purchase Order Number Card
  const renderPONumber = (poNumber, groupPOs) => (
    <>
      <Card
        title={
          <>
            <span style={{ fontWeight: 400 }}>Purchase Order</span>{" "}
            <span>{poNumber}</span>
          </>
        }
        extra={<>{getVendorName(existingOrder.vendor, vendors)}</>}
        size="small"
        className="box-shadow"
        styles={{ body: { padding: existingOrder.groupNumber ? 6 : 0 } }}
      >
        {existingOrder.groupNumber && (
          <Tag color="blue">Group {existingOrder.groupNumber}</Tag>
        )}
        {groupPOs &&
          groupPOs.map((po) => (
            <a
              key={po.id}
              href={`/purchasing/purchase/edit-purchase-orders/${po.id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: "8px" }}
            >
              {po.poNumber}
            </a>
          ))}
      </Card>
    </>
  );

  // Custom Filter Option for Item Select
  const customFilterOption = (input, option) => {
    if (!option) return false;

    // Retrieve custom data attributes
    const itemNumber = option.props["data-itemnumber"] || "";
    const itemPrice = option.props["data-itemprice"] || "";
    const itemDescription = option.props["data-itemdescription"] || "";
    const mfgId = option.props["data-mfgid"] || "";

    // Normalize the input by removing $, ,, and - (for number and price matching)
    const normalizedInput = input
      .replace(/[$,\-]/g, "")
      .trim()
      .toLowerCase();

    // Trim and lowercase input (for description and mfgId matching)
    const trimmedInput = input.trim().toLowerCase();

    // Normalize the fields for price and item number
    const normalizedPrice = parseFloat(itemPrice)
      .toFixed(2)
      .toString()
      .replace(/[$,]/g, ""); // Convert to fixed decimal and remove $, ,

    const normalizedItemNumber = itemNumber
      .toString()
      .toLowerCase()
      .replace(/[-]/g, ""); // Remove dashes and convert to lowercase

    // Trim and lowercase the item description and mfgId
    const trimmedItemDescription = itemDescription.trim().toLowerCase();
    const trimmedMfgId = mfgId.trim().toLowerCase();

    // Check if the normalized input is included in normalized price or item number
    const priceMatch = normalizedPrice.includes(normalizedInput);
    const itemNumberMatch = normalizedItemNumber.includes(normalizedInput);

    // Check if the trimmed input is included in item description or mfgId
    const descriptionMatch = trimmedItemDescription.includes(trimmedInput);
    const mfgIdMatch = trimmedMfgId.includes(trimmedInput);

    return priceMatch || itemNumberMatch || descriptionMatch || mfgIdMatch;
  };

  return (
    <Form form={form}>
      {loading && (
        <div style={overlayStyle}>
          <Spin size="large" />
        </div>
      )}

      <Row
        gutter={[16, 8]}
        style={{
          marginBottom: 16,
          display: "flex",
          alignItems: "flex-start",
        }}
        align="middle"
      >
        {isEdit && existingOrder && (
          <Col span={9}>
            <div>{renderPONumber(existingOrder.poNumber, groupPOs)}</div>
          </Col>
        )}

        {!isEdit && (
          <Col span={9}>
            <Form.Item label="Vendor" style={{ margin: 0 }}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Vendor"
                optionFilterProp="children"
                onChange={handleVendorChange}
                value={selectedVendor}
              >
                {sortedVendors.map((vendor) => (
                  <Option key={vendor.id} value={vendor.id}>
                    {getVendorName(vendor.id, vendors)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {/* Reminder Controls */}
        <Col
          span={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form.Item style={{ marginBottom: 0 }}>
            {/* Conditionally render Add Reminder button or Reminder Tag */}
            {isEdit ? (
              // In Edit Mode: Show existing reminder as Tag or Add Reminder button
              currentReminderPO.type === "main" ? (
                mainReminder ? (
                  <Tag
                    icon={<BellOutlined />}
                    color={mainReminder.active ? "blue" : "red"}
                  >
                    {mainReminder.active
                      ? `Reminder: ${mainReminder.date.format("MM/DD/YYYY")}`
                      : "Reminder: Inactive"}
                  </Tag>
                ) : (
                  <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setCurrentReminderPO({ type: "main", index: null });
                      setReminderModalVisible(true);
                    }}
                    disabled={loading}
                  >
                    Add Reminder
                  </Button>
                )
              ) : // For additional tables, handled within each table
              null
            ) : pendingReminders.main ? (
              // In Add Mode: Show Tag if a main pending reminder exists
              <Tag
                icon={<BellOutlined />}
                color={pendingReminders.main.active ? "blue" : "red"}
              >
                {pendingReminders.main.active
                  ? `Reminder: ${dayjs(
                      pendingReminders.main.date,
                      "MM/DD/YYYY"
                    ).format("MM/DD/YYYY")}`
                  : "Reminder: Inactive"}
              </Tag>
            ) : (
              // In Add Mode: Show Add Reminder button if no pending reminder exists
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => {
                  setCurrentReminderPO({ type: "main", index: null });
                  setReminderModalVisible(true);
                }}
                disabled={loading}
              >
                Add Reminder
              </Button>
            )}
          </Form.Item>
        </Col>

        <Col
          span={9}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            {isEdit && (
              <Button
                danger
                disabled={loading || hasReceivedItems()}
                onClick={handleDeletePurchaseOrder}
                icon={<DeleteOutlined />}
              >
                Cancel Purchase Order
              </Button>
            )}

            {!isEdit && (
              <>
                <Button
                  type="default"
                  icon={<CopyOutlined />}
                  onClick={handleOpenDuplicateModal}
                >
                  Duplicate Purchase Order
                </Button>

                <DuplicatePOModal
                  isVisible={isDuplicateModalVisible}
                  onClose={handleCloseDuplicateModal}
                  purchaseOrders={purchaseOrders}
                  onDuplicate={handleDuplicateItems}
                  vendors={vendors}
                />
              </>
            )}

            <Button
              type="primary"
              icon={<RocketOutlined />}
              style={{ marginLeft: 16 }}
              disabled={!isFormValid() || loading} // Disable the button if form is not valid
              onClick={handleSubmitPurchaseOrder}
              loading={loading}
            >
              Submit Purchase Order
            </Button>
          </div>
        </Col>
      </Row>

      {selectedVendor && (
        <Row gutter={[16, 8]} style={{ marginBottom: 8 }}>
          <Col span={12}>
            <Form.Item label="Email" style={{ margin: 0 }}>
              <div>
                {emails.length > 0 &&
                  emails.map((email) => (
                    <Tag
                      closable
                      onClose={() => handleRemoveEmail(email)}
                      key={email}
                      style={{ marginTop: 4 }}
                      color={COLORS.PRIMARY}
                    >
                      {email}
                    </Tag>
                  ))}
                <Popover
                  content={emailContent}
                  title="Add Email"
                  trigger="click"
                  open={popoverVisible}
                  onOpenChange={setPopoverVisible}
                >
                  <Button
                    type="dashed"
                    disabled={loading}
                    icon={<PlusOutlined />}
                    style={{ marginLeft: 8 }}
                  />
                </Popover>
              </div>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Contact Name" style={{ margin: 0 }}>
              <Input
                value={getVendorDetails(selectedVendor).contactName || ""}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Phone Number" style={{ margin: 0 }}>
              <PhoneInput
                country={"us"}
                value={getVendorDetails(selectedVendor).phoneNumber || ""}
                containerClass="displayOnlyPhone"
                inputClass="displayOnlyPhone"
                dropdownClass="displayOnlyPhone"
                disableDropdown={true}
                disabled
                containerStyle={{ width: "100%" }}
                inputStyle={{
                  width: "100%",
                  border: "none",
                  background: "none",
                  cursor: "default",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={[16, 8]} style={{ marginBottom: 8 }}>
        <Col span={12}>
          <Form.Item label="Ship To Address" style={{ margin: 0 }}>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Ship To Address"
              value={isOtherAddress ? "other" : shipToAddress}
              onChange={(value) => {
                if (value === "other") {
                  setIsOtherAddress(true);
                  setShipToAddress("");
                } else {
                  setIsOtherAddress(false);
                  setShipToAddress(value);
                }
              }}
            >
              {ADDRESSES.map((address, index) => (
                <Option key={index} value={address}>
                  {address}
                </Option>
              ))}
              <Option key="other" value="other">
                Other
              </Option>
            </Select>
          </Form.Item>
          {isOtherAddress && (
            <Form.Item label="Other Ship To Address" style={{ marginTop: 8 }}>
              <AddressAutocomplete
                form={form}
                value={shipToAddress}
                initialValue={shipToAddress}
                onChange={setShipToAddress}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={6}>
          <Form.Item label="Project" style={{ margin: 0 }}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Project"
              optionFilterProp="children"
              onChange={handleProjectChange}
              value={selectedProject}
            >
              {projects
                .slice() // Create a copy of the array to avoid mutating the original
                .sort((a, b) =>
                  getProjectName(a.id, projects).localeCompare(
                    getProjectName(b.id, projects)
                  )
                ) // Alphabetize using getProjectName
                .map((project) => (
                  <Option key={project.id} value={project.id}>
                    {getProjectName(project.id, projects)}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Required Date" style={{ margin: 0 }}>
            <DatePicker
              inputReadOnly
              style={{ width: "100%" }}
              value={requiredDate ? dayjs(requiredDate) : null}
              onChange={handleDateChange}
            />
          </Form.Item>
        </Col>
      </Row>

      {selectedVendor && (
        <>
          <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
            <Col span={24}>
              <Form.Item label="Add Item" style={{ margin: 0 }}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Item to Add"
                  optionFilterProp="children"
                  onChange={(value) => handleItemSelect(value, setPoLines)}
                  value={null}
                  filterOption={customFilterOption} // Add the custom filter function here
                >
                  {itemsData
                    .filter((item) =>
                      item.vendors.some(
                        (vendor) => vendor.id === selectedVendor
                      )
                    )
                    .filter(
                      (item) =>
                        !poLines.some((line) =>
                          item.vendors.some(
                            (vendor) =>
                              vendor.id === selectedVendor &&
                              line.itemId === vendor.itemId
                          )
                        )
                    )
                    .map((item) => {
                      const vendor = item.vendors.find(
                        (vendor) => vendor.id === selectedVendor
                      );
                      const vendorItemId = vendor?.itemId;
                      const itemPrice = vendor?.price;
                      const itemNumber = item?.itemNumber || "";
                      const mfgId = item?.manufacturerId || "";

                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          data-itemnumber={itemNumber}
                          data-itemprice={itemPrice}
                          data-itemdescription={item.itemDescription}
                          data-mfgid={mfgId}
                        >
                          <Tag>{item.itemNumber}</Tag>
                          <Tag color="green">{formatMoney(itemPrice)}</Tag>
                          <Tag color="blue">{item.manufacturerId}</Tag>
                          <span style={{ fontSize: 12 }}>
                            {item.itemDescription}
                          </span>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {poLines.length > 0 && (
            <>
              <Table
                dataSource={poLines}
                columns={columns(setPoLines)}
                pagination={false}
                size="small"
                rowKey="key"
                className="small-table"
              />

              <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                <Col span={4} />
                <Col span={4}>
                  <Form.Item label="Shipping">
                    <InputNumber
                      min={0}
                      value={shipping}
                      onChange={(value) => setShipping(value)}
                      type="number"
                      prefix="$"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Other">
                    <InputNumber
                      min={0}
                      value={other}
                      onChange={(value) => setOther(value)}
                      type="number"
                      prefix="$"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Discount">
                    <InputNumber
                      min={0}
                      value={discount}
                      onChange={(value) => setDiscount(value)}
                      type="number"
                      className="red-input"
                      prefix="- $"
                      style={{ width: "100%", color: "#f10000" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Subtotal" style={{ display: "none" }}>
                    {/* Original InputNumber is hidden */}
                    <InputNumber
                      disabled
                      value={subtotal.toFixed(2)}
                      type="number"
                      prefix="$"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  {/* Add the Subtotal as a Tag outside the hidden Form.Item */}
                  <Form.Item label="Subtotal" style={{ marginBottom: 0 }}>
                    <Tag
                      color="green"
                      style={{
                        fontSize: "16px",
                        width: "100%",
                        padding: 6,
                        textAlign: "center",
                        opacity: 0.9,
                      }}
                    >
                      {formatMoney(subtotal)}
                    </Tag>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Total" style={{ fontWeight: 600 }}>
                    <Tag
                      color="green"
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        padding: 6,
                        width: "100%",
                      }}
                    >
                      <strong>{formatMoney(totalPO)}</strong>
                    </Tag>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                <Col span={24}>
                  <Form.Item label="Notes">
                    <Input.TextArea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {hasItemsWithQty(poLines) && !isEdit && (
                <Button
                  type="primary"
                  style={{ width: "100%", marginBottom: 16 }}
                  onClick={handleAddAdditionalRequiredDate}
                  disabled={loading}
                >
                  Add Additional Required Date
                </Button>
              )}
            </>
          )}
        </>
      )}

      {/* Render Additional Tables (each represents a separate PO) */}
      {additionalTables.map((table, index) => (
        <div key={index}>
          <Divider />
          <Row
            gutter={[16, 16]}
            style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
          >
            <Col span={9} style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: 18, fontWeight: 600 }}>
                Additional Required Date {index + 1}
              </div>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveAdditionalTable(index)}
                color="red"
                style={{ marginLeft: 16 }}
                danger
              >
                Remove
              </Button>
            </Col>
            <Col span={6} align="middle">
              <Form.Item style={{ marginBottom: 0 }}>
                {/* Conditionally render Add Reminder button or Reminder Tag for additional tables */}
                {isEdit ? (
                  additionalReminders[index] ? (
                    <Tag
                      icon={<BellOutlined />}
                      color={additionalReminders[index].active ? "blue" : "red"}
                    >
                      {additionalReminders[index].active
                        ? `Reminder: ${additionalReminders[index].date.format(
                            "MM/DD/YYYY"
                          )}`
                        : "Reminder: Inactive"}
                    </Tag>
                  ) : (
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setCurrentReminderPO({ type: "additional", index });
                        setReminderModalVisible(true);
                      }}
                      disabled={loading}
                    >
                      Add Reminder
                    </Button>
                  )
                ) : pendingReminders.additional[index] ? (
                  <Tag
                    color={
                      pendingReminders.additional[index].active ? "blue" : "red"
                    }
                  >
                    {pendingReminders.additional[index].active
                      ? `Reminder: ${dayjs(
                          pendingReminders.additional[index].date,
                          "MM/DD/YYYY"
                        ).format("MM/DD/YYYY")}`
                      : "Reminder: Inactive"}
                  </Tag>
                ) : (
                  <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setCurrentReminderPO({ type: "additional", index });
                      setReminderModalVisible(true);
                    }}
                    disabled={loading}
                  >
                    Add Reminder
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col span={9} align="right">
              <Form.Item
                label="Required Date"
                style={{ margin: 0, maxWidth: 300 }}
              >
                <DatePicker
                  inputReadOnly
                  style={{ width: "100%" }}
                  value={table.requiredDate ? dayjs(table.requiredDate) : null}
                  onChange={(date) => handleAdditionalDateChange(index, date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
            <Col span={24}>
              <Form.Item label="Add Item" style={{ margin: 0 }}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Item to Add"
                  optionFilterProp="children"
                  onChange={(value) =>
                    handleItemSelect(value, () => {}, true, index)
                  }
                  value={null}
                  filterOption={customFilterOption} // Add the custom filter function here
                >
                  {itemsData
                    .filter((item) =>
                      item.vendors.some(
                        (vendor) => vendor.id === selectedVendor
                      )
                    )
                    .filter(
                      (item) =>
                        !table.poLines.some((line) =>
                          item.vendors.some(
                            (vendor) =>
                              vendor.id === selectedVendor &&
                              line.itemId === vendor.itemId
                          )
                        )
                    )
                    .map((item) => {
                      const vendor = item.vendors.find(
                        (vendor) => vendor.id === selectedVendor
                      );
                      const vendorItemId = vendor?.itemId;
                      const itemPrice = vendor?.price;
                      const itemNumber = item?.itemNumber || "";
                      const mfgId = item?.manufacturerId || "";

                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          data-itemnumber={itemNumber}
                          data-itemprice={itemPrice}
                          data-itemdescription={item.itemDescription}
                          data-mfgid={mfgId}
                        >
                          <Tag>{item.itemNumber}</Tag>
                          <Tag color="green">{formatMoney(itemPrice)}</Tag>
                          <Tag color="blue">{item.manufacturerId}</Tag>
                          <span style={{ fontSize: 12 }}>
                            {item.itemDescription}
                          </span>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <AdditionalTable
            tableData={table}
            setTableData={(newData) => {
              const newTables = [...additionalTables];
              newTables[index] = newData;
              setAdditionalTables(newTables);
            }}
            index={index}
          />

          {/* Optionally, display reminders for additional tables here if needed */}
        </div>
      ))}

      {/* Display Bulk Purchase Total when more than one PO exists */}
      {additionalTables.length > 0 && (
        <Tag
          style={{
            position: "fixed",
            bottom: 16,
            right: 16,
            padding: 8,
            border: "1px solid #ddd",
            borderRadius: 4,
            zIndex: 1000,
          }}
          color="green"
        >
          <strong>Bulk Purchase Total: {formatMoney(totalBulkPurchase)}</strong>
        </Tag>
      )}

      {/* Reminder Modal */}
      <Modal
        title="Add Reminder"
        visible={reminderModalVisible}
        onCancel={() => {
          setReminderModalVisible(false);
          setReminderDate(null);
          setReminderNotes("");
          setCurrentReminderPO({ type: "main", index: null });
        }}
        onOk={handleSaveReminder}
        okText="Save"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="Reminder Date" required>
            <DatePicker
              inputReadOnly
              style={{ width: "100%" }}
              value={reminderDate}
              onChange={(date) => setReminderDate(date)}
            />
          </Form.Item>
          <Form.Item label="Notes">
            <Input.TextArea
              value={reminderNotes}
              onChange={(e) => setReminderNotes(e.target.value)}
              placeholder="Add notes for the reminder"
            />
          </Form.Item>
        </Form>
      </Modal>
    </Form>
  );
}

export default AddPurchaseOrder;
