// src/components/ProjectDeliveryDetails.js
import React from "react";
import { Card, Row, Col, Space, Typography } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import DisplayPhoneNumber from "../../Formatters/DisplayPhoneNumber";
import DeliveryRoute from "./DeliveryRoute"; // Adjust the import path as necessary
import { getCustomerCompanyName } from "../../utils/getCustomerCompanyName";

const { Text } = Typography;

const ProjectDeliveryDetails = ({
  project,
  originPlaceId,
  destinationPlaceId,
}) => {
  // Get Company Name
  const companyName = project.customerId
    ? getCustomerCompanyName(project.customerId)
    : "N/A";

  // Get Submitted By
  const submittedBy = project.submittedBy || "N/A";

  // Get Project Description
  const projectDescription =
    project.projectDescription || "No description provided.";

  // Get Manufacturing Location Nickname and Address
  const manufacturingNickname =
    project.manufacturingLocation?.nickname || "N/A";
  const manufacturingAddress = project.manufacturingLocation?.address || "N/A";

  return (
    <div>
      {/* Primary Contact Details */}
      {/* <Card title="Primary Contact" size="small" style={{ marginBottom: 16 }}>
        <Row gutter={16}>
          <Col span={8}>
            <Space align="center">
              <UserOutlined />
              <Text strong>Name:</Text>
              <Text>
                {project.contactFirstName} {project.contactLastName}
              </Text>
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center">
              <UserOutlined />
              <Text strong>Email:</Text>
              <Text>{project.contactEmail || "N/A"}</Text>
            </Space>
          </Col>
          <Col span={8}>
            <Space align="center">
              <PhoneOutlined />
              <Text strong>Phone:</Text>
              <DisplayPhoneNumber phoneNumber={project.contactPhoneNumber} />
            </Space>
          </Col>
        </Row>
      </Card> */}

      {/* Delivery Route and Additional Details */}
      <Card title="Delivery Route">
        <Row gutter={[16, 16]}>
          {/* Delivery Map */}
          <Col span={14}>
            <DeliveryRoute
              originPlaceId={originPlaceId}
              destinationPlaceId={destinationPlaceId}
            />
          </Col>

          {/* Additional Project Details */}
          <Col span={10}>
            <Card type="inner" title="Manufacturing Location" size="small">
              <Space direction="vertical">
                <Space>
                  <Text strong>{manufacturingNickname}</Text>

                </Space>
                <Space>
                  <InfoCircleOutlined />
                  <Text strong>Address:</Text>
                  <Text>{manufacturingAddress}</Text>
                </Space>
              </Space>
            </Card>
            {/* Add more inner cards if needed */}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ProjectDeliveryDetails;
