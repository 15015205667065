// src/Components/Google/FilePreview.js

import React, { useState, useEffect, useCallback, useRef } from "react";
import { message, Tooltip, Modal } from "antd";
import { useDropzone } from "react-dropzone";
import { Loader } from "../../Styled/Loader";
import { s3Upload } from "../../Functions/s3Upload";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import {
  ExclamationCircleOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import FileIconComponent from "../../Styled/FileIconComponent";
import { getBaseFileName } from "../../utils/getBaseFileName";

const { confirm } = Modal;

const renderFilePreview = (file, onDelete, isQuote = false) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = file.url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  return (
    <div
      style={{
        position: "relative",
        width: 180,
        height: 180,
        overflow: "hidden",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        background: "#fff",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "10px",
      }}
    >
      <Tooltip title="Download">
        <DownloadOutlined
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            fontSize: "18px",
            color: "#1890ff",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={handleDownload}
        />
      </Tooltip>

      <div style={{ width: "80px", height: "80px", marginBottom: "10px" }}>
        <FileIconComponent file={file} />
      </div>

      <div
        style={{
          marginTop: "10px",
          whiteSpace: "normal",
          wordBreak: "break-word",
          textAlign: "center",
          width: "100%",
          maxHeight: "40px",
          overflow: "hidden",
        }}
      >
        {getBaseFileName(file.name)}
      </div>

      {!isQuote && (
        <Tooltip title="Delete">
          <DeleteOutlined
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              fontSize: "18px",
              color: "#ff4d4f", // Ant Design's red color
              cursor: "pointer",
              zIndex: 10,
            }}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(file);
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

const FileList = ({ files, onDelete, isQuote = false }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
        gap: "16px",
      }}
    >
      {files.map((file, index) => (
        <div key={index} style={{ display: "block", textDecoration: "none" }}>
          {renderFilePreview(file, onDelete, isQuote)}
        </div>
      ))}
    </div>
  );
};

const FilePreview = ({ onFilesCountChange, estimate }) => {
  const [files, setFiles] = useState(estimate?.files || []);
  const [fileLoading, setFileLoading] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (estimate && estimate.files) {
      setFiles(estimate.files);
      if (onFilesCountChange) {
        onFilesCountChange(estimate.files.length);
      }
    }
  }, [estimate, onFilesCountChange]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!estimate || !estimate.id) return;
      setFileLoading(true);
      try {
        const uploadedFileUrls = [];
        for (const file of acceptedFiles) {
          const fileExtension = file.name.split(".").pop();
          const fileName = `${file.name.replace(
            `.${fileExtension}`,
            ""
          )}_${new Date().toISOString()}.${fileExtension}`;
          const fileUrl = await s3Upload(file, fileName);
          uploadedFileUrls.push({ name: fileName, url: fileUrl });
        }

        if (uploadedFileUrls.length > 0) {
          const updatedFiles = [...(estimate.files || []), ...uploadedFileUrls];
          await updateById(
            TABLES.ESTIMATES,
            { files: updatedFiles },
            estimate.id
          );
          message.success("Files uploaded and estimate updated successfully!");
          setFiles(updatedFiles);
          if (onFilesCountChange) {
            onFilesCountChange(updatedFiles.length);
          }
        }
      } catch (error) {
        console.error("Failed to upload files:", error);
        message.error("Failed to upload files.");
      } finally {
        setFileLoading(false);
      }
    },
    [estimate, onFilesCountChange]
  );

  const showDeleteConfirm = (file) => {
    confirm({
      title: "Are you sure you want to delete this file?",
      icon: <ExclamationCircleOutlined />,
      content: `File: ${file.name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(file);
      },
      onCancel() {
        console.log("Cancel deletion");
      },
    });
  };

  const handleDelete = async (fileToDelete) => {
    if (!estimate || !estimate.id) return;

    const updatedFiles = files.filter(
      (file) => file.name !== fileToDelete.name
    );
    try {
      await updateById(TABLES.ESTIMATES, { files: updatedFiles }, estimate.id);
      message.success("File deleted successfully!");
      setFiles(updatedFiles);
      if (onFilesCountChange) {
        onFilesCountChange(updatedFiles.length);
      }
    } catch (error) {
      console.error("Failed to delete file:", error);
      message.error("Failed to delete file.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div ref={containerRef}>
      {fileLoading ? (
        <Loader tip="Loading files..." />
      ) : (
        <>
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #d9d9d9",
              padding: "20px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag and drop files here, or click to select files</p>
          </div>

          {files && files.length > 0 && (
            <div>
              <h3>Uploaded Files</h3>
              <FileList files={files} onDelete={showDeleteConfirm} />
            </div>
          )}

          {estimate.quote.files && estimate.quote.files.length > 0 && (
            <div>
              <h3>Quotes</h3>
              <FileList files={estimate.quote.files} isQuote={true} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FilePreview;
