import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FileIcon } from "react-file-icon";
import { Modal, Image, Spin, Alert } from "antd";
import { getBaseFileName } from "../utils/getBaseFileName";

// Define a refined color scheme for different file types
const colorMap = {
  csv: {
    color: "#34A853",
    foldColor: "#2A8C3A",
    labelColor: "#34A853",
    gradientColor: "#34A853",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "spreadsheet",
  },
  pdf: {
    color: "#EA4335",
    foldColor: "#CC332D",
    labelColor: "#EA4335",
    gradientColor: "#EA4335",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "acrobat",
  },
  image: {
    color: "#FBBC05",
    foldColor: "#F0A202",
    labelColor: "#FBBC05",
    gradientColor: "#F0A202",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "image",
  },
  xls: {
    color: "#1D6F42",
    foldColor: "#145C31",
    labelColor: "#1D6F42",
    gradientColor: "#1D6F42",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "spreadsheet",
  },
  xlsx: {
    color: "#1D6F42",
    foldColor: "#145C31",
    labelColor: "#1D6F42",
    gradientColor: "#1D6F42",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "spreadsheet",
  },
  docx: {
    color: "#4285F4",
    foldColor: "#3367D6",
    labelColor: "#4285F4",
    gradientColor: "#4285F4",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "document",
  },
  doc: {
    color: "#4285F4",
    foldColor: "#3367D6",
    labelColor: "#4285F4",
    gradientColor: "#4285F4",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "document",
  },
  pptx: {
    color: "#D14423",
    foldColor: "#D14423",
    labelColor: "#D14423",
    gradientColor: "#D14423",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "presentation",
  },
  ppt: {
    color: "#D14423",
    foldColor: "#D14423",
    labelColor: "#D14423",
    gradientColor: "#D14423",
    gradientOpacity: 2,
    glyphColor: "#D3D3D3",
    labelUppercase: true,
    type: "presentation",
  },
  default: {
    color: "whitesmoke",
    foldColor: "gray",
    labelColor: "gray",
    gradientColor: "whitesmoke",
    gradientOpacity: 0.25,
    glyphColor: "black",
    labelUppercase: true,
    type: "document",
  },
};


const getGoogleDocsViewerUrl = (fileUrl) => {
  return `https://docs.google.com/viewer?url=${encodeURIComponent(
    fileUrl
  )}&embedded=true&t=${Date.now()}`;
};

export const FileIconComponent = ({ file, style }) => {
  const { name = "", url = "" } = file || {};

  const [isFileModalVisible, setFileModalVisible] = useState(false);
  const [iframeKey, setIframeKey] = useState(0); 
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const maxRetries = 5; // Set maximum retry attempts
  const retryInterval = 3000; // Set interval between retries (in milliseconds)

  useEffect(() => {
    let retryTimer;

    // Only initiate retry if modal is visible, still loading, and retry limit hasn't been reached
    if (isFileModalVisible && isLoading && retryCount < maxRetries) {
      retryTimer = setInterval(() => {
        console.warn(`Retrying iframe load for ${name} (Attempt ${retryCount + 1})`);
        setRetryCount((prevCount) => prevCount + 1);
        setIframeKey((prevKey) => prevKey + 1); // Force reload by changing key
      }, retryInterval);
    }

    // Clear interval and stop retry if successfully loaded or max retries reached
    if (!isLoading || retryCount >= maxRetries) {
      clearInterval(retryTimer);
      if (retryCount >= maxRetries) {
        setIsError(true);
      }
    }

    return () => clearInterval(retryTimer);
  }, [isFileModalVisible, isLoading, retryCount, name]);

  const handleNonImageClick = () => {
    setIsError(false);
    setIsLoading(true);
    setRetryCount(0); // Reset retry count
    setIframeKey((prevKey) => prevKey + 1);
    setFileModalVisible(true);
  };

  const handleFileModalClose = () => {
    setFileModalVisible(false);
    setIsLoading(true);
    setIsError(false);
    setRetryCount(0); // Reset retry count when modal closes
  };

  const handleIframeLoad = () => {
    console.log("Iframe loaded successfully for:", name);
    setIsLoading(false);
  };

  const handleIframeError = () => {
    console.error("Iframe failed to load for:", name);
    setIsLoading(false);
    setIsError(true);
  };

  if (!file || !name || !url) {
    console.warn("FileIconComponent: 'file' prop is missing or incomplete.");
    return null;
  }

  const extension = name.split(".").pop().toLowerCase();
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
  const iconStyle = colorMap[extension] || colorMap["default"];
  const iconSize = style?.width || 80;

  if (imageExtensions.includes(extension)) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%"}}>
        <Image
          src={url}
          alt={name}
          width={80}
          style={{
            borderRadius: 4,
            cursor: "pointer",
            ...style,
          }}
          preview={{ src: url }}
        />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          ...style,
        }}
        onClick={handleNonImageClick}
      >
        <FileIcon
          extension={extension}
          type={iconStyle.type}
          fold={true}
          foldColor={iconStyle.foldColor}
          glyphColor={iconStyle.glyphColor}
          gradientColor={iconStyle.gradientColor}
          labelColor={iconStyle.labelColor}
          labelUppercase={iconStyle.labelUppercase}
          radius={4}
          size={iconSize}
        />
      </div>

      <Modal
        open={isFileModalVisible}
        footer={null}
        onCancel={handleFileModalClose}
        title={getBaseFileName(name)}
        centered
        width="80%"
        closable={true}
      >
        {isLoading && (
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <Spin tip="Loading preview..." />
          </div>
        )}

        {isError && (
          <Alert
            message="Error"
            description="Failed to load the file preview. Please ensure the file is accessible and supported."
            type="error"
            showIcon
          />
        )}

        {!isError && (
          <iframe
            key={iframeKey}
            src={getGoogleDocsViewerUrl(url)}
            title={name}
            width="100%"
            height="600px"
            style={{ border: "none", display: isLoading ? "none" : "block" }}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
          ></iframe>
        )}
      </Modal>
    </>
  );
};

FileIconComponent.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  style: PropTypes.object,
};

FileIconComponent.defaultProps = {
  file: null,
  style: {},
};

export default FileIconComponent;
