import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PurchasingTable from "../../Components/Reporting/PurchasingTable";
import ItemsTable from "../../Components/Reporting/ItemsTable";
import ReturnsTable from "../../Components/Reporting/ReturnsTable";
import { Col, Row, Button, Radio } from "antd";
import {
  DollarCircleOutlined,
  PaperClipOutlined,
  ContainerOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { exportToCsv } from "../../Functions/exportToCsv";
import { getVendorName } from "../../Formatters/getVendorName";
import moment from "moment";
import { useVendors, useProjects } from "../../Contexts/useSpecificData";
import { formatBuyerName } from "../../Formatters/formatBuyerName";
import { formatMoney } from "../../Formatters/helpers";
import { format } from "path-browserify";
import { getProjectName } from "../../Formatters/getProjectName";

const Reporting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTable, setActiveTable] = useState(() => {
    const path = location.pathname.split("/").pop();
    return ["purchasing", "returns", "items"].includes(path)
      ? path
      : "purchasing";
  });
  const [filteredData, setFilteredData] = useState([]);
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();
  const iconStyle = { marginRight: "8px" };

  // Memoize the onFilterChange function
  const onFilterChange = useCallback((data) => {
    setFilteredData(data);
  }, []);

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    if (["purchasing", "returns", "items"].includes(path)) {
      setActiveTable(path);
    }
  }, [location]);

  useEffect(() => {
    let title = "Reporting";
    if (activeTable === "purchasing") {
      title = "Reporting - Purchase Orders";
    } else if (activeTable === "returns") {
      title = "Reporting - Return Orders";
    } else if (activeTable === "items") {
      title = "Reporting - Items";
    }
    document.title = title;
    const path = location.pathname.split("/").pop();
    if (path !== activeTable) {
      navigate(`/purchasing/reporting/${activeTable}`, { replace: true });
    }
  }, [activeTable, navigate, location]);

  const handleTableSwitch = (e) => {
    setActiveTable(e.target.value);
  };

  const handleExport = () => {
    const exportData = filteredData.map((record) => {
      const vendorName = getVendorName(record.vendor, vendors);
      const orderedDate = moment(record.dateSubmitted).format("MM/DD/YYYY");
      const requiredDate = moment(record.requiredDate).format("MM/DD/YYYY");
      const buyerName = formatBuyerName(record.buyer);
      const projectNumber = getProjectName(record.projectNumber, projects) || "";
      const totalAmount = record.totalAmount;
      const receivedDate = record.receivedDate
        ? moment(record.receivedDate).format("MM/DD/YYYY")
        : "";
      const timeStatus = calculateTimeStatus(record);

      if (activeTable === "purchasing") {
        return {
          poNumber: record.poNumber,
          vendor: vendorName,
          orderedDate,
          requiredDate,
          buyer: buyerName,
          projectNumber,
          totalAmount: formatMoney(totalAmount),
          poStatus: record.poStatus,
          timeStatus,
          receivedDate,
        };
      } else if (activeTable === "returns") {
        return {
          roNumber: record.roNumber,
          vendor: vendorName,
          orderedDate,
          requiredDate,
          buyer: buyerName,
          projectNumber,
          totalAmount: formatMoney(totalAmount),
          poStatus: record.poStatus,
          timeStatus,
          shippedDate: receivedDate,
        };
      } else if (activeTable === "items") {
        return {
          itemNumber: record.itemNumber,
          itemDescription: record.itemDescription,
          poNumber: record.poNumber,
          vendor: vendorName,
          dateSubmitted: orderedDate,
          requiredDate,
          buyer: buyerName,
          projectNumber,
          itemPrice: formatMoney(record.itemPrice),
          qtyOrdered: record.qtyOrdered,
          qtyReceived: record.qtyReceived || "",
          totalAmount: formatMoney(record.itemPrice  * record.qtyOrdered),
          lineStatus: record.lineStatus,
          timeStatus,
          receivedDate,
        };
      }
      return {};
    });

    const filename =
      activeTable === "purchasing"
        ? "purchase_orders"
        : activeTable === "returns"
        ? "return_orders"
        : "items";

    exportToCsv(filename, exportData);
  };

  const calculateTimeStatus = (record) => {
    const requiredDateMidnight = new Date(record.requiredDate);
    requiredDateMidnight.setHours(0, 0, 0, 0);

    const receivedDate = record.receivedDate
      ? new Date(record.receivedDate)
      : null;
    const todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);

    if (receivedDate) {
      return receivedDate > requiredDateMidnight ? "Late" : "On Time";
    }
    return todayMidnight > requiredDateMidnight ? "Late" : "On Time";
  };

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        Reporting
        <Radio.Group value={activeTable} onChange={handleTableSwitch}>
          <Radio.Button
            style={{ width: 150, textAlign: "center" }}
            value="purchasing"
          >
            <DollarCircleOutlined style={{ marginRight: 4 }} /> Purchasing
          </Radio.Button>
          <Radio.Button
            style={{ width: 150, textAlign: "center" }}
            value="returns"
          >
            <RetweetOutlined style={{ marginRight: 4 }} /> Returns
          </Radio.Button>
          <Radio.Button
            style={{ width: 150, textAlign: "center" }}
            value="items"
          >
            <ContainerOutlined style={{ marginRight: 4 }} /> Items
          </Radio.Button>
        </Radio.Group>
        <Button type="primary" onClick={handleExport}>
          <PaperClipOutlined /> Export
        </Button>
      </Col>
      <Col span={24}>
        {activeTable === "purchasing" && (
          <PurchasingTable onFilterChange={onFilterChange} />
        )}
        {activeTable === "returns" && (
          <ReturnsTable onFilterChange={onFilterChange} />
        )}
        {activeTable === "items" && (
          <ItemsTable onFilterChange={onFilterChange} />
        )}
      </Col>
    </Row>
  );
};

export default Reporting;
