// src/Components/Assemblies/DuplicateAssemblyModal.jsx

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  Card,
  message,
} from "antd";
import { addAssembly } from "../../Functions/assemblyHooks"; // Ensure this function exists and is correctly implemented
import { ITEM_CATEGORIES, EXPENSE_ACCOUNTS, TABLES } from "../../constants";
import { useUser } from "@clerk/clerk-react";

const { Option } = Select;

const DuplicateAssemblyModal = ({
  children,
  assembly, // The existing assembly to duplicate
  onAddSuccess, // Callback after successful addition
  title = "Duplicate Assembly", // Modal title
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useUser();

  useEffect(() => {
    if (visible) {
      if (assembly) {
        form.setFieldsValue({
          assemblyName: assembly.assemblyName || "",
          description: assembly.description || "",
          // Do not prefill laborPrice, category, or expenseAccount
          laborPrice: undefined,
          category: undefined,
          expenseAccount: undefined,
        });
      } else {
        form.resetFields();
      }
    }
  }, [assembly, form, visible]);

  const handleCategoryChange = (value) => {
    const correspondingExpenseAccount = ITEM_CATEGORIES[value]?.expenseAccount;

    if (
      correspondingExpenseAccount &&
      EXPENSE_ACCOUNTS.includes(correspondingExpenseAccount)
    ) {
      form.setFieldsValue({ expenseAccount: correspondingExpenseAccount });
    } else {
      form.setFieldsValue({ expenseAccount: undefined });
    }
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // Prepare the data for duplication
      const duplicateData = {
        assemblyName: values.assemblyName,
        description: values.description,
        laborPrice: values.laborPrice || 0, // Default to 0 if not provided
        category: values.category || null,
        expenseAccount: values.expenseAccount || null,
        submittedBy: user?.user?.id || null, // Assign current user
          assemblyItems: assembly.assemblyItems || [], // Preserve assemblyItems
          ExpenseId: values.expenseAccount, // Duplicate expenseAccount
          isDuplicate: true, // Flag the assembly as a duplicate
       duplicatedFrom: assembly.id, // Reference to the original assembly
      };

      // Call the addAssembly function to submit the new assembly
      const result = await addAssembly(duplicateData);


      setVisible(false);
      form.resetFields();
      if (onAddSuccess) {
        onAddSuccess(result, true); // Pass the result and a flag indicating duplication
      }
    } catch (error) {
      console.error("Error duplicating assembly:", error);
      message.error(
        error.response?.data?.error || "Failed to duplicate the assembly."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const showModal = () => {
    setVisible(true);
  };

  // Custom filter function for Select components
  const customFilterOption = (input, option) =>
    option.label.toLowerCase().includes(input.toLowerCase());

  // Prepare options for Category Select
  const categoryOptions = Object.entries(ITEM_CATEGORIES).map(
    ([key, value]) => ({
      label: key, // Display name
      value: key, // Actual value
    })
  );

  // Prepare options for Expense Account Select
  const expenseAccountOptions = EXPENSE_ACCOUNTS.map((account) => ({
    label: account,
    value: account, // Using the full string as the value
  }));

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: showModal })
      )}
          <Modal
              width={650}
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Duplicate Assembly
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="assemblyName"
            label="Assembly Name"
            rules={[
              {
                required: true,
                message: "Please input the name of the assembly!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Quote Description"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>

          <Card title="Labor">
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="laborPrice"
                  label="Cost"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      message: "Labor price must be at least $0",
                    },
                  ]}
                >
                  <InputNumber
                    prefix={"$"}
                    style={{ width: "100%" }}
                    type="number"
                    min={0}
                    step={0.01}
                    placeholder="Enter labor price"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    { required: true, message: "Please select a category!" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select a category"
                    optionFilterProp="label"
                    onChange={handleCategoryChange} // Include the category change handler
                    filterOption={customFilterOption}
                    options={categoryOptions}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="expenseAccount"
                  label="Expense Account"
                  rules={[
                    {
                      required: true,
                      message: "Please select an expense account!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select an expense account"
                    optionFilterProp="label"
                    filterOption={customFilterOption}
                    options={expenseAccountOptions}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default DuplicateAssemblyModal;
