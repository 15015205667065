export const urlify = (content) => {
  if (content === null || content === undefined) {
    return "";
  }

  if (typeof content !== "string") {
    return content;
  }

  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|\b(www\.[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  let replaced = content.replace(urlRegex, (url) => {
    if (url.startsWith("www.")) {
      url = `https://${url}`;
    }
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });

  return replaced;
};
