import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  InputNumber,
  Upload,
  message,
  Row,
  Col,
  Table,
  Tag,
} from "antd";
import {
  ArrowLeftOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import { updateById } from "../../Functions/updateById";
import { s3Upload } from "../../Functions/s3Upload";
import { TABLES, SLACK_CHANNELS } from "../../constants";
import { sendSlackMessage } from "../../Functions/sendSlackMessage";
import { Loader } from "../../Styled/Loader";
import dayjs from "dayjs";
import { useUser } from "@clerk/clerk-react";
import { getProjectName } from "../../Formatters/getProjectName";

const ReceiveItemDetailReturn = () => {
  const { id, itemNumber } = useParams();
  const navigate = useNavigate();
  const { data: purchaseOrders, refresh: refreshPurchaseOrders } =
    usePurchaseOrders();
  const { data: vendors } = useVendors();
  const { data: projects } = useProjects();
  const [loading, setLoading] = useState(false);

  const [selectedPO, setSelectedPO] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [qtyReturn, setQtyReturn] = useState(0);
  const [fileList, setFileList] = useState([]);

  const { user } = useUser();

  const userEmail = user?.primaryEmailAddress?.emailAddress;

  // Determine the appropriate Slack channel based on shipFromAddress
  const shipFromAddress = selectedPO?.returnOrder?.shipFromAddress || "";
  let slackChannel;

  if (shipFromAddress.toLowerCase().includes("6544 warren")) {
    slackChannel =
      process.env.REACT_APP_STAGE === "_dev"
        ? SLACK_CHANNELS.NORCROSS_RECEIVING_DEV
        : SLACK_CHANNELS.NORCROSS_RECEIVING_PROD;
  } else {
    slackChannel =
      process.env.REACT_APP_STAGE === "_dev"
        ? SLACK_CHANNELS.RECEIVING_DEV
        : SLACK_CHANNELS.RECEIVING_PROD;
  }

  useEffect(() => {
    if (purchaseOrders) {
      const foundPO = purchaseOrders.find((po) => po.id === id);
      setSelectedPO(foundPO);
      if (foundPO) {
        const foundItem = foundPO.lines.find(
          (line) => line.itemNumber === itemNumber
        );
        setSelectedItem(foundItem);
      }
    }
  }, [id, itemNumber, purchaseOrders]);

  const handleBack = () => {
    navigate(`/receiving/receive/return-orders/${id}`);
  };

  const handleMaxQty = () => {
    if (selectedItem) {
      setQtyReturn(
        Number(selectedItem.qtyReturn) - Number(selectedItem.qtyShipped)
      ); // Ensure qtyShipped is treated as a number
    }
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  if (!selectedPO || !selectedItem || loading) {
    return <Loader />;
  }

  const qtyRemaining = selectedItem.qtyReturn - selectedItem.qtyShipped;

  if (qtyRemaining <= 0) {
    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <h2>This line item has been fully returned.</h2>
        <Button type="primary" onClick={handleBack}>
          Back to Return Orders
        </Button>
      </div>
    );
  }

  const uploadButton = (
    <div>
      <UploadOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (Number(qtyReturn) <= 0 || fileList.length === 0) {
      message.error(
        "Please enter a valid quantity and upload at least one photo."
      );
      return;
    }

    try {
      const uploadedUrls = await Promise.all(
        fileList.map((file, index) => {
          const fileName = `${selectedPO.roNumber}_${
            selectedItem.itemNumber
          }_image_${index + 1}_${new Date().toISOString()}`;
          return s3Upload(file.originFileObj, fileName);
        })
      );

      const updatedItem = {
        ...selectedItem,
        qtyShipped: Number(selectedItem.qtyShipped) + Number(qtyReturn), // Safeguard qtyShipped as a number
        images: [...(selectedItem.images || []), ...uploadedUrls],
        shippedDate: new Date().toISOString(),
        shippedBy: userEmail,
      };

      const updatedLines = selectedPO.lines.map((line) =>
        line.itemNumber === selectedItem.itemNumber ? updatedItem : line
      );

      const updatedPO = {
        ...selectedPO,
        lines: updatedLines,
      };

      await updateById(TABLES.PURCHASE_ORDERS, updatedPO, selectedPO.id);

      const slackMessage = {
        channel: slackChannel,
        text: `*New Items Returned :truck: :arrow_left: :house: ${selectedPO.roNumber}*`,
        blocks: [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: `New Items Returned :truck: :arrow_left: :house: ${selectedPO.roNumber}`,
              emoji: true,
            },
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*Address:*\n${selectedPO?.returnOrder?.shipFromAddress}`,
              },
            ],
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*Vendor:*\n${getVendorName(selectedPO.vendor, vendors)}`,
              },
              {
                type: "mrkdwn",
                text: `*Category:*\n${selectedItem.category}`,
              },
            ],
          },
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: `*Item:*\n${selectedItem.itemDescription}`,
            },
          },
          {
            type: "section",
            fields: [
              {
                type: "mrkdwn",
                text: `*Quantity Shipped:*\n${qtyReturn}`,
              },
              {
                type: "mrkdwn",
                text: `*Project:*\n${getProjectName(
                  selectedPO.projectNumber,
                  projects
                )}`,
              },
            ],
          },
          ...uploadedUrls.map((url, index) => ({
            type: "image",
            image_url: url,
            alt_text: `Image ${index + 1}`,
            title: {
              type: "plain_text",
              text: `Image ${index + 1}: ${
                selectedItem.itemNumber
              } - ${dayjs().format("MM-DD-YYYY")} - ${selectedPO.roNumber}`,
            },
          })),
        ],
      };

      await sendSlackMessage(slackMessage);

      const allLinesShipped = updatedLines.every(
        (line) => Number(line.qtyReturn) === Number(line.qtyShipped) // Ensure comparison as numbers
      );

      if (allLinesShipped) {
        await sendSlackMessage({
          channel: slackChannel,
          text: `:ballot_box_with_check: Return Order #${selectedPO.roNumber} has been completed! :ballot_box_with_check:`,
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: `:ballot_box_with_check: Return Order #${selectedPO.roNumber} has been completed! :ballot_box_with_check:`,
                emoji: true,
              },
            },

            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Address:*\n${selectedPO?.returnOrder?.shipFromAddress}`,
                },
              ],
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Vendor:*\n${getVendorName(
                    selectedPO.vendor,
                    vendors
                  )}`,
                },
                {
                  type: "mrkdwn",
                  text: `*Number of Items:*\n${selectedPO.lines.length}`,
                },
              ],
            },
          ],
        });
      }

      // await refreshPurchaseOrders(); leaving commented out for test
      setLoading(false);
      message.success("Item returned successfully.");

      if (allLinesShipped) {
        navigate(`/receiving/receive/return-orders/`);
      } else {
        navigate(`/receiving/receive/return-orders/${id}`);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error returning item:", error);
      message.error("Failed to return item. Please try again.");
    }
  };

  const isSubmitDisabled =
    qtyReturn <= 0 || (fileList.length === 0 && qtyReturn > 0);

  return (
    <>
      <Row justify="center" style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={() =>
              navigate(`/receiving/receive/return-orders/${selectedPO.id}`)
            }
          >
            Back
          </Button>
        </Col>
        <Col>
          <div style={{ fontWeight: 600, fontSize: 18, textAlign: "center" }}>
            {selectedPO.roNumber}
          </div>
          <Col
            style={{ marginTop: 8, marginBottom: 8 }}
            span={24}
            align="center"
          >
            <Tag color="blue">
              {selectedPO?.returnOrder?.shipFromAddress.split(",")[0]}
            </Tag>
          </Col>
          <div style={{ textAlign: "center" }}>
            {getVendorName(selectedPO.vendor, vendors)}
          </div>
        </Col>
      </Row>
      <Card
        title={`Line ${selectedItem.lineNumber}`}
        extra={
          <>
            <Tag style={{ fontSize: 14, fontWeight: 600 }} color="blue">
              Qty Remaining: {qtyRemaining}
            </Tag>
          </>
        }
        style={{ marginTop: 16 }}
        styles={{ body: { padding: 8 } }}
      >
        <Table
          bordered
          showHeader={false}
          pagination={false}
          size="small"
          dataSource={[
            { key: "1", label: "Item #", value: selectedItem.itemNumber },
            { key: "2", label: "Category", value: selectedItem.category },
            {
              key: "3",
              label: "Item",
              value: selectedItem.itemDescription,
            },
            { key: "4", label: "UOM", value: selectedItem.uom },
            {
              key: "5",
              label: "Qty Returning",
              value: selectedItem.qtyReturn,
            },
            {
              key: "6",
              label: "Qty Shipped",
              value: selectedItem.qtyShipped,
            },
          ]}
          columns={[
            { dataIndex: "label", key: "label", width: 120 },
            { dataIndex: "value", key: "value" },
          ]}
        />

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <InputNumber
              min={0}
              max={qtyRemaining}
              value={qtyReturn}
              type="number"
              onChange={setQtyReturn}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <Button
              onClick={handleMaxQty}
              type="primary"
              style={{ width: "100%" }}
            >
              Return Max Qty
            </Button>
          </Col>
        </Row>

        {qtyReturn > 0 && (
          <Row style={{ marginTop: 16 }}>
            <Col span={24}>
              <Upload
                className="custom-upload"
                listType="picture-card"
                fileList={fileList}
                onChange={handleUploadChange}
                onRemove={handleRemove}
                beforeUpload={() => false}
                accept="image/*,text/plain"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                  removeIcon: <CloseOutlined style={{ color: "red" }} />,
                }}
              >
                {fileList.length < 5 && "+ Upload"}
              </Upload>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{ marginTop: 16, width: "100%" }}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ReceiveItemDetailReturn;
