import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Tag,
  Badge,
  Popover,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import {
  PlusOutlined,
  StopOutlined,
  CalendarOutlined,
  PaperClipOutlined,
  WarningOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  useVendors,
  useItems,
  usePurchaseOrders,
  useProjects,
} from "../../Contexts/useSpecificData";
import AddVendorModal from "../../Components/Vendors/AddVendorModal";
import { COLORS } from "../../constants";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getPoPrice } from "../../Formatters/getPoPrice";
import { formatMoney } from "../../Formatters/helpers";
import {
  ResizableTitle,
  makeColumnsResizable,
} from "../../utils/resizableColumns";
import { exportToCsv } from "../../Functions/exportToCsv";
import PurchaseOrderModal from "../../Components/Dashboard/PurchaseOrderModal";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import { getVendorName } from "../../Formatters/getVendorName";
import PhoneInput from "react-phone-input-2";
import debounce from "lodash/debounce";
import { SearchInput } from "../../Styled/SearchInput";
import VendorItems from "../../Components/Items/VendorItems";

const Vendors = ({viewOnly = false}) => {
  const { data: vendors = [], refresh: refreshVendors } = useVendors();
  const { data: items = [], refresh: refreshItems } = useItems();
  const { data: purchaseOrders = [] } = usePurchaseOrders();
  const { data: projects = [] } = useProjects();

  const [filteredVendors, setFilteredVendors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [poModalVisible, setPoModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedVendorPOs, setSelectedVendorPOs] = useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [daysRange, setDaysRange] = useState("all"); // Default to All Time
  const [isRangeLoading, setIsRangeLoading] = useState(false);
  const navigate = useNavigate();
  const [showItemsModal, setShowItemsModal] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState(null);

  // Debounced search handler to optimize performance
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 50),
    []
  );

  useEffect(() => {
    document.title = "Vendors";
  }, []);

  // Update loading state when daysRange changes
  useEffect(() => {
    setIsRangeLoading(true);
    updateFilteredVendors();
  }, [daysRange]);

  // Combined data processing function
  const updateFilteredVendors = useCallback(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = vendors
      .filter((vendor) => {
        const address = [
          vendor.vendorAddress,
          vendor.vendorAddressSuite,
          vendor.vendorCity,
          vendor.vendorState,
          vendor.vendorZipCode,
        ]
          .filter(Boolean)
          .join(", ");
        const name = `${vendor.vendorFirstName || ""} ${
          vendor.vendorLastName || ""
        }`.trim();

        return (
          (vendor.companyName || "").toLowerCase().includes(lowercasedFilter) ||
          address.toLowerCase().includes(lowercasedFilter) ||
          name.toLowerCase().includes(lowercasedFilter) ||
          (vendor.vendorEmail || "").toLowerCase().includes(lowercasedFilter) ||
          (vendor.vendorPhone || "").toLowerCase().includes(lowercasedFilter) ||
          (vendor.vendorMobilePhone || "")
            .toLowerCase()
            .includes(lowercasedFilter)
        );
      })
      .sort((a, b) => (a.companyName || "").localeCompare(b.companyName || ""));

    // Add 'address' property to each vendor for consistent access
    filteredData.forEach((vendor) => {
      vendor.address = vendor.onlineOrder
        ? "ONLINE ORDER"
        : [
            vendor.vendorAddress,
            vendor.vendorAddressSuite,
            vendor.vendorCity,
            vendor.vendorState,
            vendor.vendorZipCode,
          ]
            .filter(Boolean)
            .join(", ");
    });

    setFilteredVendors(filteredData);
    setIsRangeLoading(false);
  }, [vendors, searchTerm]);

  useEffect(() => {
    updateFilteredVendors();
  }, [vendors, searchTerm, daysRange, updateFilteredVendors]);

  // Helper Functions
  const handleAddSuccess = () => {
    setIsModalVisible(false);
    setSelectedVendor(null);
    refreshVendors();
  };

  const getDaysRangeLabel = (range) => {
    if (range === "all") return "All Time";
    if (range === "thisYear") return "This Year";
    if (range === "lastYear") return "Last Year";
    return `Last ${range} Days`;
  };

  const handleExportCSV = () => {
    const timeRanges = [7, 30, 60, 90, 180, "thisYear", "lastYear", "all"];

    const rows = filteredVendors.map((vendor) => {
      const row = {
        "Company Name": vendor.companyName,
        "Vendor Name": `${vendor.vendorFirstName || ""} ${
          vendor.vendorLastName || ""
        }`.trim(),
        Email: vendor.vendorEmail,
        Phone: vendor.vendorPhone,
        "Mobile Phone": vendor.vendorMobilePhone,
        Address: vendor.address,
        "Items Count": getItemCountForVendor(vendor.id),
        "Open PO #": getOpenPurchaseOrders(vendor.id),
      };

      timeRanges.forEach((range) => {
        const poCount = getTotalPurchaseOrdersLastNDays(vendor.id, range);
        const purchaseAmount = getTotalPurchaseAmountLastNDays(
          vendor.id,
          range
        );
        row[`# POs (${getDaysRangeLabel(range)})`] = poCount;
        row[`Purchase Amount (${getDaysRangeLabel(range)})`] =
          formatMoney(purchaseAmount);
      });

      return row;
    });

    exportToCsv(`vendors_${dayjs().format("YYYY-MM-DD")}`, rows);
  };

  const handleAddVendorClick = () => {
    setSelectedVendor(null);
    setIsModalVisible(true);
  };

  const handleEditVendorClick = (vendor) => {
    setSelectedVendor(vendor);
    setIsModalVisible(true);
  };

  const handleViewItemsClick = (vendorId) => {
    if (viewOnly) {
      setSelectedVendorId(vendorId);
      setShowItemsModal(true);
      
    } else {
      navigate(`/purchasing/items/vendor-items/${vendorId}`);
    }
  };

  const getItemCountForVendor = (vendorId) => {
    return (
      items?.filter((item) =>
        item.vendors.some((vendor) => vendor.id === vendorId)
      ).length || 0
    );
  };

  const getTotalPurchaseOrdersLastNDays = (vendorId, range) => {
    if (range === "all") {
      return purchaseOrders?.filter((po) => po.vendor === vendorId).length || 0;
    }

    const now = dayjs();
    let dateFrom;

    if (range === "thisYear") {
      dateFrom = dayjs().startOf("year");
    } else if (range === "lastYear") {
      dateFrom = dayjs().subtract(1, "year").startOf("year");
      const dateTo = dayjs().subtract(1, "year").endOf("year");
      return (
        purchaseOrders?.filter(
          (po) =>
            po.vendor === vendorId &&
            dayjs(po.orderedDate).isBetween(dateFrom, dateTo, null, "[]")
        ).length || 0
      );
    } else {
      dateFrom = now.subtract(range, "day");
    }

    return (
      purchaseOrders?.filter(
        (po) =>
          po.vendor === vendorId && dayjs(po.orderedDate).isAfter(dateFrom)
      ).length || 0
    );
  };

  const getTotalPurchaseAmountLastNDays = (vendorId, range) => {
    if (range === "all") {
      return (
        purchaseOrders
          ?.filter((po) => po.vendor === vendorId)
          .reduce((sum, po) => sum + getPoPrice(po), 0) || 0
      );
    }

    const now = dayjs();
    let dateFrom;

    if (range === "thisYear") {
      dateFrom = dayjs().startOf("year");
    } else if (range === "lastYear") {
      dateFrom = dayjs().subtract(1, "year").startOf("year");
      const dateTo = dayjs().subtract(1, "year").endOf("year");
      return (
        purchaseOrders
          ?.filter(
            (po) =>
              po.vendor === vendorId &&
              dayjs(po.orderedDate).isBetween(dateFrom, dateTo, null, "[]")
          )
          .reduce((sum, po) => sum + getPoPrice(po), 0) || 0
      );
    } else {
      dateFrom = now.subtract(range, "day");
    }

    return (
      purchaseOrders
        ?.filter(
          (po) =>
            po.vendor === vendorId && dayjs(po.orderedDate).isAfter(dateFrom)
        )
        .reduce((sum, po) => sum + getPoPrice(po), 0) || 0
    );
  };

  const getOpenPurchaseOrders = (vendorId) => {
    return (
      purchaseOrders?.filter(
        (po) =>
          po.vendor === vendorId &&
          po.lines.some((line) => line.qtyReceived < line.qtyOrdered)
      ).length || 0
    );
  };

  const showPurchaseOrdersModal = (vendor, isOpenPo = false) => {
    let vendorPOs = [];

    if (isOpenPo) {
      // Get all open POs for the vendor
      vendorPOs = purchaseOrders.filter(
        (po) =>
          po.vendor === vendor.id &&
          po.lines.some((line) => line.qtyReceived < line.qtyOrdered)
      );
    } else {
      if (daysRange === "all") {
        vendorPOs = purchaseOrders.filter((po) => po.vendor === vendor.id);
      } else if (daysRange === "thisYear") {
        vendorPOs = purchaseOrders.filter(
          (po) =>
            po.vendor === vendor.id &&
            dayjs(po.orderedDate).isAfter(dayjs().startOf("year"))
        );
      } else if (daysRange === "lastYear") {
        const lastYearStart = dayjs().subtract(1, "year").startOf("year");
        const lastYearEnd = dayjs().subtract(1, "year").endOf("year");
        vendorPOs = purchaseOrders.filter(
          (po) =>
            po.vendor === vendor.id &&
            dayjs(po.orderedDate).isBetween(
              lastYearStart,
              lastYearEnd,
              null,
              "[]"
            )
        );
      } else {
        const dateFrom = dayjs().subtract(daysRange, "day");
        vendorPOs = purchaseOrders.filter(
          (po) =>
            po.vendor === vendor.id && dayjs(po.orderedDate).isAfter(dateFrom)
        );
      }
    }

    setSelectedVendorPOs(vendorPOs);
    setSelectedVendor(vendor);

    const title = isOpenPo
      ? `Open POs - ${vendor.companyName} (${vendorPOs.length})`
      : `${getDaysRangeLabel(daysRange)} - ${vendor.companyName} (${
          vendorPOs.length
        })`;

    setModalTitle(title);
    setPoModalVisible(true);
  };

  const showPurchaseOrderDetails = (purchaseOrder) => {
    setSelectedPurchaseOrder(purchaseOrder);
  };

  const handleRangeChange = (value) => {
    setDaysRange(value);
  };

  const handleDeleteVendor = (vendor) => {
    // Filter items where this vendor is included
    const itemsWithVendor = items.filter((item) =>
      item.vendors.some((v) => v.id === vendor.id)
    );

    // Separate items into sole vendor items and shared vendor items
    const soleVendorItems = itemsWithVendor.filter(
      (item) => item.vendors.length === 1
    );

    const sharedVendorItems = itemsWithVendor.filter(
      (item) => item.vendors.length > 1
    );

    // Modal to confirm deletion
    Modal.confirm({
      title: `Delete Vendor: ${getVendorName(vendor.id, vendors)}`,
      icon: <WarningOutlined />,
      width: 600,
      content: (
        <div>
          {soleVendorItems.length === 0 && sharedVendorItems.length === 0 && (
            <p>
              This vendor is not associated with any items.
              <br />
              Are you sure you want to delete this vendor?
            </p>
          )}
          {(soleVendorItems.length > 0 || sharedVendorItems.length > 0) && (
            <h4>Items associated with this vendor:</h4>
          )}

          {soleVendorItems.length > 0 && (
            <>
              <p>
                The following items <b>will be deleted</b> since this vendor is
                the sole vendor:
              </p>
              <ul>
                {soleVendorItems.map((item) => (
                  <li key={item.id}>{item.itemDescription}</li>
                ))}
              </ul>
            </>
          )}

          {sharedVendorItems.length > 0 && (
            <>
              <p>
                The following items will have this vendor removed from their
                vendor list:
              </p>
              <ul>
                {sharedVendorItems.map((item) => (
                  <li key={item.id}>{item.itemDescription}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      ),
      onOk: () => {
        const promises = [];

        // For sole vendor items, mark them as deleted
        soleVendorItems.forEach((item) => {
          promises.push(updateById(TABLES.ITEMS, { deleted: true }, item.id));
        });

        // For shared vendor items, remove this vendor from their vendor list
        sharedVendorItems.forEach((item) => {
          const updatedVendors = item.vendors.filter((v) => v.id !== vendor.id);
          promises.push(
            updateById(TABLES.ITEMS, { vendors: updatedVendors }, item.id)
          );
        });

        // Mark the vendor as deleted
        promises.push(updateById(TABLES.VENDORS, { deleted: true }, vendor.id));

        // Execute all promises and refresh vendors and items on completion
        Promise.all(promises)
          .then(() => {
            message.success("Vendor and associated items updated successfully");
            refreshVendors();
            refreshItems();
          })
          .catch(() => {
            message.error("Failed to delete vendor or update items");
          });
      },
      okText: "Delete",
      cancelText: "Cancel",
    });
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      defaultSortOrder: "ascend",
      render: (text, record) =>
        !viewOnly ? (
          <a onClick={() => handleEditVendorClick(record)}>{text}</a>
        ) : (
          text
        ), // Conditionally render as link or plain text
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record) => {
        // If 'onlineOrder' is true, display the Tag
        if (record.onlineOrder) {
          return (
            <Tag style={{ fontSize: 11 }} color="blue">
              ONLINE ORDER
            </Tag>
          );
        }

        // Safely access 'googleAddress.formatted_address' using optional chaining
        const formattedAddress =
          record.googleAddress?.formatted_address?.trim();

        // If 'formatted_address' exists and is not empty, display it
        if (formattedAddress) {
          return formattedAddress;
        }

        // Fallback to 'record.address'
        return (
          record.address || (
            <StopOutlined
              style={{ color: "red" }}
              title="No Address Available"
            />
          )
        );
      },
    },
    {
      title: "Suite",
      key: "suite",
      width: 75,
      render: (text, record) => {
        // Helper function to check if a string is non-empty after trimming
        const isValid = (str) => str && str.trim() !== "";

        if (isValid(record.suite)) {
          return record.suite.trim();
        }

        if (isValid(record.vendorAddressSuite)) {
          return record.vendorAddressSuite.trim();
        }

        // Fallback: Display a red StopOutlined icon
        return <StopOutlined style={{ color: "red" }} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
      key: "name",
      render: (_, record) =>
        `${record.vendorFirstName || ""} ${
          record.vendorLastName || ""
        }`.trim() || <StopOutlined style={{ color: "red" }} />,
    },
    {
      title: "Phone Number",
      dataIndex: "vendorPhone",
      key: "phoneNumber",
      render: (text, record) =>
        text ? (
          <PhoneInput
            value={text}
            country={"us"}
            containerClass="displayOnlyPhone"
            inputClass="displayOnlyPhone"
            dropdownClass="displayOnlyPhone"
            disableDropdown={true}
            disabled
            containerStyle={{ width: "100%" }}
            inputStyle={{
              width: "100%",
              border: "none",
              background: "none",
              cursor: "default",
              fontSize: 12,
            }}
          />
        ) : (
          <StopOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Email",
      dataIndex: "vendorEmail",
      key: "vendorEmail",
      ellipsis: true,
      render: (_, record) => {
        const allEmails = [
          record.vendorEmail,
          ...(record.additionalEmails || []),
        ]
          .filter(Boolean)
          .join(", ");
        return allEmails || <StopOutlined style={{ color: "red" }} />;
      },
    },

    // Conditionally include the "Items" column based on `!viewOnly`
 {
      title: "Items",
      key: "actions",
      width: 100,
      sorter: (a, b) =>
        getItemCountForVendor(a.id) - getItemCountForVendor(b.id),
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="primary"
            size="small"
            onClick={() => handleViewItemsClick(record.id)}
            // disabled={getItemCountForVendor(record.id) === 0}
          >
            <span style={{ fontSize: 12 }}>Items</span>
          </Button>
          <Badge
            count={getItemCountForVendor(record.id)}
            style={{
              backgroundColor: COLORS.PRIMARY,
              marginLeft: 8,
              boxShadow: "0 0 0 1px #d9d9d9 inset",
            }}
          />
        </div>
      ),
    },

    {
      className: "column-stats",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Select
            value={daysRange}
            className="transparent-select"
            onChange={handleRangeChange}
            style={{ width: "150px" }}
            size="small"
          >
            <Select.Option value={7}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 7 Days
            </Select.Option>
            <Select.Option value={30}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 30 Days
            </Select.Option>
            <Select.Option value={60}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 60 Days
            </Select.Option>
            <Select.Option value={90}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 90 Days
            </Select.Option>
            <Select.Option value={180}>
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last 180 Days
            </Select.Option>
            <Select.Option value="thisYear">
              <CalendarOutlined style={{ marginRight: 8 }} />
              This Year
            </Select.Option>
            <Select.Option value="lastYear">
              <CalendarOutlined style={{ marginRight: 8 }} />
              Last Year
            </Select.Option>
            <Select.Option value="all">
              <CalendarOutlined style={{ marginRight: 8 }} /> All Time
            </Select.Option>
          </Select>
        </div>
      ),
      children: [
        {
          title: "# PO's",
          dataIndex: "poLastXDays",
          key: "poLastXDays",
          align: "center",
          sorter: (a, b) =>
            getTotalPurchaseOrdersLastNDays(a.id, daysRange) -
            getTotalPurchaseOrdersLastNDays(b.id, daysRange),
          render: (_, record) => {
            const poCount = getTotalPurchaseOrdersLastNDays(
              record.id,
              daysRange
            );
            return isRangeLoading ? (
              <Spin size="small" />
            ) : poCount > 0 ? (
              <a onClick={() => showPurchaseOrdersModal(record)}>{poCount}</a>
            ) : (
              <span>{poCount}</span>
            );
          },
          className: "column-stats",
          width: 75,
        },

        {
          title: <>$ PO's</>,
          dataIndex: "purchaseAmountLastXDays",
          key: "purchaseAmountLastXDays",
          width: 125,
          align: "center",
          sorter: (a, b) =>
            getTotalPurchaseAmountLastNDays(a.id, daysRange) -
            getTotalPurchaseAmountLastNDays(b.id, daysRange),
          render: (_, record) => {
            const purchaseAmount = getTotalPurchaseAmountLastNDays(
              record.id,
              daysRange
            );
            return isRangeLoading ? (
              <Spin size="small" />
            ) : (
              <Tag color="green">{formatMoney(purchaseAmount)}</Tag>
            );
          },
          className: "column-stats",
        },
      ],
    },
    {
      title: "Open PO",
      key: "openPos",
      width: 75,
      align: "center",
      sorter: (a, b) =>
        getOpenPurchaseOrders(a.id) - getOpenPurchaseOrders(b.id),
      render: (_, record) => {
        const openPoCount = getOpenPurchaseOrders(record.id);
        return openPoCount > 0 ? (
          <a onClick={() => showPurchaseOrdersModal(record, true)}>
            {openPoCount}
          </a>
        ) : (
          <span>{openPoCount}</span>
        );
      },
    },

    // Conditionally include the "Delete" column based on `!viewOnly`
    !viewOnly && {
      title: "",
      key: "delete",
      width: 100,
      render: (_, record) => {
        const totalPoCount = getTotalPurchaseOrdersLastNDays(record.id, "all");
        const itemCount = getItemCountForVendor(record.id);

        const isDeleteDisabled = totalPoCount > 0 || itemCount > 0;

        const deleteButton = (
          <Button
            danger
            size="small"
            icon={<DeleteOutlined />}
            disabled={isDeleteDisabled}
            onClick={() => handleDeleteVendor(record)}
          >
            Delete
          </Button>
        );

        // If the button is disabled, wrap it with a Popover
        if (isDeleteDisabled) {
          return (
            <Popover
              content="Cannot delete vendor with any existing purchase orders or attached items."
              trigger="hover"
            >
              <span style={{ display: "inline-block" }}>{deleteButton}</span>
            </Popover>
          );
        }

        // If the button is enabled, render it normally without a Popover
        return deleteButton;
      },
    },
  ].filter(Boolean); // Remove any `false` values from the array

  return (
    <Row gutter={[16, 16]}>
      <Col
        span={8}
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        <div>
          Vendors{" "}
          <span style={{ opacity: 0.6, fontStyle: "italic", fontSize: 18 }}>
            ({vendors.length})
          </span>
          {viewOnly && (
            <Tag icon={<EyeOutlined />} style={{ marginLeft: 8 }} color="blue">
              {" "}
              View Only{" "}
            </Tag>
          )}
        </div>
      </Col>
      <Col span={8}></Col>
      <Col align="right" span={8}>
        <Button
          type="default"
          style={{ marginRight: !viewOnly ? 16 : 0 }}
          icon={<PaperClipOutlined />}
          onClick={handleExportCSV}
          disabled={filteredVendors.length === 0}
        >
          Export
        </Button>
        {!viewOnly && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddVendorClick}
          >
            Add Vendor
          </Button>
        )}
      </Col>
      <Col span={24}>
        <SearchInput
          placeholder="Search Vendors"
          onChange={(e) => debouncedSearch(e.target.value)}
          style={{}}
          resultsLength={filteredVendors.length}
        />
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={filteredVendors}
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100", "250", "500"],
          }}
          className="small-table"
          size="small"
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
        />
      </Col>

      <AddVendorModal
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        onAddSuccess={handleAddSuccess}
        vendor={selectedVendor}
      />

      <Modal
        title={modalTitle}
        open={poModalVisible}
        onCancel={() => setPoModalVisible(false)}
        footer={null}
      >
        <Row gutter={[16, 16]}>
          {selectedVendorPOs.map((po) => (
            <Col span={4} key={po.poNumber}>
              <Button onClick={() => showPurchaseOrderDetails(po)}>
                {po.poNumber}
              </Button>
            </Col>
          ))}
        </Row>
      </Modal>

      {selectedPurchaseOrder && (
        <PurchaseOrderModal
          purchaseOrder={selectedPurchaseOrder}
          visible={!!selectedPurchaseOrder}
          onClose={() => setSelectedPurchaseOrder(null)}
          vendors={vendors}
          projects={projects}
        />
      )}
      <Modal
        width="90%"
        footer={null}
        onCancel={() => setShowItemsModal(false)} // Correct handler to close modal
        open={showItemsModal} // Correct visibility control
      >
        <VendorItems vendorIdProp={selectedVendorId} viewOnly />
      </Modal>
    </Row>
  );
};

export default Vendors;
