import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row, message, Tabs, Card, Space, Badge, Typography } from "antd";
import {
  EditOutlined,
  UserOutlined,
  CalendarOutlined,
  DollarOutlined,
  NumberOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  useEstimates,
  useItems,
  useCustomers,
} from "../../Contexts/useSpecificData";
import AddEstimateModal from "../../Components/Estimating/AddEstimateModal";
import EstimateModules from "../../Components/Estimating/Modules/EstimateModules";
import EstimateAssemblies from "../../Components/Estimating/EstimateAssemblies/EstimateAssemblies";
import CustomItems from "../../Components/Estimating/CustomItems/CustomItems";
import BOM from "../../Components/Estimating/BOM/bom";
import QuotePDF from "../../Components/Estimating/Quote/quote";
import InternalQuote from "../../Components/Estimating/InternalQuote/InternalQuote";
import AriaItems from "../../Components/Estimating/AriaItems/AriaItems";
import FilePreview from "../../Components/Google/FilePreview";
import { calculateEstimateCosts } from "../../Formatters/calculateEstimate";
import { Loader } from "../../Styled/Loader";
import PriceTag from "../../Styled/PriceTag";
import { formatDate } from "../../Formatters/helpers";
import { getCustomerName } from "../../Formatters/getCustomerName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { aggregateBOMItems } from "./BomCountHelper";
import EstimateTimeline from "../../Components/Estimating/Timeline/EstimateTimeline";

const { Text } = Typography;

const BuildEstimate = () => {
  const { id, tab } = useParams();
  const navigate = useNavigate();

  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bomLineItemCount, setBomLineItemCount] = useState(0);

  const { data: estimates, refresh: refreshEstimates } = useEstimates();
  const { data: customers } = useCustomers();
  const { data: items } = useItems();

  // Memoize estimates to ensure stable reference
  const memoizedEstimates = useMemo(() => estimates, [estimates]);

  useEffect(() => {
    if (memoizedEstimates) {
      const selectedEstimate =
        memoizedEstimates.find((est) => est.id === id) || null;
      if (selectedEstimate !== estimate) {
        setEstimate(selectedEstimate);
      }
      if (loading) {
        setLoading(false);
      }
    }
  }, [id, memoizedEstimates]); // Changed dependency to memoizedEstimates

  useEffect(() => {
    if (estimate) {
      const tabName = tab ? tab.replace("-", " ") : "modules";
      document.title = `${estimate.estimateNumber} - ${tabName}`;
    } else {
      document.title = "Build Estimate";
    }
  }, [estimate, tab]);

  const onUpdateSuccess = () => {
    refreshEstimates();
    message.success("Estimate updated successfully!");
  };

  if (loading) return <Loader />;
  if (!estimate) return <Loader />;

  const costs = estimate ? calculateEstimateCosts(estimate, items) : {};

  const handleTabChange = (activeKey) => {
    navigate(`/estimating/estimates/${id}/${activeKey}`);
  };

  const createTabLabel = (label, count) => {
    if (typeof count === "number") {
      const offsetValue = count > 9 ? 18 : 12;
      return (
        <Space style={{ padding: "0 24px" }}>
          <Badge offset={[offsetValue, 0]} size="small" count={count}>
            {label}
          </Badge>
        </Space>
      );
    }
    return (
      <Space style={{ padding: "0 22px" }}>
        <Badge>{label}</Badge>
      </Space>
    );
  };

  const assemblyItemCount =
    estimate?.assemblies?.reduce(
      (total, assembly) => total + (assembly.assemblyItems?.length || 0),
      0
    ) || 0;

  const ariaItemCount = estimate?.ariaItems?.length || 0;
  const customItemCount = estimate?.customItems?.length || 0;

  const tabItems = [
    {
      key: "modules",
      label: createTabLabel("Modules", estimate?.modules?.length || 0),
      children: estimate ? (
        <EstimateModules estimate={estimate} onAddSuccess={refreshEstimates} />
      ) : (
        <Loader />
      ),
    },
    {
      key: "assemblies",
      label: createTabLabel("Assemblies", estimate?.assemblies?.length || 0),
      children: estimate ? (
        <EstimateAssemblies estimate={estimate} />
      ) : (
        <Loader />
      ),
    },
    {
      key: "aria-items",
      label: createTabLabel("Aria Items", ariaItemCount),
      children: estimate ? <AriaItems estimate={estimate} /> : <Loader />,
    },
    {
      key: "custom-items",
      label: createTabLabel("Custom Items", customItemCount),
      children: estimate ? <CustomItems estimate={estimate} /> : <Loader />,
    },
    {
      key: "bom",
      label: createTabLabel("BOM", aggregateBOMItems(estimate)),
      children: estimate ? <BOM estimate={estimate} /> : <Loader />,
    },
    {
      key: "internal-quote",
      label: createTabLabel("Internal Quote"),
      children: estimate ? <InternalQuote estimate={estimate} /> : <Loader />,
    },
    ...(estimate?.costs
      ? [
          {
            key: "quote",
            label: createTabLabel("Quote"),
            children: estimate ? <QuotePDF estimate={estimate} /> : <Loader />,
          },
        ]
      : []),
    {
      key: "files",
      label: createTabLabel(
        "Files",
        (estimate?.quote?.files?.length || 0) + (estimate?.files?.length || 0)
      ),
      children: estimate ? <FilePreview estimate={estimate} /> : <Loader />,
    },
    ...(estimate?.history && estimate.history.length > 0
      ? [
          {
            key: "timeline",
            label: createTabLabel("Timeline", estimate.history.length+1),
            children: <EstimateTimeline estimate={estimate} />,
          },
        ]
      : []),
  ];

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <AddEstimateModal
          title="Update Estimate"
          estimate={estimate}
          onAddSuccess={onUpdateSuccess}
        >
          <Card
            style={{
              backgroundColor: "white",
              cursor: "pointer",
              position: "relative",
            }}
            size="small"
          >
            <EditOutlined
              style={{
                fontSize: "18px",
                color: "#08c",
                position: "absolute",
                top: 16,
                right: 16,
              }}
            />
            <Row gutter={16}>
              <Col span={24}>
                <Space
                  direction="horizontal"
                  size="large"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <div>
                    <div style={{ fontSize: 18, fontWeight: 600 }}>
                      {estimate?.estimateName || "N/A"}
                    </div>
                  </div>
                  <div>
                    <NumberOutlined style={{ color: "#555", marginRight: 8 }} />
                    <Text>{estimate?.estimateNumber || "N/A"}</Text>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faHandshake}
                      style={{ color: "#555", marginRight: 8 }}
                    />
                    <Text>
                      {getCustomerName(estimate?.customerId, customers)}
                    </Text>
                  </div>
                  <div>
                    <UserOutlined style={{ color: "#555", marginRight: 8 }} />
                    <Text>
                      {estimate?.assignedTo?.fullName || "Unassigned"}
                    </Text>
                  </div>
                  <div>
                    <CalendarOutlined
                      style={{ color: "#555", marginRight: 8 }}
                    />
                    <Text>
                      {formatDate(estimate?.quoteBy) || "No Deadline"}
                    </Text>
                  </div>
                  <div>
                    <InfoCircleOutlined style={{ color: "#555", marginRight: 8 }} />
                    <Text>
                      {estimate?.estimateStatus|| "No Status"}
                    </Text>
                  </div>
                  <div>
                    <DollarOutlined style={{ color: "#555", marginRight: 8 }} />
                    <PriceTag amount={estimate?.costs?.saleAmount || 0} />
                  </div>
                </Space>
              </Col>
            </Row>
          </Card>
        </AddEstimateModal>
      </Col>
      <Col span={24}>
        <Tabs
          activeKey={tab || "modules"}
          onChange={handleTabChange}
          type="card"
          items={tabItems}
        />
      </Col>
    </Row>
  );
};

export default BuildEstimate;
