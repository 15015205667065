import React, { useState, useEffect } from "react";
import { Table, Tag, Badge, Modal, Descriptions, Statistic, Row, Col } from "antd";
import { StopOutlined } from "@ant-design/icons";
import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getVendorName } from "../../Formatters/getVendorName";
import dayjs from "dayjs";
import { formatBuyerName } from "../../Formatters/formatBuyerName";
import { getProjectName } from "../../Formatters/getProjectName";
import PurchaseOrderModal from "../Dashboard/PurchaseOrderModal";
import TableHeader from "../../Styled/TableHeader";
import { getSubtotal, getTotalAmount } from "../../utils/purchaseOrderUtils";
import PriceTag from "../../Styled/PriceTag";

const PurchasingTable = ({ onFilterChange }) => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const [selectedGroupNumber, setSelectedGroupNumber] = useState(null);
  const [groupData, setGroupData] = useState([]);

  const { data: purchaseOrders, loading: poLoading } = usePurchaseOrders();
  const {
    data: vendors,
    loading: vendorsLoading,
    error: vendorsError,
  } = useVendors();
  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
  } = useProjects();

  useEffect(() => {
    if (!poLoading && !vendorsLoading && !projectsLoading) {
      const aggregatedResult = aggregateData(purchaseOrders);
      const sortedResult = aggregatedResult.sort((a, b) =>
        compareDates(a.orderedDate, b.orderedDate)
      );
      setOriginalData(sortedResult);
      setLoading(false);
    }
  }, [
    poLoading,
    purchaseOrders,
    vendorsLoading,
    projectsLoading,
    vendors,
    projects,
  ]);

  useEffect(() => {
    applyFilters();
  }, [searchTerm, filteredInfo, originalData]);

  const compareDates = (a, b) => dayjs(b).diff(dayjs(a));

  const aggregateData = (data) => {
    const aggregated = {};

    data.forEach((item) => {
      if (!aggregated[item.poNumber]) {
        // Calculate subtotal using utility function
        const subtotal = getSubtotal(item);
        // Calculate total amount using utility function
        const totalAmount = getTotalAmount(item, subtotal);

        aggregated[item.poNumber] = {
          ...item,
          lines: item.lines || [],
          totalQty: item.lines.reduce(
            (sum, line) => sum + (parseFloat(line.qtyOrdered) || 0),
            0
          ),
          subtotal, // Store subtotal
          totalAmount, // Store total amount
          receivedDate: null,
          poStatus: "Closed",
          vendorName: getVendorName(item.vendor, vendors), // Added vendorName
        };
      }

      item.lines.forEach((line) => {
        if (line.receivedDate) {
          const lineReceivedDate = dayjs(line.receivedDate);
          if (
            !aggregated[item.poNumber].receivedDate ||
            lineReceivedDate.isAfter(
              dayjs(aggregated[item.poNumber].receivedDate)
            )
          ) {
            aggregated[item.poNumber].receivedDate = line.receivedDate;
          }
        }
        const lineStatus =
          line.qtyReceived >= line.qtyOrdered
            ? "Closed"
            : line.qtyReceived > 0
            ? "Partial"
            : "Open";
        if (lineStatus !== "Closed") {
          aggregated[item.poNumber].poStatus = "Open";
        }
      });

      const requiredDateMidnight = dayjs(
        aggregated[item.poNumber].requiredDate
      ).startOf("day");
      const latestReceivedDate = aggregated[item.poNumber].receivedDate
        ? dayjs(aggregated[item.poNumber].receivedDate).startOf("day")
        : null;
      const todayMidnight = dayjs().startOf("day");
      let timeStatus = "On Time";
      if (
        latestReceivedDate &&
        latestReceivedDate.isAfter(requiredDateMidnight)
      ) {
        timeStatus = "Late";
      } else if (
        todayMidnight.isAfter(requiredDateMidnight) &&
        aggregated[item.poNumber].poStatus === "Open"
      ) {
        timeStatus = "Late";
      }
      aggregated[item.poNumber].timeStatus = timeStatus;
    });

    return Object.values(aggregated);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
  };

  const handleFilterChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const applyFilters = () => {
    let filteredData = originalData;

    // Apply table filters
    if (Object.keys(filteredInfo).length > 0) {
      Object.keys(filteredInfo).forEach((key) => {
        const filterValues = filteredInfo[key];
        if (filterValues && filterValues.length > 0) {
          filteredData = filteredData.filter((record) => {
            const recordValue =
              key === "vendor"
                ? record.vendorName
                : key === "buyer"
                ? formatBuyerName(record?.buyer)
                : key === "projectNumber"
                ? getProjectName(record.projectNumber, projects)
                : key === "poStatus"
                ? record.poStatus
                : key === "shipToAddress"
                ? record.shipToAddress
                : record[key];

            return filterValues.includes(recordValue);
          });
        }
      });
    }

    // Apply search filter
    if (searchTerm.trim() !== "") {
      const searchTerms = searchTerm.split(/\s+/).filter((term) => term);
      filteredData = filteredData.filter((record) =>
        [
          "poNumber",
          "vendorName",
          "buyerName",
          "groupNumber",
          "projectName",
        ].some((key) => {
          let fieldValue = "";
          switch (key) {
            case "poNumber":
              fieldValue = record.poNumber?.toString().toLowerCase() || "";
              break;
            case "vendorName":
              fieldValue = (record.vendorName || "").toString().toLowerCase();
              break;
            case "buyerName":
              fieldValue = formatBuyerName(record.buyer)?.toLowerCase() || "";
              break;
            case "groupNumber":
              fieldValue = record.groupNumber?.toString().toLowerCase() || "";
              break;
            case "projectName":
              fieldValue =
                getProjectName(record.projectNumber, projects)?.toLowerCase() ||
                "";
              break;
            default:
              fieldValue = "";
          }

          return searchTerms.every((term) => fieldValue.includes(term));
        })
      );
    }

    setData(filteredData);
    onFilterChange && onFilterChange(filteredData);
  };

  const getUniqueFilters = (dataSource, dataIndex) => {
    const uniqueValues = Array.from(
      new Set(
        dataSource.map((item) => {
          if (dataIndex === "vendor") {
            return item.vendorName;
          } else if (dataIndex === "buyer") {
            return formatBuyerName(item.buyer);
          } else if (dataIndex === "projectNumber") {
            return getProjectName(item.projectNumber, projects);
          } else if (dataIndex === "poStatus") {
            return item.poStatus;
          } else if (dataIndex === "shipToAddress") {
            return item.shipToAddress;
          } else {
            return item[dataIndex];
          }
        })
      )
    )
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b))
      .map((value) => ({
        text: value,
        value: value,
      }));

    return uniqueValues;
  };

  // Generate unique filters based on current data
  const vendorFilters = getUniqueFilters(data, "vendor");
  const buyerFilters = getUniqueFilters(data, "buyer");
  const projectFilters = getUniqueFilters(data, "projectNumber");
  const statusFilters = getUniqueFilters(data, "poStatus");
  const addressFilters = getUniqueFilters(data, "shipToAddress");

  const removeFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setSearchTerm("");
  };

  const columns = [
    {
      title: "Address",
      dataIndex: "shipToAddress",
      key: "shipToAddress",
      sorter: (a, b) => a.shipToAddress.localeCompare(b.shipToAddress),
      filteredValue: filteredInfo.shipToAddress || null,
      filters: addressFilters, // Add filters for Address
      filterSearch: true,
      onFilter: (value, record) =>
        record.shipToAddress
          ? record.shipToAddress.toLowerCase().includes(value.toLowerCase())
          : false,
      render: (text) => {
        if (!text) return null; // Handle null or undefined addresses

        // Split the address at the first comma
        const [firstPart, ...rest] = text.split(",");

        // Join the remaining parts back together (in case there are multiple commas)
        const secondPart = rest.join(",").trim();

        return (
          <div>
            <div>{firstPart.trim()}</div>
            {secondPart && <div>{secondPart}</div>}
          </div>
        );
      },
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      filteredValue: filteredInfo.poNumber || null,
      defaultSortOrder: "descend",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{text}</span>
          {record.groupNumber && (
            <Tag
              color="blue"
              style={{ marginLeft: 8, fontSize: 10, cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click event
                handleGroupTagClick(record.groupNumber);
              }}
            >
              Group {record.groupNumber}
            </Tag>
          )}
          <Badge
            count={record.lines.length}
            style={{ backgroundColor: "#6BA1E2", fontWeight: "bold" }}
          />
        </div>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "vendorName", // Use vendorName for display and filtering
      key: "vendor",
      sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
      filters: vendorFilters,
      filteredValue: filteredInfo.vendor || null,
      filterSearch: true,
      onFilter: (value, record) => record.vendorName?.includes(value),
      render: (text) => {
        if (vendorsLoading) return "Loading...";
        if (vendorsError) return "Error loading vendors";
        return text || "N/A";
      },
    },
    {
      title: "Date Submitted",
      dataIndex: "orderedDate",
      key: "orderedDate",
      sorter: (a, b) => dayjs(a.orderedDate).diff(dayjs(b.orderedDate)),
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "Required Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      sorter: (a, b) =>
        formatBuyerName(a.buyer).localeCompare(formatBuyerName(b.buyer)),
      filters: buyerFilters,
      filteredValue: filteredInfo.buyer || null,
      filterSearch: true,
      onFilter: (value, record) =>
        formatBuyerName(record.buyer)
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (text) => formatBuyerName(text),
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      sorter: (a, b) => {
        let aProjName = getProjectName(a.projectNumber, projects) || "";
        let bProjName = getProjectName(b.projectNumber, projects) || "";
        return aProjName.localeCompare(bProjName);
      },
      filters: projectFilters,
      filteredValue: filteredInfo.projectNumber || null,
      filterSearch: true,
      onFilter: (value, record) => {
        let projName = getProjectName(record.projectNumber, projects) || "";
        return projName.includes(value);
      },
      render: (text) => getProjectName(text, projects),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      render: (text) => <PriceTag amount={text} />,
    },
    {
      title: "PO Status",
      dataIndex: "poStatus",
      key: "poStatus",
      sorter: (a, b) => a.poStatus.localeCompare(b.poStatus),
      filters: statusFilters,
      filteredValue: filteredInfo.poStatus || null,
      onFilter: (value, record) => record.poStatus.includes(value),
      render: (status) => {
        let color = "orange";
        if (status === "Closed") color = "green";
        if (status === "Open") color = "blue";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Time Status",
      dataIndex: "timeStatus",
      key: "timeStatus",
      filters: [
        { text: "On Time", value: "On Time" },
        { text: "Late", value: "Late" },
      ],
      filteredValue: filteredInfo.timeStatus || null,
      onFilter: (value, record) => record.timeStatus === value,
      render: (text, record) => {
        const color = record.timeStatus === "Late" ? "red" : "green";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {record.timeStatus}
          </Tag>
        );
      },
    },
    {
      title: "Date Received",
      dataIndex: "receivedDate",
      key: "receivedDate",
      sorter: (a, b) => {
        if (!a.receivedDate && !b.receivedDate) return 0;
        if (!a.receivedDate) return -1; // `a` is null, so it comes after `b`
        if (!b.receivedDate) return 1; // `b` is null, so it comes after `a`
        return dayjs(a.receivedDate).diff(dayjs(b.receivedDate));
      },
      render: (text, record) => {
        if (record.poStatus !== "Closed")
          return <StopOutlined style={{ color: "#d3d3d3" }} />;
        return dayjs(text).format("MM/DD/YYYY");
      },
    },
  ];

  const onRowClick = (record) => {
    setSelectedRecord(record);
    setModalVisible(true);
  };

  const handleGroupTagClick = (groupNumber) => {
    // Filter purchase orders with the same group number
    const groupPurchaseOrders = originalData.filter(
      (po) => po.groupNumber === groupNumber
    );

    // Sort the group purchase orders by PO Number or any other criteria
    const sortedGroupPOs = groupPurchaseOrders.sort((a, b) =>
      a.poNumber.localeCompare(b.poNumber)
    );

    setSelectedGroupNumber(groupNumber);
    setGroupData(sortedGroupPOs);
    setGroupModalVisible(true);
  };

  return (
    <>
      <TableHeader
        title="Purchase Orders"
        totalResults={data.length}
        totalItems={originalData.length}
        searchValue={searchTerm}
        onSearchChange={handleSearch}
        removeFilters={removeFilters}
        showRemoveFilters={true}
      />
      <Table
        className="small-table"
        dataSource={data}
        columns={columns}
        rowClassName="clickable-row"
        rowKey={(record) => `${record.poNumber}-${record.id}`} // Ensure unique rowKey
        loading={loading || poLoading || vendorsLoading || projectsLoading}
        onRow={(record) => ({ onClick: () => onRowClick(record) })}
        onChange={handleFilterChange}
        pagination={{
          pageSizeOptions: ["10", "25", "50", "100", "250"],
          showSizeChanger: true,
        }}
        locale={{
          emptyText: searchTerm
            ? "No purchase orders match your search."
            : "No data available.",
        }}
      />
      <PurchaseOrderModal
        purchaseOrder={selectedRecord}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        vendors={vendors}
        projects={projects}
      />

      <Modal
        title={`Group ${selectedGroupNumber} Details`}
        visible={groupModalVisible}
        onCancel={() => setGroupModalVisible(false)}
        footer={null}
        width={800}
      >
        {/* Display statistics at the top */}
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={8}>
            <Statistic
              title="Total Amount for Group"
              value={groupData.reduce((sum, po) => sum + po.totalAmount, 0)}
              precision={2}
              prefix="$"
              valueStyle={{ color: "#3f8600", fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Buyer"
              value={formatBuyerName(groupData[0]?.buyer)}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              size="small"
              title="Vendor"
              value={groupData[0]?.vendorName}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>

        {/* Display each PO in the group */}
        <Row gutter={[16, 16]}>
          {groupData.map((item) => (
            <Col span={12} key={item.id}>
              <Descriptions
                bordered
                size="small"
                column={1}
                title={`PO Number: ${item.poNumber}`}
                style={{ marginBottom: 16 }}
              >
                <Descriptions.Item label="Total Amount">
                  <PriceTag amount={item.totalAmount} />
                </Descriptions.Item>
                <Descriptions.Item label="Date Submitted">
                  {dayjs(item.orderedDate).format("MM/DD/YYYY")}
                </Descriptions.Item>
                <Descriptions.Item label="Required Date">
                  {dayjs(item.requiredDate).format("MM/DD/YYYY")}
                </Descriptions.Item>
                <Descriptions.Item label="Project">
                  {getProjectName(item.projectNumber, projects)}
                </Descriptions.Item>
                {/* Add more Descriptions.Item components as needed */}
              </Descriptions>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default PurchasingTable;
