// src/utils/bomHelpers.js

/**
 * Aggregates BOM items from the estimate.
 * @param {Object} estimate - The estimate object containing various items.
 * @returns {number} - The total count of unique BOM line items.
 */
export const aggregateBOMItems = (estimate) => {
  if (!estimate) return 0;

  const customItems = estimate.customItems || [];
  const modules = estimate.modules || [];
  const assemblies = estimate.assemblies || [];
  const ariaItems = estimate.ariaItems || [];

  // Function to normalize type
  const normalizeType = (type) =>
    type
      .trim()
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());

  // Aggregation logic similar to BOM component
  const assemblyItems = assemblies.flatMap((assemblyItem) =>
    (assemblyItem.assemblyItems || []).map((item) => ({
      ...item,
      price: parseFloat(item.price) || 0,
      quantity: item.quantity || 0,
      type: normalizeType("Assembly"),
    }))
  );

  const moduleItems = modules.flatMap((module) => {
    const moduleItemList = [];

    if (module.container) {
      moduleItemList.push({
        ...module.container,
        price: parseFloat(module.container.price) || 0,
        quantity: parseInt(module.container.quantity, 10) || 0,
        type: normalizeType("Module"),
      });
    }

    const materialItems = Object.values(module.selectedOptions || {}).flatMap(
      (option) =>
        (option.materialItems || []).map((materialItem) => {
          const defaultPrice = parseFloat(materialItem.price) || 0;

          return {
            ...materialItem,
            price: defaultPrice,
            quantity: materialItem.qtyToOrder || 0,
            type: normalizeType("Module"),
            category: materialItem.category || "",
            itemNumber: materialItem.itemNumber || "",
            itemDescription: materialItem.name || "",
          };
        })
    );

    moduleItemList.push(...materialItems);

    if (module.rainProtection) {
      const defaultPrice = parseFloat(module.rainProtection.price) || 0;

      moduleItemList.push({
        ...module.rainProtection,
        price: defaultPrice,
        quantity: module.rainProtection.quantity || 0,
        type: normalizeType("Module"),
        category: module.rainProtection.category || "",
        itemDescription: module.rainProtection.itemDescription || "",
      });
    }

    return moduleItemList;
  });

  const ariaItemsData = ariaItems.map((item) => ({
    ...item,
    price: parseFloat(item.price) || 0,
    quantity: parseInt(item.quantity, 10) || 0,
    type: normalizeType("Aria Item"),
  }));

  const customItemsData = customItems.map((item) => ({
    ...item,
    price: parseFloat(item.price) || 0,
    quantity: parseInt(item.quantity, 10) || 0,
    type: normalizeType("Custom Item"),
  }));

  const allItems = [
    ...customItemsData,
    ...ariaItemsData,
    ...assemblyItems,
    ...moduleItems,
  ];

  // Aggregate unique items based on itemNumber or id
  const itemMap = new Map();

  allItems.forEach((item) => {
    const key = item.itemNumber || item.id;
    if (itemMap.has(key)) {
      const existingItem = itemMap.get(key);
      existingItem.quantity += item.quantity || 0;
    } else {
      itemMap.set(key, { ...item });
    }
  });

  return itemMap.size;
};
