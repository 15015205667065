// src/Components/Manufacturing/AddLocationModal.jsx

import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import AddressAutocomplete from "../../Components/Google/AddressAutocomplete";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import { useUser } from "@clerk/clerk-react";

const AddLocationModal = ({ visible, onClose, onAdd, location }) => {
  const [form] = Form.useForm();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location) {
      // If editing, set form fields with existing location data
      form.setFieldsValue({
        nickname: location.nickname,
        address: location.address,
        googleAddress: {
          place_id: location.placeId,
          formatted_address: location.address,
          geometry: {
            location: {
              lat: () => location.latitude,
              lng: () => location.longitude,
            },
          },
        },
      });
    } else {
      // If adding, reset the form
      form.resetFields();
    }
  }, [location, form]);

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const { nickname, address, googleAddress } = values;

      // Ensure googleAddress is available
      if (!googleAddress || !googleAddress.place_id) {
        message.error("Please select a valid address from the suggestions.");
        setLoading(false);
        return;
      }

      const placeId = googleAddress.place_id;
      const latitude = googleAddress.geometry.location.lat();
      const longitude = googleAddress.geometry.location.lng();

      // Construct user information
      const userName = `${user.firstName} ${user.lastName}`;
      const userEmail = user.primaryEmailAddress.emailAddress;

      // Create the location object
      const locationData = {
        nickname,
        address: googleAddress.formatted_address,
        placeId,
        latitude,
        longitude,
        submittedBy: {
          userName,
          userEmail,
        },
      };

      let updatedLocation;

      if (location) {
        // Editing mode: Update the existing location
        updatedLocation = await updateById(
            TABLES.MANUFACTURING_LOCATIONS,
                      locationData,
          location.id,

        );
        message.success("Location updated successfully!");
      } else {
        // Adding mode: Add a new location
        updatedLocation = await addItemsToTable(
          TABLES.MANUFACTURING_LOCATIONS,
          locationData
        );
        message.success("Location added successfully!");
      }

      form.resetFields();
      onAdd(updatedLocation); // Update parent component's state
      onClose();
    } catch (error) {
      console.error("Error processing location:", error);
      message.error(`Failed to ${location ? "update" : "add"} location.`);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title={
        location ? "Edit Manufacturing Location" : "Add Manufacturing Location"
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="Nickname"
          name="nickname"
          rules={[{ required: true, message: "Please enter a nickname" }]}
        >
          <Input placeholder="Enter nickname" />
        </Form.Item>

        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: "Please select an address" }]}
        >
          <AddressAutocomplete
            form={form}
            value={form.getFieldValue("address")}
            onChange={(value) => form.setFieldsValue({ address: value })}
          />
        </Form.Item>

        {/* Hidden field to store googleAddress */}
        <Form.Item name="googleAddress" noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            {location ? "Update Location" : "Add Location"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLocationModal;
