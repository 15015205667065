import React, { useEffect, useState, useMemo } from "react";
import { SearchInput } from "../../Styled/SearchInput";
import { Col, Row, Button, Table, Tag, Drawer } from "antd";
import {
  CopyOutlined,
  PlusOutlined,
  StopOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { formatDate, formatMoney } from "../../Formatters/helpers";
import AddEstimateModal from "../../Components/Estimating/AddEstimateModal";
import DuplicateEstimateModal from "../../Components/Estimating/DuplicateEstimateModal";
import { COLORS } from "../../constants";
import { Link } from "react-router-dom";
import { useEstimates, useCustomers } from "../../Contexts/useSpecificData";
import HeaderText from "../../Styled/HeaderText";
import PriceTag from "../../Styled/PriceTag";
import { getCustomerName } from "../../Formatters/getCustomerName";
import EstimateTimeline from "../../Components/Estimating/Timeline/EstimateTimeline";

const Estimates = () => {
  const { data: estimates, loading, error, refresh } = useEstimates();
  const { data: customers } = useCustomers();

  const [searchText, setSearchText] = useState("");
  const [timelineDrawerVisible, setTimelineDrawerVisible] = useState(false); // Drawer visibility state
  const [selectedEstimate, setSelectedEstimate] = useState(null); // Selected estimate for the timeline
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false); // Duplicate modal visibility

  useEffect(() => {
    if (estimates) {
      console.log("Estimates Data:", estimates);
      document.title = `Estimates`;
    }
  }, [estimates]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredEstimates = useMemo(() => {
    if (!searchText) return estimates;

    const lowercasedSearchText = searchText.toLowerCase();

    return estimates.filter((estimate) => {
      const customerName =
        getCustomerName(estimate.customerId, customers)?.toLowerCase() || "";
      const id = estimate.estimateNumber?.toString().toLowerCase() || "";
      const estimateName = estimate.estimateName?.toLowerCase() || "";
      const description = estimate.estimateDescription?.toLowerCase() || "";
      const status = estimate.estimateStatus?.toLowerCase() || "";
      const saleAmount =
        estimate.costs?.saleAmount?.toString().toLowerCase() || "";

      return (
        customerName.includes(lowercasedSearchText) ||
        id.includes(lowercasedSearchText) ||
        estimateName.includes(lowercasedSearchText) ||
        description.includes(lowercasedSearchText) ||
        status.includes(lowercasedSearchText) ||
        saleAmount.includes(lowercasedSearchText)
      );
    });
  }, [searchText, estimates, customers]);

  const statusFilters = useMemo(() => {
    const statuses = estimates.map((est) => est.estimateStatus).filter(Boolean);
    const uniqueStatuses = Array.from(new Set(statuses));
    return uniqueStatuses.map((status) => ({ text: status, value: status }));
  }, [estimates]);


  const columns = [
    {
      title: "Created Date",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      render: (text) => formatDate(text, true),
    },
    {
      title: "ID",
      dataIndex: "estimateNumber",
      key: "estimateNumber",
      sorter: (a, b) => a.estimateNumber.localeCompare(b.estimateNumber),
    },
    {
      title: "Customer",
      dataIndex: "customerId",
      key: "customerId",
      render: (text, record) => getCustomerName(record.customerId, customers),
    },
    {
      title: "Description",
      dataIndex: "estimateDescription",
      key: "estimateDescription",
      sorter: (a, b) =>
        a.estimateDescription.localeCompare(b.estimateDescription),
      render: (text, record) => (
        <Link to={`/estimating/estimates/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Quote By",
      key: "quoteBy",
      dataIndex: "quoteBy",
      sorter: (a, b) => new Date(a.quoteBy) - new Date(b.quoteBy),
      render: (text) => formatDate(text),
    },
    {
      title: "Status",
      dataIndex: "estimateStatus",
      key: "estimateStatus",
      filters: statusFilters,
      onFilter: (value, record) => record.estimateStatus === value,
      render: (status) => {
        const normalizedStatus = status.toLowerCase();
        let color;

        if (normalizedStatus === "needs quote") {
          color = "red";
        } else if (normalizedStatus === "re-quote needed") {
          color = "gold";
        } else {
          color = "green";
        }

        return <Tag color={color}>{status}</Tag>;
      },
    },

    {
      title: "Sale Amount",
      key: "saleAmount",
      sorter: (a, b) => (a.costs?.saleAmount || 0) - (b.costs?.saleAmount || 0),
      render: (text, record) => {
        const saleAmount = record?.costs?.saleAmount;
        return saleAmount != null ? (
          <PriceTag amount={saleAmount} />
        ) : (
          <StopOutlined />
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          type="link"
          icon={<ClockCircleOutlined />}
          onClick={() => {
            setSelectedEstimate(record); // Set the selected estimate
            setTimelineDrawerVisible(true); // Open the drawer
          }}
        >
          View Timeline
        </Button>
      ),
    },
  ];

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HeaderText text="Estimates" />
        </Col>
        <Col span={8} />
        <Col align="right" span={8}>
          <DuplicateEstimateModal onAddSuccess={refresh}>
            <Button
              style={{ marginRight: 16 }}
              icon={<CopyOutlined />}
              onClick={() => setDuplicateModalVisible(true)}
            >
              Duplicate Estimate
            </Button>
          </DuplicateEstimateModal>
          <AddEstimateModal onAddSuccess={refresh}>
            <Button type="primary" icon={<PlusOutlined />}>
              Add Estimate
            </Button>
          </AddEstimateModal>
        </Col>
        <Col span={24}>
          <SearchInput
            placeholder="Search Estimates"
            value={searchText}
            onChange={handleSearch}
          />
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={filteredEstimates}
            size="small"
            rowKey="id"
          />
        </Col>
      </Row>

      {/* Timeline Drawer */}
      <Drawer
        title={`Timeline for Estimate #${selectedEstimate?.estimateNumber}`}
        placement="right"
        width={600}
        onClose={() => setTimelineDrawerVisible(false)}
        visible={timelineDrawerVisible}
      >
        {selectedEstimate && <EstimateTimeline dynamicAlign estimate={selectedEstimate} />}
      </Drawer>
    </>
  );
};

export default Estimates;
